<script>
  export let company;

  import InputEmail from "../../Lib/Inputs/InputEmail.svelte";
  import PortalApiServer from "../../Server/PortalApiServer";
  import { toastNotification } from "../../Stores/toastNotification";
  import PortalHeader from "./_PortalHeader.svelte";
  import HappyIcon from "../../lib/Icons/HappyIcon.svelte";

  let passwordreset = {};
  let passwordResetSuccess = false;

  function handlePasswordResetRequest() {
    passwordreset.companyID = company.id;
    PortalApiServer.PostAnonymous(
      "/api/portal/portallogin/resetpasswordrequest",
      passwordreset
    ).then((data) => {
      if (data.success) {
        passwordResetSuccess = true;
        toastNotification.Success(
          "Email sent. Check your email for more information."
        );
      } else {
        passwordResetSuccess = false;
        toastNotification.Error(
          "Error sending the email. Please provide a valid email address. " +
            data.message
        );
      }
    });
  }
</script>

<main class="container-fluid container-sm body-content">
  {#if company}
    <PortalHeader {company} />
  {/if}
  <div class="row">
    <div class="col-lg-6 mx-auto">
      <h1>Sign In Help</h1>
      {#if !passwordResetSuccess}
        <div class="card">
          <div class="card-body ">
            <div class="card-text">
              <form on:submit|preventDefault={handlePasswordResetRequest}>
                <div class="row">
                  <div class="col">
                    <InputEmail
                      label="Enter your email address used during Sign Up"
                      bind:value={passwordreset.email}
                      required={true}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    If we find an account that matches this email we will send
                    instructions on how to reset your password.
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <br />
                    <div class="primary-nav">
                      <span />
                      <button type="submit" class="btn btn-danger">
                        Send Help
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      {:else}
        <div class="card">
          <div class="card-body ">
            <div class="card-text">
              <h3><HappyIcon /> Check your email!</h3>

              <p>
                If your account information matches you will receive an email
                with more details on how to reset your password.
              </p>
            </div>
          </div>
        </div>
      {/if}
    </div>
  </div>
  <p />
  <div class="row">
    <div class="col-lg-6 mx-auto">
      <a href="/portal?c={company.name}">Back to Sign In</a>
    </div>
  </div>
</main>

<style>
  .card {
    margin: 0 auto; /* Added */
    float: none; /* Added */
    margin-bottom: 10px; /* Added */
  }
  .primary-nav {
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
  }
</style>
