<script>
  export let job;
  export let jobOptions;

  import { createEventDispatcher, onMount } from "svelte";
  import ImageSelectList from "../../../Lib/Images/ImageSelectList.svelte";
  import JobCloseOptions from "./JobCloseOptions.svelte";
  const dispatch = createEventDispatcher();
  let stopStatus;
  let altStopStatus;

  let urls = {
    getJobImages: `/api/Job/images?jobID=`,
  };

  function handleShowCloseJob() {
    dispatch("showCloseJobEvent");
  }

  function handleImageSelectionChange(event) {
    let imageList = event.detail;
    job.imageUrls = [];
    imageList.forEach((image) => {
      job.imageUrls.push(image.sourceURL);
    });
  }

  function handleJobClose() {
    if (altStopStatus) stopStatus = altStopStatus;
    job.closedStatusMessage = stopStatus;
    dispatch("closeJob", job);
  }

  onMount(async () => {
    stopStatus = jobOptions.jobCloseOptions[0];
  });
</script>

<div class="row">
  <div class="col">
    <label for="stopStatus">Status</label>
    <JobCloseOptions
      jobCloseOptions={jobOptions.jobCloseOptions}
      bind:stopStatus
    />

    {#if stopStatus == "Other"}
      <input
        type="text"
        maxlength="25"
        bind:value={altStopStatus}
        class="form-control"
      />
    {/if}
  </div>
</div>

<div class="row">
  <div class="col">
    <p>Select the images you want to include in the job notification:</p>
    <ImageSelectList
      relatedID={job.id}
      fetchImagesURL={urls.getJobImages}
      folder="Jobs"
      on:imageSelectionChange={handleImageSelectionChange}
    />
  </div>
</div>

<div class="row">
  <div class="col">
    <button class="btn btn-sm btn-outline-warning" on:click={handleShowCloseJob}
      >Cancel</button
    >
  </div>
  <div class="col">
    <button class="btn btn-sm btn-outline-danger" on:click={handleJobClose}
      >Close Job</button
    >
  </div>
</div>

<style>
  .col {
    padding: 5px;
    margin: 2px;
  }
</style>
