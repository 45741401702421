<script>
  import ButtonCancel from "../../../Lib/Buttons/ButtonCancel.svelte";
  import { Offcanvas } from "sveltestrap";
  import ButtonLink from "../../../Lib/Buttons/ButtonLink.svelte";
  import { toastNotification } from "../../../Stores/toastNotification";
  import ApiServer from "../../../Server/ApiServer";
  import { onMount } from "svelte";
  import SettingControl from "./SettingControl.svelte";
  import ButtonSave from "../../../Lib/Buttons/ButtonSave.svelte";
  import InputSwitch from "../../../Lib/Inputs/InputSwitch.svelte";
  import InputNumberBox from "../../../Lib/Inputs/InputNumberBox.svelte";

  let isOpen = false;
  let companyTax;

  let urls = {
    getCompanyTax: "/api/Company/Settings/GeneralSettings/companytax",
    updateCompanyTax: "/api/Company/Settings/GeneralSettings/companytax",
  };

  const openScreen = () => (isOpen = !isOpen);

  function loadSettings() {
    ApiServer.Get(urls.getCompanyTax).then((data) => {
      companyTax = data;
    });
  }

  function saveSettings() {
    ApiServer.Put(urls.updateCompanyTax, companyTax).then((data) => {
      toastNotification.Success("Tax settings saved!");
      isOpen = false;
    });
  }
  onMount(async () => {
    loadSettings();
  });
</script>

<ButtonLink text="Tax Settings" callback={openScreen} />

<Offcanvas header="Tax Settings" {isOpen} toggle={openScreen} placement="end">
  {#if companyTax}
    <div class="row">
      <div class="col">
        <p>Set your tax settings below.</p>

        <div class="row">
          <div class="col">
            <InputSwitch
              text="Enable Taxes"
              label="Enable to collect taxes"
              bind:value={companyTax.collectTax}
            />
          </div>
          <div class="col">
            <InputNumberBox label="Tax Rate" bind:value={companyTax.taxRate} />
          </div>
        </div>
      </div>
    </div>
  {/if}
  <p>
    <ButtonCancel text="Close" callback={openScreen} />
    <ButtonSave text="Save" callback={saveSettings} />
  </p>
</Offcanvas>
