<script>
  export let accountId;

  import { onMount } from "svelte";
  import Header from "./AccountHeader.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import PrivateRoute from "../../../Lib/Authentication/PrivateRoute.svelte";
  import Notes from "./Notes/Notes.svelte";
  import Jobs from "../Jobs/Jobs.svelte";
  import Job from "../Jobs/Job/Job.svelte";
  import AccountDetail from "./AccountDetail.svelte";
  import Billing from "./Billing.svelte";
  import AccountEdit from "./Edit/AccountEdit.svelte";
  import Portal from "./Portal/Portal.svelte";
  import PageTitle from "../../../Lib/PageTitle.svelte";
  import Messages from "./Messages/Messages.svelte";

  let account;

  let urls = {
    account: `/api/Account/Account/account?accountID=${accountId}`,
  };

  onMount(async () => {
    fetchAccount();
  });

  function fetchAccount() {
    ApiServer.Get(urls.account).then((data) => {
      account = data;
    });
  }
</script>

{#if account}
  <PageTitle title="{account.fullName} Detail" />

  <div class="account-control">
    <Header {account} />

    <PrivateRoute path="/notes" roles={["Admin"]}>
      <Notes {account} />
    </PrivateRoute>

    <PrivateRoute path="/billing" roles={["Admin"]}>
      <Billing {account} />
    </PrivateRoute>

    <!-- <PrivateRoute path="/edit/:id" roles={["Admin"]} let:params>
      <AccountEdit accountId={params.id} />
    </PrivateRoute> -->

    <PrivateRoute path="/jobs" roles={["Admin"]}>
      <Jobs {account} />
    </PrivateRoute>

    <PrivateRoute path="/messages" roles={["Admin"]}>
      <Messages {account} />
    </PrivateRoute>

    <PrivateRoute path="/jobs/:id/*" roles={["Admin"]} let:params>
      <Job jobID={params.id} accountID={account.id} />
    </PrivateRoute>

    <PrivateRoute path="/portal" roles={["Admin"]}>
      <Portal {account} />
    </PrivateRoute>

    <PrivateRoute path="/" roles={["Admin"]}>
      <AccountDetail {account} />
    </PrivateRoute>
  </div>
{:else}
  <h2>Loading....</h2>
{/if}
