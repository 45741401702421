<script>
  import InputTextboxInline from "../../Lib/Inputs/InputTextboxInline.svelte";

  let account = {};
</script>

<div class="row top justify-content-center">
  <div class="col-md-6">
    <div class="card">
      <div class="card-body ">
        <div class="card-title">
          <header class="card-header" style="line-height: 50px">
            <h3 style="display: inline-block" class="card-title mt-2">
              company.name Checkout
            </h3>
          </header>
        </div>
        <div class="card-text">
          <form>
            <h5>Contact Information</h5>
            <hr />
            <InputTextboxInline
              label="First Name"
              bind:value={account.firstName}
              required={true}
            />
            <InputTextboxInline
              label="Last Name"
              bind:value={account.lastName}
              required={true}
            />
            <InputTextboxInline
              label="Email Address"
              bind:value={account.email}
              required={true}
            />
            <InputTextboxInline
              label="Mobile Phone"
              bind:value={account.phoneNumber}
              required={true}
              help="We send Notifications via text message."
            />

            <h5>Service Address</h5>
            <hr />
            Address fields here...

            <h5>Access</h5>
            <hr />
            <InputTextboxInline
              label="Gate Code"
              bind:value={account.gateCode}
              required={true}
            />
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">Guard Gate</div>
              <div class="col-sm-10">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="gridCheck1"
                    checked={account.hasGuardGate}
                    on:change={(e) => {
                      account.hasGuardGate = e.target.checked;
                    }}
                  />
                  <label class="form-check-label" for="gridCheck1">
                    My Community has a guard gate
                  </label>
                </div>
              </div>
            </div>

            {#if account.hasGuardGate}
              <div class="form-group row">
                <div class="col-sm-2 col-form-label">Time Restriction</div>

                <div class="col-sm-10">
                  <select
                    id="vendorTimeRestriction"
                    bind:value={account.vendorTimeRestriction}
                    class="form-control"
                  >
                    <option value="None">None</option>
                    <option value="5PM">5 PM</option>
                    <option value="6PM">6 PM</option>
                    <option value="7PM">7 PM</option>
                    <option value="8PM">8 PM</option>
                  </select>
                  <small class="form-text text-muted">
                    What time does your community prevent vendors from entering.
                  </small>
                </div>
              </div>
            {/if}

            <div class="form-group row">
              <div class="col-sm-2 col-form-label">Special Instructions</div>
              <div class="col-sm-10">
                <textarea
                  rows="2"
                  id="specialInstructions"
                  class="form-control"
                  bind:value={account.specialInstructions}
                />
                <small class="form-text text-muted">
                  Any information that will help us find your house or your
                  bins.
                </small>
              </div>
            </div>

            <h5>Order Summary</h5>
            <hr />

            <div class="form-group row">
              <div class="col-sm-2 col-form-label">Plan</div>
              <div class="col-sm-10">
                <!-- <select
                  id="planType"
                  bind:value={selectedBillingPlan}
                  class="form-control"
                  required
                >
                  <option value="">--Select a plan--</option>
                  {#each billingPlans as plan}
                    <option value={plan}>{plan.name}</option>
                  {/each}
                </select>
                <small class="form-text text-muted">
                  {#if selectedBillingPlan}
                    {selectedBillingPlan.description
                      ? selectedBillingPlan.description
                      : ""}
                  {/if}
                </small> -->
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-2 col-form-label">Trash Day</div>
              <div class="col-sm-10">Select trash day</div>
            </div>

            <div class="form-group row">
              <div class="col-sm-2 col-form-label">Number of Bins</div>
              <div class="col-sm-10">Select Bins</div>
            </div>

            <div class="form-group row">
              <div class="col">
                <div
                  style="border: 1px solid black; padding: 5px; border-radius: 5px"
                >
                  Order Details - Monthly Plan
                  <hr />

                  Monday pickup with 2 Bins<br />
                  Tuesday pickup with 3 Bins <br />

                  Total due today: $24.00
                  <br />
                  Total due every 1 month: $24.99
                  <br />
                </div>
              </div>
            </div>
            <br /><br />
            <h5>Service Agreement</h5>
            <hr />

            <div class="form-group row">
              <div class="col-sm-2 col-form-label">Service Agreement</div>
              <div class="col-sm-10">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="gridCheck1"
                    checked={account.agree}
                    on:change={(e) => {
                      account.agree = e.target.checked;
                    }}
                  />
                  <label class="form-check-label" for="gridCheck1">
                    I have read and agree to the service agreement
                  </label>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-10">
                <button type="submit" class="btn btn-primary"
                  >Preview Order</button
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
