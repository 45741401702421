<script>
  export let job;
  import DateFormat from "../../../../Lib/DateFormat.svelte";
</script>

{#if job.id == "new"}
  <h5>New Job</h5>
{:else}
  <div class="same-line">
    <h5>
      <DateFormat convertTimeZone={false} date={job.jobDate} />
      - {job.status}/{job.paymentStatus}
    </h5>
    <h5>Job:{job.jobNumber}</h5>
  </div>
{/if}
