<script>
  import { onMount } from "svelte";
  import { link } from "svelte-navigator";
  import DateFormat from "../../Lib/DateFormat.svelte";
  import Money from "../../Lib/Money.svelte";
  import ApiServer from "../../Server/ApiServer";

  let urls = {
    invoicelist: "/api/Company/SystemBilling/invoices",
  };

  let invoiceList;

  onMount(async () => {
    ApiServer.Get(urls.invoicelist).then((data) => {
      invoiceList = data;
    });
  });
</script>

<h4>Past Billing Periods</h4>
{#if invoiceList}
  <div class="row bb-header">
    <div class="col">Invoice Number</div>
    <div class="col">Date</div>
    <div class="col">Period</div>
    <div class="col">Amount</div>
    <div class="col">Status</div>
  </div>
  {#each invoiceList as invoice}
    <div class="row bb-row">
      <div class="col">
        <a href="/systembilling/invoice/{invoice.id}" use:link>
          #{invoice.invoiceNumber}
        </a>
      </div>
      <div class="col">
        <DateFormat date={invoice.dateBilled} />
      </div>
      <div class="col">
        {invoice.month}/{invoice.year}
      </div>
      <div class="col">
        <Money amount={invoice.totalBill} />
      </div>
      <div class="col">
        {invoice.paymentStatus}
      </div>
    </div>
  {/each}
{/if}
