<script>
  export let message = "";
  export let icon = null;
</script>

<div class="task-card">
  <div class="row">
    <div class="col">
      {#if icon}
        <span style="font-size: x-large;">
          <svelte:component this={icon} />
        </span>
      {/if}
      {message}
    </div>
  </div>
</div>

<style>
  /* .task-card {
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 16px;
  } */

  .task-card {
    display: flex;
    justify-content: left;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 16px;
  }
</style>
