<script>
  export let job;
  export let showModal = false;

  import Modal from "sv-bootstrap-modal";
  import { useNavigate } from "svelte-navigator";
  const navigate = useNavigate();

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import ButtonCancel from "../../../../Lib/Buttons/ButtonCancel.svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import { user } from "../../../../Stores/stores";
  import { toastNotification } from "../../../../Stores/toastNotification";

  let urls = {
    reopenjob: `/api/Job/reopenjob`,
  };

  function handleJobReopen() {
    let openJobRequest = {
      jobID: job.id,
      userName: $user.fullName,
    };

    ApiServer.Post(urls.reopenjob, openJobRequest).then((data) => {
      toastNotification.Success("Job Opened!");
      showModal = false;
      dispatch("afterStatusChange");
    });
  }
</script>

<Modal bind:open={showModal}>
  <div class="modal-header">
    <h5 class="modal-title">Reopening Job {job.jobNumber}</h5>
    <button type="button" class="close" on:click={() => (showModal = false)}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">Are you sure you want to reopen this job?</div>
  <div class="modal-footer">
    <ButtonCancel text="Cancel" callback={() => (showModal = false)} />
    <button
      type="button"
      class="btn btn-outline-primary"
      on:click={handleJobReopen}>Reopen Job</button
    >
  </div>
</Modal>
