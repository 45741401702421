<script>
  export let job;
  export let showModal = false;

  import Modal from "sv-bootstrap-modal";
  import { useNavigate } from "svelte-navigator";
  const navigate = useNavigate();

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import { user } from "../../../../Stores/stores";
  import ButtonCancel from "../../../../Lib/Buttons/ButtonCancel.svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import { toastNotification } from "../../../../Stores/toastNotification";
  import PaymentRefundList from "./_PaymentRefundList.svelte";

  let cancelReason;
  let altCancelReason = "";
  let jobPayments;
  let refundOption = "norefund";
  let refundedPayments;
  let cancelMessage = "";

  let urls = {
    cancelJob: `/api/Job/canceljob`,
    jobPayments: `/api/Job/jobpayments?jobID=`,
  };

  function handleJobCancel() {
    if (!altCancelReason) cancelReason = altCancelReason;

    let cancelJobRequest = {
      jobID: job.id,
      userName: $user.fullName,
      cancelReason: cancelReason,
    };

    cancelJobRequest.paymentsToRefund = refundedPayments;

    ApiServer.Post(urls.cancelJob, cancelJobRequest).then((data) => {
      if (data.success) {
        toastNotification.Success("Job Canceled");
        showModal = false;
        dispatch("afterStatusChange");
      } else {
        toastNotification.Success("Job Cancel failed. " + data.message);
        cancelMessage = data.message;
      }
    });
  }

  function getJobSuccessfulPayments() {
    //Trying to be efficent and not load these too much.  Might bite in booty later... 1/23/22
    if (!jobPayments) {
      ApiServer.Get(urls.jobPayments + job.id).then((data) => {
        jobPayments = data;
      });
    }
  }

  $: showModal, getJobSuccessfulPayments();
</script>

{#if job}
  <Modal bind:open={showModal}>
    <div class="modal-header">
      <h5 class="modal-title">Cancel Job {job.jobNumber}</h5>
      <button type="button" class="close" on:click={() => (showModal = false)}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <PaymentRefundList
        bind:jobPayments
        bind:refundOption
        bind:refundedPayments
      />

      <p>Please enter a cancelation reason</p>
      <p>
        <select bind:value={cancelReason} class="form-control">
          <option>Customer canceled the job</option>
          <option>Unable to complete the job</option>
          <option>Job moved to another day</option>
          <option>Other</option>
        </select>
      </p>
      {#if cancelReason == "Other"}
        <input
          type="text"
          maxlength="25"
          bind:value={altCancelReason}
          class="form-control"
        />
      {/if}

      {#if cancelMessage}
        <p>
          {cancelMessage}
        </p>
      {/if}
    </div>
    <div class="modal-footer">
      <ButtonCancel text="Cancel" callback={() => (showModal = false)} />
      <button
        type="button"
        class="btn btn-outline-primary"
        on:click={handleJobCancel}>Cancel Job</button
      >
    </div>
  </Modal>
{/if}
