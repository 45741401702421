<script>
  export let accountRequestID;

  import { onMount } from "svelte";
  import { useNavigate } from "svelte-navigator";

  import PickupDays from "../Controls/Checkout/PickupDays.svelte";
  import Money from "../Lib/Money.svelte";
  import ApiServer from "../Server/ApiServer";
  import AddressAutocomplete from "../Lib/AddressAutocomplete/Autocomplete.svelte";
  import { toastNotification } from "../Stores/toastNotification";
  import ButtonDelete from "../Lib/Buttons/ButtonDelete.svelte";
  import PageTitle from "../Lib/PageTitle.svelte";
  import OrderSummary from "../Controls/Checkout/OrderSummary.svelte";

  const navigate = useNavigate();

  let urls = {
    getRequest: `/api/Account/Account/checkoutrequest?accountRequestID=${accountRequestID}`,
    saveRequest: `/api/Account/Account/checkoutrequest`,
    removeRequest: `/api/Account/Account/checkoutrequest?accountRequestID=${accountRequestID}`,
    getSources: `/api/public/Checkout/sources?companyID=`,
    companytax: `/api/Public/Checkout/companytax?companyID=`,
    getBillingPlans: `/api/public/Checkout/billingplans?companyID=`,
    checkoutrules: `/api/Public/Checkout/checkoutrules?companyID=`,
  };

  let checkoutRequest;
  let selectedBillingPlan;
  let totalCost;
  let totalTax;
  let totalBins;
  let companyTax;
  let recurringCost;
  let checkoutRules;
  let billingPlans = [];
  let sameDayPickup = true;
  let sources = [];

  function handlePickupDayResult(event) {
    checkoutRequest.pickupDays = event.detail;
  }

  function deleteCheckoutRequest() {
    ApiServer.Delete(urls.removeRequest).then((data) => {
      toastNotification.Success("Account Request Deleted");
      navigate("/inbox");
    });
  }

  function processcheckoutrequest() {
    ApiServer.Post(urls.saveRequest, checkoutRequest).then((data) => {
      toastNotification.Success(
        `Account created for ${checkoutRequest.account.fullName}`
      );
      navigate("/inbox");
    });
  }

  function setSources() {
    ApiServer.GetAnonymous(urls.getSources + checkoutRequest.companyID).then(
      (data) => {
        sources = data;
      }
    );
  }

  function setBillingPlans() {
    ApiServer.GetAnonymous(
      urls.getBillingPlans + checkoutRequest.companyID
    ).then((data) => {
      billingPlans = data;
      billingPlans.forEach((billingPlan) => {
        if (
          billingPlan.id.toLowerCase() ==
          checkoutRequest.billingPlanID.toLowerCase()
        ) {
          selectedBillingPlan = billingPlan;
        }
      });
    });
  }

  onMount(async () => {
    checkoutRequest = await ApiServer.Get(urls.getRequest);

    var rules = await ApiServer.GetAnonymous(
      urls.checkoutrules + checkoutRequest.companyID
    );
    checkoutRules = rules.checkoutRule;

    sameDayPickup = checkoutRequest.pickupDays.length == 1 ? true : false;

    companyTax = await ApiServer.GetAnonymous(
      urls.companytax + checkoutRequest.companyID
    );

    setSources();
    setBillingPlans();
  });
</script>

<PageTitle title="Signup Review" />

{#if checkoutRequest}
  <h1>Review Sign Up</h1>

  <article class="card-body">
    <div class="row">
      <div class="col form-group">
        <p class="planLabel" />
        <p class="planPriceLabel" />
      </div>
    </div>
    <form id="customer-form" on:submit|preventDefault={processcheckoutrequest}>
      <div class="row">
        <div class="col">
          <h4>Contact Information</h4>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <label for="firstName">First Name *</label>
          <input
            type="text"
            id="firstName"
            class="form-control"
            required
            bind:value={checkoutRequest.account.firstName}
          />
          <div class="invalid-feedback">First Name is required!</div>
        </div>

        <div class="col form-group">
          <label for="lastName">Last Name *</label>
          <input
            type="text"
            id="lastName"
            class="form-control"
            required
            bind:value={checkoutRequest.account.lastName}
          />
          <div class="invalid-feedback">Last Name is required!</div>
        </div>
      </div>

      <div class="row">
        <div class="col form-group">
          <label for="email">Email Address *</label>
          <input
            type="email"
            id="email"
            class="form-control"
            bind:value={checkoutRequest.account.email}
            required
          />
          <div class="invalid-feedback">Email is required!</div>
        </div>
        <div class="col form-group">
          <label for="phoneNumber">Mobile Phone *</label>
          <input
            type="text"
            id="phoneNumber"
            class="form-control"
            bind:value={checkoutRequest.account.phoneNumber}
            required
          />
          <div class="invalid-feedback">Mobile phone is required!</div>
        </div>
      </div>

      <AddressAutocomplete
        bind:address={checkoutRequest.account.address}
        bind:unit={checkoutRequest.account.addressUnit}
        bind:city={checkoutRequest.account.city}
        bind:state={checkoutRequest.account.state}
        bind:zip={checkoutRequest.account.zip}
        companyID={checkoutRequest.companyID}
      />

      <div class="row">
        <div class="col form-group">
          <label for="gateCode">Gate Code</label>
          <input
            type="text"
            id="gateCode"
            bind:value={checkoutRequest.account.gateCode}
            class="form-control"
          />
        </div>

        <div class="col form-group">
          <label for="hasGuardGate">Guard Gate</label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="hasGuardGate"
              checked={checkoutRequest.account.hasGuardGate}
              on:change={(e) => {
                checkoutRequest.account.hasGuardGate = e.target.checked;
              }}
            />
            <label class="form-check-label" for="isGuardGate">
              My community has a guard gate
            </label>
          </div>
        </div>
      </div>
      {#if checkoutRequest.account.hasGuardGate}
        <div class="row" id="vendorTimeRestrictionControl">
          <div class="col form-group">
            <label for="vendorTimeRestriction">Vendor Time Restriction</label>
            <select
              id="vendorTimeRestriction"
              bind:value={checkoutRequest.account.vendorTimeRestriction}
              class="form-control"
            >
              <option value="None">None</option>
              <option value="5PM">5 PM</option>
              <option value="6PM">6 PM</option>
              <option value="7PM">7 PM</option>
              <option value="8PM">8 PM</option>
            </select>
            <small
              id="vendorTimeRestrictionNotification"
              class="form-text text-muted"
            >
              What time does your community prevent Vendors from entering.
            </small>
          </div>
        </div>
      {/if}

      <div class="row">
        <div class="col form-group">
          <label for="specialInstructions">Special Instructions</label>
          <textarea
            rows="2"
            id="specialInstructions"
            class="form-control"
            bind:value={checkoutRequest.account.specialInstructions}
          />
          <small class="form-text text-muted">
            Any information that will help us find your house or your bins.
          </small>
        </div>
      </div>

      <div class="row">
        <div class="col form-group">
          <label for="source">How did you hear about us?</label>
          <select
            class="form-control"
            id="source"
            bind:value={checkoutRequest.account.acquisitionSource}
          >
            {#each sources as source}
              <option value={source.source}>{source.source}</option>
            {/each}
          </select>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col">
          <h4>Billing Plan</h4>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <label for="planType">Select a Plan</label>
          <select
            id="planType"
            bind:value={selectedBillingPlan}
            class="form-control"
            required
          >
            <option value="">--Select a plan--</option>
            {#each billingPlans as plan}
              <option value={plan}>{plan.name}</option>
            {/each}
          </select>
          <small class="form-text text-muted">
            {#if selectedBillingPlan}
              {selectedBillingPlan.description
                ? selectedBillingPlan.description
                : ""}
            {/if}
          </small>
          <div class="invalid-feedback">Plan is required!</div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <h4>Trash/Recycling Pickup Schedule</h4>
        </div>
      </div>

      {#if checkoutRules}
        <PickupDays
          billingPlan={selectedBillingPlan}
          bind:totalCost
          bind:totalTax
          bind:totalBins
          bind:recurringCost
          {companyTax}
          {checkoutRules}
          bind:sameDayPickup
          loadedPickupDays={checkoutRequest.pickupDays}
          on:pickupDayResult={handlePickupDayResult}
        />
      {/if}
      <hr />

      <OrderSummary
        planName={selectedBillingPlan?.name}
        {totalCost}
        {totalTax}
        {totalBins}
      />

      <!-- {#if totalCost}
        <div class="row">
          <div class="col form-group">
            <h4>Total Cost <Money amount={totalCost} /></h4>
          </div>
        </div>
      {/if} -->

      <hr />
      <button class="btn btn-success float-right">
        <span id="button-text">Approve Checkout Request</span>
      </button>
      <ButtonDelete text="Delete Request" callback={deleteCheckoutRequest} />
    </form>
  </article>
{/if}

<style>
</style>
