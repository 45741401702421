<script>
  export let accountMessage;
  import { Badge } from "sveltestrap";
  import DateFormat from "../../Lib/DateFormat.svelte";
  import PhoneNumber from "../../Lib/PhoneNumber.svelte";
</script>

<div>
  <div class="same-line">
    <div class="title">{accountMessage.accountName}</div>
    <DateFormat date={accountMessage.lastMessageDate} />
    {#if accountMessage.hasUnread}
      <div class="new-icon">
        <Badge color="danger">New!</Badge>
      </div>
    {/if}
  </div>
  <div class="phonenumber">
    <PhoneNumber number={accountMessage.accountPhoneNumber} />
  </div>
  <div>{accountMessage.lastMessage}</div>
</div>

<style>
  .new-icon {
    display: -webkit-flex;
    display: flex;
    justify-content: flex-start;
  }

  .title {
    font-weight: bolder;
    font-size: large;
  }
  .phonenumber {
    font-weight: bolder;
  }
</style>
