<script>
  export let message;

  import ButtonLink from "../../../../Lib/Buttons/ButtonLink.svelte";
  import DateFormat from "../../../../Lib/DateFormat.svelte";
  import MessageDetailModal from "../../../Messenger/MessageDetailModal.svelte";
  import MessageBadge from "./_MessageBadge.svelte";

  let selectedMessage;
  let showDetailModal = false;

  function showMessageDetailModal(message) {
    selectedMessage = message;
    showDetailModal = true;
  }
</script>

<div class="row message-card">
  <div class="col-sm-2">
    <div class="message-sender">
      <ButtonLink
        text={message.fromName}
        callback={() => showMessageDetailModal(message)}
      />
    </div>
  </div>
  <div class="col-sm-8">
    <div class="message-body">
      <MessageBadge messageType={message.messageType} />
      {#if message.messageType == "EMAIL"}
        {message.subject}
      {:else}
        {message.body}
      {/if}
    </div>
  </div>
  <div class="col-sm-2">
    <div>
      <DateFormat date={message.dateAdded} formatType="date-time-short" />
      <br />
      <DateFormat date={message.dateAdded} formatType="timeago" />
    </div>
  </div>
</div>

<MessageDetailModal
  bind:message={selectedMessage}
  bind:show={showDetailModal}
/>

<style>
  .message-body {
    margin-left: 10px;
  }

  .message-card {
    background-color: #f6f9fb;
    color: #676778;
    border-radius: 5px;
    margin: 10px;
    padding: 5px;
  }
</style>
