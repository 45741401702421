<script>
  import { onMount } from "svelte";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import ApiServer from "../../../../Server/ApiServer";
  import InputNumberBox from "../../../../Lib/Inputs/InputNumberBox.svelte";
  import InputTextBox from "../../../../Lib/Inputs/InputTextBox.svelte";
  import ButtonConfirmAction from "../../../../Lib/Buttons/ButtonConfirmAction.svelte";
  import PhoneNumber from "../../../../Lib/PhoneNumber.svelte";
  import ButtonSave from "../../../../Lib/Buttons/ButtonSave.svelte";
  import { toastNotification } from "../../../../Stores/toastNotification";
  import FormatChecker from "../../../../Util/FormatChecker";

  let urls = {
    search: `/api/Company/Settings/NotificationNumber/searchnumbers`,
    purchase: `/api/Company/Settings/NotificationNumber/purchasenumber`,
    getcompanynumber: `/api/Company/Settings/NotificationNumber/getcompanynumber`,
    updatecompanynumber: `/api/Company/Settings/NotificationNumber/updatecompanynumber?phonenumber=`,
    mynumber: "/api/Messenger/myphonenumber",
  };

  let numbers;
  let areaCode;
  let numberSearch;
  let errorMessage;
  let myphonenumber;
  let companyForwardNumber;

  function confirmMakePurchase(selectedNumber) {
    let request = {
      phoneNumber: selectedNumber,
    };
    let message =
      "One last check, are you really really sure you want to get this number?";
    if (confirm(message)) {
      ApiServer.Post(urls.purchase, request).then((data) => {
        if (data.success) {
          errorMessage =
            "Phone number reservation success: " +
            selectedNumber +
            " is your new phone number!";

          dispatch("onPhoneNumberSelected");
        } else {
          errorMessage = "Phone number reservation failed: " + data.message;
        }
      });
    }
  }

  function updateCompanyNumber() {
    if (!companyForwardNumber) {
      alert("Please provide a forward to phone number.");
      return;
    }

    if (!FormatChecker.PhoneNumberIsValid(companyForwardNumber)) {
      alert(
        "The phone number entered is invalid. Please make sure to use 10 digits."
      );
      return;
    }

    ApiServer.Get(urls.updatecompanynumber + companyForwardNumber).then(
      (data) => {
        toastNotification.Success("Company forward phone number updated.");
        //This will update the Tutorial and save the progress
        dispatch("onPhoneNumberSelected");
      }
    );
  }

  function searchNumber() {
    let request = { areaCode: areaCode, numberSearch: numberSearch };

    numbers = null;
    errorMessage = "";

    ApiServer.Post(urls.search, request).then((data) => {
      if (data.success) {
        numbers = data.phoneNumbers;
      } else {
        errorMessage = data.message;
      }
    });
  }
  onMount(() => {
    ApiServer.Get(urls.mynumber).then((data) => {
      myphonenumber = data;
    });

    ApiServer.Get(urls.getcompanynumber).then((data) => {
      companyForwardNumber = data;
    });
  });
</script>

<h4>Your company Phone Number</h4>
<ul>
  <li>All text message communication from Bin Bird uses this phone number.</li>
  <li>
    Customers text messages show in <a href="/messenger">Bin Bird Messenger</a>.
  </li>
  <li>
    Customer phone calls are forwarded the company phone number we have on file.
  </li>
</ul>

{#if myphonenumber}
  <h4>Company Number</h4>
  <h4><PhoneNumber number={myphonenumber} /></h4>

  <p>This phone number currently forwards all calls to:</p>
  <p><PhoneNumber number={companyForwardNumber} /></p>
  <div class="row">
    <div class="col">
      <input
        type="text"
        class="form-control"
        bind:value={companyForwardNumber}
      />
    </div>
    <div class="col">
      <ButtonSave text="Update" callback={updateCompanyNumber} />
    </div>
  </div>
{:else}
  <h4>Get Your Phone Number</h4>

  <p>Use the search below and select any phone number you like!</p>

  <form on:submit|preventDefault={searchNumber}>
    <div class="row">
      <div class="col">
        <InputNumberBox
          label="Area Code"
          required={true}
          bind:value={areaCode}
        />
      </div>
      <div class="col">
        <InputTextBox label="Contains" bind:value={numberSearch} />
      </div>
      <div class="col">
        <label for="button">Search</label>
        <button type="submit" class="btn btn-sm btn-outline-primary">
          Search!
        </button>
      </div>
    </div>
  </form>

  {#if errorMessage}
    <h4>{errorMessage}</h4>
  {/if}

  {#if numbers}
    <div class="row">
      <div class="col">Number</div>
      <div class="col">Region</div>
    </div>
    {#each numbers as number}
      <div class="row">
        <div class="col">
          <ButtonConfirmAction
            text="Reserve"
            linkButton={true}
            callback={() => confirmMakePurchase(number.number)}
            tooltip="Make this your phone number?"
            warningText="By continuing we will make this your new Notificaiton phone number."
          />
        </div>
        <div class="col"><PhoneNumber number={number.number} /></div>
      </div>
    {/each}
  {/if}
  <br />
  <p>
    <strong>Pro Tip:</strong> The Contains field can be a word or phase that you
    want to match, it can also be a number.
  </p>
  <p>
    For example: if you input the word <strong>BINS</strong> we will search for
    a phone number in the area code that contains <strong>2467</strong>
  </p>
{/if}
