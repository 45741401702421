<script>
  export let text;
  export let callback = null;
  export let linkButton = false;
  export let smallButton = true;

  import EditIcon from "../Icons/EditIcon.svelte";

  function handleBackClick() {
    if (callback) {
      callback();
    } else {
      window.history.back();
    }
  }

  let buttonClass = "btn";

  if (smallButton) {
    buttonClass += " btn-sm";
  }

  if (linkButton) {
    buttonClass += " link-btn";
  } else {
    buttonClass += " btn-outline-info";
  }
</script>

<button class={buttonClass} on:click={handleBackClick}>
  <EditIcon />
  {text}
</button>
