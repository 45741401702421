<script>
  export let relatedID;
  export let folder;
  export let isOpen = false;
  export let isPublicImage = false;

  import Modal from "sv-bootstrap-modal";

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import ImagePreview from "./ImagePreview.svelte";
  import UserStore from "../../Stores/UserStore";
  import ApiServer from "../../Server/ApiServer";
  import SpinnerIcon from "../Icons/SpinnerIcon.svelte";
  import AddIcon from "../Icons/AddIcon.svelte";

  let uploadFiles = [];
  let uploadCountRemaining = 0;
  let uploadMessage = "";

  let urls = {
    startUploadRequest: `/api/Attachment/StartFileUpload`,
    finishUploadRequest: `/api/Attachment/FinishFileUpload`,
  };

  function submitForm(event) {
    event.preventDefault();

    if (uploadFiles.length == 0) {
      uploadMessage = "Please add files to upload!";
    } else {
      uploadMessage = "";
    }

    for (let i = 0; i < uploadFiles.length; i++) {
      uploadCountRemaining++;
      uploadFile(uploadFiles[i]);
    }
  }

  async function uploadFile(file) {
    var fName = file.name;
    var fType = file.type;
    var fSize = file.size;

    let fileAttachmentRequest = {
      relatedID: relatedID,
      description: fName,
      fileName: fName,
      folder: folder,
      userName: UserStore.getUser().fullName,
      fileSize: fSize,
      isPublic: isPublicImage,
    };

    let fileAttachmentResponse = await ApiServer.Post(
      urls.startUploadRequest,
      fileAttachmentRequest
    );

    let fileUpload = await fetch(fileAttachmentResponse.sourceURL, {
      method: "PUT",
      headers: [["Content-Type", fType]],
      body: file,
    });

    //console.log(fileAttachmentResponse);
    console.log(fileUpload);

    fileAttachmentResponse.status =
      fileUpload.status == 200 ? "Upload Success" : "Upload Failed";

    let finishFileUpload = await ApiServer.Post(
      urls.finishUploadRequest,
      fileAttachmentResponse
    );

    dispatch("fileUploadComplete", fileAttachmentResponse);

    //clear the upload files array;
    uploadFiles = [];
    uploadCountRemaining--;
    checkUploadFinished();
  }

  function checkUploadFinished() {
    if (uploadCountRemaining == 0) {
      closeModal();
    }
  }

  function onNewFileSelected(e) {
    for (let i = 0; i < e.target.files.length; i++) {
      uploadFiles = [...uploadFiles, e.target.files[i]];
    }
  }

  function closeModal() {
    if (uploadFiles.length > 0) {
      let shouldCancel = confirm(
        "There are pending files not uploaded. Are you sure you want to cancel?"
      );
      if (shouldCancel) {
        uploadFiles = [];
        isOpen = false;
        dispatch("uploadCompleteEvent");
      }
    } else {
      isOpen = false;
      dispatch("uploadCompleteEvent");
    }
  }
</script>

<Modal bind:open={isOpen}>
  <form on:submit={submitForm}>
    <div class="modal-header">
      <h5 class="modal-title">Upload Files</h5>
      <button type="button" class="close" on:click={closeModal}>
        <span>&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col">
          <button
            type="button"
            onclick="document.getElementById('fileUploadButton').click();"
            class="btn btn-primary"
          >
            <AddIcon />
            Add
          </button>
          <input
            style="display:none"
            id="fileUploadButton"
            type="file"
            class="btn btn-primary"
            on:change={(e) => onNewFileSelected(e)}
            accept=".jpg, .jpeg, .png, .gif, .bmp, .pdf"
            multiple
          />
          <p>
            Press the Add button above to start adding files. When finished
            press the Upload button. Pro Tip: You can upload more than one at a
            time.
          </p>
          <p>Files: {uploadFiles.length}</p>
          <p>
            <strong>{uploadMessage}</strong>
          </p>
        </div>
      </div>

      {#if uploadFiles}
        <ul>
          {#each uploadFiles as f}
            <li>
              <ImagePreview imageFile={f} />
              {f.name}
            </li>
          {/each}
        </ul>
      {/if}
    </div>
    <div class="modal-footer">
      <button
        type="submit"
        class="btn btn-outline-warning"
        disabled={uploadCountRemaining > 0}
      >
        {#if uploadCountRemaining > 0}
          <SpinnerIcon />
        {/if}
        Upload Files
      </button>

      <button
        type="button"
        class="btn btn-outline-primary"
        on:click={closeModal}>Cancel</button
      >
    </div>
  </form>
</Modal>

<style>
  ul {
    list-style-type: none;
  }
  li {
    padding: 5px;
    margin: 5px;
  }
</style>
