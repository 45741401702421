<script>
  export let templateID;
  export let template = null;
  export let editable = true;
  export let filter = "";

  import { onMount } from "svelte";
  import Editor from "@tinymce/tinymce-svelte";
  import InputSelectGroup from "../../../Lib/Inputs/InputSelectGroup.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import { tinyApiKey } from "../../../Constant/Environment";

  let templates;

  let urls = {
    templates: `/api/Company/NotificationSetting/templates`,
    templatesWithFilter: `/api/Company/NotificationSetting/templatesbymethod?notificationmethod=`,
  };

  function getTemplates() {
    let url = urls.templates;
    if (filter) {
      url = urls.templatesWithFilter + filter;
    }
    ApiServer.Get(url).then((data) => {
      templates = data;
      buildTemplateBody();
    });
  }

  function buildTemplateBody() {
    if (!templates) return;

    var temp = templates.find((template) => template.id == templateID);

    if (temp) {
      template = temp;
    }
  }

  onMount(async () => {
    getTemplates();
  });

  $: templateID, buildTemplateBody();
</script>

{#if templates}
  <InputSelectGroup
    label="Notification Template"
    bind:value={templateID}
    labelKey="name"
    valueKey="id"
    groupKey="templateMethod"
    bind:options={templates}
  />
  {#if template}
    {#if editable}
      {#if template.templateMethod == "SMS"}
        <textarea
          id="desc"
          class="form-control"
          bind:value={template.body}
          rows="4"
        />
      {:else}
        <label for="subject">Subject: </label>
        <input
          type="text"
          id="subject"
          bind:value={template.subject}
          class="form-control"
        />
        <Editor apiKey={tinyApiKey} bind:value={template.body} />
      {/if}
    {:else}
      <div class="template-body">
        {@html template.body}
      </div>
    {/if}
  {/if}
{/if}

<style>
  .template-body {
    border: 1px solid gray;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
  }
</style>
