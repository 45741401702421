<script>
  export let text;
  export let callback;
  export let size = "normal";

  const className =
    size == "normal"
      ? "btn btn-outline-warning"
      : "btn btn-sm btn-outline-warning";

  import Fa from "svelte-fa";
  import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
</script>

<button type="button" class={className} on:click={callback}>
  <Fa icon={faWindowClose} />
  {text}
</button>
