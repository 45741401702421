<script>
  export let path;

  import { onMount } from "svelte";
  import { navigate, Route, Router } from "svelte-navigator";

  import { portaluser } from "../../Stores/PortalUser";
  import PortalMain from "../../Controls/Portal/PortalMain.svelte";
  import PortalUserStore from "../../Stores/PortalUserStore";
  import PortalLogin from "./PortalLogin.svelte";
  import PortalAcceptInvite from "./PortalAcceptInvite.svelte";
  import PortalSignUp from "./PortalSignUp.svelte";
  import PortalLostPassword from "./PortalLostPassword.svelte";
  import InvalidCompany from "./InvalidCompany.svelte";
  import CompanyCheck from "./CompanyCheck";
  import PortalPasswordReset from "./PortalPasswordReset.svelte";

  const urlParams = new URLSearchParams(window.location.search);
  const companyID = urlParams.get("id");
  const companyName = urlParams.get("c");

  let company;
  let currentUser = PortalUserStore.getUser();

  portaluser.set(currentUser);
  const userSubscribe = portaluser.subscribe((value) => {
    currentUser = value;
  });

  function redirectToErrorPage() {
    var url = "/portal/invalid?" + urlParams.toString();
    navigate(url, { replace: false });
  }

  onMount(async () => {
    company = await CompanyCheck.GetValidCompany(companyName, companyID);
  });
</script>

<main class="container-fluid container-sm body-content">
  {#if !company}
    <p>Company {companyName} NOT found</p>
  {:else if currentUser}
    <Router>
      <Route path="/">
        <PortalMain {company} />
      </Route>
    </Router>
  {:else}
    <Router>
      <Route path="/" primary={false}>
        <PortalLogin {company} {path} />
      </Route>

      <Route path="/signInHelp" primary={false}>
        <PortalLostPassword {company} {path} />
      </Route>

      <Route path="/portalpasswordreset/:id/*" let:params>
        <PortalPasswordReset {company} requestID={params.id} />
      </Route>

      <Route path="/acceptinvite">
        <PortalAcceptInvite {company} />
      </Route>

      <Route path="/invalid">
        <InvalidCompany />
      </Route>

      <Route path="/signup">
        <PortalSignUp {company} />
      </Route>
    </Router>
  {/if}
</main>

<style>
  main {
    margin-top: 10px;
  }
</style>
