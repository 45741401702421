<script>
  export let label;
  export let callback;

  import ButtonEdit from "../../Lib/Buttons/ButtonEdit.svelte";
</script>

<div class="parent">
  <div class="child">
    <ButtonEdit text="Edit" {callback} />
  </div>
  <div>
    <h4>{label}</h4>
  </div>
</div>

<style>
  .parent {
    display: flex;
  }
  .child {
    margin-left: auto;
    order: 2;
  }
</style>
