<script>
  export let account;

  import { link } from "svelte-navigator";
  import Back from "../../../Lib/Buttons/Back.svelte";
</script>

<div class="primary-nav">
  <div><Back /></div>
  <div>
    <h3><a href="/account/{account.id}" use:link>{account.accountName}</a></h3>
  </div>
</div>

<style>
  .primary-nav {
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
  }
</style>
