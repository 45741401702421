<script>
  export let tutorialSettings;

  import { createEventDispatcher } from "svelte";
  import { Link } from "svelte-navigator";
  import ButtonSave from "../../../Lib/Buttons/ButtonSave.svelte";
  const dispatch = createEventDispatcher();

  import HappyIcon from "../../../Lib/Icons/HappyIcon.svelte";
  import { toastNotification } from "../../../Stores/toastNotification";

  function markAsFinished() {
    tutorialSettings.tutorialSettings.customerImportDone = true;
    dispatch("saveSettings");
    toastNotification.Success("Customer Import Complete!");
  }
</script>

<div class="row">
  <div class="col">
    <div class="card mb-3">
      <div class="card-header">Customer Import</div>
      <div class="card-body">
        <div class="card-text">
          <p>If you have lots of accounts Bin Bird can import them!</p>
          <!-- <p>But... maybe you like typing! <HappyIcon /></p> -->
          <p />
          <p>
            <Link to="/company/accountimport">Import Accounts Now</Link>
          </p>
          <p>
            <ButtonSave text="Mark as Complete" callback={markAsFinished} />
          </p>
        </div>
        <div class="card-footer" />
      </div>
    </div>
  </div>
</div>
