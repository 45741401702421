<script>
  import { onMount } from "svelte";
  import { Alert } from "sveltestrap";

  import { navigate } from "svelte-navigator";
  import { fade } from "svelte/transition";
  import ApiServer from "../Server/ApiServer";
  import { user } from "../Stores/stores";
  import PickupDays from "../Controls/Checkout/PickupDays.svelte";
  import CustomCheckoutField from "../Controls/Checkout/CustomCheckoutField.svelte";
  import CheckoutEditLabel from "../Controls/Checkout/CheckoutEditLabel.svelte";
  import PhoneNumber from "../Lib/PhoneNumber.svelte";
  import ButtonLink from "../Lib/Buttons/ButtonLink.svelte";
  import CheckoutPayment from "../Controls/Checkout/CheckoutPayment.svelte";
  import ProgressLabel from "../Lib/ProgressLabel.svelte";
  import AddressAutocomplete from "../Lib/AddressAutocomplete/Autocomplete.svelte";
  import ButtonDelete from "../Lib/Buttons/ButtonDelete.svelte";
  import OrderSummary from "../Controls/Checkout/OrderSummary.svelte";
  import InputPasswordStrengthIndicator from "../Lib/Inputs/InputPasswordStrengthIndicator.svelte";
  import InputPasswordMatchIndicator from "../Lib/Inputs/InputPasswordMatchIndicator.svelte";

  //Portal Password Stuff
  let passwordsMatch = false;
  let passwordsComplexity;
  let portalPassword = {};
  let passwordError = "";

  const urlParams = new URLSearchParams(window.location.search);
  const companyID = urlParams.get("id");
  const companyName = urlParams.get("name");
  let plan = urlParams.get("plan");
  let planID = urlParams.get("planID");
  let bins = urlParams.get("bins");
  let trashday = urlParams.get("trashday");

  //TODO: test this a lot!
  let skipPayment = false;
  let sameDayPickup = true;
  let saveInProgress = false;
  let showTerms = false;

  let sources = [];
  let billingPlans = [];
  let selectedBillingPlan = null;
  let company;
  let checkoutRules;
  var selectedPickupDays = [];
  let totalCost = 0;
  let totalTax = 0;
  let totalBins = 0;
  let recurringCost = 0;
  let showPaymentPreview = false;
  let showOrderResultPage = false;
  let checkoutRequest = null;
  let billingEnabled = false;
  let companyTax;

  let urls = {
    getCompany: `/api/Public/Checkout/company?companyID=${companyID}`,
    getCompanyByName: `/api/Public/Checkout/companybyname?companyName=${companyName}`,
    processcheckoutrequest: `/api/Public/Checkout/processcheckoutrequest`,
    checkoutrules: `/api/Public/Checkout/checkoutrules?companyID=`,
    companytax: `/api/Public/Checkout/companytax?companyID=`,
    getSources: `/api/Public/Checkout/sources?companyID=`,
    getBillingEnabled: `/api/Public/Checkout/billingenabled?companyID=`,
    getBillingPlans: `/api/Public/Checkout/billingplans?companyID=`,
  };

  let account = {
    companyID: companyID,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    addressUnit: "",
    city: "",
    state: "",
    zip: "",
    gateCode: "",
    hadGuardGate: false,
    vendorTimeRestriction: "",
    acquisitionSource: "",
  };

  onMount(async () => {
    if (!companyID && !companyName) {
      navigate("/invalid", { replace: true });
    }

    let urlToPullCompany = companyID ? urls.getCompany : urls.getCompanyByName;

    ApiServer.GetAnonymous(urlToPullCompany).then((data) => {
      company = data;

      checkValidCompany(company);

      initPage(company);
    });
  });

  //Password Stuff
  function handlePasswordMatchChange(event) {
    passwordsMatch = event.detail;
  }

  function handlePasswordStrengthChange(event) {
    passwordsComplexity = event.detail;
  }

  function validatePassword() {
    return passwordsComplexity.strength >= 2 && passwordsMatch.match;
  }

  function handlePasswordCheck() {
    passwordError = "";
    if (portalPassword.password == "") return true;

    if (validatePassword()) {
      checkoutRequest.portalPassword = portalPassword.password;
      return true;
    } else {
      if (passwordsComplexity.strength <= 2) {
        passwordError = "Password is not complex enough.";
      }

      if (!passwordsMatch.match) {
        passwordError = "Passwords do not match.";
      }
      //Stop the flow and tell the user they need to fix their bad password.
      return false;
    }
  }

  //END Password stuff

  function handleSaveWithoutPayment() {
    handlePaymentComplete();
  }

  function handlePaymentComplete(event) {
    if (event && event.detail) {
      checkoutRequest.paymentIntent = event.detail.paymentIntent;
    }

    checkoutRequest.userName = account.firstName;

    ApiServer.PostAnonymous(urls.processcheckoutrequest, checkoutRequest).then(
      (result) => {
        showOrderResultPage = true;
      }
    );
  }

  function handlePickupDayResult(event) {
    selectedPickupDays = event.detail;
  }

  function setSources() {
    ApiServer.GetAnonymous(urls.getSources + company.id).then((data) => {
      sources = data;
    });
  }

  function setBillingEnabled() {
    ApiServer.GetAnonymous(urls.getBillingEnabled + company.id).then((data) => {
      billingEnabled = data;
    });
  }

  function setBillingPlans() {
    ApiServer.GetAnonymous(urls.getBillingPlans + company.id).then((data) => {
      billingPlans = data;

      billingPlans.forEach((billingPlan) => {
        if (
          plan?.toLowerCase() == billingPlan.name.toLowerCase() ||
          planID?.toLowerCase() == billingPlan.id.toLowerCase()
        ) {
          selectedBillingPlan = billingPlan;
        }
      });
    });
  }

  function buildCheckoutRequest() {
    let taxRate = 0;
    if (companyTax && companyTax.collectTax) {
      taxRate = companyTax.taxRate;
    }

    let bins = 0;

    selectedPickupDays.forEach((pickupDay) => {
      bins += pickupDay.bins;
    });

    var request = {
      companyID: company.id,
      account: account,
      billingPlanID: selectedBillingPlan.id,
      pickupDays: selectedPickupDays,
      billingPlan: selectedBillingPlan,
      totalCost: totalCost,
      totalTax: totalTax,
      taxRate: taxRate,
      totalBins: bins,
      recurringCost: recurringCost,
    };

    return request;
  }

  function processcheckoutrequest() {
    checkoutRequest = buildCheckoutRequest();
    if (!handlePasswordCheck()) return;
    showPaymentPreview = true;
  }

  function returnToCheckout() {
    showPaymentPreview = false;
  }

  function checkValidCompany(company) {
    if (!company) {
      navigate("/invalid", { replace: true });
      return;
    }

    if (company.status && company.staus == 400) {
      navigate("/invalid", { replace: true });
      return;
    }
  }

  async function initPage() {
    //TODO: Get these defaults from company settings
    if (!plan) {
      plan = "Monthly";
    }

    if (!bins) {
      bins = 2;
    }

    //Waiting for checkout rules to load before we load page.
    var rules = await ApiServer.GetAnonymous(urls.checkoutrules + company.id);
    checkoutRules = rules.checkoutRule;

    ApiServer.GetAnonymous(urls.companytax + company.id).then((data) => {
      companyTax = data;
    });

    setSources();
    setBillingPlans();
    setBillingEnabled();
  }

  function flipTerms() {
    showTerms = !showTerms;
  }

  function gateCode() {
    return checkoutRequest.account.gateCode
      ? checkoutRequest.account.gateCode
      : "None";
  }

  function guardGate() {
    let guardGateText = checkoutRequest.account.hasGuardGate
      ? "Guard Gate: Yes"
      : "No Guard Gate";
    return guardGateText;
  }

  function vendorTimeRestriction() {
    let timeRestriction = checkoutRequest.account.vendorTimeRestriction
      ? "Vendor Time Restriction: " +
        checkoutRequest.account.vendorTimeRestriction
      : "";
    return timeRestriction;
  }

  function plural(number, name) {
    if (number > 1) {
      return name + "s";
    } else {
      return name;
    }
  }
</script>

{#if company}
  <div class="pagebreak" />
  <div class="row top justify-content-center">
    <div class="col-md-6">
      <div class="card">
        <header class="card-header" style="line-height: 50px">
          <img src={company.companyInformation.companyLogoURL} alt="Logo" />
          <h3 style="display: inline-block" class="card-title mt-2">
            {company.name} Checkout
          </h3>
        </header>

        {#if !showPaymentPreview}
          <article class="card-body">
            <div class="row">
              <div class="col form-group">
                <p class="planLabel" />
                <p class="planPriceLabel" />
              </div>
            </div>
            <form
              id="customer-form"
              on:submit|preventDefault={processcheckoutrequest}
            >
              {#if selectedBillingPlan && selectedBillingPlan.billingPlanRules && selectedBillingPlan.billingPlanRules.checkoutFieldDisplay}
                <CustomCheckoutField
                  customDisplayField={selectedBillingPlan.billingPlanRules
                    .checkoutFieldDisplay.BeforeAccount}
                />
              {/if}
              <div class="row">
                <div class="col">
                  <h4>Contact Information</h4>
                </div>
              </div>
              <div class="row">
                <div class="col form-group">
                  <label for="firstName">First Name *</label>
                  <input
                    type="text"
                    id="firstName"
                    class="form-control"
                    required
                    bind:value={account.firstName}
                  />
                  <div class="invalid-feedback">First Name is required!</div>
                </div>

                <div class="col form-group">
                  <label for="lastName">Last Name *</label>
                  <input
                    type="text"
                    id="lastName"
                    class="form-control"
                    required
                    bind:value={account.lastName}
                  />
                  <div class="invalid-feedback">Last Name is required!</div>
                </div>
              </div>

              <div class="row">
                <div class="col form-group">
                  <label for="email">Email Address *</label>
                  <input
                    type="email"
                    id="email"
                    class="form-control"
                    bind:value={account.email}
                    required
                  />
                  <div class="invalid-feedback">Email is required!</div>
                </div>
                <div class="col form-group">
                  <label for="phoneNumber">Mobile Phone *</label>
                  <input
                    type="text"
                    id="phoneNumber"
                    class="form-control"
                    bind:value={account.phoneNumber}
                    required
                  />
                  <small id="mobileNotification" class="form-text text-muted">
                    We send service reminders to this number
                  </small>
                  <div class="invalid-feedback">Mobile phone is required!</div>
                </div>
              </div>

              <AddressAutocomplete
                bind:address={account.address}
                bind:unit={account.addressUnit}
                bind:city={account.city}
                bind:state={account.state}
                bind:zip={account.zip}
              />

              <div class="row">
                <div class="col form-group">
                  <label for="gateCode">Gate Code</label>
                  <input
                    type="text"
                    id="gateCode"
                    bind:value={account.gateCode}
                    class="form-control"
                  />
                </div>

                <div class="col form-group">
                  <label for="hasGuardGate">Guard Gate</label>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="hasGuardGate"
                      checked={account.hasGuardGate}
                      on:change={(e) => {
                        account.hasGuardGate = e.target.checked;
                      }}
                    />
                    <label class="form-check-label" for="isGuardGate">
                      My community has a guard gate
                    </label>
                  </div>
                </div>
              </div>
              {#if account.hasGuardGate}
                <div class="row" id="vendorTimeRestrictionControl">
                  <div class="col form-group">
                    <label for="vendorTimeRestriction"
                      >Vendor Time Restriction</label
                    >
                    <select
                      id="vendorTimeRestriction"
                      bind:value={account.vendorTimeRestriction}
                      class="form-control"
                    >
                      <option value="None">None</option>
                      <option value="5PM">5 PM</option>
                      <option value="6PM">6 PM</option>
                      <option value="7PM">7 PM</option>
                      <option value="8PM">8 PM</option>
                    </select>
                    <small
                      id="vendorTimeRestrictionNotification"
                      class="form-text text-muted"
                    >
                      What time does your community prevent Vendors from
                      entering.
                    </small>
                  </div>
                </div>
              {/if}
              {#if selectedBillingPlan && selectedBillingPlan.billingPlanRules && selectedBillingPlan.billingPlanRules.checkoutFieldDisplay}
                <CustomCheckoutField
                  customDisplayField={selectedBillingPlan.billingPlanRules
                    .checkoutFieldDisplay.AfterAccount}
                />
              {/if}

              <div class="row">
                <div class="col form-group">
                  <label for="specialInstructions">Special Instructions</label>
                  <textarea
                    rows="2"
                    id="specialInstructions"
                    class="form-control"
                    bind:value={account.specialInstructions}
                  />
                  <small class="form-text text-muted">
                    Any information that will help us find your house or your
                    bins.
                  </small>
                </div>
              </div>

              <div class="row">
                <div class="col form-group">
                  <label for="source">How did you hear about us?</label>
                  <select
                    class="form-control"
                    id="source"
                    bind:value={account.acquisitionSource}
                  >
                    <option value="Not Selected">Not Selected</option>
                    {#each sources as source}
                      <option value={source.source}>{source.source}</option>
                    {/each}
                  </select>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col">
                  <h4>Create Portal Password</h4>
                </div>
              </div>
              <div class="row">
                <div class="col form-group">
                  <p>
                    Easily manage your account online by creating a password.
                    (optional)
                  </p>
                  <p>
                    <InputPasswordStrengthIndicator
                      label="Password"
                      bind:value={portalPassword.password}
                      required={true}
                      password={portalPassword.password}
                      on:change={handlePasswordStrengthChange}
                    />

                    <InputPasswordMatchIndicator
                      label="Confirm Password"
                      bind:value={portalPassword.confirmPassword}
                      required={true}
                      password={portalPassword.password}
                      on:change={handlePasswordMatchChange}
                    />
                  </p>
                  {#if passwordError}
                    <p class="password-error">
                      Password Error: {passwordError}
                    </p>
                  {/if}
                </div>
              </div>

              <hr />
              <div class="row">
                <div class="col">
                  <h4>Billing Plan</h4>
                </div>
              </div>

              <div class="row">
                <div class="col form-group">
                  <label for="planType">Select a Plan</label>
                  <select
                    id="planType"
                    bind:value={selectedBillingPlan}
                    class="form-control"
                    required
                  >
                    <option value="">--Select a plan--</option>
                    {#each billingPlans as plan}
                      <option value={plan}>{plan.name}</option>
                    {/each}
                  </select>
                  <small class="form-text text-muted">
                    {#if selectedBillingPlan}
                      {selectedBillingPlan.description
                        ? selectedBillingPlan.description
                        : ""}
                    {/if}
                  </small>
                  <div class="invalid-feedback">Plan is required!</div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <h4>Trash/Recycling Pickup Schedule</h4>
                </div>
              </div>
              {#if checkoutRules}
                <PickupDays
                  billingPlan={selectedBillingPlan}
                  bind:totalCost
                  bind:totalTax
                  bind:totalBins
                  {companyTax}
                  bind:recurringCost
                  {checkoutRules}
                  bind:sameDayPickup
                  on:pickupDayResult={handlePickupDayResult}
                />
              {/if}

              {#if selectedBillingPlan && selectedBillingPlan.billingPlanRules && selectedBillingPlan.billingPlanRules.checkoutFieldDisplay}
                <CustomCheckoutField
                  customDisplayField={selectedBillingPlan.billingPlanRules
                    .checkoutFieldDisplay.AfterPickupSchedule}
                />
              {/if}

              <hr />

              {#if selectedBillingPlan}
                <OrderSummary
                  planName={selectedBillingPlan.name}
                  {totalCost}
                  {totalTax}
                  {totalBins}
                />
              {/if}

              {#if selectedBillingPlan && selectedBillingPlan.billingPlanRules && selectedBillingPlan.billingPlanRules.checkoutFieldDisplay}
                <CustomCheckoutField
                  customDisplayField={selectedBillingPlan.billingPlanRules
                    .checkoutFieldDisplay.BeforeSignupButton}
                />
              {/if}

              <div class="row">
                <div class="col form-group">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="agreeToTerms"
                      required
                    />
                    <label class="form-check-label" for="agreeToTerms" />
                    I have read and agree to the {company.name}
                    <ButtonLink
                      text="terms and conditions *"
                      callback={flipTerms}
                    />
                  </div>

                  {#if showTerms}
                    <div
                      in:fade
                      out:fade
                      id="terms"
                      class="card"
                      style="padding: 10px;"
                    >
                      {@html checkoutRules.termsAndConditions}
                    </div>
                  {/if}
                </div>
              </div>
              <hr />
              <button class="btn btn-lg btn-success float-right">
                <span id="button-text">Preview Order</span>
              </button>
            </form>
          </article>
        {/if}

        {#if showPaymentPreview && !showOrderResultPage}
          <article class="card-body">
            <div class="row">
              <div class="col">
                <CheckoutEditLabel
                  label="Contact Information"
                  callback={returnToCheckout}
                />

                <p>
                  {checkoutRequest.account.firstName}
                  {checkoutRequest.account.lastName}<br />

                  {checkoutRequest.account.email}<br />
                  <PhoneNumber number={checkoutRequest.account.phoneNumber} />
                  <br />
                </p>
                <hr />
                <h4>Service Address</h4>
                <p>
                  {checkoutRequest.account.address}
                  {checkoutRequest.account.addressUnit}<br />
                  {checkoutRequest.account.city}, {checkoutRequest.account
                    .state}
                  {checkoutRequest.account.zip} <br />
                  Gate Code: {gateCode()} <br />
                  {guardGate()}<br />
                  {vendorTimeRestriction()}
                </p>
                <hr />
                <h4>Service Plan</h4>
                <p>
                  Selected Service: <strong>
                    {checkoutRequest.billingPlan.name}</strong
                  > <br />
                  <small>{checkoutRequest.billingPlan.description}</small>
                </p>
                <p>
                  {#each checkoutRequest.pickupDays as pickup}
                    {pickup.dayName}:
                    <strong
                      >{pickup.day.name}
                      cleaning {pickup.bins}
                      {plural(pickup.bins, "Bin")}
                    </strong> <br />
                  {/each}
                </p>
                <hr />
                {#if selectedBillingPlan}
                  <OrderSummary
                    planName={selectedBillingPlan.name}
                    {totalCost}
                    {totalTax}
                    {totalBins}
                  />
                {/if}
                <!-- <h4>Plan Cost</h4>
                <div>
                  <h5>
                    Total due today:
                    <Money amount={checkoutRequest.cost} />
                  </h5>
                  {#if selectedBillingPlan.billingPlanRules.recurringRule.isRecurring}
                    <h5>
                      Total due each billing cycle: <Money
                        amount={checkoutRequest.recurringCost}
                      />
                    </h5>
                  {/if}
                </div> -->
                {#if $user && $user.fullName}
                  <div>
                    <hr />
                    <h4>Skip Payment</h4>
                    {#if !skipPayment}
                      <p>
                        As a logged in user you can choose to skip collecting
                        this credit card payment.
                      </p>
                    {/if}

                    <ButtonDelete
                      text={skipPayment ? "Don't skip payment" : "Skip Payment"}
                      tooltip="Skip collecting credit card information"
                      warningText="If you skip the payment the customers credit card will not be saved or charged."
                      callback={() => (skipPayment = !skipPayment)}
                    />

                    <br />

                    {#if skipPayment}
                      <Alert color="danger">
                        The customers card will not be charged or saved!
                      </Alert>
                    {/if}
                  </div>
                {/if}
                {#if billingEnabled && !skipPayment}
                  <hr />
                  <h4>Payment</h4>
                  <p>
                    <CheckoutPayment
                      on:paymentComplete={handlePaymentComplete}
                      bind:checkoutRequest
                    />
                  </p>
                {:else}
                  <p>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      on:click={handleSaveWithoutPayment}
                      disabled={saveInProgress}>Sign Up Now</button
                    >
                    {#if saveInProgress}
                      <ProgressLabel text="Save in progress..." />
                    {/if}
                  </p>
                {/if}
              </div>
            </div>
          </article>
        {/if}

        {#if showOrderResultPage}
          <article class="card-body">
            <div class="row">
              <div class="col">
                {@html checkoutRules.checkoutSuccessMessage}
              </div>
            </div>
          </article>
        {/if}
      </div>
    </div>
  </div>
{:else}
  loading...
{/if}

<style>
  .top {
    margin-top: 15px;
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    } /* page-break-after works, as well */
  }

  .password-error {
    color: red;
    font-weight: bold;
  }
</style>
