<script>
  import { Offcanvas } from "sveltestrap";
  import ButtonCancel from "../../../Lib/Buttons/ButtonCancel.svelte";
  import ButtonLink from "../../../Lib/Buttons/ButtonLink.svelte";
  import EmailSettings from "./Controls/EmailSettings.svelte";

  let isOpen = false;
  const openScreen = () => (isOpen = !isOpen);
</script>

<ButtonLink text="Email Settings" callback={openScreen} />

<Offcanvas header="Email Settings" {isOpen} toggle={openScreen} placement="end">
  <EmailSettings />
  <br />
  <hr />
  <ButtonCancel text="Close" callback={openScreen} />
</Offcanvas>
