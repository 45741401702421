<script>
  export let job;
  export let showModal = false;

  import Modal from "sv-bootstrap-modal";
  import { useNavigate } from "svelte-navigator";
  const navigate = useNavigate();

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import ButtonCancel from "../../../../Lib/Buttons/ButtonCancel.svelte";
  import InputSwitch from "../../../../Lib/Inputs/InputSwitch.svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import { user } from "../../../../Stores/stores";
  import { toastNotification } from "../../../../Stores/toastNotification";
  import JobCloseOptions from "../../../Map/Stop/JobCloseOptions.svelte";

  let urls = {
    closeJob: `/api/Job/closejob`,
  };

  let altStopStatus;
  let stopStatus;
  let sendCloseNotification = false;

  function handleJobClose() {
    job.modified = true;

    if (altStopStatus) stopStatus = altStopStatus;

    let closeJobRequest = {
      jobID: job.id,
      closeStatusMessage: stopStatus,
      userName: $user.fullName,
      sendCustomerNotification: sendCloseNotification,
    };

    ApiServer.Post(urls.closeJob, closeJobRequest).then((data) => {
      toastNotification.Success("Job Closed!");
      showModal = false;
      dispatch("afterStatusChange");
    });
  }
</script>

<Modal bind:open={showModal}>
  <div class="modal-header">
    <h5 class="modal-title">Closing Job {job.jobNumber}</h5>
    <button type="button" class="close" on:click={() => (showModal = false)}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Closing a job will charge the customer as if the work has been performed.
      To close this job without charging the customer cancel this job.
    </p>
    <p>Please enter a close status</p>
    <JobCloseOptions bind:stopStatus />
    {#if stopStatus == "Other"}
      <input
        type="text"
        maxlength="25"
        bind:value={altStopStatus}
        class="form-control"
      />
    {/if}
    <p>
      <InputSwitch
        label="Send customer notification"
        text="Notify Customer"
        bind:value={sendCloseNotification}
      />
    </p>
  </div>
  <div class="modal-footer">
    <ButtonCancel text="Cancel" callback={() => (showModal = false)} />
    <button
      type="button"
      class="btn btn-outline-primary"
      on:click={handleJobClose}>Close Job</button
    >
  </div>
</Modal>
