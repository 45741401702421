<script>
  export let portalSummary;
  export let paymentEditOpen = false;

  import { onMount } from "svelte";
  import { loadStripe } from "@stripe/stripe-js";
  import { Container, CardNumber, CardExpiry, CardCvc } from "svelte-stripe-js";
  import Modal from "sv-bootstrap-modal";

  import ButtonConfirmAction from "../../Lib/Buttons/ButtonConfirmAction.svelte";
  import AddIcon from "../../Lib/Icons/AddIcon.svelte";
  import PortalApiServer from "../../Server/PortalApiServer";
  import { toastNotification } from "../../Stores/toastNotification";
  import DateFormat from "../../Lib/DateFormat.svelte";
  import ButtonCancel from "../../Lib/Buttons/ButtonCancel.svelte";

  let paymentMethods;
  let errorMessage;
  let stripe = null;
  let cardElement;
  let cardAddMessage;

  let account = portalSummary.account;

  const togglepaymentEdit = () => (paymentEditOpen = !paymentEditOpen);

  let urls = {
    getPaymentMethods: `/api/portal/portal/billing_paymentmethods?accountID=${account.id}`,
    stripeKey: `/api/portal/portal/billing_stripekey`,
    addPaymentMethod: `/api/portal/portal/billing_addpaymentmethod`,
    deletePaymentMethod: `/api/portal/portal/billing_deletepaymentmethod`,
  };

  onMount(async () => {
    setPaymentMethods();

    let stripeKey = await PortalApiServer.Get(urls.stripeKey);

    stripe = await loadStripe(stripeKey);
  });

  function setPaymentMethods() {
    errorMessage = "";
    PortalApiServer.Get(urls.getPaymentMethods).then((data) => {
      if (data.success) {
        paymentMethods = data.data;
        portalSummary.paymentMethods = data.data;
      } else {
        errorMessage = data.message;
      }
    });
  }

  function deletePaymentMethod(cardID) {
    errorMessage = "";
    let request = {
      accountID: account.id,
      stripeCardID: cardID,
    };

    PortalApiServer.Post(urls.deletePaymentMethod, request).then((data) => {
      if (data.success) {
        setPaymentMethods();
        toastNotification.Success("Payment method deleted!");
      } else {
        errorMessage = "Payment method delete failed: " + data.message;
        toastNotification.Error(errorMessage);
      }
    });
  }

  async function submit() {
    cardAddMessage = "";

    const result = await stripe.createToken(cardElement);

    if (result.error) {
      cardAddMessage = "Add Card Failure: " + result.error.message;
      toastNotification.Error(cardAddMessage);
      return;
    }

    let newCardRequest = {
      accountID: account.id,
      stripeTokenID: result.token.id,
    };

    PortalApiServer.Post(urls.addPaymentMethod, newCardRequest).then((data) => {
      if (data.success) {
        toastNotification.Success("New payment method added!");
        setPaymentMethods();
        togglepaymentEdit();
      } else {
        cardAddMessage = data.message;
        toastNotification.Error("Add Card Failure: " + data.message);
      }
    });
  }
</script>

<Modal bind:open={paymentEditOpen} ignoreBackdrop={true}>
  <div class="modal-header">
    <h5 class="modal-title">Your payment methods</h5>
    <button
      type="button"
      class="close"
      on:click={() => (paymentEditOpen = false)}
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {#if paymentMethods}
      <div class="row bb-header">
        <div class="col">Action</div>
        <div class="col">Details</div>
        <div class="col">Created</div>
      </div>

      {#each paymentMethods as method}
        <div class="row bb-row">
          <div class="col">
            <ButtonConfirmAction
              text="Delete"
              callback={() => deletePaymentMethod(method.paymentMethodID)}
              tooltip="Delete this payment method"
              warningText="This will permanently delete this card. Are you sure?"
              buttonClass="btn btn-link"
            />
          </div>
          <div class="col">
            {method.brand}
            {method.last4}
            {method.expiry}
          </div>
          <div class="col">
            <DateFormat date={method.dateCreated} />
          </div>
        </div>
      {/each}
    {/if}

    {#if errorMessage}
      <p>
        <strong>{errorMessage}</strong>
      </p>
    {/if}

    {#if stripe}
      <Container {stripe}>
        {#if !account.stripeCustomerID}
          <h5>Customer not connected to stripe</h5>
          <p>
            Adding this card will also create a stripe customer and associate
            them to this card.
          </p>
        {/if}
        <form on:submit|preventDefault={submit}>
          <div class="form-group">
            <label for="card-element">Credit or debit card</label>
            <div id="card-element" required class="form-control fix-height">
              <CardNumber bind:element={cardElement} />
            </div>
          </div>
          <div class="row">
            <div class="col form-group">
              <label for="card-element">Expiration Date</label>
              <div id="card-element" required class="form-control fix-height">
                <CardExpiry />
              </div>
            </div>
            <div class="col form-group">
              <label for="card-element">CVC</label>
              <div id="card-element" required class="form-control fix-height">
                <CardCvc />
              </div>
            </div>
          </div>
          <br />
          <ButtonCancel text="Cancel" callback={togglepaymentEdit} />
          <button class="btn btn-outline-primary"> <AddIcon /> Add Card</button>
        </form>

        {#if cardAddMessage}
          <p>
            <strong>{cardAddMessage}</strong>
          </p>
        {/if}
      </Container>
    {/if}
  </div>
</Modal>
