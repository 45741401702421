<script>
  export let account;
  export let contactActionsOpen = false;

  import { Offcanvas } from "sveltestrap";
  import { createEventDispatcher } from "svelte";

  import { user } from "../../../Stores/stores";
  import CopyIcon from "../../../Lib/Icons/CopyIcon.svelte";
  import TemplateSelector from "../../Company/Templates/_TemplateSelector.svelte";
  import ButtonConfirmAction from "../../../Lib/Buttons/ButtonConfirmAction.svelte";
  import ButtonCancel from "../../../Lib/Buttons/ButtonCancel.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import { toastNotification } from "../../../Stores/toastNotification";
  const dispatch = createEventDispatcher();

  let notificationTemplate;
  let hydratedMessage;

  const toggleContactActions = () => (contactActionsOpen = !contactActionsOpen);

  let urls = {
    sendNotification: `/api/Account/Account/sendnotification`,
    previewMessage: `/api/Account/Account/previewhydratedmessage`,
  };

  function previewHydratedMessage() {
    var request = {
      accountID: account.id,
      notificationTemplate: notificationTemplate,
    };
    ApiServer.Post(urls.previewMessage, request).then((data) => {
      if (data.success) {
        hydratedMessage = data.data;
      } else {
        hydratedMessage = {};
        hydratedMessage.body = "Error previewing the message";
      }
    });
  }

  function sendCustomerMessage() {
    if (!notificationTemplate) return;

    var request = {
      accountID: account.id,
      userName: $user.fullName,
      notificationTemplate: notificationTemplate,
    };

    ApiServer.Post(urls.sendNotification, request).then((data) => {
      if (data.success) {
        toastNotification.Success("Message sent to customer!");
      } else {
        toastNotification.Error("Notification not sent: " + data.message);
      }
      dispatch("messageSent", true);
      toggleContactActions();
    });
  }

  $: notificationTemplate, (hydratedMessage = null);
</script>

<Offcanvas
  header="Contact Customer"
  isOpen={contactActionsOpen}
  toggle={toggleContactActions}
  placement="end"
>
  <div class="container">
    <h5>Email, Call or Text customer</h5>

    <div class="row">
      <div class="col-10">
        <p>
          <strong>Call</strong>:
          <a href="tel:{account.phoneNumber}">
            {account.formattedPhoneNumber}
          </a>
        </p>
      </div>
      <div class="col-2">
        <p>
          <CopyIcon
            title="Copy the phone number"
            textToCopy={account.phoneNumber}
          />
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-10">
        <p>
          <strong>Email</strong>:
          <a href="mailto:{account.email}">{account.email}</a>
        </p>
      </div>
      <div class="col-2">
        <p>
          <CopyIcon title="Copy the email address" textToCopy={account.email} />
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p><strong>Send A Message</strong></p>
        <p>
          <TemplateSelector bind:template={notificationTemplate} />

          <ButtonConfirmAction
            text="GO!"
            warningText="Clicking Yes will send a message to this customer. Are you sure?"
            callback={sendCustomerMessage}
          />

          <button
            class="btn btn-outline btn-warning"
            on:click={previewHydratedMessage}>Preview</button
          >
        </p>
      </div>
    </div>

    {#if hydratedMessage}
      <div class="row">
        <div class="col">
          <h5>Message Preview</h5>
          <div class="preview-body">
            {@html hydratedMessage.body}
          </div>
        </div>
      </div>
    {/if}

    <hr />

    <div class="same-line">
      <ButtonCancel text="Close" callback={toggleContactActions} />
    </div>
  </div>
</Offcanvas>

<style>
  .preview-body {
    border: 1px solid gray;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
  }
</style>
