<script>
  import ButtonCancel from '../../../Lib/Buttons/ButtonCancel.svelte';
  import { Offcanvas } from 'sveltestrap';
  import ButtonLink from '../../../Lib/Buttons/ButtonLink.svelte';
  import { toastNotification } from '../../../Stores/toastNotification';
  import ApiServer from '../../../Server/ApiServer';
  import { onMount } from 'svelte';
  import SettingControl from './SettingControl.svelte';
  import ButtonSave from '../../../Lib/Buttons/ButtonSave.svelte';
  import UTCDate from '../../../Util/UTCDate';

  let isOpen = false;
  let setting;
  let selectedTimeZone;
  let timezonelist;

  let urls = {
    get: `/api/Company/Settings/GeneralSettings/timezone`,
    update: `/api/Company/Settings/GeneralSettings`,
    timezones: `/api/Company/Settings/GeneralSettings/timezones`,
  };

  const openScreen = () => (isOpen = !isOpen);

  function loadSettings() {
    ApiServer.Get(urls.get).then((data) => {
      setting = data;
    });
  }

  function saveSettings() {
    var settings = [];
    settings.push(setting);
    ApiServer.Post(urls.update, settings).then((data) => {
      toastNotification.Success('Time Zone saved!');
      isOpen = false;
    });
  }
  onMount(async () => {
    loadSettings();

    ApiServer.Get(urls.timezones).then((data) => {
      timezonelist = data;
    });
  });
</script>

<ButtonLink text="Time Zone" callback={openScreen} />

<Offcanvas
  header="Time Zone Settings"
  {isOpen}
  toggle={openScreen}
  placement="end"
>
  {#if setting}
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col">
            {#if timezonelist}
              <select class="form-control" bind:value={setting.value}>
                {#each timezonelist as tz}
                  <option value={tz.tzCode}>{tz.label}</option>
                {/each}
              </select>
            {/if}
          </div>
        </div>
      </div>
    </div>
  {/if}
  <p>
    <ButtonCancel text="Close" callback={openScreen} />
    <ButtonSave text="Save" callback={saveSettings} />
  </p>
</Offcanvas>
