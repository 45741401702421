<script>
  import { navigate } from "svelte-navigator";
  import { TutorialStatus } from "../Stores/TutorialStore";

  import { Alert } from "sveltestrap";
  function navigateToTutorial() {
    navigate("/tutorial/");
  }
</script>

{#if $TutorialStatus && $TutorialStatus.tutorialSettings && !$TutorialStatus.tutorialSettings.finished}
  <Alert color="warning">
    <div class="same-line">
      Onboard Tutorial Status {$TutorialStatus.tutorialSettings.percentComplete}
      <button class="btn btn-sm btn-primary" on:click={navigateToTutorial}
        >Finish The Tutorial</button
      >
    </div>
  </Alert>
{/if}
