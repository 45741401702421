<script>
  import Templates from "../Controls/Company/Templates/Templates.svelte";
  import CompanyNav from "../Controls/Company/CompanyNav.svelte";
  import BillingPlans from "../Controls/Company/BillingPlans/BillingPlans.svelte";
  //import Routes from "../Controls/Company/Routes/Routes.svelte";
  import Routes from "../Controls/Company/Routes/Routes.svelte";
  import Users from "../Controls/Company/Users/Users.svelte";
  import PrivateRoute from "../Lib/Authentication/PrivateRoute.svelte";
  import User from "../Controls/Company/Users/User.svelte";
  import BillingPlan from "../Controls/Company/BillingPlans/BillingPlan.svelte";
  import CompanySettings from "../Controls/Company/Settings/CompanySettings.svelte";
  import CheckoutRules from "../Controls/Company/CheckoutRules/CheckoutRules.svelte";
  import StripeConnect from "../Controls/Company/StripeConnect/StripeConnect.svelte";
  import Template from "../Controls/Company/Templates/Template.svelte";
  import Reminder from "../Controls/Company/Templates/Reminder.svelte";
  import PageTitle from "../Lib/PageTitle.svelte";
  import AccountImport from "../Controls/Company/AccountImport/AccountImport.svelte";
  import UserInvite from "../Controls/Company/Users/UserInvite.svelte";
</script>

<PageTitle title="Company" />

<PrivateRoute path="/" roles={["Admin"]}>
  <CompanyNav />
</PrivateRoute>

<PrivateRoute path="billingplans" roles={["Admin"]}>
  <BillingPlans />
</PrivateRoute>

<PrivateRoute path="stripeconnect" roles={["Admin"]}>
  <StripeConnect />
</PrivateRoute>

<PrivateRoute path="billingplans/:id" roles={["Admin"]} let:params>
  <BillingPlan planID={params.id} />
</PrivateRoute>

<PrivateRoute path="routes" roles={["Admin"]}>
  <Routes />
</PrivateRoute>

<PrivateRoute path="settings" roles={["Admin"]}>
  <CompanySettings />
</PrivateRoute>

<PrivateRoute path="templates" roles={["Admin"]}>
  <Templates />
</PrivateRoute>

<PrivateRoute path="templates/:id" roles={["Admin"]} let:params>
  <Template templateID={params.id} />
</PrivateRoute>

<PrivateRoute path="reminders/:id" roles={["Admin"]} let:params>
  <Reminder settingID={params.id} />
</PrivateRoute>

<PrivateRoute path="checkoutrules" roles={["Admin"]}>
  <CheckoutRules />
</PrivateRoute>

<PrivateRoute path="users" roles={["Admin"]}>
  <Users />
</PrivateRoute>

<PrivateRoute path="users/:id" roles={["Admin"]} let:params>
  <User userID={params.id} />
</PrivateRoute>

<PrivateRoute path="invite/:id" roles={["Admin"]} let:params>
  <UserInvite inviteID={params.id} />
</PrivateRoute>

<PrivateRoute path="accountimport" roles={["Admin"]}>
  <AccountImport />
</PrivateRoute>
