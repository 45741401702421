<script>
  import Invoice from "../Controls/Profile/Invoice.svelte";

  import SystemBillingCurrent from "../Controls/Profile/SystemBillingCurrent.svelte";
  import SystemBillingPast from "../Controls/Profile/SystemBillingPast.svelte";
  import PrivateRoute from "../Lib/Authentication/PrivateRoute.svelte";
  import PageTitle from "../Lib/PageTitle.svelte";
</script>

<PageTitle title="Invoices" />

<PrivateRoute path="/" roles={["Admin"]}>
  <SystemBillingCurrent />
  <SystemBillingPast />
</PrivateRoute>

<PrivateRoute path="invoice/:id" roles={["Admin"]} let:params>
  <Invoice invoiceID={params.id} />
</PrivateRoute>
