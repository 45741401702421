<script>
  import { onMount } from "svelte";
  import AccountIcon from "../../../Lib/Icons/AccountIcon.svelte";
  import ArrowRightIcon from "../../../Lib/Icons/ArrowRightIcon.svelte";
  import DeleteIcon from "../../../Lib/Icons/DeleteIcon.svelte";
  import SearchIcon from "../../../Lib/Icons/SearchIcon.svelte";
  import StripeIcon from "../../../Lib/Icons/StripeIcon.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import AccountCardStripe from "./AccountCardStripe.svelte";

  let accounts = [];
  let currentPage = 1;
  let searchText = "";

  let urls = {
    accounts: "/api/Account/Account/nonstripeaccounts",
  };

  function onSearchKeyDown(element) {
    if (element.charCode === 13) search();
  }

  function clearSearch() {
    currentPage = 1;
    searchText = "";
    fetchAccounts();
  }

  function search() {
    currentPage = 1;
    fetchAccounts();
  }

  function nextPage() {
    currentPage++;
    fetchAccounts();
  }

  function prevPage() {
    if (currentPage != 1) {
      currentPage--;
      fetchAccounts();
    }
  }

  function fetchAccounts() {
    var pageRequest = {
      PageNumber: currentPage,
      ItemsPerPage: 20,
      OrderBy: { column: "DateAdded" },
      SearchText: searchText,
    };

    ApiServer.Post(urls.accounts, pageRequest).then((data) => {
      accounts = data;
    });
  }

  onMount(async () => {
    fetchAccounts();
  });
</script>

<h3>
  <StripeIcon />
  <ArrowRightIcon />
  <AccountIcon /> Connect Stripe Accounts
</h3>

<div class="card">
  <div class="card-body ">
    <div class="card-title" />
    <div class="card-text">
      <p>
        The purpose of this page is to connect your Bin Bird customer account to
        your Stripe customer account. This will allow you to charge your
        customers Credit Cards for jobs.
      </p>
      <p>
        If the automatic search does not find any results you can click on the
        search Stripe link. Find your Stripe customer, copy their customer ID
        and paste it into the Stripe Customer ID box.
      </p>
      <div class="row">
        <div class="col">
          <div class="row justify-content" style="padding-bottom: 10px;">
            <div class="col-md-10">
              <input
                type="text"
                on:keypress={onSearchKeyDown}
                bind:value={searchText}
                class="form-control"
              />
            </div>
            <div class="col tools">
              <button
                on:click={() => search()}
                class="btn btn-primary"
                title="Search"><SearchIcon /></button
              >
              <button
                on:click={() => clearSearch()}
                class="btn btn-warning"
                title="clear"><DeleteIcon /></button
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          {#if accounts}
            {#each accounts as account}
              <!--Customer Card-->
              <AccountCardStripe {account} />
              <!--END Customer Card-->
            {/each}
          {/if}
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div>
            Total Records: {accounts.length}
          </div>

          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <a
                  class="page-link"
                  href="javascript:void(0)"
                  aria-label="Previous"
                  on:click={prevPage}
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <li class="page-item">
                <a
                  class="page-link"
                  href="javascript:void(0)"
                  on:click={nextPage}
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
