<script>
  export let plan;

  import { Offcanvas } from "sveltestrap";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import Money from "../../../Lib/Money.svelte";
  import LinkButton from "../../../Lib/Buttons/ButtonLink.svelte";
  import ButtonCancel from "../../../Lib/Buttons/ButtonCancel.svelte";
  import ButtonAdd from "../../../Lib/Buttons/ButtonAdd.svelte";
  import DeleteIcon from "../../../Lib/Icons/DeleteIcon.svelte";
  let thePlanTotal = 0;
  let selectedLineItem;

  $: selectedLineItem, calculatePlanTotal();
  $: plan.lineItems, calculatePlanTotal();

  const openEditScreen = () => (smallEdit.isOpen = !smallEdit.isOpen);

  let smallEdit = {
    isOpen: false,
    toggle: openEditScreen,
  };

  function editLineItem(item) {
    selectedLineItem = item;
    openEditScreen();
  }

  function calculatePlanTotal() {
    let bins = 0;
    if (plan.lineItems) {
      thePlanTotal = 0;
      plan.lineItems.forEach((item) => {
        thePlanTotal += item.cost * item.quantity;
        if (item.isBin) {
          bins = bins + item.quantity;
        }
      });

      plan.cost = thePlanTotal;
    }
    let change = { total: thePlanTotal, bins: bins };
    dispatch("lineItemChange", change);
  }

  function validateLineItems() {}

  function removeLineItem(item) {
    plan.lineItems.splice(plan.lineItems.indexOf(item), 1);
    plan.lineItems = plan.lineItems;
  }

  function addLineItem() {
    if (!plan.lineItems) plan.lineItems = [];

    var item = {
      name: "",
      description: "",
      quantity: 1,
      cost: 0.0,
    };
    plan.lineItems = [item, ...plan.lineItems];
  }
</script>

<br />

<!--Line Items-->
<div class="float-end">
  <ButtonAdd text="Line Item" callback={addLineItem} />
</div>
<div style="clear:both" />

{#if plan.lineItems}
  <div class="row table-row-header">
    <div class="col d-md-none">Edit</div>
    <div class="col d-md-none">Name</div>
    <div class="col d-none d-md-block">Name</div>
    <div class="col d-none d-md-block">Description</div>
    <div class="col d-none d-md-block">Is Bin</div>
    <div class="col d-none d-md-block">Quantity</div>
    <div class="col d-none d-md-block">Cost</div>
    <div class="col">Total</div>
    <div class="col">Remove</div>
  </div>
  {#each plan.lineItems as lineItem}
    <div class="row table-row">
      <div class="col d-md-none">
        <LinkButton text="Edit" callback={() => editLineItem(lineItem)} />
      </div>
      <div class="col d-md-none">{lineItem.name}</div>
      <div class="col d-none d-md-block">
        <input
          type="text"
          placeholder="Line Item Name"
          class="form-control form-control-sm"
          bind:value={lineItem.name}
        />
      </div>
      <div class="col d-none d-md-block">
        <input
          type="text"
          placeholder="Line Item Description"
          class="form-control form-control-sm"
          bind:value={lineItem.description}
        />
      </div>
      <div class="col d-none d-md-block">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            id="isBin-"
            checked={lineItem.isBin}
            on:change={(e) => {
              lineItem.isBin = e.target.checked;
            }}
          />
          <label class="form-check-label" for="isBin-">Is Bin</label>
        </div>
      </div>
      <div class="col d-none d-md-block">
        <input
          type="number"
          placeholder="Quantity"
          min="0"
          step="1"
          oninput="validity.valid||(value='');"
          class="form-control form-control-sm"
          bind:value={lineItem.quantity}
        />
      </div>
      <div class="col d-none d-md-block ">
        <input
          type="text"
          class="form-control form-control-sm"
          bind:value={lineItem.cost}
        />
      </div>
      <div class="col">
        <Money amount={lineItem.cost * lineItem.quantity} />
      </div>
      <div class="col">
        <button
          class="btn btn-link"
          type="button"
          on:click={removeLineItem(lineItem)}
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  {/each}
  <div class="row table-row">
    <div class="col d-md-none" />
    <div class="col d-md-none" />
    <div class="col d-none d-md-block" />
    <div class="col d-none d-md-block" />
    <div class="col d-none d-md-block" />
    <div class="col d-none d-md-block" />
    <div class="col d-none d-md-block " />
    <div class="col">
      <strong><Money amount={thePlanTotal} /> Total</strong>
    </div>
    <div class="col" />
  </div>
{/if}

<Offcanvas
  header="Line Item Edit"
  isOpen={smallEdit.isOpen}
  toggle={smallEdit.toggle}
  placement="end"
>
  {#if selectedLineItem}
    <div class="row">
      <div class="group col-xs-12">
        <label for="name">Name</label>
        <input
          type="text"
          class="form-control form-control-sm"
          id="name"
          placeholder="Line item name"
          bind:value={selectedLineItem.name}
        />
      </div>
    </div>

    <div class="row">
      <div class="group col-xs-12">
        <label for="description">Description</label>
        <input
          type="text"
          class="form-control form-control-sm"
          id="description"
          placeholder="Line item description"
          bind:value={selectedLineItem.description}
        />
      </div>
    </div>

    <div class="row">
      <div class="group col-xs-12">
        <label for="quantity">Quantity</label>
        <input
          type="number"
          id="quantity"
          min="0"
          step="1"
          oninput="validity.valid||(value='');"
          class="form-control form-control-sm"
          bind:value={selectedLineItem.quantity}
        />
      </div>
    </div>

    <div class="row">
      <div class="group col-xs-12">
        <label for="cost">Cost</label>
        <input
          type="text"
          id="cost"
          class="form-control form-control-sm"
          bind:value={selectedLineItem.cost}
        />
      </div>
    </div>

    <div class="row">
      <div class="group col-xs-12">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            id="isBin"
            checked={selectedLineItem.isBin}
            on:change={(e) => {
              selectedLineItem.isBin = e.target.checked;
            }}
          />
          <label class="form-check-label" for="isBin">Is Bin</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="group col-xs-12">
        Line Item Total: <Money
          amount={selectedLineItem.cost * selectedLineItem.quantity}
        />
      </div>
    </div>
  {/if}
  <p>
    <ButtonCancel text="Close" callback={smallEdit.toggle} />
  </p>
</Offcanvas>

<style>
  .table-row {
    padding: 3px;
    margin: 2px;
    border-bottom: 1px solid lightgray;
  }

  .table-row-header {
    font-size: 16px;
    font-weight: 700;
    padding: 3px;
    margin: 2px;
    border-bottom: 1px solid gray;
  }
</style>
