<script>
  export let roles;
  import { useNavigate, useLocation } from "svelte-navigator";
  import { user } from "../../Stores/stores";
  import UserStore from "../../Stores/UserStore";

  const navigate = useNavigate();
  const location = useLocation();

  $: if (!$user) {
    navigate("/");
    location.reload();
  }

  let userHasAccess = UserStore.userHasRole(roles);
  if (!userHasAccess) {
    console.log($user.fullName + " does not have access here");
  }
</script>

{#if $user && userHasAccess}
  <slot />
{:else}
  <div>{$user.fullName} does not have access here.</div>
{/if}
