<script>
  export let callback = null;
  export let warningText = "This will be deleted forever.";
  export let title = "Are you sure?";

  import { Popover } from "sveltestrap";

  let buttonId = `binbird-button-${Math.floor(Math.random() * 100)}`;
  let buttonName = "Delete Action";
  let isOpen = false;

  function performAction() {
    if (callback) {
      callback();
    }
    isOpen = false;
  }

  function dismissAction() {
    isOpen = false;
  }
</script>

<div class="b" id={buttonId} name={buttonName}>
  <slot />
</div>
<Popover placement="top" bind:isOpen target={buttonId} {title}>
  <p>{warningText}</p>
  <p>
    <button
      data-bs-toggle="popover"
      class="btn btn-danger btn-sm"
      on:click={performAction}
    >
      Yes
    </button>
    <button
      data-bs-toggle="popover"
      class="btn btn-success btn-sm"
      on:click={dismissAction}>Cancel</button
    >
  </p>
</Popover>
