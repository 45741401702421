<script>
  export let jobList;

  import { Offcanvas, Collapse, Button, Card } from "sveltestrap";
  import TextMessageIcon from "../../../Lib/Icons/TextMessageIcon.svelte";
  import JobMenu from "./JobMenu.svelte";
  import CheckIcon from "../../../Lib/Icons/CheckIcon.svelte";
  import CloseIcon from "../../../Lib/Icons/CloseIcon.svelte";
  import ButtonCancel from "../../../Lib/Buttons/ButtonCancel.svelte";
  import ButtonConfirmAction from "../../../Lib/Buttons/ButtonConfirmAction.svelte";
  import { user } from "../../../Stores/stores";
  import ApiServer from "../../../Server/ApiServer";
  import ProgressLabel from "../../../Lib/ProgressLabel.svelte";
  import AddressGoogleLink from "../../../Lib/AddressGoogleLink.svelte";
  import InputSelect from "../../../Lib/Inputs/InputSelect.svelte";
  import { onMount } from "svelte";
  import TemplateSelector from "../../Company/Templates/_TemplateSelector.svelte";
  import UserStore from "../../../Stores/UserStore";

  let jobActionsOpen = false;
  let selectAll;
  let jobFilter = "All";
  let selectedJobs = [];
  let loading = false;
  let resultMessage = "";
  let moveDate;
  let moveJobsToNewDate = false;
  let sendCustomerMessage = false;

  let selectedTemplate;

  $: moveJobIcon = moveJobsToNewDate ? CheckIcon : CloseIcon;
  $: messageCustomerIcon = sendCustomerMessage ? CheckIcon : CloseIcon;
  let confirmWarningText;

  let urls = {
    moveJobs: "/api/Job/movejobs",
  };

  const toggleJobActions = () => (jobActionsOpen = !jobActionsOpen);

  function openJobActions() {
    //Reset from last message sent.
    resultMessage = "";
    selectedTemplate = null;
    moveDate = null;
    selectedJobs = [];

    jobList.jobs.forEach((job) => {
      if (job.selected) {
        selectedJobs.push(job);
      }
    });
    if (selectedJobs.length == 0) {
      alert("Select at least 1 job to perform this action.");
      return;
    }
    toggleJobActions();
  }

  function selectAllJobs() {
    jobList.jobs.forEach((item) => {
      if (item.show) item.selected = selectAll;
    });
    jobList.jobs = jobList.jobs;
  }

  function filterJobs() {
    if (!jobList) return;

    selectAll = false;
    //selectAll = selectAll;
    jobList.jobs.forEach((job) => {
      //Reset job selection;
      job.selected = false;
      if (job.status == jobFilter || jobFilter == "All") {
        job.show = true;
      } else {
        job.show = false;
      }
    });
    jobList.jobs = jobList.jobs;
  }

  function cancel() {
    moveJobsToNewDate = false;
    sendCustomerMessage = false;
    toggleJobActions();
  }

  function moveAndMessage() {
    let request = {
      moveToDate: moveDate,
      sendMessage: sendCustomerMessage,
      moveJobs: moveJobsToNewDate,
      notificationTemplate: selectedTemplate,
      userName: $user.fullName,
    };

    if (!sendCustomerMessage && !moveJobsToNewDate) {
      alert("Please select at least one action to perform.");
      return;
    }

    request.jobs = [];

    selectedJobs.forEach(function (job) {
      request.jobs.push(job.id);
    });

    loading = true;
    ApiServer.Post(urls.moveJobs, request).then((data) => {
      //show result
      resultMessage = data.message;
      //stop spinner
      loading = false;
    });
  }

  function buildWarningText() {
    let message = "";
    if (sendCustomerMessage && moveJobsToNewDate) {
      message = `Messaging ${selectedJobs.length} customers and moving them to ${moveDate}.`;
    } else if (sendCustomerMessage) {
      message = `Messaging ${selectedJobs.length} customers.`;
    } else if (moveJobsToNewDate) {
      message = `Moving ${selectedJobs.length} customers to ${moveDate} without a message.`;
    } else {
      message = "Please select to Move Jobs, Message Customer, or both above.";
    }
    confirmWarningText = message;
  }

  $: moveJobsToNewDate, buildWarningText();
  $: sendCustomerMessage, buildWarningText();
  $: moveDate, buildWarningText();

  $: jobFilter, filterJobs();
  // $: jobList, filterJobs(); causes double filters each time the jobMenu changes...Not good when there are 500+ jobs
</script>

{#if UserStore.userHasRole(["Admin"])}
  <div class="row">
    <div class="col">
      <div class="one-line">
        <h4>Jobs</h4>
        <div class="right-side">
          <button
            class="btn btn-sm btn-outline-primary"
            on:click={openJobActions}
          >
            <TextMessageIcon /> Move/Message Jobs
          </button>
        </div>
      </div>
    </div>
  </div>
{/if}

{#if jobList}
  <div class="row">
    <div class="col">
      <JobMenu bind:jobFilter {jobList} />
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="row bb-header">
        <div class="col-2">
          <input
            type="checkbox"
            class="form-check-input"
            bind:value={selectAll}
            on:change={(e) => {
              selectAll = e.target.checked;
              selectAllJobs();
            }}
          />
          Map ID
        </div>
        <div class="col">Account</div>
        <div class="col">Address</div>
        <div class="col d-none d-sm-block">Job</div>
        <div class="col d-none d-sm-block">Status</div>
      </div>
      {#each jobList.jobs as job}
        {#if job.show}
          <div class="row bb-row">
            <div class="col-2">
              <input
                type="checkbox"
                class="form-check-input"
                bind:checked={job.selected}
              />
              {job.index}
            </div>
            <div class="col">
              <a href="/account/{job.accountID}">
                {job.accountName}
              </a>
            </div>
            <div class="col">
              <AddressGoogleLink
                showStateZip={false}
                address={job.address}
                city={job.city}
                state={job.state}
                zip={job.zip}
              />
            </div>
            <div class="col d-none d-sm-block">
              Job
              <a href="/account/{job.accountID}/jobs/{job.id}">
                {job.jobNumber}
              </a>
            </div>
            <div class="col d-none d-sm-block">
              {job.status}/{job.paymentStatus}
            </div>
          </div>
        {/if}
      {/each}
    </div>
  </div>
{/if}

<Offcanvas
  header="Job Actions"
  isOpen={jobActionsOpen}
  toggle={toggleJobActions}
  placement="end"
>
  <div class="container">
    <h5>Move and/or message {selectedJobs.length} customers</h5>

    <Button
      color="primary"
      on:click={() => (moveJobsToNewDate = !moveJobsToNewDate)}
      class="mb-3"
    >
      <svelte:component this={moveJobIcon} /> Move Jobs
    </Button>
    <Collapse isOpen={moveJobsToNewDate}>
      <Card body>
        <p>Select the new job date:</p>
        <input type="date" class="form-control" bind:value={moveDate} />
      </Card>
    </Collapse>

    <hr />

    <Button
      color="info"
      on:click={() => (sendCustomerMessage = !sendCustomerMessage)}
      class="mb-3"
    >
      <svelte:component this={messageCustomerIcon} /> Message Customer
    </Button>
    <Collapse isOpen={sendCustomerMessage}>
      <Card body>
        <p>Select the message template or write your own message.</p>
        <TemplateSelector bind:template={selectedTemplate} />
      </Card>
    </Collapse>

    <p>{confirmWarningText}</p>

    {#if loading}
      <div style="font-size: 25px">
        <ProgressLabel text="Loading..." />
      </div>
    {/if}

    <p>{resultMessage}</p>

    <br />
    <br />
    <div class="same-line">
      <ButtonCancel text="Close" callback={cancel} />
      <ButtonConfirmAction
        text="GO!"
        warningText={confirmWarningText}
        callback={moveAndMessage}
      />
    </div>
  </div>
</Offcanvas>

<style>
  .one-line {
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
  }
</style>
