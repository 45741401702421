<script>
  import { onMount } from "svelte";

  export let label;
  export let value;
  export let options;
  export let groupKey;
  export let valueKey = "id";
  export let labelKey = "text";
  export let firstOptionLabel = "Select an option...";
  export let blankFirstOption = true;

  let name = "name";

  //Group the elements into different lists

  let groups = [];

  onMount(async () => {
    if (options) {
      options.forEach(function (option) {
        let label = groups.find((g) => g.text == option[groupKey]);
        if (label) {
          //add more options
          label.options.push({ id: option[valueKey], label: option[labelKey] });
        } else {
          //Add new label and option
          label = {};
          label.text = option[groupKey];
          label.options = [];
          label.options.push({
            id: option[valueKey].toString(),
            label: option[labelKey],
          });
          groups.push(label);
        }
      });
    }
  });
</script>

<label for={name}>{label}</label>
<select id={name} {name} class="form-control" bind:value>
  {#if blankFirstOption}
    <option selected>{firstOptionLabel}</option>
  {/if}

  {#if groups}
    {#each groups as group}
      <optgroup label={group.text}>
        {#each group.options as option}
          <option value={option["id"]}>{option["label"]}</option>
        {/each}
      </optgroup>
    {/each}
  {/if}
</select>
