<script>
  export let settingID;

  import { onMount } from "svelte";
  import ButtonAdd from "../../../Lib/Buttons/ButtonAdd.svelte";
  import ButtonDelete from "../../../Lib/Buttons/ButtonDelete.svelte";
  import ReminderData from "./ReminderData";
  import ApiServer from "../../../Server/ApiServer";
  import { toastNotification } from "../../../Stores/toastNotification";
  import Header from "../Header.svelte";
  import InputSelect from "../../../Lib/Inputs/InputSelect.svelte";
  import TemplateSelector from "./_TemplateSelector.svelte";
  import InputTextBox from "../../../Lib/Inputs/InputTextBox.svelte";

  let setting;
  let templateID;
  let selectedTemplate;

  let urls = {
    getOne: `/api/Company/NotificationSetting/setting?id=`,
    delete: `/api/Company/NotificationSetting/setting?id=`,
    add: `/api/Company/NotificationSetting/setting`,
    methods: `/api/Company/NotificationSetting/methods`,
  };

  function save() {
    setting.notificationMethod = selectedTemplate.templateMethod;
    setting.templateID = templateID;

    if (settingID == "new") {
      ApiServer.Post(urls.add, setting).then((data) => {
        toastNotification.Success("Reminder Saved!");
        window.history.back();
      });
      return;
    }

    ApiServer.Put(urls.add, setting).then((data) => {
      toastNotification.Success("Reminder Updated!");
      window.history.back();
    });
  }

  function remove() {
    ApiServer.Delete(urls.delete + settingID).then((data) => {
      toastNotification.Success("Reminder Removed!");
      window.history.back();
    });
  }

  onMount(async () => {
    if (settingID == "new") {
      setting = {
        templateID: "",
      };
      return;
    }

    ApiServer.Get(urls.getOne + settingID).then((data) => {
      setting = data;
      templateID = setting.templateID;
      setting.hoursBeforeJob = setting.hoursBeforeJob.toString();
    });
  });
</script>

{#if setting}
  {#if selectedTemplate}
    <Header text="{selectedTemplate.name} Reminder" />
  {:else}
    <Header text="Reminder" />
  {/if}

  <article class="card-body">
    <div class="row">
      <div class="col-6">
        <InputSelect
          label="Hours before job"
          bind:value={setting.hoursBeforeJob}
          options={ReminderData.HoursBeforeJob}
        />
      </div>
      <div class="col-6">
        <!-- <InputTextBox label="Time to remind" bind:value={setting.hourToRun} /> -->
        <InputSelect
          label="Time to remind"
          bind:value={setting.hourToRun}
          options={ReminderData.HourToRun}
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <TemplateSelector
          editable={false}
          bind:templateID
          bind:template={selectedTemplate}
        />
      </div>
    </div>
    <hr />
    <ButtonAdd text="Save" callback={save} />
    <ButtonDelete text="Delete" callback={remove} />
  </article>
{/if}
