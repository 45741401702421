<script>
  import { onMount } from "svelte";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import { Alert } from "sveltestrap";
  import ButtonCancel from "../../../../Lib/Buttons/ButtonCancel.svelte";
  import ButtonIcon from "../../../../Lib/Buttons/ButtonIcon.svelte";
  import ButtonSave from "../../../../Lib/Buttons/ButtonSave.svelte";
  import EmailIcon from "../../../../Lib/Icons/EmailIcon.svelte";
  import TestIcon from "../../../../Lib/Icons/TestIcon.svelte";
  import InputTextArea from "../../../../Lib/Inputs/InputTextArea.svelte";
  import InputTextBox from "../../../../Lib/Inputs/InputTextBox.svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import { toastNotification } from "../../../../Stores/toastNotification";
  import { user } from "../../../../Stores/stores";

  let fromEmail;
  let verificationStatus;
  var showSendTest = false;
  let testEmailTo;
  let testEmailBody = "Test email from Bin Bird!";
  let emailSendStatus;

  let urls = {
    get: `/api/Company/Settings/EmailSettings/getemailidentity`,
    setNewEmail: `/api/Company/Settings/EmailSettings/setemailidentity?emailAddress=`,
    checkEmail: `/api/Company/Settings/EmailSettings/checkemailidentity?emailAddress=`,
    sendTestEmail: `/api/Company/Settings/EmailSettings/testemail`,
  };

  function loadSettings() {
    ApiServer.Get(urls.get).then((data) => {
      if (data) {
        fromEmail = data;
      } else {
        fromEmail = "sendfrom@email.com";
      }
    });
  }

  function getEmailVerificationStatus() {
    ApiServer.Get(urls.checkEmail + fromEmail).then((data) => {
      if (data.success) {
        verificationStatus = "Email is verified and ready to be used.";
        dispatch("onVerificationStatus");
      } else {
        verificationStatus =
          "Email is waiting verification status or something went wrong.";
      }
    });
  }

  function sendTestEmail() {
    showSendTest = true;
  }

  function sendEmail() {
    if (!testEmailTo) {
      emailSendStatus = "Please enter a To email address";
      return;
    }
    if (!testEmailBody) {
      emailSendStatus = "Please enter an email body";
      return;
    }

    var request = {
      toEmail: testEmailTo,
      subject: "Test email from Bin Bird",
      body: testEmailBody,
    };

    ApiServer.Post(urls.sendTestEmail, request).then((data) => {
      if (data.success) {
        toastNotification.Success("Test email successfully sent.");
      } else {
        toastNotification.Error("Test email failed to send." + data.message);
      }
      emailSendStatus = data.message;
    });
  }

  function saveSettings() {
    ApiServer.Get(urls.setNewEmail + fromEmail).then((data) => {
      if (data.success) {
        verificationStatus = data.message;
        toastNotification.Success("From Email saved!");
      } else {
        verificationStatus =
          "Email was saved but verification failed: " + data.message;
        toastNotification.Error(verificationStatus);
      }
    });
  }

  onMount(async () => {
    loadSettings();
  });
</script>

<h4>Your company Email</h4>
<ul>
  <li>
    This email address us used when email is sent from Bin Bird. This is the
    email address your customer will see.
  </li>
  <li>
    Email Verification must be successful in order for Bin Bird to send email on
    behalf of {$user.companyName}.
  </li>
  <li>
    Once you have Verified the email, come back here and click the Check Status
    button.
  </li>
</ul>

<h5>Getting Started</h5>
<ol>
  <li>Enter your company email address below.</li>
  <li>
    You will receive an verification email that <strong>Requires Action</strong
    >.
  </li>
  <li>
    Click the link at the bottom of that email to verifiy this email address.
  </li>
</ol>

{#if fromEmail}
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col">
          <InputTextBox label="From Email Address" bind:value={fromEmail} />
        </div>
      </div>
    </div>
  </div>
{/if}

{#if verificationStatus}
  <div class="row">
    <div class="col">
      <Alert color="primary">
        <h4 class="alert-heading text-capitalize">Verification Status</h4>
        {verificationStatus}
      </Alert>
    </div>
  </div>
{/if}

<div class="row">
  <div class="col">
    <div class="same-line">
      <ButtonSave text="Save" callback={saveSettings} />

      <ButtonCancel text="Check Status" callback={getEmailVerificationStatus} />

      <ButtonIcon
        text="Send Test Email"
        icon={TestIcon}
        callback={sendTestEmail}
      />
    </div>
  </div>
</div>

{#if showSendTest}
  <hr />
  <div class="row">
    <div class="col">
      <InputTextBox label="Email To:" bind:value={testEmailTo} />
    </div>
  </div>

  <div class="row">
    <div class="col">
      <InputTextArea label="Email Body" bind:value={testEmailBody} />
    </div>
  </div>

  <ButtonIcon text="Send Email" icon={EmailIcon} callback={sendEmail} />

  {#if emailSendStatus}
    <br />
    {emailSendStatus}
  {/if}
{/if}
