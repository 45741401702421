<script>
  import { onMount } from "svelte";

  import Chart from "svelte-frappe-charts";
  import ApiServer from "../Server/ApiServer";
  import BrokenHeartIcon from "../Lib/Icons/BrokenHeartIcon.svelte";
  import DashboardMessage from "./_DashboardMessage.svelte";

  // let data = {
  //   labels: [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "July",
  //     "Aug",
  //     "Sept",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ],
  //   datasets: [
  //     { values: [89, 121, 134, 132, 145, 201, 220, 250, 165, 189, 142, 107] },
  //   ],
  // };

  let urls = {
    accounts: `/api/Dashboard/accountsthisyear`,
  };

  let chartRef;
  let count = 0;
  let data;

  let barOptions = {
    stacked: 1,
  };

  onMount(async () => {
    ApiServer.Get(urls.accounts).then((result) => {
      data = result;
      count = result.totalCount;
    });
  });
</script>

<div class="card">
  <div class="card-body">
    <div class="card-title">
      <h3>{count} customers this year</h3>
    </div>
    <div class="card-text">
      {#if data && count > 0}
        <Chart
          {data}
          valuesOverPoints="1"
          {barOptions}
          bind:this={chartRef}
          type="bar"
        />
      {:else}
        <DashboardMessage icon={BrokenHeartIcon} />
      {/if}
    </div>
  </div>
</div>
