<script>
  export let company;

  import AccountInformation from "./AccountInformation.svelte";
  import Billing from "./Billing.svelte";
  import BillingPlan from "./BillingPlan.svelte";
  import Header from "./Header.svelte";
  import NextCleaning from "./NextCleaning.svelte";
  import PaymentMethod from "./PaymentMethod.svelte";
  import ServiceHistory from "./ServiceHistory.svelte";
  import PortalApiServer from "../../Server/PortalApiServer";

  import { portaluser } from "../../Stores/PortalUser";

  let urls = {
    portalSummary: "/api/portal/portal/portalsummary?accountID=",
  };

  let portalSummary;

  function fetchPortalSummary() {
    PortalApiServer.Get(urls.portalSummary + $portaluser.accountID).then(
      (data) => {
        portalSummary = data;
      }
    );
  }

  $: $portaluser, fetchPortalSummary();
</script>

{#if company}
  <Header {company} />
  {#if portalSummary}
    <div class="box">
      <div><NextCleaning {portalSummary} /></div>
      <div><AccountInformation {portalSummary} /></div>

      {#if portalSummary.billingIsEnabled}
        <div><Billing {portalSummary} /></div>
        <div><PaymentMethod {portalSummary} /></div>
      {/if}

      <div><BillingPlan {portalSummary} /></div>

      <div><ServiceHistory {portalSummary} /></div>
    </div>
  {:else}
    <div class="box">
      <div>Loading your information...</div>
    </div>
  {/if}
{/if}

<style>
  .box {
    display: flex;
    flex-wrap: wrap;
  }
  .box > * {
    flex: 1 1 460px;
    margin: 5px 5px 0 5px;
  }
</style>
