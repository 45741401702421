<script>
  export let invoiceID;

  import { onMount } from "svelte";
  import { Alert } from "sveltestrap";
  import Modal from "sv-bootstrap-modal";

  import Back from "../../Lib/Buttons/Back.svelte";
  import DateFormat from "../../Lib/DateFormat.svelte";
  import Money from "../../Lib/Money.svelte";
  import ApiServer from "../../Server/ApiServer";
  import { user } from "../../Stores/stores";
  import ButtonCancel from "../../Lib/Buttons/ButtonCancel.svelte";
  import { toastNotification } from "../../Stores/toastNotification";

  let urls = {
    invoicedetail: "/api/Company/SystemBilling/invoice?id=",
    makepayment: "/api/Company/SystemBilling/makepayment",
  };

  let invoice;
  let paymentMessage;
  let showPaymentScreen = false;

  onMount(async () => {
    ApiServer.Get(urls.invoicedetail + invoiceID).then((data) => {
      invoice = data;
    });
  });

  function showMakePayment() {
    showPaymentScreen = true;
  }

  function hideMakePayment() {
    showPaymentScreen = false;
  }

  function handlePayInvoice() {
    let paymentRequest = {
      invoiceID: invoice.invoice.id,
    };

    ApiServer.Post(urls.makepayment, paymentRequest).then((data) => {
      if (data.success) {
        toastNotification.Success("Invoice successfully paid");
        showPaymentScreen = false;
        location.reload();
      } else {
        toastNotification.Success("Invoice payment failed. " + data.message);
        paymentMessage = data.message;
      }
    });
  }

  function print() {
    var printElement = document.getElementById("print-me");
    // var originalContents = document.body.innerHTML;

    var inner = document.getElementById("inner-print");
    inner.classList.add("printable-area");
    var printContents = printElement.innerHTML;

    document.body.innerHTML = printContents;
    window.print();

    //inner.classList.remove("printable-area");
    //document.body.innerHTML = originalContents;
    location.reload();
  }
</script>

{#if invoice}
  <div class="row">
    <div class="col"><Back /></div>
    <div class="col right">
      <button class="btn btn-primary" style="margin-right: 5px" on:click={print}
        >Print</button
      >
      {#if invoice.invoice.paymentSuccessful == false}
        <button class="btn btn-outline btn-success" on:click={showMakePayment}>
          Pay This Invoice</button
        >
      {/if}
    </div>
  </div>

  <div id="print-me">
    <div id="inner-print">
      {#if invoice.invoice.pastDue}
        <Alert color="danger"
          >This invoice is {invoice.invoice.daysPastDue} days overdue.</Alert
        >
      {/if}
      <div class="row">
        <div class="col">
          <div class="header">
            <img
              width="25%"
              height="25%"
              src="/images/logo_horizontal_cropped_100.png"
              alt="Bin Bird Logo"
            />
            <p>Software for Bin Cleaners</p>
            <h5>Bill To: {$user.companyName}</h5>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col">
              <span class="title">Invoice Number:</span>
              {invoice.invoice.invoiceNumber} For {invoice.invoice
                .month}/{invoice.invoice.year}
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="title">Invoice Date:</span>
              <DateFormat date={invoice.invoice.dateBilled} />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="title">Due Date:</span>
              <DateFormat date={invoice.invoice.dueDate} />
            </div>
          </div>
          <div class="row">
            <div class="col">
              {#if invoice.invoice.datePaid}
                <span class="title">Date Paid:</span>
                <DateFormat date={invoice.invoice.datePaid} />
              {:else}
                <span class="title">Date Paid:</span>
              {/if}
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="title">Amount Paid:</span>
              <Money amount={invoice.invoice.totalPaid} />
            </div>
          </div>
        </div>
      </div>

      <hr />
      <h3>Total Due <Money amount={invoice.invoice.totalBill} /></h3>
      <hr />

      <h4>Summary Of Charges</h4>
      <div class="row bb-header">
        <div class="col">Category</div>
        <div class="col">Quantity</div>
        <div class="col">Cost</div>
      </div>
      {#each invoice.invoiceSummaryList as summary}
        <div class="row bb-row">
          <div class="col">{summary.chargeType}</div>
          <div class="col">{summary.quantity}</div>
          <div class="col"><Money amount={summary.chargeAmount} /></div>
        </div>
      {/each}

      <h4>Detailed Daily Usage</h4>
      <div class="row bb-header">
        <div class="col">Day</div>
        <div class="col">Category</div>
        <div class="col">Quantity</div>
        <div class="col">Cost</div>
      </div>
      {#each invoice.invoiceDetailList as summary}
        <div class="row bb-row">
          <div class="col"><DateFormat date={summary.day} /></div>
          <div class="col">{summary.chargeType}</div>
          <div class="col">{summary.quantity}</div>
          <div class="col"><Money amount={summary.chargeAmount} /></div>
        </div>
      {/each}
    </div>
  </div>
{/if}

<Modal bind:open={showPaymentScreen} ignoreBackdrop={true}>
  <div class="modal-header">
    <h5 class="modal-title">Pay Invoice</h5>
    <button type="button" class="close" on:click={hideMakePayment}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      This will charge the card on file <Money
        amount={invoice.invoice.totalBill}
      />. Are you sure?
    </p>

    <button class="btn btn-primary" on:click={handlePayInvoice}
      >Make Payment</button
    >

    {#if paymentMessage}
      <p>
        {paymentMessage}
      </p>
    {/if}
  </div>
  <div class="modal-footer">
    <ButtonCancel text="Close" callback={hideMakePayment} />
  </div>
</Modal>

<style>
  .title {
    font-weight: bold;
  }

  .right {
    display: -webkit-flex;
    display: flex;
    justify-content: flex-end;
  }
</style>
