<script>
  export let planID;
  import { onMount } from "svelte";
  import ApiServer from "../../../Server/ApiServer";
  import { toastNotification } from "../../../Stores/toastNotification";
  import BillingPlanLineItem from "./BillingPlanLineItem.svelte";
  import Header from "../Header.svelte";
  import Checkbox from "../../../Lib/Inputs/InputCheckbox.svelte";
  import PickupDays from "../../Checkout/PickupDays.svelte";
  import Money from "../../../Lib/Money.svelte";
  import RuleRecurringRule from "./RuleRecurringRule.svelte";
  import RuleBinCharges from "./RuleBinCharges.svelte";
  import RuleStartupFees from "./RuleStartupFees.svelte";
  import RuleNoShow from "./RuleNoShow.svelte";
  import RuleRescheduleRule from "./RuleRescheduleRule.svelte";
  import RuleCheckoutFields from "./RuleCheckoutFields.svelte";

  let plan;
  let rules;
  let checkoutRules;
  let totalCost;

  let urls = {
    get: `/api/Company/BillingPlan/existingbillingplan?id=${planID}`,
    getDefaultPlanRules: `/api/Company/BillingPlan/defaultbillingplanrules`,
    update: `/api/Company/BillingPlan/billingplan`,
    create: `/api/Company/BillingPlan/billingplan`,
    delete: `/api/Company/BillingPlan/billingplan?id=${planID}`,
    checkoutRules: `/api/Company/CompanyInfo/checkoutrules`,
  };

  function save() {
    plan.billingPlanRuleText = JSON.stringify(rules);
    plan.cost = totalCost;
    ApiServer.Post(urls.create, plan).then((data) => {
      toastNotification.Success("Plan Saved!");
      window.history.back();
    });
  }

  function remove() {
    ApiServer.Delete(urls.delete).then((data) => {
      if (data.success) {
        toastNotification.Success("Plan Deleted!");
        window.history.back();
      } else {
        toastNotification.Error(data.message);
      }
    });
  }

  function setCheckoutRules() {
    ApiServer.Get(urls.checkoutRules).then((data) => {
      checkoutRules = data.checkoutRule;

      // if (checkoutRules) {
      //   loadedPickupDays = [];
      //   checkoutRules.pickupDays.forEach((checkoutPickup) => {
      //     loadedPickupDays.push(checkoutPickup);
      //   });

      //   //Setting defaults for the calculator so the user does not have to pick a pickup day of week to save Billing Plan.
      //   loadedPickupDays.forEach((pickupDay) => {
      //     pickupDay.day = {};
      //     pickupDay.day.name == "Monday";
      //     pickupDay.estimatedJobDate = new Date();
      //     pickupDay.weekOfMonth = 1;
      //   });
      // }
    });
  }

  let planName = "";
  let loadedPickupDays;

  onMount(async () => {
    setCheckoutRules();
    var billingPlanUrl = urls.get;

    if (planID == "new") {
      plan = {};
      billingPlanUrl = urls.getDefaultPlanRules;
    }

    ApiServer.Get(billingPlanUrl).then((data) => {
      plan = data;

      planName = planID == "new" ? "New Billing Plan" : plan.name;
      rules = plan.billingPlanRules;
      //Not sure how to make this better.  The value is an int but the select will only match on a string
      rules.recurringRule.interval = rules.recurringRule.interval.toString();
      rules.recurringRule.frequency = rules.recurringRule.frequency.toString();
      rules.rescheduleRule.rule = rules.rescheduleRule.rule.toString();
    });
  });

  function updatePlanCost() {
    //I know its weird but it triggers cascading changes through all the parts.
    plan = plan;
  }
</script>

{#if rules}
  <Header text={planName} />
  <form on:submit|preventDefault={save}>
    <div class="row">
      <div class="col-sm-12">
        <article class="card-body">
          <h3>Billing Plan Description</h3>
          <div class="row">
            <div class="form-group col-md-6">
              <label for="firstName">Plan Name</label>
              <input
                type="text"
                class="form-control"
                id="planName"
                placeholder="Plan Name"
                bind:value={plan.name}
              />
            </div>
            <div class="form-group col-md-6">
              <Checkbox
                bind:value={plan.visibleOnCheckout}
                text="Visible for customer checkout?"
                label="Publicly Visible"
              />
            </div>
          </div>

          <div class="row">
            <div class="col form-group">
              <label for="desc"
                >Biling Plan Notes <small
                  >(Publicly visible on checkout page!)</small
                ></label
              >
              <textarea
                id="desc"
                class="form-control"
                bind:value={plan.description}
                rows="2"
              />
            </div>
          </div>
          <!-- <hr />
          <h3>{plan.name} Plan Rules</h3> -->

          <div class="rule-section">
            <RuleBinCharges {rules} />
          </div>

          <div class="rule-section">
            <RuleRecurringRule {rules} />
          </div>

          <div class="rule-section">
            <RuleStartupFees {rules} />
          </div>

          <div class="rule-section">
            <h4>Additional Line Items</h4>
            <p>
              These line items will be added to each job generated by this
              Billing Plan.
            </p>
            <BillingPlanLineItem {plan} />
          </div>

          <div class="rule-section">
            {#if checkoutRules}
              <h4>{plan.name} calculator</h4>
              <p>
                Using all the setting above this, section can be used to
                determine the cost of this billing plan. When pressing Save this
                cost will be recorded on the Billing Plan. <br />
                <small
                  >These settings are only used as a sample calculation. Actual
                  cost is determined by the customers selection at checkout.</small
                >
              </p>
              <PickupDays
                billingPlan={plan}
                {loadedPickupDays}
                bind:totalCost
                {checkoutRules}
              />

              {#if totalCost}
                <div class="row">
                  <div class="col form-group">
                    <h4>Total Cost <Money amount={totalCost} /></h4>
                  </div>
                  <div class="col form-group">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      on:click={updatePlanCost}>Calculate Total</button
                    >
                  </div>
                </div>
              {/if}
            {/if}
          </div>
          <hr />
          <p>The following rules do not affect the cost of the billing plan.</p>

          <div class="rule-section">
            <RuleRescheduleRule {rules} />
          </div>

          <div class="rule-section">
            <RuleNoShow {rules} />
          </div>

          <div class="rule-section">
            <RuleCheckoutFields {rules} />
          </div>

          <button type="submit" class="btn btn-primary">Save</button>
          <button type="submit" class="btn btn-primary" on:click={remove}
            >Remove</button
          >
        </article>
      </div>
    </div>
  </form>
{/if}

<style>
  .rule-section {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;

    border: 1px solid lightgray;
    border-radius: 5px;
  }
</style>
