<script>
  export let label;
  export let value;
  export let options;
  export let valueKey = "id";
  export let labelKey = "text";
  export let firstOptionLabel = "Select an option...";
  export let blankFirstOption = true;

  let name = "name";
</script>

<label for={name}>{label}</label>
<select id={name} {name} class="form-control" bind:value>
  {#if blankFirstOption}
    <option value="" selected>{firstOptionLabel}</option>
  {/if}

  {#if options}
    {#each options as option}
      <option value={option[valueKey]}>{option[labelKey]}</option>
    {/each}
  {/if}
</select>
