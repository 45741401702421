<script>
  export let job;
  export let jobOptions;

  import { onMount } from "svelte";
  import JobLogIcon from "../../../Lib/Icons/JobLogIcon.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import UTCDate from "../../../Util/UTCDate";
  import { user } from "../../../Stores/stores";
  import DateFormat from "../../../Lib/DateFormat.svelte";
  import DeleteIcon from "../../../Lib/Icons/DeleteIcon.svelte";

  let logs;
  let logReason = "";
  let altLogReason = "";

  let urls = {
    jobLogs: `/api/Job/joblogs?jobID=`,
    addJobLog: `/api/Job/joblog`,
    removeJobLog: `/api/Job/joblog?jobLogID=`,
  };

  function deleteJobLog(jobLog) {
    ApiServer.Delete(urls.removeJobLog + jobLog.id).then((data) => {
      logs.splice(logs.indexOf(jobLog), 1);
      logs = logs;
    });
  }

  function addJobLog() {
    if (logReason == "Other") {
      logReason = altLogReason;
    }

    var log = {
      jobID: job.id,
      accountID: job.accountID,
      logDate: UTCDate.UtcNow(),
      logText: logReason,
      userName: $user.fullName,
    };

    ApiServer.Post(urls.addJobLog, log).then((data) => {
      if (logReason) logs = [...logs, data];
    });
  }

  function getLogs() {
    ApiServer.Get(urls.jobLogs + job.id).then((data) => {
      logs = data;
    });
  }

  onMount(async () => {
    logReason = jobOptions.jobLogOptions[0];
  });
  $: job, getLogs();
</script>

<hr />

<div><h4>Job Log</h4></div>
<button
  type="button"
  on:click={addJobLog}
  class="btn btn-sm btn-outline-primary float-end"
>
  <div><JobLogIcon /> Add</div></button
>

<div class="row">
  <div class="col">
    <select class="form-control" bind:value={logReason}>
      {#each jobOptions.jobLogOptions as option}
        <option value={option}>{option}</option>
      {/each}
    </select>

    {#if logReason == "Other"}
      <input
        type="text"
        maxlength="25"
        bind:value={altLogReason}
        class="form-control"
      />
    {/if}
  </div>
</div>

{#if logs}
  {#each logs as log}
    <div class="row">
      <div class="col-1">
        <span class="log-delete" on:click={() => deleteJobLog(log)}
          ><DeleteIcon /></span
        >
      </div>
      <div class="col-11">
        <DateFormat formatType="date-time-short" date={log.logDate} />
        <strong>{log.logText}</strong>
        {log.userName}
      </div>
    </div>
  {/each}
{/if}

<style>
  .log-delete {
    cursor: pointer;
  }
</style>
