<script>
  export let job;
  import { onMount } from "svelte";
  import Fa from "svelte-fa";
  import {
    faPlusSquare,
    faWindowClose,
    faSave,
  } from "@fortawesome/free-solid-svg-icons";

  import JobNote from "./JobNote.svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import UserStore from "../../../../Stores/UserStore";
  import UTCDate from "../../../../Util/UTCDate";
  import ButtonAdd from "../../../../Lib/Buttons/ButtonAdd.svelte";
  import ButtonSave from "../../../../Lib/Buttons/ButtonSave.svelte";
  import ButtonCancel from "../../../../Lib/Buttons/ButtonCancel.svelte";

  let notes = [];
  let addNote = false;
  let noteText;
  let currentUser = UserStore.getUser();
  let currentNote = null;

  let urls = {
    getnotes: `/api/JobNote/notes?jobID=`,
    addnote: `/api/JobNote/note`,
    editNote: `/api/JobNote/note`,
    deletenote: `/api/JobNote/note?id=`,
  };

  function showAddNote() {
    addNote = true;
  }

  function handleNoteDelete(event) {
    var note = event.detail;
    ApiServer.Delete(urls.deletenote + note.id).then((result) => {
      if (result > 0) {
        notes.splice(notes.indexOf(note), 1);
        notes = notes;
        cancelAddNote();
      }
    });
  }

  function cancelAddNote() {
    noteText = "";
    addNote = false;
    currentNote = null;
  }

  function saveNote() {
    if (currentNote) {
      saveNoteEdit();
      return;
    }

    var note = {
      body: noteText,
      jobID: job.id,
      accountID: job.accountID,
      noteDate: UTCDate.UtcNow(),
      userName: currentUser.fullName,
    };
    ApiServer.Post(urls.addnote, note).then((data) => {
      notes = [data, ...notes];
      cancelAddNote();
    });
  }

  function loadEditNote(event) {
    currentNote = event.detail;
    noteText = currentNote.body;
    addNote = true;
  }

  function saveNoteEdit() {
    currentNote.body = noteText;
    ApiServer.Put(urls.editNote, currentNote).then((data) => {
      currentNote = data;
      fetchNotes();
      cancelAddNote();
    });
  }

  onMount(async () => {
    fetchNotes();
  });

  function fetchNotes() {
    ApiServer.Get(urls.getnotes + job.id).then((data) => {
      notes = data;
    });
  }
</script>

<p>Notes for this job.</p>

{#if job}
  <div class="fload-end">
    <ButtonAdd text="Add Note" callback={showAddNote} />
  </div>

  {#if addNote}
    <div class="border-success">
      <p>
        <textarea class="form-control" bind:value={noteText} rows="2" />
      </p>
    </div>
    <div class="button-container">
      <ButtonSave text="Save" callback={saveNote} />
      <ButtonCancel text="Cancel" callback={cancelAddNote} />
    </div>
  {/if}

  {#if notes}
    {#each notes as note}
      <div class="notes">
        <JobNote
          {note}
          on:deleteNote={handleNoteDelete}
          on:loadEditNote={loadEditNote}
        />
      </div>
    {/each}
  {/if}

  <style>
    div :global(.large-icon) {
      font-size: 20px;
    }
    .notes {
      margin-bottom: 3px;
    }
    .button-container {
      margin-bottom: 10px;
    }
  </style>
{/if}
