<script>
  export let results;

  import ButtonLink from "../Buttons/ButtonLink.svelte";

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  function selectResult(result) {
    results = null;
    dispatch("resultSelected", result);
  }

  function clearSearch() {
    results = null;
  }
</script>

<div class="search-container">
  <div class="row">
    <div class="container">
      <div class="col" style="position: relative">
        {#if results}
          <ul class="result list-group">
            <li class="list-group-item">
              <div class="row">
                <div class="col d-flex justify-content-end">
                  <ButtonLink text="Clear" callback={clearSearch} />
                </div>
              </div>
            </li>
            {#each results as result}
              <li class="list-group-item">
                <div class="row">
                  <div class="col">
                    <div
                      class="result-item"
                      on:click={() => selectResult(result)}
                    >
                      {result.label}
                    </div>
                  </div>
                </div>
              </li>
            {/each}
          </ul>
        {/if}
      </div>
    </div>
  </div>
</div>

<style>
  .result {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    position: absolute;
  }
  .search-container {
    margin-bottom: 10px;
  }
  .result-item {
    cursor: pointer;
  }
</style>
