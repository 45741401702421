<script>
  export let tutorialSettings;

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import CheckoutRulesComponenet from "../CheckoutRules/_CheckOutRulesComponent.svelte";

  function saveSettings() {
    tutorialSettings.tutorialSettings.checkoutRulesDone = true;
    dispatch("saveSettings");
  }
</script>

<div class="row">
  <div class="col">
    <div class="card mb-3">
      <div class="card-header">Checkout Rules</div>
      <div class="card-body">
        <div class="card-text">
          <CheckoutRulesComponenet on:onCheckoutRuleSave={saveSettings} />
        </div>
        <div class="card-footer" />
      </div>
    </div>
  </div>
</div>
