<script>
  export let requestID;

  import { onMount } from "svelte";
  import InputPassword from "../Lib/Inputs/InputPassword.svelte";
  import InputTextBox from "../Lib/Inputs/InputTextBox.svelte";
  import ApiServer from "../Server/ApiServer";
  import { toastNotification } from "../Stores/toastNotification";
  import PublicPageHeader from "./PublicPageHeader.svelte";
  import FormatChecker from "../Util/FormatChecker";

  let password;
  let confirmPassword;
  let signUpRequest;
  let signUpRequestFail;
  let signUpPending = true;
  let strongPassword = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );

  let urls = {
    verify: "/api/public/signup/verifynewsignup",
    getRequest: `/api/public/signup/signup?id=${requestID}`,
  };

  function handleSignUpComplete() {
    if (password != confirmPassword) {
      toastNotification.Error("Passwords do not match.");
      return;
    }

    if (!requestID) {
      toastNotification.Error("Invalid Sign Up Request.");
      return;
    }

    if (!strongPassword.test(password)) {
      toastNotification.Warning(
        "Password not strong enough. Please provide 1 lower case, 1 upper case, one digit, one special character and at least 8 characters long."
      );
      return;
    }

    if (!FormatChecker.UrlIsValid(signUpRequest.companyWebSite)) {
      toastNotification.Warning(
        "Please make sure the company URL contains HTTP or HTTPS."
      );
      return;
    }

    if (!FormatChecker.PhoneNumberIsValid(signUpRequest.companyPhoneNumber)) {
      toastNotification.Warning(
        "Please make sure the company Phone Number is correct."
      );
      return;
    }

    if (!FormatChecker.PhoneNumberIsValid(signUpRequest.phoneNumber)) {
      toastNotification.Warning(
        "Please make sure your Mobile Phone Number is correct."
      );
      return;
    }

    signUpRequest.password = password;
    signUpRequest.confirmPassword = confirmPassword;

    ApiServer.PostAnonymous(urls.verify, signUpRequest).then((data) => {
      if (data.success) {
        toastNotification.Success(
          "Sign up success. Please login with your email and password."
        );
        signUpPending = false;
      } else {
        if (data.message) {
          toastNotification.Error("Sign up request failed: " + data.message);
        }
      }
    });
  }

  onMount(async () => {
    ApiServer.GetAnonymous(urls.getRequest).then((data) => {
      if (data.success) {
        signUpRequest = data.data;
      } else {
        toastNotification.Error(
          "Error retrieving sign up request: " + data.message
        );
        signUpRequestFail = {
          message: data.message,
        };
      }
    });
  });
</script>

<svelte:head>
  <title>Bin Bird - Sign up complete</title>
</svelte:head>

<main class="container-fluid container-sm body-content">
  <PublicPageHeader />

  {#if signUpRequest}
    <main class="container-fluid container-sm body-content">
      {#if signUpPending}
        <div class="row">
          <div class="col-lg-6 mx-auto">
            <h1>Sign Up almost done!</h1>
            <div class="card">
              <div class="card-body ">
                <div
                  class="card-title d-flex justify-content-between align-items-center"
                >
                  <h5>
                    Last step! Review your information and set your password.
                  </h5>
                </div>
                <div class="card-text">
                  <form on:submit|preventDefault={handleSignUpComplete}>
                    <div class="row">
                      <div class="col">
                        <InputTextBox
                          label="Company Name"
                          value={signUpRequest.companyName}
                          readonly={true}
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <InputTextBox
                          label="Company Website"
                          bind:value={signUpRequest.companyWebSite}
                        />
                      </div>
                      <div class="col">
                        <InputTextBox
                          label="Company Phone Number"
                          bind:value={signUpRequest.companyPhoneNumber}
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <InputTextBox
                          label="First Name"
                          bind:value={signUpRequest.firstName}
                          required={true}
                        />
                      </div>
                      <div class="col">
                        <InputTextBox
                          label="Last Name"
                          bind:value={signUpRequest.lastName}
                          required={true}
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <InputTextBox
                          label="Email Address"
                          value={signUpRequest.email}
                          readonly={true}
                        />
                      </div>
                      <div class="col">
                        <InputTextBox
                          label="Mobile Phone"
                          bind:value={signUpRequest.phoneNumber}
                          required={true}
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <InputPassword
                          label="Password"
                          bind:value={password}
                          required={true}
                        />
                      </div>
                      <div class="col">
                        <InputPassword
                          label="Confirm Password"
                          bind:value={confirmPassword}
                          required={true}
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <br />
                        <button type="submit" class="btn btn-primary">
                          Save & Go
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/if}
      {#if !signUpPending}
        <div class="row">
          <div class="col-lg-6 mx-auto">
            <h1>Finished!</h1>
            <div class="card">
              <div class="card-body ">
                <div class="card-text">
                  <p>Your Bin Bird account is ready and waiting!</p>
                  <h5>Happy Cleaning!</h5>
                  <p>
                    <a href="/" class="btn btn-danger">Go to Sign In page</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/if}
    </main>
  {/if}

  {#if signUpRequestFail}
    <div class="row">
      <div class="col-lg-6 mx-auto">
        <h1>Bin Bird Sign Up Request Expired!</h1>
        <div class="card">
          <div class="card-body ">
            <h5>
              It appears your sign up request has expired or is already
              complete.
            </h5>
            <div class="card-text">
              <p>{signUpRequestFail.message}</p>
              <p>
                Please check your email and other records. It is possible the
                request has expired or you already completed this step. Feel
                free to reach out if you need help.
              </p>
            </div>
            <div class="row">
              <div class="col">
                <br />
                <a href="/" class="btn btn-danger">Back to Sign In </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  {/if}
</main>
