<script>
  export let label;
  export let value;
  export let options;
  export let required = false;
  export let firstOptionLabel = "Select an option...";
  export let blankFirstOption = true;

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  function handleChangeCallBack() {
    dispatch("valueChanged", value);
  }

  let name = "name";
</script>

<label for={name}>{label}</label>
<select
  id={name}
  {name}
  class="form-control"
  bind:value
  on:change={handleChangeCallBack}
  {required}
>
  {#if blankFirstOption}
    <option value="" selected>{firstOptionLabel}</option>
  {/if}

  {#if options}
    {#each options as option}
      <option value={option}>{option}</option>
    {/each}
  {/if}
</select>
