<script>
  import { onMount } from "svelte";

  import Header from "../Header.svelte";
  import * as Env from "../../../Constant/Environment";
  import AddIcon from "../../../Lib/Icons/AddIcon.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import DateFormat from "../../../Lib/DateFormat.svelte";
  import ButtonDelete from "../../../Lib/Buttons/ButtonDelete.svelte";
  import { toastNotification } from "../../../Stores/toastNotification";

  let uploadMessage;
  let uploadFiles = [];
  let imports;

  const urls = {
    getImports: "/api/Account/AccountImport/imports",
    deleteImport: "/api/Account/AccountImport/import?id=",
    uploadFile: `/api/Account/AccountImport/uploadimportfile`,
    emailResult: `/api/Account/AccountImport/sendreport?id=`,
  };

  function onNewFileSelected(e) {
    for (let i = 0; i < e.target.files.length; i++) {
      uploadFiles = [...uploadFiles, e.target.files[i]];
    }
  }

  async function uploadFile(file) {
    var fName = file.name;
    var fType = file.type;
    var fSize = file.size;

    const formData = new FormData();
    formData.append("file", file, fName);

    let fileUploadResult = await ApiServer.PostFile(urls.uploadFile, formData);

    if (fileUploadResult.success) {
      toastNotification.Success("Import Added.");
      if (!imports) imports = [];
      uploadFiles = [];
    } else {
      toastNotification.Error(
        "Import Upload Failed. " + fileUploadResult.message
      );
    }

    imports.push(fileUploadResult.data);
    imports = imports;
  }

  function submitForm(event) {
    event.preventDefault();

    if (uploadFiles.length == 0) {
      uploadMessage = "Please add files to upload!";
    } else {
      uploadMessage = "";
    }

    for (let i = 0; i < uploadFiles.length; i++) {
      uploadFile(uploadFiles[i]);
    }
  }

  function downloadReport(id) {
    ApiServer.Get(urls.emailResult + id).then((data) => {
      if (data.success) {
        toastNotification.Success(
          "Report files are being generated and will be emailed to you when finished."
        );
      } else {
        toastNotification.Error("Error building the report. " + data.message);
      }
    });
  }

  function deleteImport(imp) {
    ApiServer.Delete(urls.deleteImport + imp.id).then((data) => {
      if (data.success) {
        imports.splice(imports.indexOf(imp), 1);
        toastNotification.Success("Import Deleted");
        imports = imports;
      } else {
        toastNotification.Error("Failed to delete the import: " + data.message);
      }
    });
  }

  onMount(async () => {
    ApiServer.Get(urls.getImports).then((data) => {
      imports = data;
    });
  });
</script>

<Header text="Account Import" />

<p>Before starting the import process make sure you have the following:</p>
<ul>
  <li>Your company areas are setup and public.</li>
  <li>
    Your company Billing Plans are setup, they are marked as public, and the
    names match the name in the BillingPlanName column in the spreadsheet.
  </li>
  <li>
    Make sure CheckoutRules/Pickup Days has at least one day labeled 'Trash
    Day'. If you are importing recycle days also (multiple pickup days), make
    sure there is a day named 'Recycle Day'.
  </li>
</ul>

<p>
  Once the file is imported it will be added to the list below and the import
  will start. You can check back on this page for status (the status is updated
  every 10 record saves).
</p>

<p>
  After the Account Import is finished you will receive an email with the
  result. You can send the email again by clicking on the Download link in the
  list below.
</p>

<form on:submit={submitForm}>
  <div class="buttons">
    <div>
      <button
        type="button"
        onclick="document.getElementById('fileUploadButton').click();"
        class="btn btn-primary"
      >
        <AddIcon />
        Add New Import File
      </button>
      <input
        style="display:none"
        id="fileUploadButton"
        type="file"
        class="btn btn-primary"
        on:change={(e) => onNewFileSelected(e)}
        accept=".csv"
        multiple
      />
    </div>
    <div>
      <button class="btn btn-outline-primary" type="submit">
        Start Import
      </button>
    </div>
    {#if uploadMessage}
      <strong>{uploadMessage}</strong>
    {/if}
  </div>
</form>

{#if uploadFiles}
  {uploadFiles.length} ready for upload. Press the Start Import button when ready.
  <ul>
    {#each uploadFiles as f}
      <li>
        {f.name}
      </li>
    {/each}
  </ul>
{/if}

{#if imports}
  <div class="row bb-header">
    <div class="col">Action</div>
    <div class="col">Name</div>
    <div class="col">Date</div>
    <div class="col" title="Total:Success:Fails">T:S:F</div>
    <div class="col">Status</div>
    <div class="col">Message</div>
  </div>
  {#each imports as imp}
    <div class="row bb-row">
      <div class="col">
        <ButtonDelete
          text="Download"
          callback={() => downloadReport(imp.id)}
          tooltip="Download the report"
          warningText="This will Email you with the report. Are you sure?"
          linkButton={true}
          showIcon={false}
        />
        <ButtonDelete
          text="Delete"
          callback={() => deleteImport(imp)}
          tooltip="Remove this Import"
          warningText="This will NOT delete the imported accounts. It will delete the raw data and results of the import. Are you sure?"
          linkButton={true}
          showIcon={false}
        />
      </div>
      <div class="col">
        {imp.name}
      </div>
      <div class="col">
        <DateFormat formatType="date-time-short" date={imp.dateAdded} />
      </div>
      <div class="col">
        {imp.recordCount}:{imp.successCount}:{imp.failCount}
      </div>
      <div class="col">
        {imp.status}
      </div>
      <div class="col">
        <div style="max-width: 400px; word-wrap: break-word">
          {imp.message}
        </div>
      </div>
    </div>
  {/each}
{/if}

<div>
  <a href="{Env.apiUrl}/api/public/stuff/sampleimportfile">
    Download Sample File
  </a>
</div>

<style>
  .buttons {
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
    padding: 10px 0;
  }

  .buttons > div {
    margin-right: 10px;
  }
</style>
