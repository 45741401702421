<script>
  export let company;
  import InputEmail from "../../Lib/Inputs/InputEmail.svelte";
  import ApiServer from "../../Server/PortalApiServer";
  import PortalHeader from "./_PortalHeader.svelte";
  import { toastNotification } from "../../Stores/toastNotification";

  const urlParams = new URLSearchParams(window.location.search);

  let portalAccountRequest = {};
  let signUpPending = true;

  let urls = {
    requestAccount: "/api/portal/portallogin/requestaccount",
  };

  function handleSignUp() {
    var request = {
      emailAddress: portalAccountRequest.email,
      companyID: company.id,
    };

    ApiServer.PostAnonymous(urls.requestAccount, request).then((data) => {
      toastNotification.Success(
        "Account request successful. Please check your email."
      );
      signUpPending = false;
    });
  }
</script>

<main class="container-fluid container-sm body-content">
  {#if company}
    <PortalHeader {company} />
    {#if signUpPending}
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <h1>Sign Up</h1>
          <div class="card">
            <div class="card-body ">
              <h5>Sign up for {company.name} Account</h5>
              <div class="card-text">
                <p>
                  Please enter the same email address you used when signing up
                  for service.
                </p>
                <form on:submit|preventDefault={handleSignUp}>
                  <div class="row">
                    <div class="col">
                      <InputEmail
                        maxlength={150}
                        label="Email Address"
                        bind:value={portalAccountRequest.email}
                        required={true}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <p>
                        If your email address you enter matches we will send an
                        email with further instructions.
                      </p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <br />
                      <div class="primary-nav">
                        <span />
                        <button type="submit" class="btn btn-danger">
                          Sign Up
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p />
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <a href="/portal?c={company.name}">Back to login</a>
        </div>
      </div>
    {/if}

    {#if !signUpPending}
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <h1>Sign Up Success!</h1>
          <div class="card">
            <div class="card-body ">
              <div class="card-text">
                <p>
                  You have successfully signed up for a new {company.name} account.
                </p>
                <h5>Check your Email</h5>
                <p>
                  Please check your email to validate your account. After
                  validation you will be able to sign in.
                </p>
                <p>
                  <a href="/portal?c={company.name}" class="btn btn-danger"
                    >Go to Sign In page</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    {/if}
  {/if}
</main>
