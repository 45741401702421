<script>
  import Header from "../Header.svelte";
  import CheckoutRulesComponenet from "./_CheckOutRulesComponent.svelte";
</script>

<Header text="Checkout Rules" />

<div class="row justify-content-center">
  <div class="col-sm-12">
    <article class="card-body">
      <CheckoutRulesComponenet />
    </article>
  </div>
</div>
