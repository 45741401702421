<script>
  export let accountId;

  import { onMount } from "svelte";

  import AccountIcon from "../../../../Lib/Icons/AccountIcon.svelte";
  import BillingIcon from "../../../../Lib/Icons/BillingIcon.svelte";
  import AccountStatus from "../AccountStatus.svelte";
  import AccountPaymentMethods from "./AccountPaymentMethods.svelte";
  import EditAccount from "./AccountEditDetail.svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import AccountHeader from "../AccountHeader.svelte";
  import PageTitle from "../../../../Lib/PageTitle.svelte";

  let account;

  let urls = {
    account: `/api/Account/Account/account?accountID=${accountId}`,
  };

  function setAccount() {
    ApiServer.Get(urls.account).then((data) => {
      account = data;
    });
  }

  onMount(async () => {
    if (accountId == "new") {
      //Do nothing??
      account = {};
      account.accountName = "New...";
      account.id = "new";
    } else {
      setAccount();
    }
  });

  let navigateAction = "editaccount";
</script>

{#if account}
  <PageTitle title="Account Details" />

  <AccountHeader {account} />

  <div class="card">
    <div class="card-body">
      <div class="card-title">
        <h5>
          <div class="same-line">
            Edit Account
            <AccountStatus {account} />
          </div>
        </h5>

        <div id="button-menu">
          <div id="action-menu">
            <span
              on:click={() => (navigateAction = "editaccount")}
              class:selected={navigateAction == "editaccount"}
              class="filter"
            >
              <AccountIcon /> Account
            </span>

            <span
              on:click={() => (navigateAction = "paymentmethods")}
              class:selected={navigateAction == "paymentmethods"}
              class="filter"
            >
              <BillingIcon /> Payment Methods
            </span>
          </div>
        </div>
      </div>

      <div class="card-text">
        {#if navigateAction == "editaccount"}
          <EditAccount {account} />
        {:else if navigateAction == "paymentmethods"}
          <AccountPaymentMethods {account} />
        {/if}
      </div>
    </div>
  </div>
{/if}

<style>
  .filter {
    cursor: pointer;
    text-transform: uppercase;
    /*top right bottom left*/
    padding: 3px 12px 3px 12px;
    margin-right: 3px;
    display: inline-block;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    color: black;
  }

  .filter.selected {
    background-color: black;
    color: white;
    border-radius: 10px;
  }
</style>
