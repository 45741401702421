<script>
  import ButtonCancel from "../../../Lib/Buttons/ButtonCancel.svelte";
  import { Offcanvas } from "sveltestrap";
  import ButtonLink from "../../../Lib/Buttons/ButtonLink.svelte";
  import { toastNotification } from "../../../Stores/toastNotification";
  import ApiServer from "../../../Server/ApiServer";
  import { onMount } from "svelte";
  import SettingControl from "./SettingControl.svelte";
  import ButtonSave from "../../../Lib/Buttons/ButtonSave.svelte";

  let isOpen = false;
  let settings;

  let urls = {
    get: `/api/Company/Settings/BillingSettings`,
    update: `/api/Company/Settings/BillingSettings`,
  };

  const openScreen = () => (isOpen = !isOpen);

  function loadSettings() {
    ApiServer.Get(urls.get).then((data) => {
      settings = data;
    });
  }

  function saveSettings() {
    ApiServer.Post(urls.update, settings).then((data) => {
      toastNotification.Success("Billing settings saved!");
      isOpen = false;
    });
  }
  onMount(async () => {
    loadSettings();
  });
</script>

<ButtonLink text="Billing Settings" callback={openScreen} />

<Offcanvas
  header="Billing Settings"
  {isOpen}
  toggle={openScreen}
  placement="end"
>
  {#if settings}
    <div class="row">
      <div class="col">
        <p>
          Set the number of days before the job you want billing to run. It will
          always run at 10:00 AM on that day.
        </p>
        <p>
          For example: if you have 10 jobs on January 11th and you are running
          bills 7 days before, the billing will run on January 4th at 10:00 AM
        </p>
        <p>
          Set 'Days before job to bill customer' to 0 to disable automatic
          billing.
        </p>
        {#each settings as setting}
          <div class="row">
            <div class="col">
              <SettingControl {setting} />
            </div>
          </div>
        {/each}
      </div>
    </div>
  {/if}
  <p>
    <ButtonCancel text="Close" callback={openScreen} />
    <ButtonSave text="Save" callback={saveSettings} />
  </p>
</Offcanvas>
