<script>
  export let tutorialSettings;

  import { Link } from "svelte-navigator";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import ButtonSave from "../../../Lib/Buttons/ButtonSave.svelte";
  import { toastNotification } from "../../../Stores/toastNotification";

  function markAsFinished() {
    tutorialSettings.tutorialSettings.mapAreasDone = true;
    dispatch("saveSettings");
    toastNotification.Success("Routes Complete!");
  }
</script>

<div class="row">
  <div class="col">
    <div class="card mb-3">
      <div class="card-header">Areas</div>
      <div class="card-body">
        <div class="card-text">
          <p>
            In order to allow auto approvals on checkout and to place recurring
            jobs on the right day of the week please go to Settings and create
            your Areas.
          </p>
          <p>
            <Link to="/company/routes">Configure Routes Now</Link>
          </p>
          <p>
            <ButtonSave text="Mark as Complete" callback={markAsFinished} />
          </p>
        </div>
        <div class="card-footer" />
      </div>
    </div>
  </div>
</div>
