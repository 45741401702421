<script>
  export let callback = null;
  import ArrowLeftIcon from "../Icons/ArrowLeftIcon.svelte";

  function handleBackClick() {
    if (callback) {
      callback();
    } else {
      //Sometimes the back button does not have a value (Like when right click, open in new tab is clicked). In this case send them back to the Inbox?
      var currentUrl = window.location.href;
      window.history.back();
      setTimeout(function () {
        // if location was not changed in 100 ms, then there is no history back
        if (currentUrl === window.location.href) {
          // redirect to site root
          window.location.href = "/inbox";
        }
      }, 100);
    }
  }
</script>

<button class="btn btn-sm btn-outline-info" on:click={handleBackClick}>
  <ArrowLeftIcon /> Back
</button>
