<script>
  export let password;
  export let label;
  export let value;
  export let required = false;

  import { passwordStrength } from "check-password-strength";
  import { Badge, Popover } from "sveltestrap";
  import { createEventDispatcher } from "svelte";

  let passwordMatchValue = "No Match";

  const dispatch = createEventDispatcher();

  function handleOnChange() {
    var matchIndicator = {
      match: password == value,
      password: value,
    };
    dispatch("change", matchIndicator);
    validatePasswordMatch();
  }

  function validatePasswordMatch() {
    passwordMatchValue = password == value ? "Passwords match!" : "No Match";
  }
</script>

<label for={label}>{label}</label>
<input
  id="confirm-password"
  type="password"
  class="form-control"
  on:keyup={handleOnChange}
  bind:value
  {required}
/>

<Popover trigger="focus" target="confirm-password">
  <div slot="title">
    <b>Confirm Password</b>
  </div>
  <h3>
    <Badge color="info">{passwordMatchValue}</Badge>
  </h3>
</Popover>
