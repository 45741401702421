<script>
  export let label;
  export let value;
  export let min = 0;
  export let step = "0.01";
  export let required = false;
</script>

<label for={label}>{label}</label>
<input type="number" {min} {step} {required} class="form-control" bind:value />
