<script>
  export let company;
  export let path;

  import { useLocation } from "svelte-navigator";
  import InputPassword from "../../Lib/Inputs/InputPassword.svelte";
  import InputEmail from "../../Lib/Inputs/InputEmail.svelte";
  import PortalUserSotre from "../../Stores/PortalUserStore";
  import PortalHeader from "./_PortalHeader.svelte";

  const location = useLocation();

  let email;
  let password;
  let loginMessage;

  async function handleSubmit(event) {
    event.preventDefault();
    loginMessage = null;
    var loginRequest = {
      email: email,
      password: password,
      path: path,
      companyID: company.id,
    };

    var loginResult = await PortalUserSotre.performLogin(loginRequest);
    if (loginResult && loginResult.success) {
    } else {
      loginMessage = loginResult.message;
    }
  }

  function getLoginPath(path) {
    return path || ($location.state && $location.state.from) || "/";
  }
</script>

<svelte:head>
  <title>Portal - Login</title>
</svelte:head>

<main class="container-fluid container-sm body-content">
  {#if company && company.companyInformation}
    <PortalHeader {company} />

    <div class="row">
      <div class="col-lg-6 mx-auto ">
        <h1>{company.companyInformation.companyName} Sign In</h1>
        <div class="card">
          <div class="card-body ">
            <h5>
              Sign in to your {company.companyInformation.companyName} account
            </h5>
            <div class="card-text">
              <form on:submit={handleSubmit}>
                {#if loginMessage}
                  <div class="alert alert-warning" role="alert">
                    {loginMessage}
                  </div>
                {/if}
                <div class="form-group">
                  <InputEmail label="Email Address" bind:value={email} />
                  <small id="emailHelp" class="form-text text-muted"
                    >This is also your username</small
                  >
                </div>
                <div class="form-group">
                  <InputPassword label="Password" bind:value={password} />
                  <small id="emailHelp" class="form-text text-muted">
                    You set this up during checkout
                  </small>
                </div>
                <p />
                <div class="primary-nav">
                  <button type="submit" class="btn btn-danger">Sign In</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p />
    <div class="row">
      <div class="col-lg-6 mx-auto">
        <div class="same-line">
          <a class="btn btn-danger" href="portal/signup?c={company.name}"
            >Sign up</a
          >
          <a href="portal/signInHelp?c={company.name}">Lost Password?</a>
        </div>
      </div>
    </div>
  {/if}
</main>

<style>
  .primary-nav {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 5px;
  }
</style>
