<script>
  export let account;

  import { link } from "svelte-navigator";
  import DateFormat from "../../Lib/DateFormat.svelte";
  import Money from "../../Lib/Money.svelte";
  import AddressGoogleLink from "../../lib/AddressGoogleLink.svelte";
</script>

<div class="account-card">
  <div class="row">
    <div class="col">
      <h3>
        <a href="account/{account.id}" use:link>{account.accountName}</a>
      </h3>
    </div>
    <div class="col">
      <AddressGoogleLink
        address={account.address}
        city={account.city}
        state={account.state}
        zip={account.zip}
      />
    </div>

    <div class="col">
      {account.email}<br />
      {account.formattedPhoneNumber}
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col">
      Job: <a href="/account/{account.id}/jobs/{account.jobID}" use:link>
        {account.jobNumber}
      </a>
    </div>
    <div class="col">
      <DateFormat date={account.jobDate} convertTimeZone={false} />
    </div>
    <div class="col">Amount: <Money amount={account.jobCost} /></div>
  </div>
</div>

<style>
  .account-card {
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 5px;
    margin-bottom: 5px;
  }
</style>
