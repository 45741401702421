<script>
  export let searchText;
  export let title;

  import { createEventDispatcher } from "svelte";
  import DeleteIcon from "../../Lib/Icons/DeleteIcon.svelte";
  const dispatch = createEventDispatcher();

  import SearchIcon from "../../Lib/Icons/SearchIcon.svelte";

  function searchkeyDown(element) {
    dispatch("searchKeyDown", element);
  }

  function performSearch() {
    dispatch("performSearch");
  }

  function clearSearch() {
    searchText = "";
    dispatch("clearSearch");
  }
</script>

<p>{title}</p>
<div class="row">
  <div class="col-8 b">
    <input
      type="text"
      on:keypress={searchkeyDown}
      bind:value={searchText}
      class="form-control"
    />
  </div>
  <div class="col-4 b">
    <div class="tools">
      <button
        on:click={() => performSearch()}
        class="btn btn-primary"
        title="Search"
      >
        <SearchIcon /></button
      >

      <button
        style="margin-left: 5px"
        on:click={() => clearSearch()}
        class="btn btn-warning"
        title="clear"><DeleteIcon /></button
      >
    </div>
  </div>
</div>

<style>
  /* .b {
    border: 1px solid black;
  } */
  .tools {
    display: -webkit-flex;
    display: flex;
    justify-content: flex-end;
  }
</style>
