<script>
  export let date;
  export let formatType = "date-only";
  export let convertTimeZone = true;

  import UTCDate from "../Util/UTCDate";
  let formattedDate;
  if (convertTimeZone) {
    formattedDate = UTCDate.GetTZDate(date, formatType);
  } else {
    formattedDate = UTCDate.FormattedDate(date, formatType);
  }
</script>

{formattedDate}
