<script>
  export let tutorialSettings;

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import EmailSettings from "../Settings/Controls/EmailSettings.svelte";

  function handleSave() {
    tutorialSettings.tutorialSettings.emailAddressDone = true;
    dispatch("emailVerified");
  }
</script>

<div class="row">
  <div class="col">
    <div class="card mb-3">
      <div class="card-header">Verified Email</div>
      <div class="card-body">
        <div class="card-text">
          <p>
            <strong>
              Once your email is verified click the 'Check Status' button to
              complete this step.
            </strong>
          </p>
          <EmailSettings on:onVerificationStatus={handleSave} />
        </div>
        <div class="card-footer" />
      </div>
    </div>
  </div>
</div>
