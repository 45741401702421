<script>
  export let userID;

  import { onMount } from "svelte";
  import { useNavigate } from "svelte-navigator";
  import ButtonDelete from "../../../Lib/Buttons/ButtonDelete.svelte";
  import InputPassword from "../../../Lib/Inputs/InputPassword.svelte";
  import InputSwitch from "../../../Lib/Inputs/InputSwitch.svelte";
  import InputTextBox from "../../../Lib/Inputs/InputTextBox.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import { toastNotification } from "../../../Stores/toastNotification";
  import Header from "../Header.svelte";

  const navigate = useNavigate();

  let user;
  let userRoles;
  let urls = {
    getUser: `/api/Company/User/user?userID=${userID}`,
    updateUser: `/api/Company/User/user`,
    createUser: `/api/Company/User/user`,
    deleteUser: `/api/Company/User/user?userID=${userID}`,
    getUserRoles: `/api/Company/User/userroles`,
  };

  function setUserRoles() {
    ApiServer.Get(urls.getUserRoles).then((data) => {
      userRoles = data;
    });
  }

  function createNewUser() {
    ApiServer.Post(urls.createUser, user).then((data) => {
      toastNotification.Success("User created!");
      navigate("/company/users");
    });
  }

  function updateExistingUser() {
    ApiServer.Put(urls.updateUser, user).then((data) => {
      toastNotification.Success("User saved!");
      navigate("/company/users");
    });
  }

  function saveUser() {
    if (userID == "new") {
      createNewUser();
    } else {
      updateExistingUser();
    }
  }

  function deleteUser() {
    ApiServer.Delete(urls.deleteUser).then((data) => {
      toastNotification.Success("User Deleted!");
      window.history.back();
    });
  }

  onMount(async () => {
    if (userID == "new") {
      user = { enabled: true };
    } else {
      ApiServer.Get(urls.getUser).then((data) => {
        user = data;
      });
    }
    setUserRoles();
  });
</script>

{#if user}
  <Header text={user.firstName ? user.firstName + " Detail" : "User Detail"} />
  <div class="row">
    <div class="col-sm-12">
      <article class="card-body">
        <form on:submit|preventDefault={saveUser} autocomplete="off">
          <div class="row">
            <div class="col-md-4">
              <InputTextBox
                label="First Name"
                bind:value={user.firstName}
                required={true}
              />
            </div>
            <div class="col-md-4">
              <InputTextBox
                label="Last Name"
                bind:value={user.lastName}
                required={true}
              />
            </div>
            <div class="col-md-4">
              <InputTextBox
                label="Initials"
                bind:value={user.initials}
                required={true}
                maxlength={2}
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <InputTextBox
                label="User Name"
                bind:value={user.email}
                required={true}
              />
            </div>
            <div class="col-md-6">
              <InputTextBox
                label="Phone Number"
                bind:value={user.phoneNumber}
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <InputPassword
                label="Password"
                bind:value={user.passwordResetText}
              />
            </div>

            <div class="col-md-6">
              <label for="role">Role</label>
              <select
                id="role"
                required
                class="form-control"
                bind:value={user.role}
              >
                <option value="">Choose...</option>
                {#if userRoles}
                  {#each userRoles as role}
                    <option value={role}>{role}</option>
                  {/each}
                {/if}
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <InputSwitch
                text="Enable/Disable User"
                label="User Status"
                bind:value={user.enabled}
              />
            </div>
            <div class="col-md-6">
              <InputSwitch
                text="Show locaton on map"
                label="Location"
                bind:value={user.allowTrackLocation}
              />
            </div>
          </div>

          <div class="row button-row">
            <div class="col">
              <button type="submit" class="btn btn-primary">Save</button>
              <ButtonDelete text="Delete" callback={deleteUser} />
            </div>
          </div>
        </form>
      </article>
    </div>
  </div>
{/if}

<style>
  .button-row {
    margin-top: 10px;
  }
</style>
