<script>
  export let phoneNumber;
  export let messageBody;
  export let name = null;

  import InputTextArea from "../../Lib/Inputs/InputTextArea.svelte";
  import PhoneNumber from "../../Lib/PhoneNumber.svelte";
</script>

{#if phoneNumber}
  <h5>
    Message to: {#if name}{name}{/if}
    <PhoneNumber number={phoneNumber} />
  </h5>

  <InputTextArea bind:value={messageBody} />
  <br />
{/if}
