<script>
  import InputTextBox from "../Lib/Inputs/InputTextBox.svelte";
  import InputEmail from "../Lib/Inputs/InputEmail.svelte";
  import ApiServer from "../Server/ApiServer";
  import { toastNotification } from "../Stores/toastNotification";
  import InputPhone from "../Lib/Inputs/InputPhone.svelte";
  import PublicPageHeader from "./PublicPageHeader.svelte";
  import FormatChecker from "../Util/FormatChecker";
  let user = {};
  let signUpPending = true;

  function handleSignUp() {
    if (!FormatChecker.PhoneNumberIsValid(user.phoneNumber)) {
      alert("Please enter a valid US phone number, including the area code.");
    }

    ApiServer.PostAnonymous("/api/public/signup/newcustomersignup", user).then(
      (data) => {
        if (data.success) {
          toastNotification.Success(
            "Sign up success. Check your email for a confirmation."
          );
          signUpPending = false;
        } else {
          toastNotification.Error("Error during sign up: " + data.message);
        }
      }
    );
  }
</script>

<svelte:head>
  <title>Bin Bird - Sign up</title>
</svelte:head>

<main class="container-fluid container-sm body-content">
  <PublicPageHeader />

  {#if signUpPending}
    <div class="row">
      <div class="col-lg-6 mx-auto">
        <h1>Sign Up</h1>
        <div class="card">
          <div class="card-body ">
            <h5>Sign up for a Bin Bird account</h5>
            <div class="card-text">
              <form on:submit|preventDefault={handleSignUp}>
                <div class="row">
                  <div class="col">
                    <InputTextBox
                      maxlength={100}
                      label="Company Name"
                      bind:value={user.companyName}
                      required={true}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <InputTextBox
                      maxlength={50}
                      label="First Name"
                      bind:value={user.firstName}
                      required={true}
                    />
                  </div>
                  <div class="col">
                    <InputTextBox
                      maxlength={50}
                      label="Last Name"
                      bind:value={user.lastName}
                      required={true}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <InputEmail
                      maxlength={100}
                      label="Email Address"
                      bind:value={user.email}
                      required={true}
                    />
                  </div>
                  <div class="col">
                    <InputPhone
                      maxlength={50}
                      label="Mobile Phone"
                      bind:value={user.phoneNumber}
                      required={true}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <p>
                      After signing up we will send an email with further
                      instructions.
                    </p>
                    <p>No Credit Card Required. Go Nuts!</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <br />
                    <div class="primary-nav">
                      <span />
                      <button type="submit" class="btn btn-danger">
                        Sign Up
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p />
    <div class="row">
      <div class="col-lg-6 mx-auto">
        <a href="/">Back to login</a>
      </div>
    </div>
  {/if}

  {#if !signUpPending}
    <div class="row">
      <div class="col-lg-6 mx-auto">
        <h1>Sign Up Success!</h1>
        <div class="card">
          <div class="card-body ">
            <div class="card-text">
              <p>You have successfully signed up for a new Bin Bird Account.</p>
              <p>There is no Credit Card to get started.</p>
              <h5>Check your Email</h5>
              <p>Please check your email for the final step.</p>
              <p>
                <a href="/" class="btn btn-danger">Go to Sign In page</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  {/if}
</main>

<style>
  .card {
    margin: 0 auto; /* Added */
    float: none; /* Added */
    margin-bottom: 10px; /* Added */
  }
  .primary-nav {
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
  }
</style>
