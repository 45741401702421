<script>
  export let address;
  export let unit;
  export let city;
  export let state;
  export let zip;

  import ApiServer from "../../Server/ApiServer";
  import AutoCompleteResult from "./AutoCompleteResult.svelte";

  let results;
  let urls = {
    getAutocomplete: `/api/Public/Checkout/addressautocomplete?searchText=`,
  };

  function closeResultWindow() {
    results = null;
    console.log("on Blur");
  }

  function fetchAddressOptions(event) {
    let char = typeof event !== "undefined" ? event.keyCode : event.which;
    if (char === 27) {
      address == null;
      results = null;
      return;
    }
    if (char === 13) return;
    // console.log(char);

    ApiServer.GetAnonymous(urls.getAutocomplete + address).then((data) => {
      results = data;
    });
  }

  function handleAddressResultSelect(event) {
    let result = event.detail;
    address = result.address;
    city = result.city;
    state = result.state;
    zip = result.zip;
  }
</script>

<div class="row">
  <div class="col form-group">
    <label for="address">Address *</label>
    <input
      type="text"
      id="blagiana.kjdfsio"
      autocomplete="off"
      spellcheck="false"
      bind:value={address}
      on:keydown={fetchAddressOptions}
      class="form-control"
      required
    />
    <AutoCompleteResult
      {results}
      on:resultSelected={handleAddressResultSelect}
    />
    <div class="invalid-feedback">Address is required!</div>
  </div>
  <div class="col form-group">
    <label for="addressUnit">Unit</label>
    <input
      type="text"
      id="addressUnit"
      bind:value={unit}
      nofill
      class="form-control"
      on:focus={closeResultWindow}
    />
  </div>
</div>

<div class="row">
  <div class="col form-group">
    <label for="city">City *</label>
    <input
      type="text"
      id="city"
      bind:value={city}
      class="form-control"
      on:focus={closeResultWindow}
      required
    />
    <div class="invalid-feedback">City is required!</div>
  </div>
  <div class="col form-group">
    <label for="state">State</label>
    <input
      type="text"
      id="state"
      bind:value={state}
      class="form-control"
      on:focus={closeResultWindow}
    />
    <div class="invalid-feedback">State is required!</div>
  </div>
  <div class="col form-group">
    <label for="zip">Zip</label>
    <input
      type="text"
      id="zip"
      bind:value={zip}
      class="form-control"
      on:focus={closeResultWindow}
      required
    />
    <div class="invalid-feedback">Postal code/zip is required!</div>
    <input type="hidden" id="country" class="form-control" />
  </div>
</div>
