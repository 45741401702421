<script>
  export let account;

  import DateFormat from "../../../Lib/DateFormat.svelte";
</script>

<span class="text-muted small">
  {#if account.id == "new"}
    New Account
  {:else if account.status == "Active"}
    {account.status} since <DateFormat date={account.dateAdded} />
  {:else}
    {account.status}
  {/if}
</span>
