<script>
  export let id;
  export let type = "job";
  //Available types are Job or Account. The billing entrys code below will help determin which ones to load.

  import { onMount } from "svelte";
  import { Offcanvas } from "sveltestrap";
  import DateFormat from "../../Lib/DateFormat.svelte";
  import ApiServer from "../../Server/ApiServer";
  import Money from "../../Lib/Money.svelte";
  import ButtonCancel from "../../Lib/Buttons/ButtonCancel.svelte";
  import ButtonLink from "../../Lib/Buttons/ButtonLink.svelte";
  import ButtonConfirmAction from "../../Lib/Buttons/ButtonConfirmAction.svelte";
  import { user } from "../../Stores/stores";
  import RefundIcon from "../../Lib/Icons/RefundIcon.svelte";

  let isRefundWindowOpen = false;
  let refundMessage = "";
  let logs = null;
  let currentLedger;
  const toggleWindow = () => (isRefundWindowOpen = !isRefundWindowOpen);

  function openDetailWindow(ledger) {
    toggleWindow();
    refundMessage = "";
    currentLedger = ledger;
  }

  let urls = {
    getJobBillingLogs: `/api/Billing/ledgerByJob?jobID=`,
    getAccountBillingLogs: `/api/Billing/ledgerByAccount?accountID=`,
    accountBalance: `/api/Billing/balance?accountID=`,
    jobBalance: `/api/Billing/jobbalance?jobID=`,
    refundPayment: `/api/Billing/processrefunds`,
  };

  function processRefund() {
    if (currentLedger.refundAmount == 0) {
      refundMessage = "Please enter the amount you want to refund.";
      return;
    }

    if (currentLedger.refundAmount > currentLedger.amount * -1) {
      refundMessage = `Refund amount is more than the charge. Max refund is ${
        currentLedger.amount * -1
      }`;
      return;
    }

    let cancelJobRequest = {
      jobID: currentLedger.jobID,
      userName: $user.fullName,
    };

    cancelJobRequest.paymentsToRefund = [];
    currentLedger.refunded = true;
    cancelJobRequest.paymentsToRefund.push(currentLedger);

    ApiServer.Post(urls.refundPayment, cancelJobRequest).then((data) => {
      getBillingLogs();
      toggleWindow();
    });
  }

  let balance;

  function getBillingLogs() {
    let url = urls.getJobBillingLogs;
    let balanceUrl = urls.jobBalance;
    if (type != "job") {
      url = urls.getAccountBillingLogs;
      balanceUrl = urls.accountBalance;
    }

    ApiServer.Get(url + id).then((data) => {
      logs = data;

      logs.forEach((log) => {
        log.isRefundable = log.credit && !log.refunded && log.success;
        //console.log(log.isRefundable);
      });
    });

    ApiServer.Get(balanceUrl + id).then((data) => {
      balance = data;
    });
  }

  onMount(async () => {
    getBillingLogs();
  });
</script>

<div class="same-line">
  <h5>Billing Log</h5>
  {#if balance}
    <div>
      Total: <Money amount={balance} />
    </div>
  {/if}
</div>
{#if logs}
  <div class="row bb-header">
    <div class="col">Detail</div>
    <div class="col">Date</div>
    <div class="col">Amount</div>
    <div class="col">Type</div>
    <div class="col d-none d-md-block">Description</div>
    <div class="col d-none d-md-block">Status</div>
    <div class="col d-none d-md-block">Details</div>
  </div>
  {#each logs as log}
    <div class="row bb-row">
      <div class="col">
        <ButtonLink text="Detail" callback={() => openDetailWindow(log)} />
      </div>
      <div class="col">
        <DateFormat formatType="date-short" date={log.dateAdded} />
      </div>
      <div class="col"><Money amount={log.amount} /></div>
      <div class="col">
        {#if log.credit}
          Pmt
        {:else}
          Chg
        {/if}
        {#if log.refunded}
          <span title="Refunded {log.refundAmount}"><RefundIcon /></span>
        {/if}
      </div>
      <div class="col d-none d-md-block">
        {#if log.description}
          {log.description}
        {/if}
      </div>
      <div class="col d-none d-md-block">
        {#if log.success}
          Success
        {:else}
          Failed {log.message}
        {/if}
      </div>
      <div class="col d-none d-md-block">
        {#if log.cardBrand}
          {log.cardBrand}
          {log.cardLast4}
          {log.cardExpiry}
        {/if}
      </div>
    </div>
  {/each}
{/if}

{#if currentLedger}
  <Offcanvas
    header="Billing Detail"
    isOpen={isRefundWindowOpen}
    toggle={toggleWindow}
    placement="end"
  >
    <ul>
      <li>
        {#if currentLedger.credit}
          <span class="label">Type:</span> Payment
        {:else}
          <span class="label">Type:</span> Charge
        {/if}
      </li>
      <li>
        <span class="label">Amount:</span>
        <Money amount={currentLedger.amount} />
      </li>
      {#if currentLedger.refunded}
        <li>
          <span class="label">Refunded : Yes</span>
        </li>
        <li>
          <span class="label">Refunded Amount:</span>
          <Money amount={currentLedger.refundAmount} />
        </li>
      {/if}
      <li>
        <span class="label">Date:</span>
        <DateFormat
          date={currentLedger.dateAdded}
          formatType="date-time-short"
        />
      </li>
      {#if currentLedger.description}
        <li>
          <span class="label">Description:</span>
          {currentLedger.description}
        </li>
      {/if}
      <li>
        {#if currentLedger.success}
          <span class="label">Status:</span> Success
        {:else}
          <span class="label">Status:</span> Failed {currentLedger.message}
        {/if}
      </li>
      {#if currentLedger.message}
        <li>
          <span class="label">Message:</span>
          {currentLedger.message}
        </li>
      {/if}
      {#if currentLedger.cardBrand}
        <li>
          <span class="label">Payment Details: </span>
          {currentLedger.cardBrand}
          {currentLedger.cardLast4}
          {currentLedger.cardExpiry}
        </li>
      {/if}
      {#if currentLedger.jobNumber}
        <li>
          <span class="label">Job Number:</span>
          <a
            href="/account/{currentLedger.accountID}/jobs/{currentLedger.jobID}"
          >
            {currentLedger.jobNumber}
          </a>
        </li>
        <li>
          <span class="label">Charged by:</span>
          {currentLedger.userName}
        </li>
      {/if}
    </ul>

    {#if currentLedger.isRefundable}
      <p>Enter an amount below to process a refund for this payment</p>
      <div class="same-line">
        <input
          type="number"
          class="form-control"
          bind:value={currentLedger.refundAmount}
        />

        <button
          type="button"
          class="btn btn-sm btn-outline-info"
          on:click={() =>
            (currentLedger.refundAmount = currentLedger.amount * -1)}
          >Max</button
        >
      </div>
    {/if}

    <p>
      <ButtonCancel text="Close" callback={toggleWindow} />
      {#if currentLedger.isRefundable}
        <ButtonConfirmAction
          text="Process Refund"
          callback={processRefund}
          tooltip="Process Refund"
          warningText="Are you sure you want to refund this payment to the customer?"
        />
      {/if}
    </p>
    {#if refundMessage}
      <p class="refund-message">{refundMessage}</p>
    {/if}
  </Offcanvas>
{/if}

<style>
  ul {
    padding: 0;
    list-style-type: none;
  }

  ul li {
    padding: 5px;
  }

  .label {
    font-weight: bold;
  }

  .refund-message {
    background-color: orange;
    padding: 4px;
  }
</style>
