<script>
  export let value;
  export let id;
  import JobData from "./JobData";
</script>

<select class="form-control" {id} bind:value>
  {#each JobData.DurationList as duration}
    <option value={duration.id}>{duration.text}</option>
  {/each}
</select>
