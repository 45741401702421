<script>
  export let portalSummary;

  import PortalCard from "./_PortalCard.svelte";
  import BillingPlanIcon from "../../Lib/Icons/BillingPlanIcon.svelte";
</script>

<PortalCard title="Plan" icon={BillingPlanIcon}>
  {#if portalSummary.account}
    <h4>{portalSummary.account.planName}</h4>

    {#each portalSummary.account.templatePickupDays as pickupDay}
      <h5>{pickupDay.dayName}: {pickupDay.bins} Bins</h5>
    {/each}
  {/if}
</PortalCard>
