import { writable } from "svelte/store";

function createToastNotification() {
  const { subscribe, set, update } = writable(null);

  return {
    subscribe,
    Error: (message) => {
      set({ message: message, type: "error" });
    },
    Success: (message) => {
      set({ message: message, type: "success" });
    },
    Info: (message) => {
      set({ message: message, type: "info" });
    },
    Warning: (message) => {
      set({ message: message, type: "warning" });
    },
    reset: () => set(null),
  };
}

export const toastNotification = createToastNotification();
