<script>
  import { onMount } from "svelte";

  import Chart from "svelte-frappe-charts";
  import ApiServer from "../Server/ApiServer";
  import BrokenHeartIcon from "../Lib/Icons/BrokenHeartIcon.svelte";
  import DashboardMessage from "./_DashboardMessage.svelte";

  let urls = {
    accounts: `/api/Dashboard/accountsalltime`,
  };

  let chartRef;
  let count = 0;
  let data;

  let barOptions = {
    stacked: 1,
  };

  onMount(async () => {
    ApiServer.Get(urls.accounts).then((result) => {
      data = result;
      count = result.totalCount;
    });
  });

  const onDataSelect = (event) => {
    console.log("Data select event fired!", event);
    selected = event;
  };
  let selected;
</script>

<div class="card">
  <div class="card-body">
    <div class="card-title">
      <h3>All Customers {count}</h3>
    </div>
    <div class="card-text">
      {#if data && count > 0}
        <Chart
          {data}
          valuesOverPoints="1"
          {barOptions}
          bind:this={chartRef}
          type="bar"
        />
      {:else}
        <DashboardMessage icon={BrokenHeartIcon} />
      {/if}
    </div>
  </div>
</div>
