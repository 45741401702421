<script>
  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();
  import ButtonSave from "../../../../Lib/Buttons/ButtonSave.svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import { toastNotification } from "../../../../Stores/toastNotification";
  import SettingControl from "../SettingControl.svelte";

  let settings;

  let urls = {
    get: `/api/Company/Settings/StripeSettings`,
    update: `/api/Company/Settings/StripeSettings`,
  };

  function loadSettings() {
    ApiServer.Get(urls.get).then((data) => {
      settings = data;
    });
  }

  function saveSettings() {
    ApiServer.Post(urls.update, settings).then((data) => {
      toastNotification.Success("Stripe settings saved!");
      dispatch("onStripeSettingsSaved");
    });
  }
  onMount(async () => {
    loadSettings();
  });
</script>

{#if settings}
  <div class="row">
    <div class="col">
      {#each settings as setting}
        <div class="row">
          <div class="col">
            <SettingControl {setting} />
          </div>
        </div>
      {/each}
    </div>
  </div>

  <ButtonSave text="Save" callback={saveSettings} />
{/if}
