<script>
  export let job;
  import ImageGallary from "../../../../Lib/Images/ImageGallary.svelte";

  let urls = {
    getJobImages: `/api/Job/images?jobID=`,
  };
</script>

<p>
  Images for this job. Typically images are added by technitions while on the
  route.
</p>

{#if job}
  <ImageGallary
    relatedID={job.id}
    fetchImagesURL={urls.getJobImages}
    folder="Jobs"
  />
{/if}
