<script>
  export let tutorialSettings;

  import { createEventDispatcher } from "svelte";
  import CompanyBranding from "../Settings/Controls/CompanyBranding.svelte";

  const dispatch = createEventDispatcher();

  function saveSettings() {
    tutorialSettings.tutorialSettings.logoDone = true;
    dispatch("saveSettings");
  }
</script>

<div class="row">
  <div class="col">
    <div class="card mb-3">
      <div class="card-header">Logo - Customize your logo</div>
      <div class="card-body">
        <div class="card-text">
          <CompanyBranding on:onLogoSaved={saveSettings} />
        </div>
        <div class="card-footer" />
      </div>
    </div>
  </div>
</div>
