<script>
  export let tutorialSettings;
  import { createEventDispatcher } from "svelte";
  import { Alert } from "sveltestrap";
  const dispatch = createEventDispatcher();

  import Checkbox from "../../../Lib/Inputs/InputCheckbox.svelte";
  import BillingPlanTutorialSteptwo from "./BillingPlanTutorialSteptwo.svelte";

  let firstStep = true;
  let secondStep = false;

  function goNext() {
    if (firstStep == true) {
      secondStep = true;
      firstStep = false;
    }
  }

  function goBack() {
    if (secondStep == true) {
      secondStep = false;
      firstStep = true;
    }
  }

  function handleSaveBillingPlans() {
    dispatch("saveBillingPlans");
  }
</script>

{#if tutorialSettings && tutorialSettings.tutorialSettings && tutorialSettings.tutorialSettings.billingPlansDone}
  <Alert color="success">This section is complete!</Alert>
{/if}

<div class="row">
  <div class="col">
    <div class="card mb-3">
      <div class="card-header">Billing Plans</div>
      <div class="card-body">
        {#if firstStep}
          <div class="card-text">
            <p>Check the services you offer</p>
            <p>
              <Checkbox
                text="Monthly - Cleanings once a month"
                bind:value={tutorialSettings.tutorialSettings.monthlyPlan
                  .enabled}
              />
              <Checkbox
                text="Bi-Monthly - Cleanings every other month"
                bind:value={tutorialSettings.tutorialSettings.biMonthlyPlan
                  .enabled}
              />
              <Checkbox
                text="Quarterly - Cleanings every 3 months"
                bind:value={tutorialSettings.tutorialSettings.quarterlyPlan
                  .enabled}
              />
              <Checkbox
                text="Onetime Cleanings"
                bind:value={tutorialSettings.tutorialSettings.onetimePlan
                  .enabled}
              />
            </p>
          </div>
        {:else if secondStep}
          <BillingPlanTutorialSteptwo
            on:saveBillingPlans={handleSaveBillingPlans}
            {tutorialSettings}
          />
        {/if}
        <div class="card-footer">
          {#if secondStep}
            <button class="btn btn-primary" on:click={goBack}>Prev</button>
          {/if}
          {#if firstStep}
            <button class="btn btn-primary" on:click={goNext}>Next</button>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>
