<script>
  export let rules;

  import Checkbox from "../../../Lib/Inputs/InputCheckbox.svelte";
</script>

<div class="row">
  <div class="col">
    <h4>Recurring Rule</h4>
    <p>
      If this is recurring, how often jobs should be generated by this Billing
      Plan.
    </p>
  </div>

  <div class="col">
    <div class="col form-group">
      <Checkbox
        text="Enabled"
        label="This is a recurring plan?"
        bind:value={rules.recurringRule.isRecurring}
      />
    </div>
  </div>
</div>

{#if rules.recurringRule.isRecurring}
  <div class="row">
    <div class="col-sm-6 form-group ">
      <label for="Interval">Every:</label>
      <select
        class="form-control"
        required
        name="Interval"
        bind:value={rules.recurringRule.interval}
      >
        <option value="">--Select a frequency--</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 form-group">
      <label for="frequency">Month, Week...</label>
      <select
        class="form-control"
        required
        name="frequency"
        bind:value={rules.recurringRule.frequency}
      >
        <option value="">--Select a frequency--</option>
        <option value="1">Monthly</option>
        <option value="2">Weekly</option>
        <option value="3">Yearly</option>
        <option value="4">Daily</option>
      </select>
    </div>
  </div>
{/if}
