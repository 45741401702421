<script>
  import { onMount } from "svelte";
  import { Offcanvas } from "sveltestrap";

  import ButtonCancel from "../../../Lib/Buttons/ButtonCancel.svelte";
  import ButtonLink from "../../../Lib/Buttons/ButtonLink.svelte";
  import CompanyBranding from "./Controls/CompanyBranding.svelte";

  let isOpen = false;
  const openScreen = () => (isOpen = !isOpen);
</script>

<ButtonLink text="Company Branding" callback={openScreen} />

<Offcanvas
  header="Company Branding Settings"
  {isOpen}
  toggle={openScreen}
  placement="end"
>
  <CompanyBranding />

  <br />
  <hr />
  <ButtonCancel text="Close" callback={openScreen} />
</Offcanvas>
