<script>
  import { onMount } from "svelte";
  import { link } from "svelte-navigator";
  import HappyIcon from "../../Lib/Icons/HappyIcon.svelte";
  import DashboardMessage from "../../Lib/_DashboardMessage.svelte";
  import ApiServer from "../../Server/ApiServer";
  import InboxItem from "../Inbox/InboxItem.svelte";
  import FailedPaymentIcon from "../../Lib/Icons/FailedPaymentIcon.svelte";
  import CheckoutIcon from "../../Lib/Icons/CheckIcon.svelte";

  let urls = {
    inbox: "/api/Inbox/inbox",
    inboxtypes: "/api/Inbox/inboxtypes",
  };

  let itemTypes = {
    PaymentFailure: {
      count: 0,
      type: "Payment Failure",
    },
    NewSignUp: {
      count: 0,
      type: "New Sign Up",
    },
    PendingJob: {
      count: 0,
      type: "Pending Job",
    },
  };

  let inboxItems;
  let inboxCount = 0;

  onMount(async () => {
    ApiServer.Get(urls.inbox).then((data) => {
      inboxItems = data;
      inboxCount = inboxItems.length;

      inboxItems.forEach(function (inboxItem) {
        switch (inboxItem.type) {
          case itemTypes.PaymentFailure.type:
            inboxItem.icon = FailedPaymentIcon;
            break;
          case itemTypes.NewSignUp.type:
            inboxItem.icon = CheckoutIcon;
            break;
          case itemTypes.PendingJob.type:
            inboxItem.icon = PendingIcon;
            break;
        }
      });
    });
  });
</script>

<div class="card">
  <div class="card-body">
    <div class="card-title">
      <h3><a href="/inbox" use:link>Inbox {inboxCount}</a></h3>
    </div>
    <div class="card-text">
      {#if inboxItems && inboxItems.length > 0}
        {#each inboxItems as inboxItem}
          <InboxItem {inboxItem} />
        {/each}
      {:else}
        <DashboardMessage
          icon={HappyIcon}
          message="All caught up! Whooohoooo"
        />
      {/if}
    </div>
  </div>
</div>
