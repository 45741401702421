<script>
  //Started as copy of MapWithStops...
  export let routeDate = UTCDate.Today("YYYY-MM-DD");

  import { onMount } from "svelte";
  import { mapboxAPIKey } from "../../Constant/Environment";
  import { Map, controls } from "@beyonk/svelte-mapbox";
  const { GeolocateControl, NavigationControl, ScaleControl } = controls;
  import debounce from "just-debounce-it";
  import { navigate } from "svelte-navigator";
  import Modal from "sv-bootstrap-modal";

  import ApiServer from "../../Server/ApiServer";
  import UTCDate from "../../Util/UTCDate";
  import { Alert, Offcanvas } from "sveltestrap";
  import AddressGoogleLink from "../../lib/AddressGoogleLink.svelte";
  import LinkPhoneNumber from "../../Lib/LinkPhoneNumber.svelte";
  import AccountIcon from "../../Lib/Icons/AccountIcon.svelte";
  import AddressIcon from "../../Lib/Icons/AddressIcon.svelte";
  import PhoneNumberIcon from "../../Lib/Icons/PhoneNumberIcon.svelte";
  import StopJobLog from "./Stop/_StopLog.svelte";
  import CloseIcon from "../../Lib/Icons/CloseIcon.svelte";
  import StopCloseJob from "./Stop/_CloseJob.svelte";
  import StopJobDetail from "./Stop/_StopDetail.svelte";
  import { user } from "../../Stores/stores";
  import StopImages from "./Stop/_StopImages.svelte";
  import JobIcon from "../../Lib/Icons/JobIcon.svelte";
  import TrackLocationIcon from "../../Lib/Icons/TrackLocationIcon.svelte";
  import InputSwitch from "../../Lib/Inputs/InputSwitch.svelte";
  import UserSettings from "../../Stores/UserSettings";
  import ArrowDownIcon from "../../Lib/Icons/ArrowDownIcon.svelte";
  import EmployeeMarkers from "./_EmployeeMarkers.svelte";
  import JobCards from "./List/JobCards.svelte";
  import ButtonLink from "../../Lib/Buttons/ButtonLink.svelte";
  import ButtonIcon from "../../Lib/Buttons/ButtonIcon.svelte";
  import ArrowLeftIcon from "../../Lib/Icons/ArrowLeftIcon.svelte";
  import ArrowRightIcon from "../../Lib/Icons/ArrowRightIcon.svelte";
  import HideIcon from "../../Lib/Icons/HideIcon.svelte";
  import ShowIcon from "../../Lib/Icons/ShowIcon.svelte";
  import MeIcon from "../../Lib/Icons/MeIcon.svelte";
  import UserStore from "../../Stores/UserStore";
  import OptimizeIcon from "../../Lib/Icons/OptimizeIcon.svelte";
  import SpinnerIcon from "../../Lib/Icons/SpinnerIcon.svelte";
  import ButtonCancel from "../../Lib/Buttons/ButtonCancel.svelte";
  import DateFormat from "../../Lib/DateFormat.svelte";

  let jobs = [];
  let mapComponent;
  let map;
  let mapbox;
  let markers;
  let isOpen = false;
  let isMapControlOpen = false;
  let loading = false;
  let showOptimizeModal = false;
  let optimizingRoute = false;
  let optimizeResult;

  let urls = {
    jobsByDate: `/api/Job/jobsbydate`,
    userLocationTracking: `/api/Company/User/userlocationtracking`,
    jobOptions: `/api/Company/Settings/JobOptions`,
    getJobImages: `/api/Job/images?jobID=`,
    closeJob: `/api/Job/closejob`,
    mapCenter: "/api/Company/Settings/GeneralSettings/mapcenter",
    optimizeJobs: "/api/Job/optimizejobs?date=",
    getOptimizeResult: "/api/Job/routeoptimization?date=",
  };

  let currentJob;
  let header;
  let showCloseJob = false;
  let jobOptions;
  let jobCount = "";
  let jobsByTech = "";
  let showClosedJobs = false;
  let showLocation = true;
  let mapVisible = true;
  let jobList;
  let searchText;
  let mapStyle = "outdoors-v11";

  let optimizationResult;

  //#region Date in URL stuff
  const urlParams = new URLSearchParams(window.location.search);
  const url = new URL(window.location);

  function updateDateInUrl() {
    let newUrlDate = UTCDate.ParseForDatePicker(routeDate);
    url.searchParams.set("date", newUrlDate);
    window.history.pushState(null, "", url.toString());
  }

  function getStartingDate() {
    const urlDate = urlParams.get("date");
    if (urlDate) {
      routeDate = urlDate;
    }
    updateDateInUrl();
  }

  //#endregion
  function nextDay() {
    routeDate = UTCDate.AddDaysStr(1, routeDate);
    updateDateInUrl();
  }
  function prevDay() {
    routeDate = UTCDate.AddDaysStr(-1, routeDate);
    updateDateInUrl();
  }

  const toggleMapControls = () => (isMapControlOpen = !isMapControlOpen);

  const openScreen = () => (isOpen = !isOpen);

  function toggleTrackLocation() {
    setUserLocationTracking();
  }

  function toggleShowClosedJobs() {
    UserSettings.setShowClosedJobs(showClosedJobs);
    setJobMarkersByArray(jobs);
  }

  function setUserLocationTracking() {
    var request = {
      allowTrackLocation: showLocation,
    };
    ApiServer.Put(urls.userLocationTracking, request).then((data) => {});
  }

  function getUserLocationTracking() {
    ApiServer.Get(urls.userLocationTracking).then((data) => {
      showLocation = data;
    });
  }

  function handleShowCloseJob() {
    showCloseJob = !showCloseJob;
  }

  function handleCloseJob(event) {
    var job = event.detail;

    let closeJobRequest = {
      jobID: currentJob.id,
      closeStatusMessage: job.closedStatusMessage,
      userName: $user.fullName,
      imageUrls: job.imageUrls,
    };

    ApiServer.Post(urls.closeJob, closeJobRequest).then((updatedJob) => {
      //Remove current marker from map, set new marker with jobdata from server.
      markers.forEach((marker) => {
        if (marker.jobID == updatedJob.id) {
          //We found the marker!!
          marker.remove();
          updatedJob.index = job.index;
          makeMarker(updatedJob);
        }
      });
      //update job list with proper job count.
      jobs.splice(jobs.indexOf(job), 1);
      jobs = [...jobs, updatedJob];
      setJobCount(jobs);
    });
    openScreen();
  }

  function showJobScreen(job) {
    currentJob = job;
    header = currentJob ? "Stop: " + currentJob.index : "Stop Detail";
    showCloseJob = false;
    openScreen();
  }

  const filterJobs = debounce((event) => {
    searchText = event.target.value;
    setJobsByDate();
  }, 500);

  async function onReady() {
    var center = await ApiServer.Get(urls.mapCenter);

    mapComponent.setCenter([center.lng.value, center.lat.value]);
    mapComponent.setZoom(center.zoom.value);

    //From company settings....
    // mapComponent.setCenter([-115.17417043395645, 36.15947230364452]);
    // mapComponent.setZoom(9);
    map = mapComponent.getMap();
    mapbox = mapComponent.getMapbox();

    map.addControl(
      new mapboxgl.AttributionControl({
        customAttribution: "Map by Bin Bird",
      })
    );
    setJobsByDate();
  }

  function getOptimizationResult() {
    ApiServer.Get(urls.getOptimizeResult + routeDate).then((data) => {
      optimizationResult = data;
    });
  }

  function setJobsByDate() {
    loading = true;
    jobs = [];

    updateDateInUrl();
    getOptimizationResult();

    var searchRequest = {
      startDate: UTCDate.Parse(routeDate),
      endDate: UTCDate.Parse(routeDate),
      searchText: searchText,
    };

    ApiServer.Post(urls.jobsByDate, searchRequest).then((data) => {
      jobs = data;
      setJobMarkersByArray(jobs);
      loading = false;
    });
  }

  //We can use this to set jobs from search or from server. Same exact code.
  function setJobMarkersByArray(jobArray) {
    if (!map) return;
    removeAllMarkersFromMap(map);

    markers = [];

    let index = 1;
    jobArray.forEach((job) => {
      job.index = index;
      makeMarker(job);
      index++;
    });

    setJobCount(jobArray);
  }

  function setJobCount(jobsToCount) {
    let total = 0;
    let complete = 0;

    total = jobsToCount.length;

    jobList = {
      jobs: jobsToCount,
      completeCount: 0,
      allCount: total,
      scheduledCount: 0,
    };

    //Set Completed Job Count at the same time.
    var techJobCount = {};

    jobsToCount.forEach((job) => {
      //a little hacky here. The only way to prevent double filters when clicking on the JobMenu (for the list of jobs) is to set show=true here...
      //See JobCards.svelte filterJobs function.
      job.show = true;

      if (job.status == "Scheduled") {
        jobList.scheduledCount++;
      }
      if (job.status == "Complete") {
        complete++;
        jobList.completeCount++;

        if (techJobCount[job.closedBy]) {
          techJobCount[job.closedBy] = techJobCount[job.closedBy] + 1;
        } else {
          techJobCount[job.closedBy] = 1;
        }
      }
    });
    //Gotta love Svelte weirdness...
    jobList = jobList;
    jobsByTech = "";
    //build string of closed  jobs
    for (var key in techJobCount) {
      var value = techJobCount[key];
      jobsByTech += `${key}:${value}`;
    }

    jobCount = `Stops: ${total} Complete: ${complete}`;
  }

  function makeMarker(job) {
    const markerOptions = {};
    let color = "#990000";
    let element = makePin(job);
    const namedParams = {
      offset: [0, 0],
      color: color,
      element: element,
    };

    let m = new mapbox.Marker(namedParams);
    //So we can find it later...
    m.jobID = job.id;
    let popupEl = makePopup(job);
    m.setPopup(popupEl);
    m.setLngLat({ lat: job.latitude, lng: job.longitude });
    if (showClosedJobs) {
      m.addTo(map);
    } else if (job.status != "Complete") {
      m.addTo(map);
    }
    markers.push(m);
  }

  function makePin(job) {
    let markupTemplate = `<svg xmlns="http://www.w3.org/2000/svg" width="30px" height="38px">
                        <path d="M 19 31 C 19 32.7 16.3 34 13 34 C 9.7 34 7 32.7 7 31 C 7 29.3 9.7 28 13 28 C 16.3 28 19 29.3 19 31 Z" fill="#c9c9c9c9" fill-opacity=".2"/>
                        <path d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z" fill="#fff"/>
                        <path d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z" fill="${job.pinColor}"/>
                        <text x="13" y="19" font-size="11pt" font-weight="bold" text-anchor="middle" fill="#fff">${job.index}</text></svg>`;

    var h1 = document.createElement("div");
    h1.innerHTML = markupTemplate;
    return h1;
  }

  function makePopup(job) {
    const popupEl = new mapbox.Popup(Object.assign({ offset: 10 }));
    var link = document.createElement("a");
    link.href = "#";
    link.onclick = function () {
      showJobScreen(job);
    };
    link.innerText = job.index + " " + job.accountName;
    var h1 = document.createElement("h4");
    h1.appendChild(link);
    popupEl.setDOMContent(h1);
    return popupEl;
  }

  function removeAllMarkersFromMap() {
    if (!markers) return;
    markers.forEach((marker) => {
      marker.remove();
    });
  }

  function handleLogoff() {
    $user == null;
    UserStore.logoff();
    navigate("/");
    location.reload();
  }

  function setMapStyle() {
    map.setStyle(`mapbox://styles/mapbox/${mapStyle}`);
  }

  function optimizeRoute() {
    optimizeResult = "";
    showOptimizeModal = true;
    optimizingRoute = true;
    ApiServer.Get(urls.optimizeJobs + routeDate).then((data) => {
      optimizeResult = data.message;
      optimizingRoute = false;
      setJobsByDate();
    });
  }

  onMount(async () => {
    ApiServer.Get(urls.jobOptions).then((data) => {
      jobOptions = data;
      jobOptions.jobLogOptions = JSON.parse(data.jobLogOptionsJSON);
      jobOptions.jobCloseOptions = JSON.parse(data.jobCloseOptionsJSON);
    });
    let settings = UserSettings.getSettings();
    showClosedJobs = settings.showClosedJobs;
    getUserLocationTracking();
  });

  $: routeDate, setJobsByDate();
  $: hideMapLinkText = mapVisible ? "Hide Map" : "Show Map";
  $: hideMapIcon = mapVisible ? HideIcon : ShowIcon;
  getStartingDate();
</script>

<div class="row">
  <div class="col same-line">
    <input
      type="search"
      name="search"
      placeholder="Search"
      class="form-control"
      bind:value={searchText}
      on:input={filterJobs}
    />
    <!-- <button class="btn btn-sm btn-outline-primary" on:click={clearSearch}
      >X</button
    > -->
    <input
      type="date"
      name="date"
      class="form-control"
      bind:value={routeDate}
    />
    <ButtonIcon
      text="Prev"
      iconLeft={false}
      icon={ArrowLeftIcon}
      callback={prevDay}
    />

    <ButtonIcon
      text="Next"
      callback={nextDay}
      icon={ArrowRightIcon}
      iconLeft={false}
    />
    <ButtonIcon
      icon={hideMapIcon}
      text={hideMapLinkText}
      callback={() => {
        mapVisible = !mapVisible;
      }}
      iconLeft={false}
    />
  </div>
</div>

{#if mapVisible}
  <div class="row">
    <div class="col" style="min-height:600px">
      <div class="map-control-hud" on:click={toggleMapControls}>
        {jobCount}
        <span><ArrowDownIcon /></span>
      </div>
      <Map
        accessToken={mapboxAPIKey}
        bind:this={mapComponent}
        on:ready={onReady}
        options={{
          style: "mapbox://styles/mapbox/" + mapStyle,
          scrollZoom: true,
          attributionControl: false,
        }}
      >
        <!-- <FullScreenControl />
        <PolylineOfRoute /> -->
        <EmployeeMarkers {showLocation} />
        <NavigationControl />
        <GeolocateControl options={{ trackUserLocation: true }} />
      </Map>
    </div>
  </div>
{/if}

<br /><br />

{#if UserStore.userHasRole(["Admin"])}
  <div class="same-line">
    {#if optimizationResult}
      <Alert color="success">
        Optimized: <DateFormat
          date={optimizationResult.dateAdded}
          formatType="date-time-short"
        />
        {optimizationResult.optimizationResult.jobCount} Jobs
        {optimizationResult.optimizationResult.totalMiles}
        {optimizationResult.optimizationResult.totalDriveTime}
        <button class="btn btn-sm btn-primary" on:click={optimizeRoute}>
          <OptimizeIcon /> Optimize
        </button>
      </Alert>
    {:else}
      <Alert color="warning">
        Route not optimized
        <button class="btn btn-sm btn-primary" on:click={optimizeRoute}>
          <OptimizeIcon /> Optimize
        </button>
      </Alert>
    {/if}
  </div>
{/if}

{#if jobList}
  <JobCards {jobList} />
{/if}

<Offcanvas
  header="Map controls"
  isOpen={isMapControlOpen}
  toggle={toggleMapControls}
  placement="end"
>
  <div class="container">
    <div class="row">
      <div class="col">
        <TrackLocationIcon />
        <InputSwitch
          text="Show My Location"
          on:valueChange={toggleTrackLocation}
          bind:value={showLocation}
        />
      </div>
      <div class="col">
        <JobIcon />
        <InputSwitch
          text="Show Closed Jobs"
          on:valueChange={toggleShowClosedJobs}
          bind:value={showClosedJobs}
        />
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col">
        <label for="mapStyles">Map Style</label>
        <select
          name="mapStyles"
          class="form-control"
          bind:value={mapStyle}
          on:change={setMapStyle}
        >
          <option value="satellite-v9">Satellite</option>
          <option value="light-v10">Light</option>
          <option value="dark-v10">Dark</option>
          <option value="streets-v11">Streets</option>
          <option value="outdoors-v11">Outdoors</option>
        </select>
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col">
        <div class="col">
          {jobCount} <br />
          {jobsByTech}
        </div>
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col">
        <MeIcon /> Me: {$user.fullName}
        <ButtonLink text="Logout" callback={handleLogoff} />
      </div>
    </div>
  </div>
</Offcanvas>

<Offcanvas {header} {isOpen} toggle={openScreen} placement="end">
  <!-- Take this off as long as the OffCanvase Header stays gray so we can see the close button. -->
  <!-- <div slot="header">
    {header}
    <ButtonClose text="Close" callback={openScreen} />
  </div> -->
  <div class="row">
    <div class="col">
      {#if currentJob}
        <h4>
          <AccountIcon />
          <a href="/account/{currentJob.accountID}" target="_blank"
            >{currentJob.accountName}</a
          >
        </h4>
        <p>
          <AddressIcon />
          <AddressGoogleLink
            address={currentJob.address}
            city={currentJob.city}
            state={currentJob.state}
            zip={currentJob.zip}
          />
        </p>
        <p>
          <PhoneNumberIcon />
          <LinkPhoneNumber phoneNumber={currentJob.phoneNumber} />
        </p>
        <p>
          <JobIcon />
          <a
            href="account/{currentJob.accountID}/jobs/{currentJob.id}/{currentJob.jobID}"
            >{currentJob.jobNumber}</a
          >
        </p>

        {#if !showCloseJob}
          <StopJobDetail {currentJob} />

          <br />
          {#if currentJob.status != "Complete"}
            <div class="row">
              <div class="col">
                <button
                  class="btn btn-sm btn-outline-danger float-end"
                  on:click={handleShowCloseJob}
                >
                  <CloseIcon /> Close Job</button
                >
              </div>
            </div>
          {/if}

          <StopImages job={currentJob} />

          <StopJobLog {jobOptions} job={currentJob} />
          <br />
        {:else}
          <StopCloseJob
            job={currentJob}
            {jobOptions}
            on:showCloseJobEvent={handleShowCloseJob}
            on:closeJob={handleCloseJob}
          />
        {/if}
      {:else}
        <p>No current job found</p>
      {/if}
    </div>
  </div>
</Offcanvas>

<Modal bind:open={showOptimizeModal} ignoreBackdrop={true}>
  <div class="modal-header">
    <h5 class="modal-title">Optimizing Route</h5>
    <button
      type="button"
      class="close"
      on:click={() => (showOptimizeModal = false)}
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {#if optimizingRoute}
      <h4>
        Optimizing Route
        <SpinnerIcon />
      </h4>
    {:else}
      <h4>Route Optimized!</h4>
    {/if}

    {#if optimizeResult}
      {optimizeResult}
    {/if}
  </div>
  <div class="modal-footer">
    <ButtonCancel text="Close" callback={() => (showOptimizeModal = false)} />
  </div>
</Modal>

<style>
  :global(.offcanvas-header) {
    background-color: #ececec;
  }

  .map-control-hud {
    width: 100%;
    height: 35px;
    background-color: #000000;
    color: #ffffff;
    padding: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
  }
</style>
