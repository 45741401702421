<script>
  export let value = true;
  import CheckIcon from "./CheckIcon.svelte";
  import CloseIcon from "./CloseIcon.svelte";
</script>

{#if value}
  <CheckIcon />
{:else}
  <CloseIcon />
{/if}
