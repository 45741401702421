<script>
  export let path;
  export let roles;
  import { Route } from "svelte-navigator";
  import PrivateRouteGuard from "./PrivateRouteGuard.svelte";
  //console.log(`Roles ${roles} from ${path}`);
</script>

<Route {path} let:params let:location let:navigate>
  <PrivateRouteGuard {roles}>
    <slot {params} {location} {navigate} />
  </PrivateRouteGuard>
</Route>
