<script>
  export let message;
  export let show;
  import Modal from "sv-bootstrap-modal";
  import ButtonCancel from "../../Lib/Buttons/ButtonCancel.svelte";
  import DateFormat from "../../Lib/DateFormat.svelte";
  import PhoneNumber from "../../Lib/PhoneNumber.svelte";
</script>

<Modal bind:open={show} ignoreBackdrop={true} dialogClasses="modal-xl">
  <div class="modal-header">
    <h4 class="modal-title">Message Detail</h4>
    <button type="button" class="close" on:click={() => (show = false)}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {#if message}
      <h4>Who and When</h4>

      Sent: <DateFormat date={message.dateAdded} formatType="date-time-short" />
      <DateFormat date={message.dateAdded} formatType="timeago" />
      {#if message.messageType != "EMAIL"}
        <br />
        From: {message.fromName} -
        <PhoneNumber number={message.fromNumber} />
        <br />
        To: {message.toName} - <PhoneNumber number={message.toNumber} />
      {:else}
        From: {message.fromName} - {message.fromNumber}
        <br />
        To: {message.toName} - {message.toNumber}
      {/if}

      <h4>Message Detail</h4>
      {#if message.messageType == "EMAIL"}
        {@html message.body}
      {:else}
        {message.body}
      {/if}
    {/if}

    <h4>Message Delivery</h4>
    {#if message.isSendSuccessful}
      <p>Message was sent successfully.</p>
      <p>MessageID: {message.messageID}</p>
    {:else}
      <p>Message Failed to send</p>
      <p>{message.sendStatusMessage}</p>
    {/if}

    <br />
    <br />
    <div class="modal-footer">
      <ButtonCancel text="Close" callback={() => (show = false)} />
    </div>
  </div>
</Modal>
