<script>
  //https://github.com/mzohaibqc/svelte-toasts
  //Four types of toasts are available i.e. success, info, error and warning. It will override toast type set by ToastContainer.
  import { toasts, ToastContainer, FlatToast } from "svelte-toasts";
  import { toastNotification } from "../Stores/toastNotification";

  $: if ($toastNotification) {
    //console.log("$notification message");
    let value = $toastNotification;
    toasts.add({
      title: value.title,
      description: value.message,
      duration: 8000, // 0 or negative to avoid auto-remove
      placement: "bottom-right",
      theme: "light",
      type: value.type,
      onClick: () => {},
      onRemove: () => {},
      //component: BootstrapToast, // allows to override toast component/template per toast
    });
  }
</script>

<ToastContainer placement="bottom-right" let:data>
  <FlatToast {data} />
</ToastContainer>
