<script>
  export let address;
  export let city;
  export let state;
  export let zip;
  export let linebreak = false;
  export let showStateZip = true;

  //WAZE
  let wazeUrl = `https://waze.com/ul?q=${address},${city},${state} ${zip}&navigate=yes`;

  //Google
  let googleUrl = `https://www.google.com/maps/dir/?api=1&origin=&destination=${address},${city},${state} ${zip}&travelmode=driving`;

  let navigateUrl = encodeURI(googleUrl);
  // console.log(navigateUrl);
</script>

<a href={navigateUrl} target="_blank">
  {address}, {city}
  {#if linebreak}
    <br />
  {/if}
  {#if showStateZip}
    {state}
    {zip}
  {/if}
</a>
