export default class JobTemplateData {
  static HoursBeforeJob = [
    { id: "24", text: "1 Day" },
    { id: "48", text: "2 Days" },
    { id: "72", text: "3 Days" },
    { id: "96", text: "4 Days" },
    { id: "120", text: "5 Days" },
    { id: "144", text: "6 Days" },
    { id: "168", text: "7 Days" },
    { id: "192", text: "8 Days" },
    { id: "216", text: "9 Days" },
    { id: "240", text: "10 Days" },
    { id: "336", text: "14 Days" },
  ];

  static HourToRun = [
    { id: "06:00", text: "6:00 AM" },
    { id: "06:30", text: "6:30 AM" },
    { id: "07:00", text: "7:00 AM" },
    { id: "07:30", text: "7:30 AM" },
    { id: "08:00", text: "8:00 AM" },
    { id: "08:30", text: "8:30 AM" },
    { id: "09:00", text: "9:00 AM" },
    { id: "09:30", text: "9:30 AM" },
    { id: "10:00", text: "10:00 AM" },
    { id: "10:30", text: "10:30 AM" },
    { id: "11:00", text: "11:00 AM" },
    { id: "11:30", text: "11:30 AM" },
    { id: "12:00", text: "12:00 PM" },
    { id: "12:30", text: "12:30 PM" },
    { id: "13:00", text: "1:00 PM" },
    { id: "13:30", text: "1:30 PM" },
    { id: "14:00", text: "2:00 PM" },
    { id: "14:30", text: "2:30 PM" },
    { id: "15:00", text: "3:00 PM" },
    { id: "15:30", text: "3:30 PM" },
    { id: "16:00", text: "4:00 PM" },
    { id: "16:30", text: "4:30 PM" },
    { id: "17:00", text: "5:00 PM" },
    { id: "17:30", text: "5:30 PM" },
    { id: "18:00", text: "6:00 PM" },
    { id: "18:30", text: "6:30 PM" },
    { id: "19:00", text: "7:00 PM" },
    { id: "19:30", text: "7:30 PM" },
    { id: "20:00", text: "8:00 PM" },
    { id: "20:30", text: "8:30 PM" },
    { id: "21:00", text: "9:00 PM" },
    { id: "21:30", text: "9:30 PM" },
    { id: "22:00", text: "10:00 PM" },
  ];
}
