<script>
  export let jobCount;
  import { mapboxAPIKey } from "../../Constant/Environment";
  import { Map, controls } from "@beyonk/svelte-mapbox";
  import EmployeeMarkers from "./_EmployeeMarkers.svelte";
  import UTCDate from "../../Util/UTCDate";
  import ApiServer from "../../Server/ApiServer";
  const { GeolocateControl, NavigationControl, ScaleControl } = controls;

  let jobs = [];
  let mapComponent;
  let map;
  let mapbox;
  let markers;
  let showLocation = true;
  let routeDate = UTCDate.Today("YYYY-MM-DD");

  let urls = {
    jobsByDate: `/api/Job/jobsbydate/`,
    userLocationTracking: `/api/Company/User/userlocationtracking`,
    mapCenter: "/api/Company/Settings/GeneralSettings/mapcenter",
  };

  function getUserLocationTracking() {
    ApiServer.Get(urls.userLocationTracking).then((data) => {
      showLocation = data;
    });
  }

  function setJobsByDate() {
    //var fixDate = UTCDate.AsDate(routeDate);
    var searchRequest = {
      startDate: UTCDate.Parse(routeDate),
      endDate: UTCDate.Parse(routeDate),
      searchText: "",
    };

    ApiServer.Post(urls.jobsByDate, searchRequest).then((data) => {
      jobs = [];
      jobs = data;
      setJobMarkersByArray(jobs);
    });
    getUserLocationTracking();
  }

  function setJobMarkersByArray(jobArray) {
    if (!map) return;
    removeAllMarkersFromMap(map);

    markers = [];

    let index = 1;
    jobArray.forEach((job) => {
      job.index = index;
      makeMarker(job);
      index++;
    });

    setJobCount(jobArray);
  }

  function setJobCount(jobsToCount) {
    let total = 0;
    let complete = 0;
    let remaining = 0;

    total = jobsToCount.length;
    jobsToCount.forEach((job) => {
      if (job.status == "Scheduled") remaining++;
      if (job.status == "Complete") complete++;
    });

    jobCount = `${total} stops: ${remaining} open`;
  }

  function removeAllMarkersFromMap() {
    if (!markers) return;
    markers.forEach((marker) => {
      marker.remove();
    });
  }

  function makeMarker(job) {
    let color = "#990000";
    let element = makePin(job);
    const namedParams = {
      offset: [0, 0],
      color: color,
      element: element,
    };

    let m = new mapbox.Marker(namedParams);
    //So we can find it later...
    m.jobID = job.id;
    let popupEl = makePopup(job);
    m.setPopup(popupEl);
    m.setLngLat({ lat: job.latitude, lng: job.longitude });
    m.addTo(map);
    markers.push(m);
  }

  function makePin(job) {
    let markupTemplate = `<svg xmlns="http://www.w3.org/2000/svg" width="30px" height="38px">
                        <path d="M 19 31 C 19 32.7 16.3 34 13 34 C 9.7 34 7 32.7 7 31 C 7 29.3 9.7 28 13 28 C 16.3 28 19 29.3 19 31 Z" fill="#c9c9c9c9" fill-opacity=".2"/>
                        <path d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z" fill="#fff"/>
                        <path d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z" fill="${job.pinColor}"/>
                        <text x="13" y="19" font-size="11pt" font-weight="bold" text-anchor="middle" fill="#fff">${job.index}</text></svg>`;

    var h1 = document.createElement("div");
    h1.innerHTML = markupTemplate;
    return h1;
  }

  function makePopup(job) {
    const popupEl = new mapbox.Popup(Object.assign({ offset: 10 }));
    var holder = document.createElement("div");
    var accountLink = document.createElement("a");
    accountLink.href = "/account/" + job.accountID;
    accountLink.target = "_blank";
    accountLink.innerText = job.index + " " + job.accountName;

    var jobLink = document.createElement("a");
    jobLink.href = `/account/${job.accountID}/jobs/${job.id}`;
    jobLink.target = "_blank";
    jobLink.innerText = `Job: ${job.jobNumber}`;

    var h1 = document.createElement("h5");
    h1.appendChild(accountLink);

    var address = document.createElement("span");
    address.innerHTML = " " + job.address;

    holder.append(h1);
    holder.append(jobLink);
    holder.append(address);
    popupEl.setDOMContent(holder);
    return popupEl;
  }

  async function onReady() {
    var center = await ApiServer.Get(urls.mapCenter);

    mapComponent.setCenter([center.lng.value, center.lat.value]);
    mapComponent.setZoom(center.zoom.value);

    // //From company settings....
    // mapComponent.setCenter([-115.16389787841163, 36.17045031158888]);
    // mapComponent.setZoom(8);
    map = mapComponent.getMap();
    mapbox = mapComponent.getMapbox();

    map.addControl(
      new mapboxgl.AttributionControl({
        customAttribution: "Map by Bin Bird",
      })
    );
    setJobsByDate();
  }

  $: routeDate, setJobsByDate();
</script>

<!--this is inbox mini map-->
<input type="date" class="form-control" bind:value={routeDate} />
<Map
  accessToken={mapboxAPIKey}
  bind:this={mapComponent}
  on:ready={onReady}
  options={{
    scrollZoom: true,
    attributionControl: false,
  }}
>
  <EmployeeMarkers {showLocation} />
  <NavigationControl />
  <GeolocateControl options={{ trackUserLocation: true }} />
</Map>
