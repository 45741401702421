<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-navigator";
  import ApiServer from "../../../Server/ApiServer";
  import Fa from "svelte-fa";
  import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
  import Back from "../../../Lib/Buttons/Back.svelte";
  import Header from "../Header.svelte";
  import YesNoIcon from "../../../Lib/Icons/YesNoIcon.svelte";

  let users;
  let userInvites;

  let urls = {
    getUsers: `/api/Company/User/users?pageNumber=1&pageSize=30`,
    userInvites: "/api/Company/UserInvite/invites",
  };

  onMount(async () => {
    ApiServer.Get(urls.getUsers).then((data) => {
      users = data.data;
    });

    ApiServer.Get(urls.userInvites).then((data) => {
      userInvites = data;
    });
  });
</script>

<Header text="Users" />
{#if users}
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <div class="float-end">
        <Link to="/company/invite/new" class="btn btn-primary"
          ><Fa icon={faUserPlus} /> Invite User</Link
        >
      </div>
      <br />
      <h4>Current Users</h4>
      <article class="card-body">
        <div>
          <div class="row bb-header">
            <div class="col">First</div>
            <div class="col d-none d-sm-block">Last</div>
            <div class="col">Email (username)</div>
            <div class="col d-none d-sm-block">Initials</div>
            <div class="col d-none d-sm-block">Role</div>
            <div class="col d-none d-sm-block">Enabled</div>
          </div>
          {#each users as user}
            <div class="row bb-row">
              <div class="col"><Link to={user.id}>{user.firstName}</Link></div>
              <div class="col d-none d-sm-block">{user.lastName}</div>
              <div class="col">{user.email}</div>
              <div class="col d-none d-sm-block">{user.initials}</div>
              <div class="col d-none d-sm-block">{user.role}</div>
              <div class="col d-none d-sm-block">
                <YesNoIcon value={user.enabled} />
              </div>
            </div>
          {/each}
        </div>
      </article>
    </div>
  </div>
{/if}

<h4>Invitations</h4>
{#if userInvites}
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <article class="card-body">
        <div>
          <div class="row bb-header">
            <div class="col">Username</div>
            <div class="col">First</div>
            <div class="col d-none d-sm-block">Last</div>
            <div class="col d-none d-sm-block">Initials</div>
            <div class="col d-none d-sm-block">Role</div>
            <div class="col d-none d-sm-block">Expires</div>
            <div class="col d-none d-sm-block">Accepted</div>
          </div>
          {#each userInvites as invite}
            <div class="row bb-row">
              <div class="col">
                <Link to="/company/invite/{invite.id}">{invite.firstName}</Link>
              </div>
              <div class="col d-none d-sm-block">{invite.lastName}</div>
              <div class="col">{invite.email}</div>
              <div class="col d-none d-sm-block">{invite.initials}</div>
              <div class="col d-none d-sm-block">{invite.role}</div>
              <div class="col d-none d-sm-block">{invite.expiresIn}</div>
              <div class="col d-none d-sm-block">
                <YesNoIcon value={invite.invitationAccepted} />
              </div>
            </div>
          {/each}
        </div>
      </article>
    </div>
  </div>
{/if}
