<script>
  export let text;
  export let callback;
  export let size = "normal";

  const className =
    size == "normal"
      ? "btn btn-outline-primary"
      : "btn btn-sm btn-outline-primary";
</script>

<button type="button" class={className} on:click={callback}>
  {text}
</button>
