<script>
  export let openUserScreen;
  import AccountIcon from "../Lib/Icons/AccountIcon.svelte";
  import InboxIcon from "../Lib/Icons/InboxIcon.svelte";
  import DashboardIcon from "../Lib/Icons/DashboardIcon.svelte";
  import MapIcon from "../Lib/Icons/MapIcon.svelte";
  import PayIcon from "../Lib/Icons/BillingIcon.svelte";
  import MenuItem from "./MenuItem.svelte";
  import MeIcon from "../Lib/Icons/MeIcon.svelte";
  import { onMount } from "svelte";
  import ApiServer from "../Server/ApiServer";
  import PlaygroundBanner from "./PlaygroundBanner.svelte";
  import BillingTrialReminder from "./BillingTrailReminder.svelte";

  let inboxCount = 0;

  let urls = {
    inboxcount: "/api/Inbox/inboxcount",
  };

  onMount(async () => {
    ApiServer.Get(urls.inboxcount).then((data) => {
      inboxCount = data;
    });
  });
</script>

<PlaygroundBanner />

<div class="bb-nav">
  <MenuItem name="Dashboard" url="/" icon={DashboardIcon} />
  <MenuItem
    name="Inbox"
    badgeLabel={inboxCount}
    url="/inbox"
    icon={InboxIcon}
  />
  <MenuItem name="Accounts" url="/accounts" icon={AccountIcon} />
  <MenuItem name="Map" url="/map" icon={MapIcon} />
  <MenuItem name="Billing" url="/billing" icon={PayIcon} />

  <div class="bb-nav-item " on:click={openUserScreen}>
    <MeIcon /> Me
  </div>
</div>

<BillingTrialReminder />

<style>
  .bb-nav {
    background-color: #f8f9fa;
    padding: 5px;

    display: -webkit-flex;
    display: flex;
    justify-content: flex-start;
  }

  .bb-nav-item {
    margin-right: 18px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    margin-left: auto;
  }
</style>
