<script>
  export let path = "";

  import { useNavigate, useLocation } from "svelte-navigator";
  import { Label } from "sveltestrap";
  import InputEmail from "../Lib/Inputs/InputEmail.svelte";
  import InputPassword from "../Lib/Inputs/InputPassword.svelte";
  import UserStore from "../Stores/UserStore";
  import PublicPageHeader from "./PublicPageHeader.svelte";

  const navigate = useNavigate();
  const location = useLocation();

  let loginMessage = "";
  let email; // = "brett@binbirdapp.com";
  let password; // = "brett";

  async function handleSubmit(event) {
    event.preventDefault();
    var loginRequest = { email: email, password: password, path: path };

    var loginResult = await UserStore.performLogin(loginRequest);

    if (loginResult && loginResult.success) {
      loginResult.path = getLoginPath(path);
      navigate(loginResult.path, { replace: true });
    } else {
      loginMessage = loginResult.message;
    }
  }

  function getLoginPath(path) {
    if (UserStore.userHasRole(["Admin"])) {
      return path || ($location.state && $location.state.from) || "/";
    } else {
      //Must be a tech
      return "/map";
    }
  }
</script>

<svelte:head>
  <title>Bin Bird - Login</title>
</svelte:head>

<main class="container-fluid container-sm body-content">
  <PublicPageHeader />

  <div class="row">
    <div class="col-lg-6 mx-auto ">
      <h1>Sign In</h1>
      <div class="card">
        <div class="card-body ">
          <h5>Sign in to your Bin Bird account</h5>
          <div class="card-text">
            <form on:submit={handleSubmit}>
              {#if loginMessage}
                <div class="alert alert-warning" role="alert">
                  {loginMessage}
                </div>
              {/if}
              <div class="form-group">
                <InputEmail
                  label="Email Address"
                  bind:value={email}
                  required={true}
                />
                <small id="emailHelp" class="form-text text-muted"
                  >This is also your username</small
                >
              </div>
              <div class="form-group">
                <InputPassword
                  label="Password"
                  bind:value={password}
                  required={true}
                />
              </div>
              <p />
              <div class="primary-nav">
                <span />
                <button type="submit" class="btn btn-danger">Sign In</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p />
  <div class="row">
    <div class="col-lg-6 mx-auto">
      <div class="same-line">
        <a class="btn btn-danger" href="signup">Sign up now!</a>
        <a href="signinhelp">Help me login</a>
      </div>
    </div>
  </div>
</main>

<style>
  .primary-nav {
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
  }
</style>
