<script>
  export let account;
  export let selectStyle = false;

  import { link } from "svelte-navigator";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import AddressGoogleLink from "../../Lib/AddressGoogleLink.svelte";

  function onAccountSelect() {
    if (selectStyle) dispatch("onAccountSelect", account);
  }
</script>

<div class="account-card" on:click={onAccountSelect}>
  <div class:account-card-selected={selectStyle}>
    <div class="row">
      <div class="col-sm-6">
        <h3>
          <a href="account/{account.id}" use:link
            >{account.firstName} {account.lastName}</a
          >
        </h3>
      </div>
      <div class="col-sm-6">
        <AddressGoogleLink
          showStateZip={false}
          address={account.address}
          city={account.city}
          state={account.state}
          zip={account.zip}
        />
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        {account.email}<br />
      </div>
      <div class="col-6">
        <a href="tel:{account.formattedPhoneNumber}">
          {account.formattedPhoneNumber}
        </a>
      </div>
    </div>
    {#if account.comment || account.planName}
      <div class="row">
        <div class="col">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col">
          {#if account.comment}
            Note: {account.comment}
          {/if}
        </div>
        <div class="col">
          {#if account.planName}
            {account.planName}
          {/if}
        </div>
      </div>
    {/if}
  </div>
</div>

<style>
  .account-card {
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 5px;
    margin-bottom: 5px;
  }

  /*https://stackoverflow.com/a/39467980/393159 allow hover on child for background, but disable click events*/
  .account-card-selected {
    pointer-events: none;
  }
  .account-card:hover > .account-card-selected {
    background-color: #f8f9fa;
  }
</style>
