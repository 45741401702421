<script>
  import { onMount } from "svelte";

  import Chart from "svelte-frappe-charts";
  import ApiServer from "../Server/ApiServer";
  import DashboardMessage from "./_DashboardMessage.svelte";
  import SadIcon from "../Lib/Icons/SadIcon.svelte";

  let urls = {
    accountsThisWeek: `/api/Dashboard/accountsthisweek`,
  };

  let count;
  let chartRef;
  let data;

  //DON'T DELETE THIS YOU WILL WANT TO REFER TO IT LATER.
  // let data = {
  //   labels: ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"],
  //   datasets: [
  //     { name: "Monthly", values: [10, 12, 3, 9, 8, 15, 9] },
  //     { name: "Onetime", values: [2, 4, 6, 7, 1, 9, 3] },
  //     { name: "Quarterly", values: [1, 3, 7, 1, 6, 2, 0] },
  //   ],
  // };

  let barOptions = {
    stacked: 1,
  };

  onMount(async () => {
    ApiServer.Get(urls.accountsThisWeek).then((result) => {
      data = result;
      count = result.totalCount;
    });
  });
</script>

<div class="card">
  <div class="card-body">
    <div class="card-title">
      <h3>{count} customers this week</h3>
    </div>
    <div class="card-text">
      {#if data && count > 0}
        <Chart
          {data}
          valuesOverPoints="1"
          {barOptions}
          bind:this={chartRef}
          type="bar"
        />
      {:else}
        <DashboardMessage icon={SadIcon} />
      {/if}
    </div>
  </div>
</div>
