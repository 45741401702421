<script>
  export let job;
  export let showModal = false;

  import Modal from "sv-bootstrap-modal";
  import { useNavigate } from "svelte-navigator";
  const navigate = useNavigate();

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import ButtonCancel from "../../../../Lib/Buttons/ButtonCancel.svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import { user } from "../../../../Stores/stores";
  import { toastNotification } from "../../../../Stores/toastNotification";
  import PaymentRefundList from "./_PaymentRefundList.svelte";

  let jobPayments;
  let refundedPayments;
  let refundOption = "norefund";

  let urls = {
    cancelJobPayment: `/api/Job/canceljobpayment`,
    jobPayments: `/api/Job/jobpayments?jobID=`,
  };

  let paymentMessage = "";

  function handleCancelJobPayment() {
    let cancelJobRequest = {
      jobID: job.id,
      userName: $user.fullName,
    };

    cancelJobRequest.paymentsToRefund = refundedPayments;
    ApiServer.Post(urls.cancelJobPayment, cancelJobRequest).then((data) => {
      if (data.success) {
        toastNotification.Success("Job payment canceled!");
        showModal = false;
        dispatch("afterStatusChange");
      } else {
        toastNotification.Success("Job payment cancel failed. " + data.message);
        paymentMessage = data.message;
      }
    });
  }

  function loadPayments() {
    if (!jobPayments) {
      ApiServer.Get(urls.jobPayments + job.id).then((data) => {
        jobPayments = data;
      });
    }
  }

  $: showModal, loadPayments();
</script>

<Modal bind:open={showModal}>
  <div class="modal-header">
    <h5 class="modal-title">Cancel Payment for Job {job.jobNumber}</h5>
    <button type="button" class="close" on:click={() => (showModal = false)}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <PaymentRefundList
      bind:jobPayments
      bind:refundOption
      bind:refundedPayments
    />

    <p>This will set the job to Not Paid. Are you sure?</p>

    {#if paymentMessage}
      <p>
        {paymentMessage}
      </p>
    {/if}
  </div>
  <div class="modal-footer">
    <ButtonCancel text="Cancel" callback={() => (showModal = false)} />
    <button
      type="button"
      class="btn btn-outline-primary"
      on:click={handleCancelJobPayment}>Cancel Payment</button
    >
  </div>
</Modal>
