<script>
  export let text = "Do Action";
  export let callback = null;
  export let tooltip = "Are you sure?";
  export let warningText = "Are you sure?";
  export let linkButton = false;
  export let buttonClass = "btn btn-outline-primary";
  export let icon = null;

  import { Popover } from "sveltestrap";
  import { onMount } from "svelte";

  let buttonId = `binbird-button-${Math.floor(Math.random() * 100)}`;
  let buttonName = text ? text : tooltip ? tooltip : "Action Button";
  let isOpen = false;

  function performAction() {
    if (callback) {
      callback();
    }
    isOpen = false;
  }

  function setButtonClass() {
    if (linkButton) buttonClass = "btn link-btn";
  }

  function dismissAction() {
    isOpen = false;
  }

  onMount(async () => {
    setButtonClass();
  });
</script>

<button
  id={buttonId}
  name={buttonName}
  type="button"
  title={tooltip}
  class={buttonClass}
>
  {#if icon} <svelte:component this={icon} /> {/if}
  {#if text} {text} {/if}
</button>

<Popover placement="top" bind:isOpen target={buttonId} title="Are you sure?">
  <p>{warningText}</p>
  <p>
    <button
      data-bs-toggle="popover"
      class="btn btn-danger btn-sm"
      on:click={performAction}
    >
      Yes
    </button>
    <button
      data-bs-toggle="popover"
      class="btn btn-success btn-sm"
      on:click={dismissAction}>Cancel</button
    >
  </p>
</Popover>
