<script>
  export let finished = false;

  import CheckIcon from "../../../Lib/Icons/CheckIcon.svelte";
  import CloseIcon from "../../../Lib/Icons/CloseIcon.svelte";
</script>

{#if finished}
  <CheckIcon />
{:else}
  <CloseIcon />
{/if}
