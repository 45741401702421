<script>
  export let templateOrJob;
  export let isJob = false;
  import { onMount } from "svelte";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import ApiServer from "../../../Server/ApiServer";
  import BillingPlanLineItem from "./BillingPlanLineItem.svelte";

  let billingPlanID;
  let billingPlans;
  let urls = {
    getNewBillingPlan: `/api/Company/BillingPlan/billingplan?id=`,
    getExistingBillingPlan: `/api/Company/BillingPlan/existingbillingplan?id=`,
    getBillingPlans: `/api/Company/BillingPlan/recurringbillingplans`,
    getBillingPlans: `/api/Company/BillingPlan/recurringbillingplans`,
  };

  //The hard part here is trying to figure out which line items to show.
  //If the template has line items then show those, if not then show the billingPlan line items.

  async function handleBillingPlanChange() {
    if (billingPlanID) {
      var pendingBillingPlan = await getNewBillingPlan(billingPlanID);
      templateOrJob.billingPlan = pendingBillingPlan;
      templateOrJob.billingPlanID = billingPlanID;
      if (
        templateOrJob.billingPlan &&
        templateOrJob.billingPlan.billingPlanRules
      ) {
        //Set Interval based on billing plan rules
        templateOrJob.interval =
          templateOrJob.billingPlan.billingPlanRules.recurringRule.interval.toString();
      }
    } else {
      // templateOrJob.billingPlan = {};
      templateOrJob.billingPlanID = null;
    }
  }

  function handleLineItemChange(event) {
    dispatch("lineItemChange", event.detail);
  }

  function getNewBillingPlan(planID) {
    if (!templateOrJob.bins) templateOrJob.bins = 2;
    return ApiServer.Get(
      urls.getNewBillingPlan + planID + "&bins=" + templateOrJob.bins
    );
  }

  function getSavedBillingPlan(planID) {
    return ApiServer.Get(urls.getExistingBillingPlan + planID);
  }

  function setBillingPlans() {
    ApiServer.Get(urls.getBillingPlans).then((data) => {
      billingPlans = data;
    });
  }

  onMount(async () => {
    setBillingPlans();
    var pendingBillingPlan = {};

    if (templateOrJob.billingPlanID) {
      billingPlanID = templateOrJob.billingPlanID;
      pendingBillingPlan = await getSavedBillingPlan(billingPlanID);
    }

    pendingBillingPlan.lineItems = templateOrJob.lineItems;
    templateOrJob.billingPlan = pendingBillingPlan;
  });
</script>

{#if templateOrJob}
  {#if billingPlans}
    <div class="row">
      <div class="col">
        <label for="billingplan">Billing Plan Template</label>

        <select
          disabled={isJob}
          class="form-control"
          id="billingplan"
          name="billingplan"
          bind:value={billingPlanID}
          on:change={handleBillingPlanChange}
        >
          <option value="">Select Billing Plan</option>
          {#each billingPlans as plan}
            <option value={plan.id}>{plan.name} {plan.cost}</option>
          {/each}
        </select>
      </div>
    </div>

    {#if templateOrJob.billingPlan}
      <BillingPlanLineItem
        on:lineItemChange={handleLineItemChange}
        bind:plan={templateOrJob.billingPlan}
      />
    {/if}
  {/if}
{/if}
