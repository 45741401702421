<script>
  export let account;

  import { onMount } from "svelte";
  import { useNavigate } from "svelte-navigator";

  import ApiServer from "../../../../Server/ApiServer";
  import ButtonDelete from "../../../../Lib/Buttons/ButtonDelete.svelte";
  import { toastNotification } from "../../../../Stores/toastNotification";
  import InputSelect from "../../../../Lib/Inputs/InputSelect.svelte";
  import DisableAccount from "./_DisableAccount.svelte";
  import InputEmail from "../../../../Lib/Inputs/InputEmail.svelte";
  import InputPhone from "../../../../Lib/Inputs/InputPhone.svelte";
  import InputSwitch from "../../../../Lib/Inputs/InputSwitch.svelte";
  import AddressAutocomplete from "../../../../Lib/AddressAutocomplete/Autocomplete.svelte";
  import InputTextBox from "../../../../Lib/Inputs/InputTextBox.svelte";
  import SaveIcon from "../../../../Lib/Icons/SaveIcon.svelte";

  const navigate = useNavigate();

  let jobVendorRestrictionOptions;

  let urls = {
    account: `/api/Account/Account/account?accountID=`,
    updateAccount: `/api/Account/Account/account`,
    get: `/api/Company/Settings/JobOptions`,
  };

  function handleSave() {
    if (account.id == "new") {
      delete account.id;
      ApiServer.Post(urls.updateAccount, account).then((data) => {
        toastNotification.Success("Account Added!!");
        navigate(`/account/${data.id}`);
      });
    } else {
      ApiServer.Put(urls.updateAccount, account).then((data) => {
        toastNotification.Success("Account Saved!");
        navigate(`/account/${data.id}`);
      });
    }
  }

  function handleRemove() {
    ApiServer.Delete(urls.account + account.id).then((data) => {
      navigate("/");
    });
  }

  function getVendorTimeRestrictions() {
    ApiServer.Get(urls.get).then((data) => {
      jobVendorRestrictionOptions = JSON.parse(data.jobVendorRestrictionsJSON);
    });
  }

  onMount(async () => {
    getVendorTimeRestrictions();
  });

  function handleAccountNameChange(event) {
    account.accountName = account.firstName + " " + account.lastName;
  }
</script>

{#if account.id != "new"}
  <div class="row">
    <div class="col">
      <div id="button-menu" style="height: 60px">
        <div id="action-menu">
          <DisableAccount {account} />
        </div>
      </div>
    </div>
  </div>
{/if}

<form on:submit|preventDefault={handleSave}>
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col">
          <InputTextBox
            label="Account Name"
            required={true}
            bind:value={account.accountName}
          />
        </div>
      </div>
      <div class="row">
        <div class="group col-md-6">
          <InputTextBox
            label="First Name"
            required={true}
            on:change={handleAccountNameChange}
            bind:value={account.firstName}
          />
        </div>
        <div class="col-md-6">
          <InputTextBox
            label="Last Name"
            on:change={handleAccountNameChange}
            required={true}
            bind:value={account.lastName}
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <InputEmail
            label="Email Address"
            bind:value={account.email}
            required={true}
          />
        </div>

        <div class="col-md-6">
          <InputPhone
            label="Phone Number"
            bind:value={account.phoneNumber}
            required={true}
          />
        </div>
      </div>

      <AddressAutocomplete
        bind:address={account.address}
        bind:unit={account.addressUnit}
        bind:city={account.city}
        bind:state={account.state}
        bind:zip={account.zip}
      />

      <div class="row">
        <div class="col-md-6">
          <InputTextBox label="Gate Code" bind:value={account.gateCode} />
        </div>

        <div class="col-md-6">
          <InputSwitch
            label="Guard Gate"
            text="Account's community has a guard gate"
            bind:value={account.hasGuardGate}
          />
        </div>
      </div>
      {#if account.hasGuardGate}
        <div class="row" id="vendorTimeRestrictionControl">
          <div class="col">
            {#if jobVendorRestrictionOptions}
              <InputSelect
                label="Vendor Time Restriction"
                bind:value={account.vendorTimeRestriction}
                options={jobVendorRestrictionOptions}
                valueKey="Text"
                labelKey="Text"
                firstOptionLabel="None"
              />
            {/if}

            <small
              id="vendorTimeRestrictionNotification"
              class="form-text text-muted"
            >
              The time the community prevent Vendors from entering.
            </small>
          </div>
        </div>
      {/if}

      <div class="row">
        <div class="col">
          <label for="specialInstructions">Special Instructions</label>
          <textarea
            rows="2"
            id="specialInstructions"
            class="form-control"
            bind:value={account.specialInstructions}
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <InputTextBox
            label="Stripe Customer ID"
            bind:value={account.stripeCustomerID}
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button type="submit" class="btn btn-outline-primary"
        ><SaveIcon /> Save Changes</button
      >
      {#if account.id != "new"}
        <ButtonDelete text="Delete Account" callback={handleRemove} />
      {/if}
    </div>
  </div>
</form>
