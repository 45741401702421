<script>
  import { onMount } from "svelte";

  import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
  } from "sveltestrap";

  import { link } from "svelte-navigator";

  import { user } from "../../../Stores/stores";
  import ThreeDotsIcon from "../../../Lib/Icons/ThreeDotsIcon.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import CheckoutIcon from "../../../Lib/Icons/CheckoutIcon.svelte";
  import AddIcon from "../../../Lib/Icons/AddIcon.svelte";

  let urls = {
    reindex: "/api/Account/Account/indexaccountstats",
    getPublicPlans: "/api/Company/BillingPlan/billingplans",
  };

  let billingPlans;

  function reindexAccounts() {
    ApiServer.Get(urls.reindex).then((data) => {});
  }

  function setBillingPlans() {
    ApiServer.Get(urls.getPublicPlans).then((data) => {
      billingPlans = data;
    });
  }

  onMount(async () => {
    setBillingPlans();
  });
</script>

<Dropdown>
  <DropdownToggle>
    <AddIcon />
  </DropdownToggle>
  <DropdownMenu>
    {#if billingPlans}
      <DropdownItem header>New Account</DropdownItem>
      <DropdownItem>
        <a href="/accountedit/new" use:link><AddIcon /> Add New Account </a>
      </DropdownItem>
      <hr />
      {#each billingPlans as plan}
        <DropdownItem>
          <a href="/checkout?name={$user.companyName}&plan={plan.name}" use:link
            ><CheckoutIcon /> {plan.name}
          </a>
        </DropdownItem>
      {/each}
    {/if}

    <!-- <DropdownItem divider />

    <DropdownItem>
      <span on:click={reindexAccounts}> Reindex Accounts </span>
    </DropdownItem> -->
  </DropdownMenu>
</Dropdown>
