<script>
  import { user } from "./Stores/stores";
  import { Router, Route, Link, navigate } from "svelte-navigator";
  import Login from "./PublicPages/Login.svelte";
  import Nav from "./Layout/Nav.svelte";
  import Footer from "./Layout/Footer.svelte";
  import UserStore from "./Stores/UserStore";
  import Checkout from "./PublicPages/Checkout.svelte";
  import InvalidPage from "./PublicPages/InvalidPage.svelte";
  import SignUp from "./PublicPages/Signup.svelte";
  import SignupComplete from "./PublicPages/SignupComplete.svelte";
  import SignInHelp from "./PublicPages/SignInHelp.svelte";
  import Notification from "./Lib/Notification.svelte";
  import PasswordReset from "./PublicPages/PasswordReset.svelte";
  import Portal from "./PublicPages/Portal/Portal.svelte";
  import Checkout2 from "./PublicPages/Checkout/Checkout2.svelte";
  import Technition from "./Pages/Technition.svelte";
  import AcceptInvite from "./PublicPages/AcceptInvite.svelte";

  let currentUser = UserStore.getUser();

  user.set(currentUser);
  const userSubscribe = user.subscribe((value) => {
    currentUser = value;
  });
</script>

{#if !currentUser}
  <Router>
    <Route path="/" component={Login} />

    <Route path="checkout/*" component={Checkout} />

    <Route path="signupcomplete/:id/*" let:params>
      <SignupComplete requestID={params.id} />
    </Route>

    <Route path="acceptinvite/:id/*" let:params>
      <AcceptInvite requestID={params.id} />
    </Route>

    <Route path="passwordreset/:id/*" let:params>
      <PasswordReset requestID={params.id} />
    </Route>

    <Route path="signup/*" component={SignUp} />

    <Route path="signinhelp" component={SignInHelp} />

    <Route path="login" component={Login} />

    <Route path="checkout/*" component={Checkout} />

    <Route path="checkout2/*" component={Checkout2} />

    <Route path="portal/*" let:location>
      <Portal path={location} />
    </Route>

    <Route path="invalid" component={InvalidPage} />

    <!-- Routes without a path are default routes.They will match if no other Route could be matched -->
    <Route let:location>
      <Login path={location} />
    </Route>

    <Notification />
  </Router>
{:else if UserStore.userHasRole(["Admin"])}
  <div class="container container-fluid body-content">
    <Nav />
    <Footer />
  </div>
{:else}
  <Technition />
{/if}

<style>
  .body-content {
    padding-bottom: 50px;
  }
</style>
