<script>
  export let account;

  import BillingLog from "../../Billing/BillingLog.svelte";
  import BillingIcon from "../../../Lib/Icons/BillingIcon.svelte";
</script>

<div class="card">
  <div class="card-body">
    <h4 class="card-title"><BillingIcon /> Billing</h4>

    <div class="card-text">
      <BillingLog id={account.id} type="account" />
    </div>
  </div>
</div>
