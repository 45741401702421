<script>
  export let rules;

  import Checkbox from "../../../Lib/Inputs/InputCheckbox.svelte";
  import InputNumberBox from "../../../Lib/Inputs/InputNumberBox.svelte";
</script>

<div class="row">
  <div class="col">
    <h4>Trip Fees</h4>
    <p>
      If you have more than one pickup day a week and want to charge a trip fee
      for each trip enable the rule below.
    </p>
    <p>
      This trip fee is only applied if the Customer picks more than 1 pickup
      day.
    </p>
  </div>
  <div class="col">
    <Checkbox
      text="Enabled"
      label="Enable trip fees for jobs"
      bind:value={rules.tripFeeRule.enabled}
    />
  </div>
</div>
{#if rules.tripFeeRule.enabled}
  <div class="row">
    <div class="col-sm-6 form-group">
      <InputNumberBox
        label="Trip Fee Charge"
        bind:value={rules.tripFeeRule.cost}
      />
    </div>
  </div>
{/if}
