<script>
  import { Router, Route, Link, useParams } from "svelte-navigator";
  import { user } from "../Stores/stores";

  import Home from "../Pages/Home.svelte";
  import PrivateRoute from "../Lib/Authentication/PrivateRoute.svelte";
  import Account from "../Controls/Accounts/Account/Account.svelte";
  import Accounts from "../Pages/Accounts.svelte";
  import Notification from "../Lib/Notification.svelte";

  import Company from "../Pages/Company.svelte";
  import Search from "./Search.svelte";
  import Tutorial from "../Controls/Company/SetupTutorial/Tutorial.svelte";
  import Inbox from "../Pages/Inbox.svelte";
  import SignUpReview from "../Pages/SignUpReview.svelte";
  import Map from "../Pages/Map.svelte";
  import Schedule from "../Pages/Schedule.svelte";
  import Checkout from "../PublicPages/Checkout.svelte";
  import Menu from "./Menu.svelte";
  import UserProfile from "../Controls/Profile/UserProfile.svelte";
  import Billing from "../Pages/Billing.svelte";
  import AccountEdit from "../Controls/Accounts/Account/Edit/AccountEdit.svelte";
  import AcceptInvite from "../PublicPages/AcceptInvite.svelte";
  import TutorialStatus from "./TutorialStatus.svelte";
  import Messenger from "../Pages/Messenger.svelte";
  import SystemBilling from "../Pages/SystemBilling.svelte";

  let currentUser;
  const userSubscribe = user.subscribe((value) => {
    currentUser = value;
  });

  let userScreenIsOpen = false;
  const openUserScreen = () => (userScreenIsOpen = !userScreenIsOpen);
</script>

<Router primary={false}>
  <!-- <main class="container-fluid body-content"> -->
  <!--moved classes to App.svelte-->
  <main>
    <Menu {openUserScreen} />
    <Search />
    {#if $user.role == "Admin"}
      <TutorialStatus />
    {/if}
    <UserProfile {openUserScreen} {userScreenIsOpen} />

    <PrivateRoute path="/" roles={["Admin"]} let:location>
      <Home />
    </PrivateRoute>

    <PrivateRoute path="accounts" roles={["Admin"]} let:location>
      <Accounts />
    </PrivateRoute>

    <PrivateRoute path="account/:id/*" roles={["Admin"]} let:params>
      <Account accountId={params.id} />
    </PrivateRoute>

    <PrivateRoute path="accountedit/:id" roles={["Admin"]} let:params>
      <AccountEdit accountId={params.id} />
    </PrivateRoute>

    <PrivateRoute path="signupreview/:id/*" roles={["Admin"]} let:params>
      <SignUpReview accountRequestID={params.id} />
    </PrivateRoute>

    <PrivateRoute path="company/*" roles={["Admin"]} let:params>
      <Company />
    </PrivateRoute>

    <PrivateRoute path="tutorial/*" roles={["Admin"]} let:params>
      <Tutorial />
    </PrivateRoute>

    <PrivateRoute path="map" roles={["Admin", "Tech"]}>
      <Map />
    </PrivateRoute>

    <PrivateRoute path="schedule/*" roles={["Admin"]}>
      <Schedule />
    </PrivateRoute>

    <PrivateRoute path="messenger" roles={["Admin"]}>
      <Messenger />
    </PrivateRoute>

    <PrivateRoute path="inbox" roles={["Admin"]}>
      <Inbox />
    </PrivateRoute>

    <PrivateRoute path="billing" roles={["Admin"]} let:location>
      <Billing />
    </PrivateRoute>

    <PrivateRoute path="systembilling/*" roles={["Admin"]} let:location>
      <SystemBilling />
    </PrivateRoute>

    <!-- Routes without a path are default routes.They will match if no other Route could be matched -->
    <PrivateRoute path="" roles={["Admin"]}>
      <Home />
    </PrivateRoute>

    <!--Don't freak out this is both Public and Private-->
    <Route path="checkout/*" component={Checkout} />
    <Route path="acceptinvite/:id/*" let:params>
      <AcceptInvite requestID={params.id} />
    </Route>

    <Notification />
  </main>
</Router>
