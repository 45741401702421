<script>
  export let account;

  import { useNavigate } from "svelte-navigator";

  import DeleteAction from "../../../../Lib/DeleteAction.svelte";
  import DisablePersonIcon from "../../../../Lib/Icons/DisablePersonIcon.svelte";
  import PersonIcon from "../../../../Lib/Icons/PersonIcon.svelte";
  import { toastNotification } from "../../../../Stores/toastNotification";
  import ApiServer from "../../../../Server/ApiServer";

  const navigate = useNavigate();

  let urls = {
    disableAccount: `/api/Account/Account/disableaccount?accountID=${account.id}`,
    enableAccount: `/api/Account/Account/enableaccount?accountID=${account.id}`,
    predisableaccount: `/api/Account/Account/predisableaccount?accountID=${account.id}`,
  };

  async function disableAccount() {
    let accountChecks = await ApiServer.Get(urls.predisableaccount);

    if (accountChecks && accountChecks.length > 0) {
      let messages = "This account has the following warnings: \r\n";
      accountChecks.forEach((item) => {
        messages += item.message + "\r\n";
      });

      messages += "Are you sure you want to disable this account?";

      if (confirm(messages)) {
        doDisable();
      } else {
        return;
      }
    } else {
      doDisable();
    }
  }

  function doDisable() {
    ApiServer.Get(urls.disableAccount).then((data) => {
      toastNotification.Success("Account disabled");
      navigate(`/account/${account.id}`);
    });
  }

  function enableAccount() {
    ApiServer.Get(urls.enableAccount).then((data) => {
      toastNotification.Success("Account enabled");
      navigate(`/account/${account.id}/jobs`);
    });
  }
</script>

{#if account.status == "Inactive"}
  <DeleteAction
    callback={enableAccount}
    title="Enable this account?"
    warningText="After enabling this account you will be directed to the Jobs page where Templates and jobs need to be manually restored."
  >
    <button type="button" class="btn btn-sm btn-outline-primary">
      <div class="large-icon">
        <PersonIcon />
      </div>
      Enable Acount
    </button>
  </DeleteAction>
{:else}
  <DeleteAction
    callback={disableAccount}
    title="Disable this account?"
    warningText="Disabling this account will also disable job templates and cancel any Scheduled jobs. Are you sure?"
  >
    <button type="button" class="btn btn-sm btn-outline-primary">
      <div class="large-icon">
        <DisablePersonIcon />
      </div>
      Disable Acount
    </button>
  </DeleteAction>
{/if}

<style>
  .large-icon {
    font-size: 20px;
  }
  .btn {
    font-size: 11px;
  }
</style>
