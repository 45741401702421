<script>
  export let portalSummary;

  import Modal from "sv-bootstrap-modal";

  import ButtonSimple from "../../Lib/Buttons/ButtonSimple.svelte";
  import DateFormat from "../../Lib/DateFormat.svelte";
  import BillingIcon from "../../Lib/Icons/BillingIcon.svelte";
  import PortalCard from "./_PortalCard.svelte";
  import Money from "../../Lib/Money.svelte";
  import ButtonLink from "../../Lib/Buttons/ButtonLink.svelte";
  import ButtonCancel from "../../Lib/Buttons/ButtonCancel.svelte";
  import CheckIcon from "../../Lib/Icons/CheckIcon.svelte";
  import FailedPaymentIcon from "../../Lib/Icons/FailedPaymentIcon.svelte";
  import InputNumberBox from "../../Lib/Inputs/InputNumberBox.svelte";
  import ButtonAdd from "../../Lib/Buttons/ButtonAdd.svelte";
  import { toastNotification } from "../../Stores/toastNotification";
  import PortalApiServer from "../../Server/PortalApiServer";
  import { portaluser } from "../../Stores/PortalUser";

  let showMakePaymentModal = false;
  let makePaymentMessage;
  let amountToPay = 0;
  let amountCustom = 0;
  let paymentHistory = [];

  let showHistoryModal = false;

  let urls = {
    accountpayment: "/api/portal/portal/accountpayment",
    paymenthistory: `/api/portal/portal/paymenthistory?accountID=`,
  };

  function handleShowPaymentHistory() {
    paymentHistory = [];
    PortalApiServer.Get(urls.paymenthistory + $portaluser.accountID).then(
      (data) => {
        showHistoryModal = true;
        paymentHistory = data;
      }
    );
  }

  function handleOpenMakePayment() {
    showMakePaymentModal = true;
  }

  function addToPayment(amount) {
    amountToPay = roundAmount((amountToPay += amount));
  }

  function roundAmount(amount) {
    return +(Math.round(amount + "e+2") + "e-2");
  }

  function setPayment(amount) {
    amountToPay = amount;
    amountToPay = roundAmount(amountToPay);
  }

  function handleAccountPayment() {
    if (amountToPay <= 0) {
      toastNotification.Error("Your payment must be more than $0.00");
      return;
    }

    var paymentRequest = {
      accountID: $portaluser.accountID,
      userName: $portaluser.fullName,
      amount: amountToPay,
      description: "Payment from user portal",
    };

    PortalApiServer.Post(urls.accountpayment, paymentRequest).then((data) => {
      if (data.success) {
        toastNotification.Success("Payment received!");
        showMakePaymentModal = false;
      } else {
        toastNotification.Error("Payment failed!");
        makePaymentMessage = data.message;
      }
    });
  }
</script>

<PortalCard title="Billing" icon={BillingIcon}>
  <h4>
    Account Balance: <Money amount={portalSummary.accountBalance} />
  </h4>

  <p>
    <ButtonLink
      text="Show Payment History"
      callback={handleShowPaymentHistory}
    />
  </p>
  <div class="action-button">
    <ButtonSimple text="Make a payment" callback={handleOpenMakePayment} />
  </div>
</PortalCard>

<!-- PAYMENT HISTORY DETAIL MODAL-->
<Modal bind:open={showHistoryModal}>
  <div class="modal-header">
    <h5 class="modal-title">Payment History</h5>
    <button
      type="button"
      class="close"
      on:click={() => (showHistoryModal = false)}
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {#if paymentHistory && paymentHistory.length > 0}
      {#each paymentHistory as pmt}
        <h4>Payment <DateFormat date={pmt.dateAdded} /> detail</h4>
        <ul>
          <li>
            Success:
            {#if pmt.success}
              <CheckIcon />
            {:else}
              <FailedPaymentIcon />
            {/if}
          </li>
          <li>
            Date Paid: <DateFormat
              date={pmt.dateAdded}
              formatType="date-time-short"
            />
          </li>
          <li>Amount: <Money amount={pmt.amount} /></li>
          {#if pmt.cardBrand}
            <li>
              {pmt.cardBrand}
              {pmt.cardLast4}
              {pmt.cardExpiry}
            </li>
          {/if}
          {#if pmt.jobNumber}
            <li>Job Number: {pmt.jobNumber}</li>
          {/if}
          <li>{pmt.description}</li>
        </ul>

        {#if pmt.refunded}
          <p>Payment was refunded</p>
          Amount Refunded: <Money amount={pmt.refundAmount} />
        {/if}
        <hr />
      {/each}
    {:else}
      <h4>We do not show any payments on your account yet.</h4>
    {/if}
  </div>
  <div class="modal-footer">
    <ButtonCancel text="Close" callback={() => (showHistoryModal = false)} />
  </div>
</Modal>

<!-- MAKE A PAYMENT MODAL-->
<Modal bind:open={showMakePaymentModal} ignoreBackdrop={true}>
  <div class="modal-header">
    <h5 class="modal-title">Make a Payment</h5>
    <button
      type="button"
      class="close"
      on:click={() => (showMakePaymentModal = false)}
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {#if portalSummary.paymentMethods}
      <h4>
        Amount Due: <Money amount={portalSummary.accountBalance} />
      </h4>
      {#if portalSummary.accountBalance > 0}
        <ButtonAdd
          text="Pay"
          callback={() => setPayment(portalSummary.accountBalance)}
        >
          <Money amount={portalSummary.accountBalance} />
        </ButtonAdd>
      {/if}
      <hr />
      Refill my account balance<br />
      <ButtonAdd text="Add" size="small" callback={() => addToPayment(10)}>
        <Money amount={10} />
      </ButtonAdd>
      <ButtonAdd text="Add" size="small" callback={() => addToPayment(25)}>
        <Money amount={25} />
      </ButtonAdd>
      <ButtonAdd text="Add" size="small" callback={() => addToPayment(50)}>
        <Money amount={50} />
      </ButtonAdd>

      <hr />
      <InputNumberBox label="Add More!" bind:value={amountCustom} />
      <ButtonAdd
        text="Add"
        size="small"
        callback={() => addToPayment(amountCustom)}
      >
        <Money amount={amountCustom} />
      </ButtonAdd>
      <hr />
      <h4>Total: <Money amount={amountToPay} /></h4>

      {#if makePaymentMessage}
        <h5>{makePaymentMessage}</h5>
      {/if}
    {:else}
      <p>
        No payment methods were found on your account. Please add a new payment
        method.
      </p>
    {/if}
  </div>
  <div class="modal-footer">
    <ButtonCancel
      text="Close"
      callback={() => (showMakePaymentModal = false)}
    />
    <ButtonAdd text="Pay" callback={handleAccountPayment}>
      <Money amount={amountToPay} />
    </ButtonAdd>
  </div>
</Modal>

<style>
  .action-button {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 5px;
  }
</style>
