<script>
  export let currentJob;
</script>

<div class="row">
  <div class="col">
    <div class="bins-to-clean">Bins: <strong>{currentJob.bins}</strong></div>
  </div>
  <div class="col">
    {#if currentJob.firstTime}
      <div class="firsttime-clean">First Time Clean</div>
    {/if}
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="plan">Plan: {currentJob.billingPlanName}</div>
  </div>
  <div class="col">
    {#if currentJob.vendorTimeRestriction && currentJob.vendorTimeRestriction != "None"}
      <div class="vendor-time-restriction">
        VTR: {currentJob.vendorTimeRestriction}
      </div>
    {/if}
  </div>
</div>

<div class="row">
  <div class="col">
    {currentJob.status}/{currentJob.paymentStatus}
  </div>
</div>

{#if currentJob.jobNote}
  <div class="row">
    <div class="col">
      <label for="notes">Job Notes</label>
      <div class="job-note">
        {currentJob.jobNote}
      </div>
    </div>
  </div>
{/if}

<style>
  .job-note {
    font-weight: bold;
    font-size: large;
    border: 1px solid black;
    border-radius: 5px;
    margin: 3px;
    padding: 4px;
  }
  .firsttime-clean {
    background-color: yellow;
    padding: 3px;
  }
  .vendor-time-restriction {
    background-color: orange;
    padding: 3px;
  }
</style>
