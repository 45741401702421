<script>
  export let showNewMessageWindow;
  export let account = null;

  import Modal from "sv-bootstrap-modal";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import ButtonCancel from "../../Lib/Buttons/ButtonCancel.svelte";
  import ApiServer from "../../Server/ApiServer";
  import AccountCard from "../Accounts/AccountCard.svelte";
  import SearchControl from "./SearchControl.svelte";
  import SimpleMessage from "./SimpleMessage.svelte";
  import FormatChecker from "../../Util/FormatChecker";

  let accounts = [];
  let currentPage = 1;
  let itemsPerPage = 10;
  let searchText = "";
  let searchAttempted = false;
  let messageBody;

  let urls = {
    accountSearch: "/api/Account/search",
    accounts: "/api/Account/Account/accounts",
  };

  function fetchAccounts() {
    var pageRequest = {
      PageNumber: currentPage,
      ItemsPerPage: itemsPerPage,
      OrderBy: { column: "DateAdded" },
      SearchText: searchText,
      AccountFilter: "Active",
    };

    ApiServer.Post(urls.accounts, pageRequest).then((data) => {
      accounts = accounts.concat(data);
      searchAttempted = true;
    });
  }

  function closeModal() {
    showNewMessageWindow = false;
    clearSearch();
  }

  function clearSearch() {
    currentPage = 1;
    accounts = [];
    searchText = "";
    messageBody = "";
    searchAttempted = false;
    account = null;
  }

  function loadMoreAccounts() {
    currentPage++;
    fetchAccounts();
  }

  function onSearchKeyDown(event) {
    let element = event.detail;
    if (element.charCode === 13) search();
  }

  function search() {
    currentPage = 1;
    accounts = [];
    fetchAccounts();
  }

  function handleSelectAccount(event) {
    account = event.detail;
    accounts = null;
    console.log(account);
  }

  function onMessageSent() {
    if (!messageBody) {
      alert("Please add a message to send.");
      return;
    }

    let newMessageRequest = {
      messageBody: messageBody,
    };

    if (account) {
      newMessageRequest.accountID = account.id;
      newMessageRequest.toNumber = account.phoneNumber;
    } else {
      newMessageRequest.accountID = null;
      newMessageRequest.toNumber = searchText;
    }

    if (!FormatChecker.PhoneNumberIsValid(newMessageRequest.toNumber)) {
      alert(
        "The phone number entered is invalid. Please make sure to use 10 digits."
      );
      return;
    }

    clearSearch();

    dispatch("onMessageSent", newMessageRequest);
  }
</script>

<Modal bind:open={showNewMessageWindow} ignoreBackdrop={true}>
  <div class="modal-header">
    <h4 class="modal-title">Start a new Message</h4>
    <button
      type="button"
      class="close"
      on:click={() => (showNewMessageWindow = false)}
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {#if !account}
      <SearchControl
        title="Search for an account or type in a phone number to message."
        bind:searchText
        on:searchKeyDown={onSearchKeyDown}
        on:clearSearch={clearSearch}
        on:performSearch={search}
      />
      {#if accounts && accounts.length > 0}
        <div class="row">
          <div class="col">
            {#each accounts as account}
              <AccountCard
                on:onAccountSelect={handleSelectAccount}
                selectStyle={true}
                {account}
              />
            {/each}

            {#if accounts.length >= 10 * currentPage}
              <button
                class="btn btn-primary"
                on:click={() => loadMoreAccounts()}>Load More...</button
              >
            {/if}
          </div>
        </div>
      {:else if searchAttempted}
        <SimpleMessage bind:messageBody phoneNumber={searchText} />
      {/if}
    {:else}
      <SimpleMessage
        bind:messageBody
        phoneNumber={account.phoneNumber}
        name={account.accountName}
      />
    {/if}
    <br />
    <br />
    <div class="modal-footer">
      <ButtonCancel text="Cancel" callback={closeModal} />
      <button
        type="button"
        class="btn btn-outline-primary"
        on:click={onMessageSent}>Send</button
      >
    </div>
  </div>
</Modal>

<style>
  .modal-body {
    overflow: auto;
  }
</style>
