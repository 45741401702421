<script>
  export let portalSummary;
  import Modal from "sv-bootstrap-modal";

  import { toastNotification } from "../../Stores/toastNotification";
  import ButtonSimple from "../../Lib/Buttons/ButtonSimple.svelte";
  import JobIcon from "../../Lib/Icons/JobIcon.svelte";
  import PortalCard from "./_PortalCard.svelte";
  import PortalApiServer from "../../Server/PortalApiServer";
  import DateFormat from "../../Lib/DateFormat.svelte";
  import ButtonCancel from "../../Lib/Buttons/ButtonCancel.svelte";
  import UTCDate from "../../Util/UTCDate";
  import Money from "../../Lib/Money.svelte";
  import { portaluser } from "../../Stores/PortalUser";

  let showModal = false;
  let skipCleaningAction = true;

  let urls = {
    skipCleaningRequest: "/api/portal/portal/skipcleaningrequest?jobID=",
    cleaningDateRequest: "/api/portal/portal/cleaningdaterequest",
    newjobrequest: "/api/portal/portal/newjobrequest",
    getnextjob: "/api/portal/portal/getnextjob?accountID=",
  };

  function handleCancelCleaning() {
    PortalApiServer.Get(
      urls.skipCleaningRequest + portalSummary.nextJob.id
    ).then((data) => {
      if (data.success) {
        portalSummary.nextJob = null;
        toastNotification.Success("Cleaning is skipped!");
        PortalApiServer.Get(urls.getnextjob + $portaluser.accountID).then(
          (data) => {
            portalSummary.nextJob = data;
          }
        );
      } else {
        toastNotification.Error(
          "Error skipping this cleaning. Please contact support."
        );
      }
      showModal = false;
    });
  }

  function canSkipCleaning() {
    //Customers can only skip cleanings 6 hours before their job.
    var hoursDiff = UTCDate.HoursDifference(
      portalSummary.nextJob.jobDate,
      UTCDate.UtcNowAsDayJS()
    );
    return hoursDiff > 6;
  }

  function skipCleaning() {
    skipCleaningAction = true;
    showModal = true;
  }

  function handleModalClose() {
    //trashDay = null;
    //futureDateResponse = null;
    showModal = false;
  }
</script>

<PortalCard title="Next Job" icon={JobIcon}>
  {#if portalSummary.nextJob}
    <h4>
      <DateFormat
        date={portalSummary.nextJob.jobDate}
        convertTimeZone={false}
      />
      <!-- (in <DateFormat
        formatType="tonow"
        date={portalSummary.nextJob.jobDate}
        convertTimeZone={false}
      />) -->
    </h4>
    <div>{portalSummary.nextJob.address}</div>
    <div>Bins: {portalSummary.nextJob.bins}</div>
    <div><Money amount={portalSummary.nextJob.total} /></div>
  {:else}
    <h4>No upcoming cleanings</h4>
  {/if}

  {#if portalSummary.nextJob}
    <div class="action-button">
      <ButtonSimple text="Skip this cleaning" callback={skipCleaning} />
    </div>
  {/if}
</PortalCard>

<Modal bind:open={showModal} ignoreBackdrop={true}>
  <div class="modal-header">
    <h5 class="modal-title">Job Details</h5>
    <button type="button" class="close" on:click={() => (showModal = false)}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {#if skipCleaningAction}
      <h3>
        Skipping cleaning for <DateFormat
          date={portalSummary.nextJob.jobDate}
          convertTimeZone={false}
        />
      </h3>
      {#if canSkipCleaning()}
        <p>
          You can skip this cleaning with no fees.<br />
          <ButtonSimple
            text="Skip this cleaning"
            callback={handleCancelCleaning}
          />
        </p>
      {:else}
        <p>
          It is too late in the day to skip this cleaning. Please call our
          support number if your bins will not be out.
        </p>
      {/if}
    {/if}
  </div>
  <div class="modal-footer">
    <ButtonCancel text="Cancel" callback={handleModalClose} />
  </div>
</Modal>

<style>
  .action-button {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 5px;
  }
</style>
