<script>
  export let day;
  export let date;
  export let schedule;
  export let editMode = false;

  import TimeSelect from "./_TimeSelect.svelte";
</script>

<!-- <div class="col">
  <div class="day-label">{label}</div>
  <ul>
    {#each schedule as s}
      <li>{s.name}: {s.time}</li>
    {/each}
  </ul>
</div> -->

<div class="col">
  <div class="card">
    <div class="card-body">
      <div class="card-title day-label">{day}</div>
      <h6 class="card-subtitle mb-2 text-muted">{date}</h6>
      <ul>
        {#each schedule as s}
          <li>
            {s.name}:
            {#if editMode}
              <TimeSelect time={s.time} />
            {:else}
              {s.time}
            {/if}
          </li>
        {/each}
      </ul>
    </div>
  </div>
</div>

<style>
  ul {
    list-style-type: none;
    padding-left: 2px;
  }
  .day-label {
    font-size: large;
    font-weight: bold;
  }
</style>
