<script>
  export let portalSummary;

  import Modal from "sv-bootstrap-modal";
  import ButtonCancel from "../../Lib/Buttons/ButtonCancel.svelte";
  import ButtonLink from "../../Lib/Buttons/ButtonLink.svelte";

  import ArrowLeftIcon from "../../Lib/Icons/ArrowLeftIcon.svelte";
  import ArrowRightIcon from "../../Lib/Icons/ArrowRightIcon.svelte";
  import DateFormat from "../../Lib/DateFormat.svelte";
  import JobIcon from "../../Lib/Icons/JobIcon.svelte";
  import PortalCard from "./_PortalCard.svelte";
  import PortalApiServer from "../../Server/PortalApiServer";
  import ButtonIcon from "../../Lib/Buttons/ButtonIcon.svelte";
  import { portaluser } from "../../Stores/PortalUser";

  let showModal = false;
  let selectedJob;
  let currentPage = 1;

  let urls = {
    jobImages: "/api/portal/portal/jobimages?jobID=",
    serviceHistory: "/api/portal/portal/servicehistory",
  };

  function handleJobSelect(job) {
    showModal = true;
    selectedJob = job;
    setJobImages();
  }

  function getEarlierJobs() {
    currentPage += 1;
    fetchJobs();
  }

  function getLaterJobs() {
    if (currentPage <= 1) {
      currentPage = 1;
    } else {
      currentPage -= 1;
    }

    fetchJobs();
  }

  function fetchJobs() {
    var serviceHistoryRequest = {
      accountID: $portaluser.accountID,
      pageRequest: {
        pageNumber: Number(currentPage),
        itemsPerPage: 3,
      },
    };

    portalSummary.serviceHistory = [];
    PortalApiServer.Post(urls.serviceHistory, serviceHistoryRequest).then(
      (data) => {
        portalSummary.serviceHistory = data;

        //Fix the pager from incrementing.
        if (data && data.length == 0) {
          currentPage -= currentPage;
        }
      }
    );
  }

  function setJobImages() {
    PortalApiServer.Get(urls.jobImages + selectedJob.id).then((data) => {
      selectedJob.jobImages = data.data;
    });
  }

  let imgSize = { h: 200, w: 200 };
</script>

<PortalCard title="Service History" icon={JobIcon}>
  <div>
    {#if portalSummary.serviceHistory && portalSummary.serviceHistory.length > 0}
      {#each portalSummary.serviceHistory as job}
        <ButtonLink callback={() => handleJobSelect(job)}>
          <h5>
            <DateFormat date={job.jobDate} convertTimeZone={false} />
            {job.status}
          </h5>
        </ButtonLink>
        <br />
      {/each}
      <ButtonIcon text="" icon={ArrowLeftIcon} callback={getLaterJobs} />
      <ButtonIcon
        text=""
        iconLeft={false}
        icon={ArrowRightIcon}
        callback={getEarlierJobs}
      />
    {:else}
      <p>No history yet! Come back after your first cleaning.</p>
    {/if}
  </div>
</PortalCard>

<Modal bind:open={showModal}>
  <div class="modal-header">
    <h5 class="modal-title">Job Details</h5>
    <button type="button" class="close" on:click={() => (showModal = false)}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {#if selectedJob}
      <h3>Job {selectedJob.jobNumber} detail</h3>

      Closed: <DateFormat
        date={selectedJob.closeDate}
        formatType="date-time-short"
      /> <br />
      Service Address: {selectedJob.streetAddress} <br />
      Status: {selectedJob.status}<br />

      {#if selectedJob.jobImages}
        {#each selectedJob.jobImages as img}
          <img width="50%" height="50%" src={img.sourceURL} alt="" />
        {/each}
      {/if}
    {/if}
  </div>
  <div class="modal-footer">
    <ButtonCancel text="Close" callback={() => (showModal = false)} />
  </div>
</Modal>
