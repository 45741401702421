<script>
  export let account;
  import { link, useNavigate } from "svelte-navigator";
  import { onMount } from "svelte";
  const navigate = useNavigate();

  import AddressGoogleLink from "../../../lib/AddressGoogleLink.svelte";
  import Plural from "../../../Lib/Plural.svelte";
  import JobIcon from "../../../Lib/Icons/JobIcon.svelte";
  import NotesIcon from "../../../Lib/Icons/NotesIcon.svelte";
  import RightArrowIcon from "../../../Lib/Icons/ArrowRightIcon.svelte";
  import TextMessageIcon from "../../../Lib/Icons/TextMessageIcon.svelte";
  import VendorTimeRestriction from "./VendorTimeRestriction.svelte";
  import EditIcon from "../../../Lib/Icons/EditIcon.svelte";
  import PayIcon from "../../../Lib/Icons/BillingIcon.svelte";
  import AccountIcon from "../../../Lib/Icons/AccountIcon.svelte";
  import AccountStatus from "./AccountStatus.svelte";
  import Money from "../../../Lib/Money.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import PhoneNumber from "../../../Lib/PhoneNumber.svelte";
  import ContactCustomer from "./_ContactCustomer.svelte";

  let contactActionsOpen = false;

  function editAccount() {
    navigate(`/accountedit/${account.id}`);
  }

  const toggleContactActions = () => (contactActionsOpen = !contactActionsOpen);

  let urls = {
    balance: `/api/Billing/balance?accountID=`,
  };

  let accountBalance;

  onMount(async () => {
    ApiServer.Get(urls.balance + account.id).then((data) => {
      accountBalance = data;
    });
  });
</script>

<div class="card">
  <div class="card-body">
    <h5 class="card-title">
      <div class="same-line">
        <div>
          {account.accountName}
        </div>

        <span class="edit-account" on:click={editAccount}>
          <EditIcon />
        </span>
      </div>
    </h5>

    <div class="card-text">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col">
              <AccountStatus {account} />
              <p>
                Balance:
                {#if accountBalance}
                  <Money amount={accountBalance} />
                {:else}
                  <Money amount={0} />
                {/if}
              </p>
              <p>
                <AddressGoogleLink
                  address={account.address}
                  city={account.city}
                  state={account.state}
                  zip={account.zip}
                />
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="clickable" on:click={toggleContactActions}>
                <PhoneNumber number={account.phoneNumber} />
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="clickable" on:click={toggleContactActions}>
                {account.email}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <VendorTimeRestriction vtr={account.vendorTimeRestriction} />

          <div class="card mb-3">
            <div class="card-header">
              Bin Pickup
              <Plural text="Day" number={account.templatePickupDays.length} />
            </div>
            <div class="card-body">
              <div class="card-text">
                Plan: {account.planName}
                {#each account.templatePickupDays as pickupDay}
                  <p>
                    {pickupDay.dayName}: {pickupDay.bins}
                    <Plural text="bin" number={pickupDay.bins} />
                  </p>
                {/each}
              </div>
            </div>
          </div>

          <div class="card mb-3">
            <div class="card-header">
              Special Instructions <small>from customer signup</small>
            </div>
            <div class="card-body">
              <div class="card-text">
                {#if account.specialInstructions}
                  <p>{account.specialInstructions}</p>
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ul class="list-group">
        <li
          class="list-group-item d-flex justify-content-between align-items-start"
        >
          <div class="ms-2 me-auto">
            <a class="menu-link" href="{account.id}/jobs" use:link>
              <JobIcon /> Jobs</a
            >
          </div>
          <span class=""><RightArrowIcon /></span>
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-start"
        >
          <div class="ms-2 me-auto">
            <a class="menu-link" href="{account.id}/messages" use:link
              ><TextMessageIcon />Messages</a
            >
          </div>
          <span class=""><RightArrowIcon /></span>
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-start"
        >
          <div class="ms-2 me-auto">
            <a class="menu-link" href="{account.id}/notes" use:link
              ><NotesIcon />Notes</a
            >
          </div>
          <span class=""><RightArrowIcon /></span>
        </li>

        <li
          class="list-group-item d-flex justify-content-between align-items-start"
        >
          <div class="ms-2 me-auto">
            <a class="menu-link" href="{account.id}/portal" use:link>
              <AccountIcon /> Portal</a
            >
          </div>
          <span class=""><RightArrowIcon /></span>
        </li>
        <li
          class="list-group-item d-flex justify-content-between align-items-start"
        >
          <div class="ms-2 me-auto">
            <a class="menu-link" href="{account.id}/billing" use:link
              ><PayIcon /> Billing</a
            >
          </div>
          <span class=""><RightArrowIcon /></span>
        </li>
      </ul>
    </div>
  </div>
</div>

<ContactCustomer {account} bind:contactActionsOpen />

<style>
  ul {
    padding: 0;
    list-style-type: none;
  }

  .menu-link {
    text-decoration: none;
    color: #000000;
    text-decoration: none;
  }

  .edit-account:hover {
    cursor: pointer;
  }

  .clickable {
    cursor: pointer;
  }
</style>
