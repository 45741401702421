<script>
  export let portalSummary;

  import { onMount } from "svelte";
  import Modal from "sv-bootstrap-modal";
  import { Alert } from "sveltestrap";

  import { toastNotification } from "../../Stores/toastNotification";

  import ButtonSimple from "../../Lib/Buttons/ButtonSimple.svelte";
  import AccountIcon from "../../Lib/Icons/AccountIcon.svelte";
  import PortalCard from "./_PortalCard.svelte";
  import PortalApiServer from "../../Server/PortalApiServer";
  import PhoneNumber from "../../Lib/PhoneNumber.svelte";
  import ButtonCancel from "../../Lib/Buttons/ButtonCancel.svelte";
  import InputTextBox from "../../Lib/Inputs/InputTextBox.svelte";
  import SaveIcon from "../../Lib/Icons/SaveIcon.svelte";
  import InputSelect from "../../Lib/Inputs/InputSelect.svelte";
  import InputSwitch from "../../Lib/Inputs/InputSwitch.svelte";
  import InputPhone from "../../Lib/Inputs/InputPhone.svelte";
  import AddressAutocomplete from "../../Lib/AddressAutocomplete/Autocomplete.svelte";
  import InputEmail from "../../Lib/Inputs/InputEmail.svelte";
  import InputPassword from "../../Lib/Inputs/InputPassword.svelte";
  import ButtonConfirmAction from "../../Lib/Buttons/ButtonConfirmAction.svelte";
  import { portaluser } from "../../Stores/PortalUser";
  import InputPasswordStrengthIndicator from "../../Lib/Inputs/InputPasswordStrengthIndicator.svelte";
  import InputPasswordMatchIndicator from "../../Lib/Inputs/InputPasswordMatchIndicator.svelte";

  let showModal = false;
  let showUpdatePasswordModal = false;
  let account = portalSummary.account;
  let jobVendorRestrictionOptions;
  let passwordUpdate = {};
  let passwordError = "";

  let passwordsMatch = false;
  let passwordsComplexity;

  let urls = {
    getJobOptions: `/api/portal/portal/getjoboptions`,
    updateAccount: `/api/portal/portal/updateaccount`,
    resetpassword: `/api/portal/portal/resetpassword`,
  };

  function handleModalClose() {
    showModal = false;
  }

  function handleResetPasswordModalClose() {
    resetPasswordReset();
    showUpdatePasswordModal = false;
  }

  function handleMatchChange(event) {
    passwordsMatch = event.detail;
  }

  function handlePasswordStrengthChange(event) {
    passwordsComplexity = event.detail;
  }

  function validatePassword() {
    return passwordsComplexity.strength >= 2 && passwordsMatch.match;
  }

  function updatePassword() {
    let validPass = validatePassword();
    if (!validPass) {
      toastNotification.Error(
        "Please fix the problem with your password before continuing!"
      );
      return;
    }

    passwordError = "";

    passwordUpdate.accountID = $portaluser.accountID;

    PortalApiServer.Post(urls.resetpassword, passwordUpdate).then((data) => {
      if (data.success) {
        toastNotification.Success("Password Updated!");
        handleResetPasswordModalClose();
      } else {
        toastNotification.Error("Error updating password!");
        passwordError = data.message;
      }
    });
  }

  function resetPasswordReset() {
    passwordError = "";
    passwordUpdate = {};
  }

  function getVendorTimeRestrictions() {
    PortalApiServer.Get(urls.getJobOptions).then((data) => {
      jobVendorRestrictionOptions = JSON.parse(data.jobVendorRestrictionsJSON);
    });
  }

  function handleSave() {
    PortalApiServer.Post(urls.updateAccount, account).then((data) => {
      if (data.success) {
        toastNotification.Success("Account updated!");
        portalSummary.account = data.data;
        handleModalClose();
      } else {
        toastNotification.Error("Account update failed." + data.message);
      }
    });
  }

  onMount(async () => {
    getVendorTimeRestrictions();
  });
</script>

{#if portalSummary.account}
  <PortalCard title="Account Information" icon={AccountIcon}>
    {portalSummary.account.firstName}
    {portalSummary.account.lastName}<br />
    <PhoneNumber number={portalSummary.account.phoneNumber} /><br />
    {portalSummary.account.email} <br />
    {portalSummary.account.streetAddress} <br />
    {portalSummary.account.cityStateZip}
    <br />
    <div class="action-button">
      <ButtonSimple text="Update" callback={() => (showModal = true)} />
      <ButtonSimple
        text="Change Password"
        callback={() => (showUpdatePasswordModal = true)}
      />
    </div>
  </PortalCard>
{/if}

<!-- UPDATE ACCOUNT MODAL -->
<Modal bind:open={showModal} ignoreBackdrop={true} keyboard="{false}+">
  <div class="modal-header">
    <h5 class="modal-title">Account Details</h5>
    <button type="button" class="close" on:click={() => (showModal = false)}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form on:submit|preventDefault={handleSave}>
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="group col-md-6">
              <InputTextBox
                label="First Name"
                required={true}
                bind:value={account.firstName}
              />
            </div>
            <div class="col-md-6">
              <InputTextBox
                label="Last Name"
                required={true}
                bind:value={account.lastName}
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <InputEmail
                label="Email Address"
                bind:value={account.email}
                required={true}
              />
            </div>

            <div class="col-md-6">
              <InputPhone
                label="Phone Number"
                bind:value={account.phoneNumber}
                required={true}
              />
            </div>
          </div>

          <AddressAutocomplete
            bind:address={account.address}
            bind:unit={account.addressUnit}
            bind:city={account.city}
            bind:state={account.state}
            bind:zip={account.zip}
          />

          <div class="row">
            <div class="col-md-6">
              <InputTextBox label="Gate Code" bind:value={account.gateCode} />
            </div>

            <div class="col-md-6">
              <InputSwitch
                label="Guard Gate"
                text="My community has a guard gate"
                bind:value={account.hasGuardGate}
              />
            </div>
          </div>
          {#if account.hasGuardGate}
            <div class="row" id="vendorTimeRestrictionControl">
              <div class="col">
                {#if jobVendorRestrictionOptions}
                  <InputSelect
                    label="Vendor Time Restriction"
                    bind:value={account.vendorTimeRestriction}
                    options={jobVendorRestrictionOptions}
                    valueKey="Text"
                    labelKey="Text"
                    firstOptionLabel="None"
                  />
                {/if}

                <small
                  id="vendorTimeRestrictionNotification"
                  class="form-text text-muted"
                >
                  The time the community prevent Vendors from entering.
                </small>
              </div>
            </div>
          {/if}

          <div class="row">
            <div class="col">
              <label for="specialInstructions">Special Instructions</label>
              <textarea
                rows="2"
                id="specialInstructions"
                class="form-control"
                bind:value={account.specialInstructions}
              />
              <small id="specialInstructions" class="form-text text-muted">
                Help us find your house.
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button type="submit" class="btn btn-outline-primary"
            ><SaveIcon /> Save Changes</button
          >
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <ButtonCancel text="Close" callback={handleModalClose} />
  </div>
</Modal>

<!-- UPDATE PASSWORD -->
<Modal bind:open={showUpdatePasswordModal} ignoreBackdrop={true}>
  <div class="modal-header">
    <h5 class="modal-title">Change Password</h5>
    <button
      type="button"
      class="close"
      on:click={() => (showUpdatePasswordModal = false)}
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {#if passwordError}
      <Alert color="primary">
        <h4 class="alert-heading text-capitalize">{passwordError}</h4>
      </Alert>
    {/if}

    <InputPassword
      label="Current Password"
      bind:value={passwordUpdate.currentPassword}
    />
    <InputPasswordStrengthIndicator
      label="Password"
      bind:value={passwordUpdate.newPassword}
      required={true}
      password={passwordUpdate.newPassword}
      on:change={handlePasswordStrengthChange}
    />

    <InputPasswordMatchIndicator
      label="Confirm Password"
      bind:value={passwordUpdate.confirmPassword}
      required={true}
      password={passwordUpdate.newPassword}
      on:change={handleMatchChange}
    />
  </div>
  <div class="modal-footer">
    <ButtonCancel text="Close" callback={handleResetPasswordModalClose} />
    <ButtonConfirmAction
      text="Update Password"
      callback={updatePassword}
      warningText={"This will change your password. Are you sure?"}
    />
  </div>
</Modal>

<style>
  .action-button {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 5px;
  }
</style>
