<script>
  export let checkoutRules;
  import Editor from "@tinymce/tinymce-svelte";
  import { tinyApiKey } from "../../../Constant/Environment";

  let tinyInit = {
    height: 500,
  };
</script>

<div class="row">
  <div class="col">
    <h4>Terms and Conditions</h4>
    <p>
      The Terms and Conditions text is displayed on the Checkout screen and each
      customer must agree to these conditions before checking out.
    </p>
  </div>
</div>
<div class="row">
  <div class="col">
    <div>
      <Editor
        conf={tinyInit}
        apiKey={tinyApiKey}
        bind:value={checkoutRules.termsAndConditions}
      />
    </div>
  </div>
</div>
