<script>
  export let jobPayments;
  export let refundOption;
  export let refundedPayments;

  import DateFormat from "../../../../Lib/DateFormat.svelte";
  import Money from "../../../../Lib/Money.svelte";

  let refundMessage = "";

  function handleJobPaymentChanges() {
    if (refundOption == "norefund") {
      jobPayments.forEach((payment) => {
        payment.refundAmount = 0;
        payment.refunded = false;
      });
    } else {
      refundedPayments = [];
      jobPayments.forEach((payment) => {
        if (payment.refundAmount > payment.amount * -1) {
          payment.refunded = false;
          //payment.refundAmount = 0;
          refundMessage = `Refund amount ${
            payment.refundAmount
          } must be less than ${payment.amount * -1}.`;
        } else if (payment.refundAmount > 0) {
          payment.refunded = true;
          refundedPayments.push(payment);
          refundMessage = "";
        }
      });
    }
  }
  $: jobPayments, handleJobPaymentChanges();
</script>

{#if jobPayments && jobPayments.length > 0}
  <p>
    This job has at least 1 payment. Do you want to refund the payments to the
    customer? If so set the refund amount on each payment below.
  </p>

  <div class="row table-row-header">
    <div class="col">Date</div>
    <div class="col">Amount</div>
    {#if refundOption == "refund"}
      <div class="col">Refund</div>
    {/if}
    <div class="col d-none d-md-block">Details</div>
  </div>
  {#each jobPayments as payment}
    <div class="row table-row">
      <div class="col">
        <DateFormat formatType="date-short" date={payment.dateAdded} />
      </div>
      <div class="col">
        <Money amount={payment.amount} />
      </div>
      {#if refundOption == "refund"}
        <div class="col">
          <input
            type="number"
            class="form-control"
            bind:value={payment.refundAmount}
          />
        </div>
      {/if}
      <div class="col d-none d-md-block">
        {#if payment.cardBrand}
          {payment.cardBrand}
          {payment.cardLast4}
          {payment.cardExpiry}
        {/if}
      </div>
    </div>
  {/each}

  <p>
    <select
      bind:value={refundOption}
      on:change={handleJobPaymentChanges}
      class="form-control"
    >
      <option value="refund">Refund Customer</option>
      <option value="norefund">No Refund</option>
    </select>
  </p>
  {#if refundMessage}
    <p class="refund-message">
      {refundMessage}
    </p>
  {/if}
{/if}

<style>
  .refund-message {
    background-color: yellow;
    padding: 5px;
  }
  .table-row {
    padding: 3px;
    margin: 2px;
    border-bottom: 1px solid lightgray;
  }

  .table-row-header {
    font-size: 16px;
    font-weight: 700;
    padding: 3px;
    margin: 2px;
    border-bottom: 1px solid gray;
  }
</style>
