<script>
  export let name;
  export let url;
  export let icon;
  export let badgeLabel = "";

  import { useNavigate, link } from "svelte-navigator";
  import { Badge } from "sveltestrap";
  const navigate = useNavigate();
</script>

<a href={url} use:link class="bb-nav-item">
  <svelte:component this={icon} />
  <br />
  {name}
  {#if badgeLabel}
    <Badge color="danger">{badgeLabel}</Badge>
  {/if}
</a>

<style>
  .bb-nav-item {
    margin-right: 18px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }

  a {
    all: unset;
  }
</style>
