<script>
  export let account;
  export let portalInvite;

  import ButtonAction from "../../../../Lib/Buttons/ButtonAction.svelte";
  import DateFormat from "../../../../Lib/DateFormat.svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import { toastNotification } from "../../../../Stores/toastNotification";

  let urls = {
    sendPortalAccountRequest: "/api/Account/AccountPortal/createaccountrequest",
  };

  function createAccountSendInvite() {
    var request = {
      emailAddress: account.email,
    };

    ApiServer.Post(urls.sendPortalAccountRequest, request).then((data) => {
      if (data.success)
        toastNotification.Success("Account created and email sent");
      else toastNotification.Error(data.message);
    });
  }
</script>

{#if portalInvite != null}
  <div class="card">
    <div class="card-body ">
      <div class="card-title d-flex justify-content-between align-items-center">
        <h4>Portal Request In Progress</h4>
      </div>
      <div class="card-text">
        <p>
          There is a pending portal invite sent on <DateFormat
            date={portalInvite.dateAdded}
          />. The user has not yet accepted the invite.
        </p>

        <p>
          You can send the invite again. This will send the user a new
          invitation email at {account.email}.
        </p>
        <p>
          <ButtonAction
            text="Create Account - Send Email"
            callback={createAccountSendInvite}
          />
        </p>
      </div>
    </div>
  </div>
{:else}
  <div class="card">
    <div class="card-body ">
      <div class="card-title d-flex justify-content-between align-items-center">
        <h4>No Portal Account</h4>
      </div>
      <div class="card-text">
        <p>
          {account.fullName} does not have an account set up yet. Click the button
          below to create a portal account and send an email invite.
        </p>
        <p>
          <ButtonAction
            text="Create Account - Send Email"
            callback={createAccountSendInvite}
          />
        </p>
      </div>
    </div>
  </div>
{/if}
