<script>
  export let account;
  import { onMount } from "svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import NoPortalAccess from "./_noPortalAccess.svelte";
  import EnablePortalAccess from "./_enablePortalAccess.svelte";
  import DateFormat from "../../../../Lib/DateFormat.svelte";
  import ResetPassword from "./_resetPassword.svelte";

  let urls = {
    portalUser: `/api/Account/AccountPortal/portalUser?accountID=${account.id}`,
    portalUserInvite: `/api/Account/AccountPortal/portaluserinvite?accountID=${account.id}`,
  };

  let portalCompanyUser;
  let portalUserInvite;

  onMount(async () => {
    ApiServer.Get(urls.portalUser).then((data) => {
      portalCompanyUser = data;
    });

    ApiServer.Get(urls.portalUserInvite).then((data) => {
      portalUserInvite = data;
    });
  });
</script>

<div class="card">
  <div class="card-body ">
    <div class="card-title d-flex justify-content-between align-items-center">
      <h5>{account.fullName} Portal Settings</h5>
    </div>
    <div class="card-text">
      {#if !portalCompanyUser}
        <NoPortalAccess {account} portalInvite={portalUserInvite} />
      {:else if !portalCompanyUser.portalUserDTO}
        <p>This user does not have a portal account. Click here to fix this.</p>
      {:else}
        <h5>
          Last Login: <DateFormat date={portalCompanyUser.lastLoginDate} />
        </h5>
        <EnablePortalAccess portalUser={portalCompanyUser.portalUserDTO} />
        <br />
        <ResetPassword portalUser={portalCompanyUser.portalUserDTO} />
      {/if}
    </div>
  </div>
</div>
