<script>
  import { onMount } from "svelte";
  import InboxItem from "../Controls/Inbox/InboxItem.svelte";
  import ButtonAction from "../Lib/Buttons/ButtonAction.svelte";
  import ButtonConfirmAction from "../Lib/Buttons/ButtonConfirmAction.svelte";
  import CheckoutIcon from "../Lib/Icons/CheckoutIcon.svelte";
  import ClearIcon from "../Lib/Icons/ClearIcon.svelte";
  import FailedPaymentIcon from "../Lib/Icons/FailedPaymentIcon.svelte";
  import HappyIcon from "../Lib/Icons/HappyIcon.svelte";
  import InboxIcon from "../Lib/Icons/InboxIcon.svelte";
  import JobIcon from "../Lib/Icons/JobIcon.svelte";
  import PendingIcon from "../Lib/Icons/PendingIcon.svelte";
  import PageTitle from "../Lib/PageTitle.svelte";
  import DashboardMessage from "../Lib/_DashboardMessage.svelte";

  import ApiServer from "../Server/ApiServer";

  let inboxItems;
  let viewItems;

  let itemTypes = {
    PaymentFailure: {
      count: 0,
      type: "Payment Failure",
    },
    NewSignUp: {
      count: 0,
      type: "New Sign Up",
    },
    PendingJob: {
      count: 0,
      type: "Pending Job",
    },
  };

  let urls = {
    inbox: "/api/Inbox/inbox",
    clearInbox: "/api/Inbox/clearall",
  };

  function fetchAll() {
    ApiServer.Get(urls.inbox).then((data) => {
      inboxItems = data;
      viewItems = inboxItems;
      inboxItems.forEach((element) => {
        setUIData(element);
      });
    });
  }

  onMount(async () => {
    fetchAll();
  });

  function setUIData(inboxItem) {
    switch (inboxItem.type) {
      case itemTypes.PaymentFailure.type:
        inboxItem.icon = FailedPaymentIcon;
        itemTypes.PaymentFailure.count++;
        break;
      case itemTypes.NewSignUp.type:
        inboxItem.icon = CheckoutIcon;
        itemTypes.NewSignUp.count++;
        break;
      case itemTypes.PendingJob.type:
        inboxItem.icon = PendingIcon;
        itemTypes.PendingJob.count++;
        break;
      default:
        break;
    }
  }

  function applyFilter(type) {
    navigateAction = type;

    if (type == "") {
      viewItems = inboxItems;
      return;
    }

    viewItems = inboxItems.filter((item) => item.type == type);
  }

  function clearAllInboxItems() {
    ApiServer.Get(urls.clearInbox).then((data) => {
      fetchAll();
    });
  }

  let navigateAction = "";
</script>

<PageTitle title="Inbox" />

{#if inboxItems}
  <div class="same-line">
    <h3><InboxIcon /> Inbox ({inboxItems.length})</h3>
    <ButtonConfirmAction
      text="Clear All"
      callback={clearAllInboxItems}
      warningText="This will clear all your Inbox items are you sure?"
      icon={ClearIcon}
    />
  </div>

  <div class="same-line">
    <div id="button-menu">
      <div id="action-menu">
        <span
          on:click={() => applyFilter("")}
          class:selected={navigateAction == ""}
          class="filter"
        >
          <InboxIcon /> All
          <span class="badge bg-info">{inboxItems.length}</span>
        </span>
        <span
          on:click={() => applyFilter(itemTypes.NewSignUp.type)}
          class:selected={navigateAction == itemTypes.NewSignUp.type}
          class="filter"
        >
          <CheckoutIcon /> New Sign Ups
          <span class="badge bg-info">
            {itemTypes.NewSignUp.count}
          </span>
        </span>
        <span
          on:click={() => applyFilter(itemTypes.PendingJob.type)}
          class:selected={navigateAction == itemTypes.PendingJob.type}
          class="filter"
        >
          <PendingIcon /> Pending Jobs
          <span class="badge bg-info">
            {itemTypes.PendingJob.count}
          </span>
        </span>
        <span
          on:click={() => applyFilter(itemTypes.PaymentFailure.type)}
          class:selected={navigateAction == itemTypes.PaymentFailure.type}
          class="filter"
        >
          <FailedPaymentIcon /> Failed Payments
          <span class="badge bg-info">
            {itemTypes.PaymentFailure.count}
          </span>
        </span>
      </div>
    </div>
  </div>

  {#if viewItems && viewItems.length > 0}
    {#each viewItems as inboxItem}
      <InboxItem {inboxItem} />
    {/each}
  {:else}
    <DashboardMessage icon={HappyIcon} message="All caught up! Whooohoooo" />
  {/if}
{/if}
