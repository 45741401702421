<script>
  export let inboxItem;

  import { link } from "svelte-navigator";
  import DateFormat from "../../Lib/DateFormat.svelte";
</script>

<div class="task-card">
  <div class="row">
    <div class="col">
      <div class="box">
        <div class="bg-white inbox-type2">
          <svelte:component this={inboxItem.icon} />
        </div>

        <a href={inboxItem.url} use:link>
          {inboxItem.name} - <DateFormat date={inboxItem.dateAdded} />
        </a>
      </div>
    </div>
  </div>
</div>

<style>
  .task-card {
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .inbox-type2 {
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0 0 2px #888;
    padding: 0.5em 0.6em;
    margin-right: 5px;
  }

  .box {
    display: flex;
    justify-content: left;
    align-items: center;
  }
</style>
