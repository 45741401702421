<script>
  import { onMount } from "svelte";
  import { Link, navigate, link } from "svelte-navigator";
  import ApiServer from "../../../Server/ApiServer";
  import Header from "../Header.svelte";
  import Money from "../../../Lib/Money.svelte";
  import ButtonAdd from "../../../Lib/Buttons/ButtonAdd.svelte";
  import { user } from "../../../Stores/stores";
  import IconCheckout from "../../../Lib/Icons/CheckoutIcon.svelte";
  let plans;

  let urls = {
    get: `/api/Company/BillingPlan/billingplans`,
  };

  function addPlan() {
    navigate("billingplans/new");
  }

  onMount(async () => {
    ApiServer.Get(urls.get).then((data) => {
      plans = data;
    });
  });
</script>

<Header text="Billing Plans" />

<div class="row justify-content-center">
  <div class="col-sm-12">
    <div class="float-end">
      <ButtonAdd text="Add Plan" callback={addPlan} />
    </div>
    <br />
    {#if plans}
      <article class="card-body">
        <div class="row table-row-header">
          <div class="col">Plan Name</div>
          <div class="col">Cost</div>
          <div class="col">Checkout Test</div>
        </div>
        {#each plans as plan}
          <div class="row table-row">
            <div class="col">
              <Link to={plan.id}>{plan.name}</Link>
            </div>
            <div class="col">
              <Money amount={plan.cost} />
            </div>
            <div class="col">
              <a
                class="menu-link"
                href="/checkout?name={$user.companyName}&plan={plan.name}"
                use:link><IconCheckout /> Checkout</a
              >
            </div>
          </div>
        {/each}
      </article>
    {/if}
  </div>
</div>

<style>
  .table-row {
    padding: 3px;
    margin: 2px;
    border-bottom: 1px solid lightgray;
  }

  .table-row-header {
    font-size: 16px;
    font-weight: 700;
    padding: 3px;
    margin: 2px;
    border-bottom: 1px solid gray;
  }
</style>
