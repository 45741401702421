<script>
  export let setting;
  import { onMount } from "svelte";
  import InputSelect from "../../../Lib/Inputs/InputSelect.svelte";
  import ReminderData from "../Templates/ReminderData";
  import InputTextBox from "../../../Lib/Inputs/InputTextBox.svelte";
  import InputPassword from "../../../Lib/Inputs/InputPassword.svelte";

  let boolValue;

  onMount(async () => {
    if (setting.dataType == "bool") {
      boolValue = setting.value.toLowerCase() === "true";
    }
  });
</script>

{#if setting.dataType == "bool"}
  <!--This has to be returned as a bool string actual bool breaks bool => string asp model binding for system settings-->
  <input
    class="form-check-input"
    type="checkbox"
    name={setting.friendlyName}
    checked={boolValue}
    on:change={(e) => {
      boolValue = e.target.checked;
      setting.value = e.target.checked.toString();
    }}
  />
  <label class="form-check-label" for={setting.friendlyName}>
    {setting.friendlyName}
  </label>
{:else if setting.dataType == "time"}
  <InputSelect
    label={setting.friendlyName}
    bind:value={setting.value}
    options={ReminderData.HourToRun}
  />
{:else if setting.dataType == "password"}
  <InputPassword label={setting.friendlyName} bind:value={setting.value} />
{:else}
  <InputTextBox label={setting.friendlyName} bind:value={setting.value} />
{/if}
