<!--This componenet is formated (using Row & Col) to go ONLY in the Checkout.svelte file.-->
<script>
  export let checkoutRules;
  export let billingPlan;
  export let totalCost;
  export let recurringCost;
  export let sameDayPickup = true;
  export let totalBins = 0;
  export let totalTax = 0;
  export let companyTax = null;
  //Used to set the values of the pickup days when loading the SignUpReview page.
  export let loadedPickupDays = null;

  import { createEventDispatcher } from "svelte";
  import Checkbox from "../../Lib/Inputs/InputCheckbox.svelte";
  import UTCDate from "../../Util/UTCDate";

  let displayPickupDays;
  let selectedPickupDays = null;

  const dispatch = createEventDispatcher();

  function buildPickupResult() {
    selectedPickupDays = [];
    displayPickupDays.forEach((pickup) => {
      selectedPickupDays.push(pickup);
    });
    setCost();
    dispatch("pickupDayResult", selectedPickupDays);
  }

  function setPickupDays() {
    displayPickupDays = [];
    if (!checkoutRules || !checkoutRules.pickupDays) return;

    if (sameDayPickup) {
      //Only show Primary pickup days
      checkoutRules.pickupDays.forEach((checkoutPickup) => {
        if (checkoutPickup.primary) {
          addPickupDay(checkoutPickup);
        }
      });
    } else {
      //Show all pickup days
      checkoutRules.pickupDays.forEach((checkoutPickup) => {
        addPickupDay(checkoutPickup);
      });
    }
    buildPickupResult();
  }

  function addPickupDay(checkoutPickup) {
    if (loadedPickupDays) {
      let pickupDay = getPickupDayFromLoadedPickupDay(checkoutPickup.dayName);
      checkoutPickup.day = pickupDay ? pickupDay.day : {};
      checkoutPickup.bins = pickupDay ? pickupDay.bins : checkoutPickup.minBins;
      checkoutPickup.estimatedJobDate = pickupDay
        ? UTCDate.ParseForDatePicker(pickupDay.estimatedJobDate)
        : new Date().toISOString().slice(0, 10);
      checkoutPickup.weekOfMonth = pickupDay.weekOfMonth.toString();
    } else {
      if (!checkoutPickup.bins) checkoutPickup.bins = checkoutPickup.minBins;
      if (!checkoutPickup.day) checkoutPickup.day = {};
    }
    displayPickupDays.push(checkoutPickup);
  }

  function getPickupDayFromLoadedPickupDay(dayName) {
    for (let i = 0; i < loadedPickupDays.length; i++) {
      const pickupDay = loadedPickupDays[i];
      if (pickupDay.dayName == dayName) return pickupDay;
    }
  }

  function setCost() {
    totalCost = 0;
    recurringCost = 0;

    if (!billingPlan) {
      return;
    }

    let lineItemCost = getLineItemCost();
    totalCost += lineItemCost;
    //total Bin cost with extra bins cost
    let binCost = getTotalBinCost();
    totalCost += binCost;
    //Total trip fees
    let tripFeeCost = getTotalTripFees();
    totalCost += tripFeeCost;
    //Total startup fees
    let startupFeeCost = getStartUpFee();
    totalCost += startupFeeCost;
    //Get plan cost
    totalCost += billingPlan.billingPlanRules.binRule.planCharge;
    recurringCost = totalCost - startupFeeCost;

    if (companyTax && companyTax.collectTax) {
      totalTax = totalCost * companyTax.taxRate;
    }
  }

  function getLineItemCost() {
    //total BillingPlanLineItems
    let cost = 0;
    if (billingPlan.lineItems) {
      billingPlan.lineItems.forEach((lineItem) => {
        cost += lineItem.quantity * lineItem.cost;
      });
    }
    return cost;
  }

  function getTotalBinCost() {
    var binRule = billingPlan.billingPlanRules.binRule;
    let cost = 0;
    totalBins = 0;
    if (selectedPickupDays && selectedPickupDays.length > 0) {
      selectedPickupDays.forEach((day) => {
        if (!day.bins) {
          return;
        }

        for (let i = 0; i < day.bins; i++) {
          totalBins++;
          if (totalBins > binRule.chargeExtraAfter) {
            cost += binRule.extraBinCharge;
          } else {
            cost += 0;
          }
        }
      });
      return cost;
    }
    return 0;
  }

  function getTotalTripFees() {
    var tripRule = checkoutRules.tripFeeRule;
    let cost = 0;
    if (tripRule.enabled) {
      if (selectedPickupDays && selectedPickupDays.length > 0) {
        selectedPickupDays.forEach((day) => {
          cost += tripRule.cost;
        });
      }
    }
    return cost;
  }

  function getStartUpFee() {
    var feeRule = billingPlan.billingPlanRules.startupFee;
    let cost = 0;
    if (feeRule.enabled) {
      cost = feeRule.cost;
    }
    return cost;
  }

  $: sameDayPickup, setPickupDays();
  $: billingPlan, setCost();
</script>

{#if !checkoutRules || !checkoutRules.pickupDays || checkoutRules.pickupDays.length == 0}
  <h2>Checkout problem. Pickup days are required. Please fix this.</h2>
{:else}
  {#if checkoutRules.pickupDays.length > 1}
    <div class="row">
      <div class="col">
        <Checkbox
          label=""
          text="All my bins are emptied on the same day"
          bind:value={sameDayPickup}
        />
      </div>
    </div>
  {/if}
  {#each displayPickupDays as pickup}
    <div class="row">
      <div class="col form-group">
        <label for={pickup.dayName}>{pickup.dayName}</label>
        <select
          id={pickup.dayName}
          class="form-control"
          on:blur={buildPickupResult}
          bind:value={pickup.day.name}
          required
        >
          <option value="">Select your pickup day</option>
          {#each pickup.days as day}
            <option value={day.name}>{day.name}</option>
          {/each}
        </select>
        <small class="form-text text-muted">
          {pickup.dayHelp}
        </small>
        <div class="invalid-feedback">{pickup.dayName} is required!</div>
      </div>
      <div class="col form-group">
        <label for="bins">Number of Bins?</label>
        <input
          type="number"
          bind:value={pickup.bins}
          on:change={buildPickupResult}
          class="form-control"
          name="bins"
          min={pickup.minBins}
          minlength={pickup.minBins}
          maxlength={pickup.maxBins}
          required
        />
        <small class="form-text text-muted" />
        <div class="invalid-feedback">Please select the number of bins</div>
      </div>
      {#if loadedPickupDays}
        <div class="col form-group">
          <label for="d">First Job Date</label>
          <input
            type="date"
            bind:value={pickup.estimatedJobDate}
            class="form-control"
          />
        </div>
        {#if billingPlan}
          {#if billingPlan.billingPlanRules.recurringRule.isRecurring}
            <div class="col form-group">
              <label for="d">Recurring Job</label>

              <select
                name="d"
                bind:value={pickup.weekOfMonth}
                class="form-control"
                on:change={buildPickupResult}
              >
                <option value="1">1st {pickup.day.name}</option>
                <option value="2">2nd {pickup.day.name}</option>
                <option value="3">3rd {pickup.day.name}</option>
                <option value="4">4th {pickup.day.name}</option>
              </select>
            </div>
          {/if}
        {/if}
      {/if}
    </div>
  {/each}
{/if}
