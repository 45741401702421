<script>
  export let jobCloseOptions;
  export let stopStatus;

  import { onMount } from "svelte";
  import ApiServer from "../../../Server/ApiServer";

  let urls = {
    jobOptions: `/api/Company/Settings/JobOptions`,
  };

  onMount(async () => {
    if (!jobCloseOptions) {
      ApiServer.Get(urls.jobOptions).then((data) => {
        jobCloseOptions = JSON.parse(data.jobCloseOptionsJSON);
        stopStatus = jobCloseOptions[0];
      });
    }
  });
</script>

{#if jobCloseOptions}
  <select name="stopStatus" class="form-control" bind:value={stopStatus}>
    {#each jobCloseOptions as option}
      <option value={option}>{option}</option>
    {/each}
  </select>
{/if}
