<script>
  export let text = "";
  export let callback;
</script>

<button type="button" on:click={callback}>{text}<slot /></button>

<style>
  button {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #93c54b;
    text-decoration: underline;
    text-decoration-style: solid;
    cursor: pointer;
  }

  button:hover {
    text-decoration: underline;
    color: #20c997;
  }
</style>
