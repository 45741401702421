<script>
  import { Offcanvas } from "sveltestrap";
  import ButtonCancel from "../../../Lib/Buttons/ButtonCancel.svelte";
  import ButtonLink from "../../../Lib/Buttons/ButtonLink.svelte";
  import NotificationPhoneNumber from "./Controls/NotificationPhoneNumber.svelte";
  let isOpen = false;
  const openScreen = () => (isOpen = !isOpen);
</script>

<ButtonLink text="Notification Phone Number" callback={openScreen} />

<Offcanvas
  header="Notification Number Settings"
  {isOpen}
  toggle={openScreen}
  placement="end"
>
  <NotificationPhoneNumber />

  <br />
  <hr />
  <ButtonCancel text="Close" callback={openScreen} />
</Offcanvas>
