<script>
  export let vtr;
</script>

{#if vtr && vtr != "None"}
  <div class="vendor-time-restriction">
    Vendor Time Restriction: {vtr}
  </div>
{/if}

<style>
  .vendor-time-restriction {
    background-color: orange;
    border-radius: 5px;
    padding: 5px;
    margin: 3px;
  }
</style>
