<script>
  import { onMount } from "svelte";
  export let messageType;
  import { Badge } from "sveltestrap";

  let color = "primary";
  let message = messageType;

  function setMessageColor() {
    message = messageType.toLowerCase();
    if (messageType == "EMAIL") {
      color = "secondary";
    } else if (messageType == "SMS") {
      color = "warning";
    }
  }

  onMount(async () => {
    setMessageColor();
  });
</script>

<Badge {color}>{message}</Badge>
