<script>
  import ButtonCancel from "../../../Lib/Buttons/ButtonCancel.svelte";
  import { Offcanvas } from "sveltestrap";
  import ButtonLink from "../../../Lib/Buttons/ButtonLink.svelte";
  import GeneralSettings from "./Controls/GeneralSettings.svelte";

  let isOpen = false;

  const openScreen = () => (isOpen = !isOpen);
</script>

<ButtonLink text="General Settings" callback={openScreen} />

<Offcanvas
  header="General Settings"
  {isOpen}
  toggle={openScreen}
  placement="end"
>
  <GeneralSettings />

  <br />
  <hr />
  <ButtonCancel text="Close" callback={openScreen} />
</Offcanvas>
