export default class JobData {
  static StatusList = [
    { id: "Complete", text: "Complete" },
    { id: "Scheduled", text: "Scheduled" },
    { id: "Canceled", text: "Canceled" },
  ];

  static PaymentStatusList = [
    { id: "Not Paid", text: "Not Paid" },
    { id: "Paid", text: "Paid" },
    { id: "Payment Failed", text: "Payment Failed" },
  ];
  static DurationList = [
    { id: 5, text: "5 Minutes" },
    { id: 10, text: "10 Minutes" },
    { id: 15, text: "15 Minutes" },
    { id: 20, text: "20 Minutes" },
    { id: 30, text: "35 Minutes" },
    { id: 45, text: "45 Minutes" },
    { id: 60, text: "1 Hour" },
    { id: 120, text: "2 Hours" },
    { id: 180, text: "3 Hours" },
  ];

  static StartTimeList = [
    { id: "00:00", text: "12:00 AM" },
    { id: "00:30", text: "12:30 AM" },
    { id: "1:00", text: "1:00 AM" },
    { id: "1:30", text: "1:30 AM" },
    { id: "2:30", text: "2:30 AM" },
    { id: "3:00", text: "3:00 AM" },
    { id: "3:30", text: "3:30 AM" },
    { id: "4:00", text: "4:00 AM" },
    { id: "4:30", text: "4:30 AM" },
    { id: "5:00", text: "5:00 AM" },
    { id: "5:30", text: "5:30 AM" },
    { id: "6:00", text: "6:00 AM" },
    { id: "6:30", text: "6:30 AM" },
    { id: "7:00", text: "7:00 AM" },
    { id: "7:30", text: "7:30 AM" },
    { id: "8:00", text: "8:00 AM" },
    { id: "8:30", text: "8:30 AM" },
    { id: "9:00", text: "9:00 AM" },
    { id: "9:30", text: "9:30 AM" },
    { id: "10:00", text: "10:00 AM" },
    { id: "10:30", text: "10:30 AM" },
    { id: "11:30", text: "11:30 AM" },
    { id: "11:30", text: "11:30 AM" },
    { id: "12:00", text: "12:00 PM" },
    { id: "13:00", text: "1:00 PM" },
    { id: "13:30", text: "1:30 PM" },
    { id: "14:00", text: "2:00 PM" },
    { id: "14:30", text: "2:30 PM" },
    { id: "15:00", text: "3:00 PM" },
    { id: "15:30", text: "3:30 PM" },
    { id: "16:00", text: "4:00 PM" },
    { id: "16:30", text: "4:30 PM" },
    { id: "17:00", text: "5:00 PM" },
    { id: "17:30", text: "5:30 PM" },
    { id: "18:00", text: "6:00 PM" },
    { id: "18:30", text: "6:30 PM" },
    { id: "19:00", text: "7:00 PM" },
    { id: "19:30", text: "7:30 PM" },
    { id: "20:00", text: "8:00 PM" },
    { id: "20:30", text: "8:30 PM" },
    { id: "21:00", text: "9:00 PM" },
    { id: "21:30", text: "9:30 PM" },
    { id: "22:00", text: "10:00 PM" },
    { id: "22:30", text: "10:30 PM" },
    { id: "23:30", text: "11:30 PM" },
    { id: "23:30", text: "11:30 PM" },
  ];
}
