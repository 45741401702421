<script>
  export let label;
  export let value;
  export let required = false;
  export let readonly = false;
  export let maxlength = 250;
  export let placeholder = "";

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  function handleOnChange() {
    dispatch("change", value);
  }
</script>

{#if label}
  <label for={label}>{label}</label>
{/if}
<input
  {maxlength}
  type="text"
  class="form-control"
  on:keyup={handleOnChange}
  {readonly}
  {placeholder}
  bind:value
  {required}
/>
