<script>
  export let requestID;
  export let company;

  import { onMount } from "svelte";
  import PortalApiServer from "../../Server/PortalApiServer";
  import { toastNotification } from "../../Stores/toastNotification";
  import { navigate } from "svelte-navigator";
  import PortalHeader from "./_PortalHeader.svelte";
  import InputPasswordStrengthIndicator from "../../Lib/Inputs/InputPasswordStrengthIndicator.svelte";
  import InputPasswordMatchIndicator from "../../Lib/Inputs/InputPasswordMatchIndicator.svelte";
  import CheckIcon from "../../lib/Icons/CheckIcon.svelte";

  let passwordRequest;
  let passwordRequestFail;
  let passwordsMatch = false;
  let passwordsComplexity;
  let passwordUpdate = {};
  let passwordIsReset = false;

  let urls = {
    updatepassword: "/api/portal/portallogin/resetpassword",
    getRequest: `/api/portal/portallogin/getpasswordresetrequest/?passwordResetRequestID=${requestID}&companyID=${company.id}`,
  };

  function handleMatchChange(event) {
    passwordsMatch = event.detail;
  }

  function handlePasswordStrengthChange(event) {
    passwordsComplexity = event.detail;
  }

  function validatePassword() {
    return passwordsComplexity.strength >= 2 && passwordsMatch.match;
  }

  function handleUpdatePassword() {
    let validPass = validatePassword();
    if (!validPass) {
      toastNotification.Error(
        "Please fix the problem with your password before continuing!"
      );
      return;
    }

    let passwordReset = {
      newPassword: passwordsMatch.password,
      currentPassword: "reset",
      accountID: passwordRequest.accountID,
      companyID: company.id,
    };

    PortalApiServer.PostAnonymous(urls.updatepassword, passwordReset).then(
      (data) => {
        if (data.success) {
          toastNotification.Success("Password Updated!");
          passwordIsReset = true;
        } else {
          toastNotification.Error("Error updating password!");
          passwordIsReset = false;
        }
      }
    );
  }

  function redirectToLoginPage() {
    var url = `/portal?c=${company.name}`;
    navigate(url);
  }

  onMount(async () => {
    PortalApiServer.GetAnonymous(urls.getRequest).then((data) => {
      if (data.success) {
        passwordRequest = data.data;
      } else {
        passwordRequestFail = {
          message: data.message,
        };
      }
    });
  });
</script>

<svelte:head>
  <title>Password Reset</title>
</svelte:head>

<main class="container-fluid container-sm body-content">
  {#if company}
    <PortalHeader {company} />

    {#if passwordRequest}
      <main class="container-fluid container-sm body-content">
        <div class="row">
          <div class="col-lg-6 mx-auto">
            {#if !passwordIsReset}
              <div class="card">
                <div class="card-body ">
                  <h5>Reset your password</h5>
                  <div class="card-text">
                    <form on:submit|preventDefault={handleUpdatePassword}>
                      <div class="row">
                        <div class="col">
                          <InputPasswordStrengthIndicator
                            label="Password"
                            bind:value={passwordUpdate.newPassword}
                            required={true}
                            password={passwordUpdate.newPassword}
                            on:change={handlePasswordStrengthChange}
                          />
                        </div>
                        <div class="col">
                          <InputPasswordMatchIndicator
                            label="Confirm Password"
                            bind:value={passwordUpdate.confirmPassword}
                            required={true}
                            password={passwordUpdate.newPassword}
                            on:change={handleMatchChange}
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <br />
                          <button type="submit" class="btn btn-primary">
                            Reset Password
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            {:else}
              <div class="card">
                <div class="card-body ">
                  <div class="card-text">
                    <h3><CheckIcon /> Password Reset Success!</h3>

                    <p>
                      Your password has been reset. Click the Sign In button
                      below to sign in.
                    </p>
                    <p>
                      <button
                        on:click={redirectToLoginPage}
                        class="btn btn-primary"
                      >
                        Reset Password
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            {/if}
          </div>
        </div>
      </main>
    {/if}

    {#if passwordRequestFail}
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <h3>Your {company.name} password reset expired!</h3>
          <div class="card">
            <div class="card-body ">
              <h5>
                It appears your password reset request has expired or was not
                found. Please try again.
              </h5>
              <div class="card-text">
                <p>{passwordRequestFail.message}</p>
                <p>
                  Please check your email and other records. It is possible the
                  password reset request has expired or you already reset your
                  password. Please try again.
                </p>
              </div>
              <div class="row">
                <div class="col">
                  <br />
                  <a class="btn btn-danger" href="/portal?c={company.name}"
                    >Back to Sign In</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    {/if}
  {/if}
</main>
