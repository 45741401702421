import { writable } from "svelte/store";
import UserStore from "./UserStore";

const store = writable(UserStore.getUser() || "");

store.subscribe((val) => {
  UserStore.setUser(val);
});

export const user = writable(null);
