<script>
  export let userScreenIsOpen;
  export let openUserScreen;

  import { Offcanvas } from "sveltestrap";
  import { navigate, link } from "svelte-navigator";
  import { onMount } from "svelte";

  import UserStore from "../../Stores/UserStore";
  import { user } from "../../Stores/stores";
  import ButtonLink from "../../Lib/Buttons/ButtonLink.svelte";
  import MeIcon from "../../Lib/Icons/MeIcon.svelte";
  import ApiServer from "../../Server/ApiServer";
  import InputSelect from "../../Lib/Inputs/InputSelect.svelte";
  import DateFormat from "../../Lib/DateFormat.svelte";
  import { BillingStatusStore } from "../../Stores/BillingStatusStore";

  let urls = {
    switchCompanies: "/api/Company/CompanyInfo/switchcompanies",
    myCompanies: "/api/Company/CompanyInfo/mycompanies?birdLoginID=",
  };

  let companies;
  let newcompany;
  let billingStatus;

  function handleLogoff() {
    $user == null;
    UserStore.logoff();
    navigate("/");
    location.reload();
  }

  function handleCompanySwitch() {
    if (!newcompany) {
      alert("Please select a new company to switch");
      return;
    }

    //get company in companylist by companyid
    const selectedCompany = companies.find((c) => c.id === newcompany);

    if (!selectedCompany) {
      alert("Please select a new company to switch");
      return;
    }

    if ($user.companyName == selectedCompany.text) {
      alert(
        `You are already logged into ${$user.companyName}. Please select a different company.`
      );
      return;
    }

    var youSure = confirm(
      `Are you sure you want to logout of ${$user.companyName} and login to ${selectedCompany.text}?`
    );

    if (youSure) {
      var request = {
        birdLoginID: $user.loginid,
        toCompanyName: selectedCompany.text,
      };

      ApiServer.Post(urls.switchCompanies, request).then((data) => {
        if (data.success) {
          //UserStore.logoff();
          var newUser = UserStore.makeUserFromRequest(data);
          user.set(newUser);
          UserStore.setUser(newUser);
          //navigate("/");
          location.reload();
        } else {
          alert("Company switch not successful: " + data.message);
        }
      });
    }
  }

  onMount(async () => {
    ApiServer.Get(urls.myCompanies + $user.loginid).then((data) => {
      companies = data;
    });
  });
</script>

<Offcanvas
  header="Profile & Stuff"
  isOpen={userScreenIsOpen}
  toggle={openUserScreen}
  placement="end"
>
  <h4>
    <MeIcon /> Me: {$user.fullName}
    <ButtonLink text="Logout" callback={handleLogoff} />
  </h4>
  <p>
    <a href="/company">{$user.companyName} Company Settings</a>
  </p>

  <p>
    {#if companies && companies.length > 1}
      <InputSelect
        label="Switch Company"
        bind:value={newcompany}
        options={companies}
      />
      <button class="btn btn-sm btn-primary" on:click={handleCompanySwitch}
        >Swtich Companies</button
      >
    {/if}
  </p>
  <hr />
  {#if $BillingStatusStore}
    <h2>Billing Status: {$BillingStatusStore.status}</h2>
    <p>
      <a href="/systembilling" use:link>
        Next Bill: <DateFormat
          date={$BillingStatusStore.nextBillDate}
          formatType="tonow"
        />
        <DateFormat
          date={$BillingStatusStore.nextBillDate}
          formatType="date-only"
        />
      </a>
    </p>
  {/if}
  <hr />
  <h2>Need assistance?</h2>
  <a target="_blank" href="https://binbirdapp.com">
    <img
      src="/images/logo_horizontal_cropped_100.png"
      style="width:200px; height:auto;"
      alt="Bin Bird Logo"
    />
  </a>
  <p>Email us: support@binbird.io</p>
</Offcanvas>
