<script>
  export let account;

  import { link, navigate } from "svelte-navigator";
  import ApiServer from "../../../Server/ApiServer";
  import DateFormat from "../../../Lib/DateFormat.svelte";
  import Templates from "./Templates/Templates.svelte";
  import Money from "../../../Lib/Money.svelte";
  import ScheduledJobIcon from "../../../Lib/Icons/ScheduledJobIcon.svelte";
  import CancelIcon from "../../../Lib/Icons/CancelIcon.svelte";
  import PendingIcon from "../../../Lib/Icons/PendingIcon.svelte";
  import ButtonAdd from "../../../Lib/Buttons/ButtonAdd.svelte";
  import CheckIcon from "../../../Lib/Icons/CheckIcon.svelte";

  let jobs = [];

  let urls = {
    getJobs: `/api/Job/Jobs`,
  };

  function handleTemplateChange() {
    fetchJobs();
  }

  function newJob() {
    navigate(`/account/${account.id}/jobs/new`);
  }

  function fetchJobs() {
    var jobRequest = {
      accountID: account.id,
      status: filter,
    };

    jobs = [];
    ApiServer.Post(urls.getJobs, jobRequest).then((data) => {
      jobs = data;
    });
  }

  let filter = "Scheduled";

  $: filter, fetchJobs();
</script>

<div class="card">
  <div class="card-body">
    <div class="card-title">
      <div class="same-line">
        <h5>{account.fullName} Jobs</h5>
        <ButtonAdd size="sm" text="New Job" callback={newJob} />
      </div>
      <div id="button-menu">
        <div id="action-menu">
          <span
            on:click={() => (filter = "Scheduled")}
            class:selected={filter == "Scheduled"}
            class="filter"
          >
            <ScheduledJobIcon /> Scheduled
          </span>
          <span
            on:click={() => (filter = "Complete")}
            class:selected={filter == "Complete"}
            class="filter"
          >
            <CheckIcon /> Complete
          </span>
          <span
            on:click={() => (filter = "Pending")}
            class:selected={filter == "Pending"}
            class="filter"
          >
            <PendingIcon /> Pending
          </span>
          <span
            on:click={() => (filter = "Canceled")}
            class:selected={filter == "Canceled"}
            class="filter"
          >
            <CancelIcon /> Canceled
          </span>
        </div>
      </div>
    </div>
    {#if jobs}
      {#each jobs as job}
        <div class="job">
          <div class="row">
            <div class="col">
              <h4>
                <a href="jobs/{job.id}" use:link>
                  <DateFormat date={job.jobDate} convertTimeZone={false} />
                  - Job {job.jobNumber}
                  {#if job.modified} * {/if}
                </a>
              </h4>
            </div>
            <div class="col">
              <h4>{job.status}<span>/</span>{job.paymentStatus}</h4>
            </div>
          </div>
          <div class="row">
            <div class="col">
              {job.streetAddress}
              {job.cityStateZip}
            </div>
            <div class="col">
              <Money amount={job.totalCost} />
              {#if job.billingPlanName} {job.billingPlanName} {/if}
            </div>
          </div>

          <div class="row">
            <div class="col">
              {job.templateName}
              for {job.bins} bins
            </div>
            <div class="col">
              {#if job.status == "Complete"}
                Closed by {job.closedBy} on <DateFormat
                  formatType="date-time"
                  date={job.closeDate}
                />
              {/if}
            </div>
          </div>
        </div>
      {/each}
    {/if}
  </div>
</div>
<br />
<div class="card">
  <div class="card-body ">
    <div class="card-title">
      <div class="same-line">
        <h5>{account.fullName} Templates</h5>
      </div>
    </div>
    <div class="card-text">
      <Templates on:onTemplateChange={handleTemplateChange} {account} />
    </div>
  </div>
</div>

<style>
  .job {
    border-top: 1px solid #f8f9fa;
    margin-top: 5px;
    padding-top: 5px;
  }
</style>
