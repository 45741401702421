<script>
  export let text;
  export let callback;

  import SaveIcon from "../Icons/SaveIcon.svelte";
</script>

<button type="button" class="btn btn-outline-primary" on:click={callback}>
  <SaveIcon />
  {text}
</button>
