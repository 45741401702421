<script>
  import InputEmail from "../Lib/Inputs/InputEmail.svelte";
  import ApiServer from "../Server/ApiServer";
  import { toastNotification } from "../Stores/toastNotification";
  import PublicPageHeader from "./PublicPageHeader.svelte";

  let passwordreset = {};

  function handlePasswordResetRequest() {
    ApiServer.PostAnonymous(
      "/api/public/signup/passwordreset",
      passwordreset
    ).then((data) => {
      if (data.success) {
        toastNotification.Success(
          "Email sent. Check your email for more information."
        );
      } else {
        toastNotification.Error(
          "Error sending the email. Please provide a valid email address. " +
            data.message
        );
      }
    });
  }
</script>

<main class="container-fluid container-sm body-content">
  <PublicPageHeader />

  <div class="row">
    <div class="col-lg-6 mx-auto">
      <h1>Sign In Help</h1>
      <div class="card">
        <div class="card-body ">
          <div class="card-text">
            <form on:submit|preventDefault={handlePasswordResetRequest}>
              <div class="row">
                <div class="col">
                  <InputEmail
                    label="Enter your email address used during Sign Up"
                    bind:value={passwordreset.email}
                    required={true}
                  />
                </div>
              </div>

              <div class="row">
                <div class="col">
                  If we find an account that matches this email we will send
                  instructions on how to reset your password.
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <br />
                  <div class="primary-nav">
                    <span />
                    <button type="submit" class="btn btn-danger">
                      Send Help
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p />
  <div class="row">
    <div class="col-lg-6 mx-auto">
      <a href="/">Back to login</a>
    </div>
  </div>
</main>

<style>
  .card {
    margin: 0 auto; /* Added */
    float: none; /* Added */
    margin-bottom: 10px; /* Added */
  }
  .primary-nav {
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
  }
</style>
