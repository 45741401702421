<script>
  /*This is the JobID or AccountID that the Image will be attached.*/
  export let relatedID;
  export let fetchImagesURL;
  export let folder;
  export let size = { h: 200, w: 200 };

  import { Carousel } from "svelte-images";
  const { Modal, open } = Carousel;

  import ApiServer from "../../Server/ApiServer";
  import ImageUploadModal from "./ImageUploadModal.svelte";
  import Image from "./Image.svelte";
  import ImageIcon from "../Icons/AttachmentIcon.svelte";
  let imagesList = [];
  let openModal = false;

  const popModal = (idx) =>
    setTimeout(() => {
      open(imagesList, idx);
    }, 0);

  function handleImageClick(event) {
    popModal(event.detail);
  }

  let urls = {
    deleteAttachment: `/api/Attachment/attachment?fileAttachmentID=`,
  };

  function openAddImage() {
    openModal = true;
  }

  function handleImageDelete(event) {
    let img = event.detail;
    deleteImage(img);
  }

  function handleUploadComplete() {
    fetchImages();
  }

  function deleteImage(img) {
    ApiServer.Delete(urls.deleteAttachment + img.id).then((result) => {
      imagesList.splice(imagesList.indexOf(img), 1);
      imagesList = imagesList;
    });
  }

  function fetchImages() {
    ApiServer.Get(fetchImagesURL + relatedID).then((result) => {
      imagesList = result || [];

      //property needed for Image carousel
      for (let i = 0; i < imagesList.length; i++) {
        const img = imagesList[i];
        img.src = img.sourceURL;
      }
    });
  }

  $: relatedID, fetchImages();
</script>

<div class="float-end">
  <button
    type="button"
    class="btn btn-sm btn-outline-primary"
    on:click={openAddImage}
  >
    <ImageIcon /> Add</button
  >
</div>

<ImageUploadModal
  bind:isOpen={openModal}
  on:uploadCompleteEvent={handleUploadComplete}
  {relatedID}
  {folder}
/>

<Modal />

{#if imagesList}
  <div class="row text-center text-lg-start">
    {#each imagesList as img, i}
      <div class="col-lg-3 col-md-4 col-6">
        <Image
          on:imageDeleted={handleImageDelete}
          on:imageClicked={handleImageClick}
          {size}
          {img}
          index={i}
        />
      </div>
    {/each}
  </div>
{/if}
