<script>
  export let company;
</script>

<div class="row">
  <div class="col-lg-6 mx-auto">
    <div class="text-center">
      <img
        src={company.companyInformation.companyLogoURL}
        style="width:75%; height:75%"
        alt="Logo"
      />
    </div>
  </div>
</div>
