import dayjs from "dayjs";
import ApiServer from "../Server/ApiServer";
import { user } from "./stores";

class UserStore {
  //Returns any non expired user.
  static getUser() {
    var user = localStorage.getItem("user");

    if (!user) {
      return null;
    }

    user = JSON.parse(user);

    let userExpirationDate = dayjs(user.expiration);
    let days = userExpirationDate.diff(dayjs(), "day");

    //The user expires arbitrarily within 7 days of last login
    if (days <= 0) {
      localStorage.removeItem("user");
      return null;
    }
    return user;
  }

  static userHasRole(roles) {
    if (!roles) return false;

    var user = localStorage.getItem("user");

    if (!user) {
      return false;
    }

    user = JSON.parse(user);

    for (let i = 0; i < roles.length; i++)
      if (user.role === roles[i]) {
        return true;
      }

    return false;
  }

  static setUser(user) {
    //Handles logout
    if (!user) {
      localStorage.removeItem("user");
      return;
    }

    if (!user.hasOwnProperty("expiration")) {
      user["expiration"] = dayjs().add(7, "day");
    }

    user = JSON.stringify(user);
    localStorage.setItem("user", user);
  }

  static logoff() {
    localStorage.removeItem("BinBirdAuthKey");
    localStorage.removeItem("user");
  }

  static async performLogin(loginRequest) {
    var loginUrl = "/api/public/login/login";

    let loginResult = {
      success: true,
      path: loginRequest.path,
      message: "",
    };

    var data = await ApiServer.Post(loginUrl, loginRequest);

    if (!data) {
      loginResult.success = false;
      loginResult.message = "Login failed.";
      return loginResult;
    }

    if (data.success) {
      var userFromLogin = UserStore.makeUserFromRequest(data);
      user.set(userFromLogin);
      UserStore.setUser(userFromLogin);
      loginResult.success = true;
      loginResult.message = "";
      return loginResult;
    } else {
      loginResult.success = false;
      loginResult.message = data.message;
      return loginResult;
    }
  }

  static makeUserFromRequest(loginResponse) {
    var user = {
      loginid: loginResponse.user.birdLoginID,
      role: loginResponse.user.role,
      fullName: loginResponse.user.fullName,
      email: loginResponse.user.email,
      companyName: loginResponse.company.name,
    };
    return user;
  }
}

export default UserStore;
