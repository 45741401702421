<script>
  import TechMap from "../Controls/Map/TechMap.svelte";
  import MapIcon from "../Lib/Icons/MapIcon.svelte";
  import PageTitle from "../Lib/PageTitle.svelte";
</script>

<PageTitle title="Map" />
<div class="same-line">
  <h3><MapIcon /> Map</h3>
</div>
<TechMap />
