<script>
  export let textToCopy = "";
  export let title = "";
  import { faCopy } from "@fortawesome/free-solid-svg-icons";
  import Fa from "svelte-fa";
  import { toastNotification } from "../../Stores/toastNotification";

  function copyText() {
    if (!textToCopy) return;
    // the navigator object in a browser holds lots of useful utilities, such as location services and clipboard interface.
    const theClipboard = navigator.clipboard;
    // write text returns a promise, so use `then` to react to success
    theClipboard
      .writeText(textToCopy)
      .then(() => toastNotification.Success("Text copied to clipboard!"));
  }
</script>

<span {title} on:click={copyText}>
  <Fa icon={faCopy} />
  <slot />
</span>
