<script>
  export let rules;

  import Checkbox from "../../../Lib/Inputs/InputCheckbox.svelte";
  import InputNumberBox from "../../../Lib/Inputs/InputNumberBox.svelte";
  import InputSelect from "../../../Lib/Inputs/InputSelect.svelte";
  import InputSelectArray from "../../../Lib/Inputs/InputSelectArray.svelte";
  import InputTextBox from "../../../Lib/Inputs/InputTextBox.svelte";
</script>

<div class="row option">
  <div class="col">
    <h4>No Show Rules</h4>
    <p>
      Rules to handle <strong>No Show</strong> jobs. No Show jobs are those jobs
      that cannot be completed. The bins are not out or cannot be serviced.
    </p>
  </div>
</div>

<div class="row option">
  <div class="col-sm-6">
    <Checkbox
      text="Enabled"
      label="Change Job status on No Show"
      bind:value={rules.noShowRule.statusRule.changeStatus}
    />
  </div>
  <div class="col-sm-6">
    {#if rules.noShowRule.statusRule.changeStatus}
      <InputSelectArray
        label="Job Status"
        bind:value={rules.noShowRule.statusRule.status}
        options={rules.noShowRule.statusRule.jobStatusOptions}
      />
    {/if}
  </div>
</div>

{#if rules.noShowRule.statusRule.status == "Complete"}
  <div class="row option">
    <div class="col-sm-6">
      <Checkbox
        text="Enabled"
        label="Send email when complete"
        bind:value={rules.noShowRule.emailRule.sendEmail}
      />
    </div>
    <div class="col-sm-6">
      {#if rules.noShowRule.emailRule.sendEmail}
        <InputTextBox
          label="Email Template"
          bind:value={rules.noShowRule.emailRule.emailTemplateID}
        />
        <Checkbox
          text="Include Images"
          label="Include job images in email?"
          bind:value={rules.noShowRule.emailRule.includeJobImages}
        />
      {/if}
    </div>
  </div>

  <div class="row option">
    <div class="col-sm-6">
      <Checkbox
        text="Enabled"
        label="Send SMS when complete"
        bind:value={rules.noShowRule.smsRule.sendSMS}
      />
    </div>
    <div class="col-sm-6">
      {#if rules.noShowRule.smsRule.sendSMS}
        <InputTextBox
          label="SMS Template"
          bind:value={rules.noShowRule.smsRule.smsTemplateID}
        />
        <Checkbox
          text="Include Images"
          label="Include job images in SMS?"
          bind:value={rules.noShowRule.smsRule.includeJobImages}
        />
      {/if}
    </div>
  </div>
{/if}

<div class="row option">
  <div class="col-sm-6">
    <Checkbox
      text="Enabled"
      label="Charge a No Show fee"
      bind:value={rules.noShowRule.feeRule.chargeFee}
    />
  </div>
  <div class="col-sm-6">
    {#if rules.noShowRule.feeRule.chargeFee}
      <Checkbox
        text="Fee is job cost"
        label="No Show fee is the same as the cost"
        bind:value={rules.noShowRule.feeRule.feeIsCostOfJob}
      />
    {/if}
    {#if rules.noShowRule.feeRule.chargeFee && !rules.noShowRule.feeRule.feeIsCostOfJob}
      <InputNumberBox
        label="No Show fee is:"
        bind:value={rules.noShowRule.feeRule.noShowFee}
      />
    {/if}
  </div>
</div>

<style>
  .option {
    margin-bottom: 10px;
  }
</style>
