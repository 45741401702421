<script>
  import { Loader } from "@googlemaps/js-api-loader";
  import { Offcanvas } from "sveltestrap";

  import Header from "../Header.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import GRouteWeek from "./_RouteWeek.svelte";
  import { toastNotification } from "../../../Stores/toastNotification";

  let selectedDay = "Monday";
  let selectedWeek = {};
  let isOpen = false;
  let selectedArea;
  let map;
  let drawingManager;
  let weeks;

  //manage all infowindows and polys so we can close/hide them.
  let layers = [];
  let polys = [];

  let testAddress;
  let testAddressResponse;
  let mapCenterZoom;
  let mapCenterLat;
  let mapCenterLng;

  const openScreen = () => (isOpen = !isOpen);

  let urls = {
    getArea: "/api/Company/CompanyInfo/area?weekday=",
    deleteArea: "/api/Company/CompanyInfo/area?areaID=",
    saveArea: "/api/Company/CompanyInfo/area",
    addressTest: "/api/Company/CompanyInfo/addresstest",
    mapCenter: "/api/Company/Settings/GeneralSettings/mapcenter",
    saveMapCenter: "/api/Company/Settings/GeneralSettings",
  };

  function testAddressLocation() {
    let testRequest = {
      address: testAddress,
      trashDay: selectedDay,
    };

    ApiServer.Post(urls.addressTest, testRequest).then((response) => {
      testAddressResponse = response.message;
    });
  }

  function setZoomAndCenter() {
    mapCenterZoom.value = map.getZoom();
    var center = map.getCenter();
    mapCenterLat.value = center.lat().toString();
    mapCenterLng.value = center.lng().toString();
    mapCenterZoom.value = mapCenterZoom.value.toString();
    console.log(mapCenterZoom.value);
    var save = [];
    save.push(mapCenterLat);
    save.push(mapCenterLng);
    save.push(mapCenterZoom);
    console.log(save);
    ApiServer.Post(urls.saveMapCenter, save).then((response) => {
      toastNotification.Success("Map Center and Zoom saved!");
    });
  }

  const loader = new Loader({
    apiKey: "AIzaSyCe46MWUKwPyZ94Y4inMKAb0daloUvFuJc",
    version: "weekly",
    libraries: ["drawing"],
  });

  var styles = {
    polygon: {
      fillColor: "#00ff80",
      fillOpacity: 0.3,
      strokeColor: "#008840",
      strokeWeight: 2,
      clickable: true,
      editable: true,
      zIndex: 1,
    },
  };

  loader.load().then(() => {
    ApiServer.Get(urls.mapCenter).then((data) => {
      //Save these for later. just in case the users sets the zoom/center button.
      mapCenterLat = data.lat;
      mapCenterLng = data.lng;
      mapCenterZoom = data.zoom;

      map = new google.maps.Map(document.getElementById("map"), {
        center: {
          lat: parseFloat(mapCenterLat.value),
          lng: parseFloat(mapCenterLng.value),
        },
        zoom: parseInt(mapCenterZoom.value),
      });

      loadMapSettings();
    });
  });

  function loadMapSettings() {
    // map = new google.maps.Map(document.getElementById("map"), {
    //   center: { lat: 36.15947230364452, lng: -115.17417043395645 },
    //   zoom: 10,
    // });

    drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ["polygon"],
      },
    });
    drawingManager.setMap(map);

    map.data.addListener("addfeature", featureAdded);

    drawingManager.addListener("polygoncomplete", function (polygon) {
      // delete drawing if doesn't have enough points
      if (polygon.getPath().getLength() < 3) {
        alert("An Area must have 3 or more points.");
        polygon.getPath().clear();
      }
      // otherwise create new feature and delete drawing
      else {
        addFeature(polygon.getPath());
        polygon.setMap(null);
      }
    });
    setAreas();
  }

  function featureAdded(e) {
    let id = e.feature.getId();
    addFeature(e.feature.getGeometry().getAt(0).getArray(), id);
    map.data.remove(e.feature);
    polys.push(e.feature);
  }

  function addFeature(path, id) {
    var polygon = new google.maps.Polygon(styles.polygon);

    setPolyColor(polygon);
    polygon.setPath(path);

    //Weekday Week Number
    polygon.id = id;

    //Show Info Window with the ID to user
    let center = getCenter(polygon);
    var infoWindow = new google.maps.InfoWindow();
    infoWindow.setContent("Area " + id);
    infoWindow.setPosition(center);
    infoWindow.open(map);

    layers.push({ id: id, window: infoWindow, poly: polygon });

    // listeners for detecting geometry changes
    // polygon.getPath().addListener("insert_at", someFunction);
    // polygon.getPath().addListener("set_at", someFunction);
    // polygon.getPath().addListener("remove_at", someFunction);
    //Does not work... see 2 events above.
    //polygon.addListener("dragend", handlePolygonChange);
    // polygon.getPath().addListener("dragend", function () {
    //   console.log("drag end");
    // });
    // polygon.addListener("dragend", function () {
    //   console.log("drag end");
    // });
    // polygon.addListener("rightclick", function (e) {
    //   outputAsGeoJSON();
    // });

    polygon.addListener("click", function (e) {
      openAreaSettings(polygon);
    });

    google.maps.event.addListener(
      polygon.getPath(),
      "insert_at",
      function (index, obj) {
        handlePolygonChange(polygon);
      }
    );
    google.maps.event.addListener(
      polygon.getPath(),
      "set_at",
      function (index, obj) {
        handlePolygonChange(polygon);
      }
    );

    //and... display it on the map
    polygon.setMap(map);
  }

  function setPolyColor(polygon) {
    //Get week to show proper color here:
    if (polygon && polygon.id) {
      let week = getWeek(polygon.id);
      if (week) {
        polygon.fillColor = week.color;
      }
    }
  }

  function getCenter(poly) {
    var bounds = new google.maps.LatLngBounds();
    poly.getPath().forEach(function (element, index) {
      bounds.extend(element);
    });
    return bounds.getCenter();
  }

  function openAreaSettings(polygon) {
    openScreen();
    selectedArea = polygon;
    selectedWeek = getWeek(polygon.id);
    if (!selectedWeek) {
      selectedWeek = {};
    }
  }

  function handlePolygonChange(polygon) {
    //When a polygon is changed this will fire.  This will fire on New and existing polygons.
    //If the polygon is existing, we want to update the new values in the database.
    //If the polygon is new we want to ignore the change and let them keep editing until they decide to save changs.

    selectedWeek = getWeek(polygon.id);

    if (selectedWeek) {
      let event = {};
      event.detail = selectedWeek;
      selectedArea = polygon;
      handleSaveWeek(event);
    }
  }

  function handleSaveWeek(event) {
    let week = event.detail;

    //If week Exists
    weeks.forEach(function (w) {
      if (w != week && w.week == week.week) {
        //Week already exists. No Save
        alert(
          `Week ${w.week} on ${selectedDay} already exists. Please set a different weekday.`
        );
        return;
      }
    });

    var data = new google.maps.Data();
    data.add({
      geometry: new google.maps.Data.Polygon([
        selectedArea.getPath().getArray(),
      ]),
      properties: {
        weekID: `${selectedDay} ${week.week}`,
      },
    });

    data.toGeoJson(function (json) {
      let polyAreaJSON = JSON.stringify(json);

      week.name = `${selectedDay} ${week.week}`;
      week.dayName = selectedDay;
      week.areaJSON = polyAreaJSON;
      week.areaID = `${selectedDay} ${week.week}`;
      ApiServer.Post(urls.saveArea, week).then((data) => {
        console.log(data);
      });
    });
  }

  function handleDeleteWeek(event) {
    let week = event.detail;

    ApiServer.Delete(urls.deleteArea + week.id).then((data) => {
      weeks.splice(weeks.indexOf(week), 1);
      weeks = weeks;
      //removeLayer(week.areaID);
      //Close info window of area we are deleting
      layers.forEach(function (win) {
        if (win.id == week.areaID) {
          win.window.close();
          win.poly.setMap(null);
        }
      });
    });
  }

  function getWeek(weekID) {
    for (let i = 0; i < weeks.length; i++) {
      let week = weeks[i];
      if (`${week.dayName} ${week.week}` == weekID) {
        return week;
      }
    }
    return null;
  }

  function addLayer(week) {
    let polyJSON = JSON.parse(week.areaJSON);
    map.data.addGeoJson(polyJSON, { idPropertyName: "weekID" });
  }

  function handleMenuChange(day) {
    selectedDay = day;
    setAreas();
  }

  function setAreas() {
    ApiServer.Get(urls.getArea + selectedDay).then((data) => {
      weeks = data;

      //The following does not work to remove layers.

      // map.data.forEach(function (feature) {
      //   map.data.remove(feature);
      // });

      // polys.forEach(function (p) {
      //   // p.setMap(null);
      //   map.data.remove(p);
      // });

      //Remove all existing layers
      //Close info window of area we are deleting
      layers.forEach(function (win) {
        win.window.close();
        win.poly.setMap(null);
      });

      //Clear layers
      layers = [];

      //Removes all elements from the map... But leaves some strange things like no colors.
      map.data.setMap(null);

      if (weeks) {
        weeks.forEach((week) => {
          addLayer(week);
        });
      }
    });
  }
</script>

<Header text="Areas" />
<div class="card">
  <div class="card-body ">
    <div class="card-title" />
    <div class="card-text">
      <div class="row">
        <div class="col">
          <div id="button-menu">
            <div id="action-menu">
              <span
                on:click={() => handleMenuChange("Monday")}
                class:selected={selectedDay == "Monday"}
                class="filter"
              >
                Monday
              </span>
              <span
                on:click={() => handleMenuChange("Tuesday")}
                class:selected={selectedDay == "Tuesday"}
                class="filter"
              >
                Tuesday
              </span>
              <span
                on:click={() => handleMenuChange("Wednesday")}
                class:selected={selectedDay == "Wednesday"}
                class="filter"
              >
                Wednesday
              </span>
              <span
                on:click={() => handleMenuChange("Thursday")}
                class:selected={selectedDay == "Thursday"}
                class="filter"
              >
                Thursday
              </span>
              <span
                on:click={() => handleMenuChange("Friday")}
                class:selected={selectedDay == "Friday"}
                class="filter"
              >
                Friday
              </span>
              <span
                on:click={() => handleMenuChange("Saturday")}
                class:selected={selectedDay == "Saturday"}
                class="filter"
              >
                Saturday
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p>
            To test the Trash Day and Week for a customer enter their full
            address below, then click Test.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-8">
          <label for="address">Test Address</label>
          <input type="text" class="form-control" bind:value={testAddress} />
        </div>
        <div class="col-2">
          <label for="testbutton">Click to test</label><br />
          <button
            id="testbutton"
            type="button"
            class="btn btn-outline-primary"
            on:click={testAddressLocation}>Test</button
          >
        </div>
        <div class="col-2">
          <label for="testbutton">Set Zoom and Center</label><br />
          <button
            id="testbutton"
            type="button"
            class="btn btn-outline-primary"
            on:click={setZoomAndCenter}>Set</button
          >
        </div>
      </div>

      {#if testAddressResponse}
        <div class="row">
          <div class="col">
            <h4>{selectedDay} week {testAddressResponse}</h4>
          </div>
        </div>
      {/if}

      <div class="row">
        <div class="col" style="height: 100%">
          <div id="map" />
        </div>
      </div>
    </div>
  </div>
</div>

<Offcanvas header="Area Settings" {isOpen} toggle={openScreen} placement="end">
  {#if selectedArea && selectedArea.id}
    <h5>{selectedArea.id}</h5>
  {:else}
    <h5>New Area...</h5>
  {/if}

  <GRouteWeek
    on:saveWeek={handleSaveWeek}
    on:deleteWeek={handleDeleteWeek}
    on:close={() => openScreen()}
    bind:week={selectedWeek}
  />
</Offcanvas>

<style>
  #map {
    height: 800px;
    width: 100%;
  }
</style>
