<script>
  import { onMount } from "svelte";

  import ButtonAdd from "../../../Lib/Buttons/ButtonAdd.svelte";
  import ButtonDelete from "../../../Lib/Buttons/ButtonDelete.svelte";
  import InputTextBox from "../../../Lib/Inputs/InputTextBox.svelte";

  export let rules;

  function addNewField() {
    var field = {
      label: "",
      description: "",
      position: 0,
    };
    rules.checkoutFields = [...rules.checkoutFields, field];
  }

  $: rules.checkoutFields, convertFields();

  function convertFields() {
    if (!rules.checkoutFields) return;

    rules.checkoutFields.forEach((field) => {
      if (field.positionx) {
        field.position = parseInt(field.positionx);
      }
    });
  }

  function removeField(field) {
    var index = rules.checkoutFields.indexOf(field);
    if (index >= 0) {
      rules.checkoutFields.splice(index, 1);
      rules.checkoutFields = rules.checkoutFields;
    }
  }

  onMount(async () => {
    if (!rules.checkoutFields) rules.checkoutFields = [];
    //I know it sucks.  Enum tostring() number converstion to string so the select picks the right one blaaaaa!
    rules.checkoutFields.forEach((field) => {
      field.positionx = field.position.toString();
    });
  });
</script>

<div class="row">
  <div class="col">
    <h4>Checkout Fields</h4>
    <p>
      Use the fields below to add text for your customers during checkout. This
      will help customize your customers checkout experience.
    </p>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="float-end">
      <ButtonAdd text="Add Field" callback={addNewField} />
    </div>
    <div style="clear:both" />
    {#if rules.checkoutFields}
      {#each rules.checkoutFields as field}
        <div class="row">
          <div class="col form-group">
            <label for="position">Field Position</label>
            <select
              class="form-control"
              name="position"
              bind:value={field.positionx}
            >
              <option value="1">Before Account</option>
              <option value="2">After Account</option>
              <option value="3">After Pickup Schedule</option>
              <option value="4">Before Signup Button</option>
            </select>
          </div>
          <div class="col form-group">
            <InputTextBox label="Label" bind:value={field.label} />
          </div>
          <div class="col form-group">
            <InputTextBox label="Description" bind:value={field.description} />
          </div>
          <div class="col">
            <label for="del">Remove</label><br />
            <ButtonDelete
              text="Remove"
              tooltip="Remove this field"
              callback={() => removeField(field)}
            />
          </div>
        </div>
      {/each}
    {/if}
  </div>
</div>
