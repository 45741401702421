import ApiServer from "../../Server/ApiServer";

export default class CompanyCheck {
  constructor() {}

  ///Returns null if the company is not found.
  static async GetValidCompany(companyName, companyID) {
    let urls = {
      getCompany: `/api/Public/Checkout/company?companyID=${companyID}`,
      getCompanyByName: `/api/Public/Checkout/companybyname?companyName=${companyName}`,
    };

    if (!companyID && !companyName) {
      return null;
    }

    let urlToPullCompany = companyID ? urls.getCompany : urls.getCompanyByName;

    let company = await CompanyCheck.GetCompany(urlToPullCompany);

    return company;
  }

  static async GetCompany(url) {
    var company = await ApiServer.GetAnonymous(url);
    return company;
  }
}
