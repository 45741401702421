<script>
  export let tutorialSettings;
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import GeneralSettings from "../Settings/Controls/GeneralSettings.svelte";

  function handleSaveSettings() {
    tutorialSettings.tutorialSettings.routingAddressDone = true;
    dispatch("saveSettings");
  }
</script>

<div class="row">
  <div class="col">
    <div class="card mb-3">
      <div class="card-header">Route Optimization Address</div>
      <div class="card-body">
        <div class="card-text">
          <GeneralSettings on:onSettingsSaved={handleSaveSettings} />
        </div>
        <div class="card-footer" />
      </div>
    </div>
  </div>
</div>
