<script>
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const companyName = urlParams.get("c");
</script>

<main class="container-fluid container-sm body-content">
  <h4>It looks like you have entered an incorrect company.</h4>
  <h5>Click the link below to try again.</h5>
</main>
