<script>
  import { onMount } from "svelte";

  import { link } from "svelte-navigator";
  import AccountMessageCard from "../Controls/Messenger/AccountMessageCard.svelte";
  import MessageCardList from "../Controls/Messenger/MessageCardList.svelte";
  import ButtonAdd from "../Lib/Buttons/ButtonAdd.svelte";

  import MessengerIcon from "../Lib/Icons/MessengerIcon.svelte";
  import ApiServer from "../Server/ApiServer";
  import InputTextArea from "../Lib/Inputs/InputTextArea.svelte";
  import { toastNotification } from "../Stores/toastNotification";
  import PhoneNumber from "../Lib/PhoneNumber.svelte";
  import MessageIcon from "../Lib/Icons/MessageIcon.svelte";
  import PageTitle from "../Lib/PageTitle.svelte";
  import NewMessageModal from "../Controls/Messenger/NewMessageModal.svelte";
  import DeleteIcon from "../Lib/Icons/DeleteIcon.svelte";
  import ButtonConfirmAction from "../Lib/Buttons/ButtonConfirmAction.svelte";
  import SearchControl from "../Controls/Messenger/SearchControl.svelte";
  import { user } from "../Stores/stores";
  import ArrowLeftIcon from "../Lib/Icons/ArrowLeftIcon.svelte";

  let urls = {
    accountList: "/api/Messenger/accountMessages",
    messages: "/api/Messenger/messages",
    addMessage: "/api/Messenger/add",
    mynumber: "/api/Messenger/myphonenumber",
    removeaccountmessage: "/api/Messenger/removeaccountmessage?id=",
  };

  let myphonenumber;
  let messages;
  let currentAccount;
  let showModal = false;
  let newMessageBody;
  let currentPage = 1;
  let outerWidth = 1000;
  let accountPaneVisible = true;
  let messagePaneVisible = true;

  let accountMessages = [];
  let accountMessagePage = 1;
  let accountMessageSearch = "";

  function startNewMessage() {
    showModal = true;
  }

  function sendNewMessage() {
    let message = {
      accountID: currentAccount.accountID,
      accountMessageID: currentAccount.id,
      toNumber: currentAccount.accountPhoneNumber,
      body: newMessageBody,
      fromCustomer: false,
    };

    ApiServer.Post(urls.addMessage, message).then((data) => {
      if (data.success) {
        newMessageBody = "";
        if (!accountMessages) accountMessages = [];

        var possibleAccountMessage = getAccountMessageOnPage(
          data.data.accountMessage
        );

        if (possibleAccountMessage == null) {
          accountMessages.unshift(data.data.accountMessage);
          accountMessages = accountMessages;
          messages = [];
        }

        currentAccount = data.data.accountMessage;

        if (!messages) messages = [];
        messages.unshift(data.data.message);
        messages = messages;

        toastNotification.Success("Message sent!");
      } else {
        toastNotification.Success("Message failed to send. " + data.message);
      }
    });
  }

  function getAccountMessageOnPage(accountMessage) {
    for (let i = 0; i < accountMessages.length; i++) {
      if (accountMessages[i].accountName === accountMessage.accountName) {
        //Why? I don't know. After returning the ID is blank...
        //accountMessages[i].id = accountMessage.id;
        return accountMessages[i];
      }
    }
    return null;
  }

  function handleNewMessageSent(event) {
    let newMessageRequest = event.detail;

    if (!currentAccount) currentAccount = {};

    currentAccount.id = null;
    currentAccount.accountID = newMessageRequest.accountID;
    currentAccount.accountPhoneNumber = newMessageRequest.toNumber;
    newMessageBody = newMessageRequest.messageBody;

    sendNewMessage();
    showModal = false;
  }

  function loadMoreMessages() {
    currentPage++;
    loadMessagesForAccount(currentAccount);
  }

  function selectAccount(account) {
    currentAccount = account;
    messages = [];
    currentPage = 1;
    loadMessagesForAccount(account);
  }

  function loadMessagesForAccount(acc) {
    let req = {
      currentPage: currentPage,
      accountMessageID: acc.id,
      userName: $user.fullName,
    };

    ApiServer.Post(urls.messages, req).then((data) => {
      messages = messages.concat(data);
    });
  }

  function onSearchKeyDown(event) {
    let element = event.detail;
    if (element.charCode === 13) searchAccountsClear();
  }

  function searchAccountsClear() {
    accountMessagePage = 1;
    accountMessages = [];
    loadAccountMessages();
  }

  function loadMoreAccountMessages() {
    accountMessagePage++;
    loadAccountMessages();
  }

  function loadAccountMessages() {
    let req = {
      currentPage: accountMessagePage,
      searchText: accountMessageSearch,
    };

    ApiServer.Post(urls.accountList, req).then((data) => {
      accountMessages = accountMessages.concat(data);
    });
  }

  function accountMessageDelete(accountMessage) {
    ApiServer.Delete(urls.removeaccountmessage + accountMessage.id).then(
      (data) => {
        toastNotification.Success("Messages deleted successfully!");
        currentAccount = null;
        messages = null;
        currentPage = 1;
        accountMessages.splice(accountMessages.indexOf(accountMessage), 1);
        accountMessages = accountMessages;
      }
    );
  }

  onMount(() => {
    loadAccountMessages();

    ApiServer.Get(urls.mynumber).then((data) => {
      myphonenumber = data;
    });
  });

  function handleMessageBack() {
    currentAccount = undefined;
  }

  $: accountPaneVisible = outerWidth > 783 || !currentAccount;
  $: messagePaneVisible = outerWidth > 783 || currentAccount;
</script>

<svelte:window bind:outerWidth />

<PageTitle title="Messenger" />

<div class="same-line">
  <h3><MessengerIcon /> Messenger</h3>
  <ButtonAdd text="New Message" callback={startNewMessage} />
</div>

<div class="row">
  <div class="col">
    <small><PhoneNumber number={myphonenumber} /></small>
  </div>
</div>

<div class="row main-chat">
  {#if accountPaneVisible}
    <div class="col-md-4 account-message-panel">
      <SearchControl
        title=""
        bind:searchText={accountMessageSearch}
        on:clearSearch={searchAccountsClear}
        on:performSearch={searchAccountsClear}
        on:searchKeyDown={onSearchKeyDown}
      />

      <div style="margin: 5px; padding: 5px" />

      {#if accountMessages}
        {#each accountMessages as acc}
          <div
            class="message-card"
            class:message-card-selected={currentAccount === acc}
            on:click={() => selectAccount(acc)}
          >
            <AccountMessageCard accountMessage={acc} />
            <div class="remove-icon">
              <ButtonConfirmAction
                text=""
                warningText="This will delete all of the messages for this account. Are you sure?"
                icon={DeleteIcon}
                callback={() => accountMessageDelete(acc)}
              />
            </div>
          </div>
        {/each}

        {#if accountMessages.length >= 10 * accountMessagePage}
          <button
            class="btn btn-primary"
            on:click={() => loadMoreAccountMessages()}>Load More...</button
          >
        {/if}
      {/if}
    </div>
  {/if}
  {#if messagePaneVisible}
    <div class="col-md-8 message-panel">
      {#if currentAccount}
        <div class="same-line">
          <div style="margin-left: 10px;">
            <button
              class="btn btn-sm btn-outline-info"
              on:click={handleMessageBack}
            >
              <ArrowLeftIcon /> Back
            </button>
          </div>

          <div>
            <strong
              ><a href="account/{currentAccount.accountID}"
                >{currentAccount.accountName}</a
              ></strong
            >
            <PhoneNumber number={currentAccount.accountPhoneNumber} />
          </div>
        </div>
        <div class="same-line new-message">
          <InputTextArea bind:value={newMessageBody} />

          <button
            type="button"
            class="btn btn-sm btn-secondary"
            on:click={sendNewMessage}>Send</button
          >
        </div>

        {#if messages}
          <MessageCardList
            accountName={currentAccount.accountName}
            messageList={messages}
          />

          {#if messages.length >= 10 * currentPage}
            <button class="btn btn-primary" on:click={() => loadMoreMessages()}
              >Load More...</button
            >
          {:else}
            <hr />
            <strong>You have reached the end!</strong>
          {/if}
        {/if}
      {:else}
        <h3><MessageIcon /> Start a new message or select one on the left.</h3>
      {/if}
      <br />
      <br />
      <br />
    </div>
  {/if}
</div>

<NewMessageModal
  on:onMessageSent={handleNewMessageSent}
  bind:showNewMessageWindow={showModal}
/>

<style>
  .account-message-panel {
    background-color: #f6f9fb;
    max-height: 695px;
    overflow: auto;
  }

  .message-panel {
    background-color: #ffffff;
    color: black;
    max-height: 695px;
    overflow: auto;
  }
  .main-chat {
    max-height: 695px;
    height: 75vh;
  }

  .message-card {
    border-radius: 5px;
    background-color: white;
    margin: 10px;
    padding: 5px;
    cursor: pointer;
  }
  .message-card .remove-icon {
    visibility: hidden;
  }

  .remove-icon {
    display: -webkit-flex;
    display: flex;
    justify-content: flex-end;
  }

  .message-card:hover {
    background-color: #7fceed;
  }

  .message-card:hover .remove-icon {
    visibility: visible;
  }

  .message-card-selected {
    background-color: #7fceed;
  }

  .new-message {
    margin-left: 10px;
  }
</style>
