<script>
  export let portalUser;

  import ButtonAction from "../../../../Lib/Buttons/ButtonAction.svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import { toastNotification } from "../../../../Stores/toastNotification";

  let urls = {
    enablePortalUser: `/api/Account/AccountPortal/enableportaluser?portalUserID=${portalUser.id}`,
    disablePortalUser: `/api/Account/AccountPortal/disableportaluser?portalUserID=${portalUser.id}`,
  };

  function disablePortalAccess() {
    ApiServer.Get(urls.disablePortalUser).then((data) => {
      toastNotification.Success("Portal access is disabled");
      portalUser.enabled = false;
    });
  }

  function enablePortalAccess() {
    ApiServer.Get(urls.enablePortalUser).then((data) => {
      toastNotification.Success("Portal access is enabled");
      portalUser.enabled = true;
    });
  }
</script>

{#if portalUser.enabled}
  <div class="card">
    <div class="card-body ">
      <div class="card-title d-flex justify-content-between align-items-center">
        <h4>Disable Portal Access</h4>
      </div>
      <div class="card-text">
        <p>
          To disable this users portal account click the Disable button below.
        </p>
        <p>
          <ButtonAction
            text="Disable Portal Access"
            callback={disablePortalAccess}
          />
        </p>
      </div>
    </div>
  </div>
{:else}
  <div class="card">
    <div class="card-body ">
      <div class="card-title d-flex justify-content-between align-items-center">
        <h4>Enable Portal Access</h4>
      </div>
      <div class="card-text">
        <p>
          Portal access is disabled for this user. To enable portal access click
          the button below.
        </p>
        <p>
          <ButtonAction
            text="Enable Portal Access"
            callback={enablePortalAccess}
          />
        </p>
      </div>
    </div>
  </div>
{/if}
