<script>
  import { onMount } from "svelte";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import ButtonSave from "../../../../Lib/Buttons/ButtonSave.svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import { toastNotification } from "../../../../Stores/toastNotification";
  import AddressAutocomplete from "../../../../Lib/AddressAutocomplete/Autocomplete.svelte";
  import InputCheckbox from "../../../../Lib/Inputs/InputCheckbox.svelte";
  import { PlaygroundModeStore } from "../../../../Stores/PlaygroundModeStore";

  let urls = {
    get: `/api/Company/Settings/GeneralSettings/optimizestartaddress`,
    update: `/api/Company/Settings/GeneralSettings`,
  };

  let savedAddress;
  let address = "";
  let addressUnit = "";
  let city = "";
  let state = "";
  let zip = "";

  function loadSettings() {
    ApiServer.Get(urls.get).then((data) => {
      savedAddress = data;
    });
  }

  function saveSettings() {
    if (address) {
      var newAddress = `${address} ${addressUnit}, ${city}, ${state} ${zip}`;
      savedAddress.value = newAddress;
      var settings = [];
      settings.push(savedAddress);

      ApiServer.Post(urls.update, settings).then((data) => {
        toastNotification.Success("Settings saved!");
        dispatch("onSettingsSaved");
      });
    }

    $PlaygroundModeStore.updatePlaygroundMode(
      $PlaygroundModeStore.inPlaygroundMode
    );
  }

  onMount(async () => {
    loadSettings();
  });
</script>

{#if savedAddress}
  <div class="row">
    <div class="col">
      <p>
        Optimize Start Address is used when optimizing your route. It is used as
        the start and ending address during route optimization. Normally this is
        the address where you keep your trucks.
      </p>

      <p>
        Current Address: <br />
        <strong>{savedAddress.value}</strong>
      </p>
      <p>To update enter a new address below.</p>

      <AddressAutocomplete
        bind:address
        bind:unit={addressUnit}
        bind:city
        bind:state
        bind:zip
      />
    </div>
  </div>

  <div class="row">
    <div class="col">
      <p>Playground Mode:</p>
      <InputCheckbox
        text="Playground Enabled"
        bind:value={$PlaygroundModeStore.inPlaygroundMode}
      />
    </div>
  </div>
  <ButtonSave text="Save" callback={saveSettings} />
{/if}
