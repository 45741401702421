<script>
  export let company;

  import { onMount } from "svelte";
  import { Alert } from "sveltestrap";

  import PortalApiServer from "../../Server/PortalApiServer";
  import { toastNotification } from "../../Stores/toastNotification";
  import PortalHeader from "./_PortalHeader.svelte";
  import InputTextBox from "../../Lib/Inputs/InputTextBox.svelte";
  import InputPassword from "../../Lib/Inputs/InputPassword.svelte";
  import InputPasswordStrengthIndicator from "../../Lib/Inputs/InputPasswordStrengthIndicator.svelte";
  import InputPasswordMatchIndicator from "../../Lib/Inputs/InputPasswordMatchIndicator.svelte";

  const urlParams = new URLSearchParams(window.location.search);
  const inviteID = urlParams.get("inviteID");
  let invite = null;
  let portalInviteFail = false;
  let portalInviteSuccess = false;
  let portalInviteErrorMessage = "";
  let passwordsMatch = false;
  let passwordsComplexity;

  let urls = {
    completeInvite: "/api/portal/portallogin/completeinvite",
    getInvite: "/api/portal/portallogin/portalinvite",
  };

  function handleSignUpComplete() {
    let validPass = validatePassword();

    if (!validPass) {
      toastNotification.Error(
        "Please fix the problem with your password before continuing!"
      );
      return;
    }

    PortalApiServer.PostAnonymous(urls.completeInvite, invite).then((data) => {
      if (data.success) {
        toastNotification.Success("Account created!");
        portalInviteSuccess = true;
      } else {
        toastNotification.Error("Error adding your account." + data.message);
        portalInviteErrorMessage = data.message;
      }
    });
  }
  function validatePassword() {
    return passwordsComplexity.strength >= 2 && passwordsMatch.match;
  }

  function handleMatchChange(event) {
    passwordsMatch = event.detail;
  }

  function handlePasswordStrengthChange(event) {
    passwordsComplexity = event.detail;
  }

  onMount(async () => {
    var portalRequest = {
      portalInviteID: inviteID,
      companyID: company.id,
    };

    PortalApiServer.PostAnonymous(urls.getInvite, portalRequest).then(
      (data) => {
        if (data.success) {
          invite = data.data;
        } else {
          toastNotification.Error("Error retrieving portal invite");
          portalInviteFail = true;
        }
      }
    );
  });
</script>

<svelte:head>
  <title>Invitation</title>
</svelte:head>

{#if company}
  <PortalHeader {company} />

  <main class="container-fluid container-sm body-content">
    {#if invite && !portalInviteSuccess}
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <h2>Welcome to {company.name}</h2>
          <div class="card">
            <div class="card-body ">
              <div
                class="card-title d-flex justify-content-between align-items-center"
              >
                <h5>
                  You are invited! Review your information and set your
                  password.
                </h5>
              </div>
              {#if portalInviteErrorMessage}
                <Alert color="danger"
                  >Error creating your account: {portalInviteErrorMessage}</Alert
                >
              {/if}
              <div class="card-text">
                <form on:submit|preventDefault={handleSignUpComplete}>
                  <div class="row">
                    <div class="col">
                      <InputTextBox
                        label="First Name"
                        bind:value={invite.firstName}
                        readonly={true}
                      />
                    </div>
                    <div class="col">
                      <InputTextBox
                        label="Last Name"
                        bind:value={invite.lastName}
                        readonly={true}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <InputTextBox
                        label="Email Address"
                        value={invite.email}
                        readonly={true}
                      />
                      <span class="text-muted small"
                        >This is also your username</span
                      >
                    </div>
                    <div class="col">
                      <InputTextBox
                        label="Phone Number"
                        bind:value={invite.phoneNumber}
                        readonly={true}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <InputPasswordStrengthIndicator
                        label="Password"
                        bind:value={invite.password}
                        required={true}
                        password={invite.password}
                        on:change={handlePasswordStrengthChange}
                      />
                    </div>
                    <div class="col">
                      <InputPasswordMatchIndicator
                        label="Confirm Password"
                        bind:value={invite.confirmPassword}
                        required={true}
                        password={invite.password}
                        on:change={handleMatchChange}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <br />
                      <div class="same-line">
                        <a
                          href="/portal?c={company.name}"
                          class="btn btn-danger"
                          >Back to Sign In
                        </a>
                        <button type="submit" class="btn btn-primary">
                          Save & Go
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    {/if}

    {#if portalInviteSuccess}
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <h1>Portal Account Created!</h1>
          <div class="card">
            <div class="card-body ">
              <h5>Your portal account has been successfully created!</h5>
              <div class="card-text">
                <p>Click the Sign In button below to see your account.</p>
              </div>
              <div class="row">
                <div class="col">
                  <br />
                  <a href="/portal?c={company.name}" class="btn btn-danger"
                    >Sign In
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    {/if}

    {#if portalInviteFail}
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <h1>Portal Account Invitation Expired!</h1>
          <div class="card">
            <div class="card-body ">
              <h5>
                It appears your request has expired or is already complete.
              </h5>
              <div class="card-text">
                <p>
                  Please check your email and other records. It is possible the
                  request has expired or you already completed this step. Feel
                  free to reach out if you need help.
                </p>
              </div>
              <div class="row">
                <div class="col">
                  <br />
                  <a href="/portal?c={company.name}" class="btn btn-danger"
                    >Back to Sign In
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    {/if}
  </main>
{/if}
