<script>
  import Modal from "sv-bootstrap-modal";

  import InputCheckbox from "../Lib/Inputs/InputCheckbox.svelte";
  import InfoCircleIcon from "../Lib/Icons/InfoCircleIcon.svelte";
  import ButtonCancel from "../Lib/Buttons/ButtonCancel.svelte";
  import ButtonSave from "../Lib/Buttons/ButtonSave.svelte";
  import PlaygroundModeIcon from "../Lib/Icons/PlaygroundModeIcon.svelte";
  import { PlaygroundModeStore } from "../Stores/PlaygroundModeStore";

  let showModal = false;

  function savePlaygroundMode() {
    $PlaygroundModeStore.updatePlaygroundMode(
      $PlaygroundModeStore.inPlaygroundMode
    );
    showModal = false;
  }
</script>

{#if $PlaygroundModeStore.inPlaygroundMode}
  <div class="playground">
    <div class="same-line">
      <div><PlaygroundModeIcon /> Playground Mode Enabled</div>
      <div
        title="What the heck is playground mode?"
        class="info-circle"
        on:click={() => (showModal = true)}
      >
        <InfoCircleIcon />
      </div>
    </div>
  </div>
{/if}
<Modal bind:open={showModal}>
  <div class="modal-header">
    <h5 class="modal-title">Playground Mode</h5>
    <button type="button" class="close" on:click={() => (showModal = false)}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Playground mode is turned on by default for new Bin Bird accounts.</p>
    <p>
      While in Playground mode, customers will NOT receive notifications. Feel
      free to import customers, add jobs, close jobs, etc without any messages
      going to customers. Once Playground mode is Off the yellow bar will go
      away and normal notifications will continue.
    </p>
    <p>
      Playground Mode also ensures that customers will not be billed for real.
      It will appear as if they have been billed but their Stripe account will
      not be touched.
    </p>
    <p>
      While in Playground Mode customers can still use the Checkout Page but
      will not receive any Email or Text notifications.
    </p>
    <p>
      You can find the Playground mode setting in Company
      Settings/Settings/General Settings
    </p>
    <p>
      <InputCheckbox
        text="Playground Enabled"
        bind:value={$PlaygroundModeStore.inPlaygroundMode}
      />
    </p>
  </div>
  <div class="modal-footer">
    <ButtonCancel text="Close" callback={() => (showModal = false)} />
    <ButtonSave text="Save" callback={savePlaygroundMode} />
  </div>
</Modal>

<style>
  .info-circle {
    cursor: pointer;
  }
  .playground {
    background-color: yellow;
    color: black;
    width: 100%;
    padding: 5px;
  }
</style>
