<script>
  export let option;

  import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
  } from "sveltestrap";
</script>

<div class="row">
  <div class="col">
    <input
      type="text"
      style="background-color:{option.Color}"
      bind:value={option.Text}
      class="form-control"
    />
  </div>
  <div class="col">
    <Dropdown>
      <DropdownToggle caret>{option.Text}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem on:click={() => (option.Color = "#FFFF33")}>
          <div class="vmr-option" style="background-color: #FFFF33">Yellow</div>
        </DropdownItem>

        <DropdownItem on:click={() => (option.Color = "#FF0000")}>
          <div class="vmr-option" style="background-color:#FF0000;">Red</div>
        </DropdownItem>

        <DropdownItem on:click={() => (option.Color = "#2A9DF4")}>
          <div class="vmr-option" style="background-color: #2A9DF4;">Blue</div>
        </DropdownItem>

        <DropdownItem on:click={() => (option.Color = "#A4DE02")}>
          <div class="vmr-option" style="background-color: #A4DE02;">Green</div>
        </DropdownItem>

        <DropdownItem on:click={() => (option.Color = "#ff9100")}>
          <div class="vmr-option" style="background-color: #ff9100;">
            Orange
          </div>
        </DropdownItem>

        <DropdownItem on:click={() => (option.Color = "#8e24aa")}>
          <div class="vmr-option" style="background-color: #8e24aa;">
            Purple
          </div>
        </DropdownItem>

        <DropdownItem on:click={() => (option.Color = "#f43484")}>
          <div class="vmr-option" style="background-color: #f43484;">Pink</div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </div>
</div>

<style>
  .vmr-option {
    height: 20px;
    padding-left: 5px;
    color: #ffffff;
    vertical-align: middle;
  }
</style>
