import dayjs from "dayjs";
import PortalApiServer from "../Server/PortalApiServer";
import ApiServer from "../Server/PortalApiServer";
import { portaluser } from "./PortalUser";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";

class PortalUserStore {
  static PORTAL_USER_KEY = "portaluser";
  //Returns any non expired user.
  static getUser() {
    var user = localStorage.getItem(this.PORTAL_USER_KEY);

    if (!user) {
      return null;
    }

    user = JSON.parse(user);

    let userExpirationDate = dayjs(user.expiration);
    let days = userExpirationDate.diff(dayjs(), "day");

    //The user expires arbitrarily within 7 days of last login
    if (days <= 0) {
      localStorage.removeItem(this.PORTAL_USER_KEY);
      return null;
    }
    return user;
  }

  static setUser(user) {
    //Handles logout
    if (!user) {
      localStorage.removeItem(this.PORTAL_USER_KEY);
      return;
    }

    if (!user.hasOwnProperty("expiration")) {
      user["expiration"] = dayjs().add(7, "day");
    }

    user = JSON.stringify(user);
    localStorage.setItem(this.PORTAL_USER_KEY, user);
  }

  static logoff() {
    localStorage.removeItem("BinBirdPortalAuthKey");
    localStorage.removeItem(this.PORTAL_USER_KEY);
  }

  static async performLogin(loginRequest) {
    var loginUrl = "/api/portal/portallogin/login";

    let loginResult = {
      success: true,
      path: loginRequest.path,
      message: "",
    };

    var data = await PortalApiServer.Post(loginUrl, loginRequest);

    if (!data) {
      loginResult.success = false;
      loginResult.message = "Login failed.";
      return loginResult;
    }

    if (data.success) {
      PortalUserStore.updateLogin(data.portalUserDTO);
      loginResult.success = true;
      loginResult.message = "";
      return loginResult;
    } else {
      loginResult.success = false;
      loginResult.message = data.message;
      return loginResult;
    }
  }

  static updateLogin(portalLogin) {
    var userFromLogin = PortalUserStore.makeUserFromRequest(portalLogin);
    portaluser.set(userFromLogin);
    PortalUserStore.setUser(userFromLogin);
  }

  static makeUserFromRequest(portalLogin) {
    var user = {
      loginid: portalLogin.id,
      role: "portal user",
      fullName: portalLogin.portalAccounts[0].accountName,
      email: portalLogin.emailAddress,
      companyName: portalLogin.portalAccounts[0].companyName,
      accountID: portalLogin.portalAccounts[0].accountID,
    };
    return user;
  }
}

export default PortalUserStore;
