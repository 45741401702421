<script>
  export let imageFile;
  let avatar;

  let reader = new FileReader();
  reader.readAsDataURL(imageFile);
  reader.onload = (e) => {
    avatar = e.target.result;
  };
</script>

{#if avatar}
  <img class="avatar" width="50px" height="50px" src={avatar} alt="d" />
{/if}
