export default class NextDaySettingData {
  static DaysOfWeek = [
    { id: "Sunday", text: "Sunday" },
    { id: "Monday", text: "Monday" },
    { id: "Tuesday", text: "Tuesday" },
    { id: "Wednesday", text: "Wednesday" },
    { id: "Thursday", text: "Thursday" },
    { id: "Friday", text: "Friday" },
    { id: "Saturday", text: "Saturday" },
  ];
}
