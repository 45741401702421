<script>
  import { onMount } from "svelte";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import ButtonSave from "../../../Lib/Buttons/ButtonSave.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import { toastNotification } from "../../../Stores/toastNotification";
  import AcquisitionSource from "./AcquisitionSource.svelte";
  import CheckoutSuccessMessage from "./CheckoutSuccessMessage.svelte";
  import RuleAutoApprove from "./RuleAutoApprove.svelte";
  import RulePickupDays from "./RulePickupDays.svelte";
  import RuleTermsAndConditions from "./RuleTermsAndConditions.svelte";
  import RuleTripFees from "./RuleTripFees.svelte";

  let checkoutRules;

  let urls = {
    checkoutRules: `/api/Company/CompanyInfo/checkoutrules`,
    saveCheckoutRules: `/api/Company/CompanyInfo/checkoutrules`,
  };
  function saveCheckoutRules() {
    processCheckoutDayChanges();
    var checkoutRule = {
      checkoutRuleText: JSON.stringify(checkoutRules),
    };

    ApiServer.Post(urls.saveCheckoutRules, checkoutRule).then((data) => {
      toastNotification.Success("Checkout Rules Saved!");
      dispatch("onCheckoutRuleSave");
    });
  }

  function processCheckoutDayChanges() {
    checkoutRules.pickupDays.forEach((pickupDay) => {
      pickupDay.days = [];

      pickupDay.daySelectionOptions.forEach((day) => {
        if (day.isSelected) {
          pickupDay.days.push(day);
        }
      });
    });
  }

  onMount(async () => {
    ApiServer.Get(urls.checkoutRules).then((data) => {
      checkoutRules = data.checkoutRule;
    });
  });
</script>

{#if checkoutRules}
  <div class="rule-box">
    <h3>Checkout Rules</h3>
    <p>The rules below are used during your customer checkout.</p>

    <div class="rule-section">
      <RuleAutoApprove {checkoutRules} />
    </div>

    <div class="rule-section">
      <RuleTermsAndConditions {checkoutRules} />
    </div>

    <div class="rule-section">
      <CheckoutSuccessMessage {checkoutRules} />
    </div>

    <div class="rule-section">
      <RulePickupDays {checkoutRules} />
    </div>

    <div class="rule-section">
      <RuleTripFees rules={checkoutRules} />
    </div>

    <div class="rule-section">
      <AcquisitionSource />
    </div>
  </div>

  <div class="row">
    <div class="col">
      <ButtonSave text="Save Checkout Rules" callback={saveCheckoutRules} />
    </div>
  </div>
{/if}

<style>
  .rule-box {
    margin-top: 10px;
  }

  .rule-section {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;

    border: 1px solid lightgray;
    border-radius: 5px;
  }
</style>
