<script>
  export let account;

  import dayjs from "dayjs";
  import { createEventDispatcher, onMount } from "svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import Money from "../../../../Lib/Money.svelte";
  import TemplateDetail from "./TemplateDetail.svelte";
  import ButtonAdd from "../../../../Lib/Buttons/ButtonAdd.svelte";
  import ButtonDelete from "../../../../Lib/Buttons/ButtonDelete.svelte";
  import TemplateDetailTwo from "./TemplateDetailTwo.svelte";

  const dispatch = createEventDispatcher();

  let urls = {
    templates: `/api/JobTemplate/Templates?accountID=${account.id}`,
    template: `/api/JobTemplate/Template?templateID=`,
    addTemplate: "/api/JobTemplate/template",
    editTemplate: "/api/JobTemplate/template",
    deleteTemplate: "/api/JobTemplate/template?id=",
  };

  let currentTemplate = null;
  let templates = null;

  function getTemplates() {
    ApiServer.Get(urls.templates).then((data) => {
      templates = data;
    });
  }

  function editTemplate(template) {
    if (!template) {
      currentTemplate = createDefaultTemplate();
    } else {
      template.startDate = dayjs(template.startDate).toDate();
      template.endDate = dayjs(template.endDate).toDate();
      currentTemplate = template;
    }
  }

  function createDefaultTemplate() {
    //Set template defaults here. I would rather put these right in the job component but can't figure it out.... so it lives here.
    let today = new Date();
    let template = {
      name: "New job template",
      recurrance: "",
      jobNotes: "",
      accountID: account.id,
      startTime: "9:00",
      duration: "5",
      monthsToCreateJobs: "6",
      startDate: today,
      endDate: null,
      runForever: true,
      adminNotes: "",
      frequency: "MONTHLY",
      interval: 1,
      bySetPos: "1",
      byDay: "SA",
      byMonthDay: "",
      byMonth: today.getMonth() + 1,
      byWhat: "BYSETPOS",
    };
    return template;
  }

  function handleSaveTemplate(event) {
    ApiServer.Post(urls.addTemplate, currentTemplate).then((data) => {
      onTemplateChange();
    });
  }

  function onTemplateChange() {
    currentTemplate = null;
    getTemplates();
    dispatch("onTemplateChange");
  }

  function handleCancelTemplateSave(event) {
    currentTemplate = null;
  }

  function deleteTemplate(template) {
    ApiServer.Delete(urls.deleteTemplate + template.id).then((data) => {
      onTemplateChange();
    });
  }

  onMount(async () => {
    getTemplates();
  });
</script>

{#if templates}
  <div class="row">
    <div class="col">Job Template</div>
    <div class="col d-flex" style="justify-content: flex-end;">
      <ButtonAdd
        size="sm"
        text="New Template"
        callback={() => editTemplate(null)}
      />
    </div>
  </div>

  <div class="row table-header">
    <div class="col d-none d-sm-block">Name</div>
    <div class="col">Definition</div>
    <div class="col">Plan</div>
    <div class="col d-flex" style="justify-content: flex-end;">Actions</div>
  </div>

  {#each templates as template}
    <div class="row table-row">
      <div class="col d-none d-sm-block">{template.name}</div>
      <div class="col">{template.definition}</div>
      <div class="col">
        {#if template.billingPlan}
          {template.billingPlan.name}
          <Money amount={template.billingPlan.cost} />
        {:else}
          None selected
        {/if}
      </div>
      <div class="col d-flex" style="justify-content: flex-end;">
        <button
          class="btn btn-sm btn-outline-info"
          on:click={() => editTemplate(template)}
        >
          Edit
        </button>
        <ButtonDelete
          text="Delete"
          callback={() => deleteTemplate(template)}
          warningText="Deleting this template will also delete all of the 'Scheduled' jobs. Are you sure?"
        />
      </div>
    </div>
  {/each}
{/if}
<!-- Change this to ADD new Template button -->
{#if currentTemplate}
  <!--Edit Template Card-->
  <div class="card">
    <div class="card-body ">
      <div class="card-text">
        <TemplateDetailTwo
          template={currentTemplate}
          on:saveTemplate={handleSaveTemplate}
          on:cancelTemplateSave={handleCancelTemplateSave}
        />
      </div>
    </div>
  </div>
  <!--/Edit Template Card-->
{/if}

<style>
  .table-row {
    padding: 5px;
    margin-bottom: 5px;
  }

  .table-header {
    padding: 5px;
    font-weight: bold;
  }
</style>
