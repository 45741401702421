<script>
  export let tutorialSettings;

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import InputTextBox from "../../../Lib/Inputs/InputTextBox.svelte";
  import InputNumberBox from "../../../Lib/Inputs/InputNumberBox.svelte";
  import ButtonSave from "../../../Lib/Buttons/ButtonSave.svelte";
  import { toastNotification } from "../../../Stores/toastNotification";

  function handleSaveBillingPlans() {
    if (tutorialSettings.tutorialSettings.billingPlansDone) {
      //We do NOT want these settings to be sent more than once. If billing plan is already done then let them know.
      toastNotification.Error(
        "It appears your Billing Plans have already been saved. Please visit Company Settings/Billing Plans to make further edits."
      );
      return;
    }

    tutorialSettings.tutorialSettings.billingPlansDone = true;
    tutorialSettings.isBillingPlanSave = true;
    dispatch("saveBillingPlans");
  }
</script>

{#if tutorialSettings.tutorialSettings.monthlyPlan.enabled}
  <h5>Monthly Plan Setting</h5>
  <InputTextBox
    label="Billing Plan Name"
    bind:value={tutorialSettings.tutorialSettings.monthlyPlan.name}
  />
  <InputNumberBox
    label="Total Monthly Plan Cost"
    bind:value={tutorialSettings.tutorialSettings.monthlyPlan.totalCost}
  />
  <InputNumberBox
    label="Number of bins included in Monthly Plan"
    bind:value={tutorialSettings.tutorialSettings.monthlyPlan
      .numberOfBinsIncluded}
  />
  <InputNumberBox
    label="Cost of initial startup fee."
    bind:value={tutorialSettings.tutorialSettings.monthlyPlan
      .initialStartupFeeCost}
  />
  <p>
    Do you charge an initial startup fee (for first time cleaning)? If you do
    not, leave the cost at 0 above.
  </p>
{/if}

{#if tutorialSettings.tutorialSettings.biMonthlyPlan.enabled}
  <hr />
  <h5>BiMonthly Plan Setting</h5>
  <InputTextBox
    label="Billing Plan Name"
    bind:value={tutorialSettings.tutorialSettings.biMonthlyPlan.name}
  />
  <InputNumberBox
    label="Total Monthly Plan Cost"
    bind:value={tutorialSettings.tutorialSettings.biMonthlyPlan.totalCost}
  />
  <InputNumberBox
    label="Number of bins included in Monthly Plan"
    bind:value={tutorialSettings.tutorialSettings.biMonthlyPlan
      .numberOfBinsIncluded}
  />

  <InputNumberBox
    label="Cost of initial startup fee."
    bind:value={tutorialSettings.tutorialSettings.biMonthlyPlan
      .initialStartupFeeCost}
  />
  <p>
    Do you charge an initial startup fee (for first time cleaning)? If you do
    not, leave the cost at 0 above.
  </p>
{/if}

{#if tutorialSettings.tutorialSettings.quarterlyPlan.enabled}
  <hr />
  <h5>Quarterly Plan Setting</h5>
  <InputTextBox
    label="Billing Plan Name"
    bind:value={tutorialSettings.tutorialSettings.quarterlyPlan.name}
  />
  <InputNumberBox
    label="Total Monthly Plan Cost"
    bind:value={tutorialSettings.tutorialSettings.quarterlyPlan.totalCost}
  />
  <InputNumberBox
    label="Number of bins included in Monthly Plan"
    bind:value={tutorialSettings.tutorialSettings.quarterlyPlan
      .numberOfBinsIncluded}
  />

  <InputNumberBox
    label="Cost of initial startup fee."
    bind:value={tutorialSettings.tutorialSettings.quarterlyPlan
      .initialStartupFeeCost}
  />
  <p>
    Do you charge an initial startup fee (for first time cleaning)? If you do
    not, leave the cost at 0 above.
  </p>
{/if}

{#if tutorialSettings.tutorialSettings.onetimePlan.enabled}
  <hr />
  <h5>Quarterly Plan Setting</h5>
  <InputTextBox
    label="Billing Plan Name"
    bind:value={tutorialSettings.tutorialSettings.onetimePlan.name}
  />
  <InputNumberBox
    label="Total Monthly Plan Cost"
    bind:value={tutorialSettings.tutorialSettings.onetimePlan.totalCost}
  />
  <InputNumberBox
    label="Number of bins included in Monthly Plan"
    bind:value={tutorialSettings.tutorialSettings.onetimePlan
      .numberOfBinsIncluded}
  />
{/if}

<br /><br />
<div class="right">
  <ButtonSave text="Finish and Save" callback={handleSaveBillingPlans} />
</div>

<style>
  .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
</style>
