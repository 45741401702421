<script>
  import { Offcanvas } from "sveltestrap";
  import ButtonCancel from "../../../Lib/Buttons/ButtonCancel.svelte";
  import ButtonLink from "../../../Lib/Buttons/ButtonLink.svelte";
  import StripeSettings from "./Controls/StripeSettings.svelte";

  let isOpen = false;
  const openScreen = () => (isOpen = !isOpen);
</script>

<div class="row table-row">
  <div class="col">
    <div class="fw-bold">
      <ButtonLink text="Stripe Settings" callback={openScreen} />
    </div>
  </div>
</div>

<Offcanvas
  header="Stripe Integration Settings"
  {isOpen}
  toggle={openScreen}
  placement="end"
>
  <StripeSettings />
  <br />
  <hr />
  <ButtonCancel text="Close" callback={openScreen} />
</Offcanvas>
