<script>
  import ButtonAdd from "../../../Lib/Buttons/ButtonAdd.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import { Link, navigate } from "svelte-navigator";
  import { onMount } from "svelte";

  let templates;

  let urls = {
    getAll: `/api/Company/NotificationTemplate/templates`,
  };

  function addTemplate() {
    navigate("templates/new");
  }

  onMount(async () => {
    ApiServer.Get(urls.getAll).then((data) => {
      templates = data;
    });
  });
</script>

<div class="float-end">
  <ButtonAdd text="Add Template" callback={addTemplate} />
</div>
<br />
<h4>SMS and Email Templates</h4>
<article class="card-body">
  <div class="row table-row-header">
    <div class="col">Edit</div>
    <div class="col">Method</div>
    <div class="col">Type</div>
    <div class="col">Used By</div>
  </div>
  {#if templates}
    {#each templates as template}
      <div class="row table-row">
        <div class="col">
          <Link to={template.id}>{template.name}</Link>
        </div>
        <div class="col">
          {template.templateMethod}
        </div>
        <div class="col">
          {template.notificationType}
        </div>
        <div class="col">
          Used by {template.usedBy} Reminders
        </div>
      </div>
    {/each}
  {/if}
</article>

<style>
  .table-row {
    padding: 3px;
    margin: 2px;
    border-bottom: 1px solid lightgray;
  }

  .table-row-header {
    font-size: 16px;
    font-weight: 700;
    padding: 3px;
    margin: 2px;
    border-bottom: 1px solid gray;
  }
</style>
