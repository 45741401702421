<script>
  import Header from "../Header.svelte";
  import BillingSettings from "./_BillingSettings.svelte";
  import JobOptions from "./JobOptions.svelte";
  import StripeSettings from "./StripeSettings.svelte";
  import TimeZoneSettings from "./_TimeZoneSettings.svelte";
  import EmailSettings from "./_EmailSettings.svelte";
  import CompanyBranding from "./_CompanyBranding.svelte";
  import GeneralSettings from "./_GeneralSettings.svelte";
  import NextDaySettings from "./_NextDaySettings.svelte";
  import NotificationPhoneNumber from "./_NotificationPhoneNumber.svelte";
  import TaxSettings from "./_TaxSettings.svelte";

  function openStripeSettings() {}
</script>

<Header text="Company Settings" />

<div class="row justify-content-center">
  <div class="col-sm-12">
    <article class="card-body">
      <div class="row table-row-header">
        <div class="col">Settings</div>
      </div>

      <div class="row table-row">
        <div class="col">
          <div class="setting-text">
            <CompanyBranding />
          </div>
        </div>
      </div>

      <div class="row table-row">
        <div class="col">
          <div class="setting-text">
            <StripeSettings />
          </div>
        </div>
      </div>
      <div class="row table-row">
        <div class="col">
          <div class="setting-text">
            <BillingSettings />
          </div>
        </div>
      </div>
      <div class="row table-row">
        <div class="col">
          <div class="setting-text">
            <TaxSettings />
          </div>
        </div>
      </div>
      <div class="row table-row">
        <div class="col">
          <div class="setting-text">
            <EmailSettings />
          </div>
        </div>
      </div>
      <div class="row table-row">
        <div class="col">
          <div class="setting-text">
            <JobOptions />
          </div>
        </div>
      </div>

      <div class="row table-row">
        <div class="col">
          <div class="setting-text">
            <TimeZoneSettings />
          </div>
        </div>
      </div>

      <div class="row table-row">
        <div class="col">
          <div class="setting-text">
            <GeneralSettings />
          </div>
        </div>
      </div>

      <div class="row table-row">
        <div class="col">
          <div class="setting-text">
            <NextDaySettings />
          </div>
        </div>
      </div>

      <div class="row table-row">
        <div class="col">
          <div class="setting-text">
            <NotificationPhoneNumber />
          </div>
        </div>
      </div>
    </article>
  </div>
</div>

<style>
  .table-row {
    padding: 3px;
    margin: 2px;
    border-bottom: 1px solid lightgray;
  }

  .table-row-header {
    font-size: 16px;
    font-weight: 700;
    padding: 3px;
    margin: 2px;
    border-bottom: 1px solid gray;
  }
  .setting-text {
    display: flex;
    align-items: stretch;
  }
</style>
