const isProduction = () => {
  let hostName = getHostName();
  return hostName != "localhost";
};

function getHostName() {
  if (typeof window !== "undefined" && window.document !== undefined) {
    // check production hostname
    if (window?.location.hostname !== undefined) {
      return window?.location.hostname.toLowerCase();
    }
  }
  return "localhost";
}

export const apiUrl = isProduction()
  ? "https://api.binbird.io"
  : "http://localhost:58011";
//: "https://localhost:44344";

export const googleStreetViewAPIKey = "AIzaSyB6XYIfpCd_THowWl9kvsaVNzFS58yPO2M";

export const mapboxAPIKey =
  "pk.eyJ1IjoiYnJldHRjaGUiLCJhIjoiY2t4ODRrdTBiMGlsbjJ2bng1NnlheDlhcSJ9.fGEDATHv8cpgzeZ7OovsrQ";

export const tinyApiKey = "6xsuuc2sx409t2ffw553im7fr68wngmjcrtrixmnnbhfno0x";
