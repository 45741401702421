<script>
  import { onMount } from "svelte";
  import { createEventDispatcher } from "svelte";
  import { portaluser } from "../../Stores/PortalUser";

  const dispatch = createEventDispatcher();

  import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
  } from "sveltestrap";

  import UserIcon from "../../Lib/Icons/UserIcon.svelte";
  import ButtonLink from "../../Lib/Buttons/ButtonLink.svelte";
  import PortalApiServer from "../../Server/PortalApiServer";
  import { toastNotification } from "../../Stores/toastNotification";
  import PortalUserStore from "../../Stores/PortalUserStore";
  let urls = {
    portalAccounts: "/api/portal/portal/getportalaccounts?email=",
  };

  let userLogin;

  function onLogout() {
    dispatch("logout");
  }

  function handleAccountChange(accountIndex) {
    ArrayMove(userLogin.portalAccounts, accountIndex, 0);
    PortalUserStore.updateLogin(userLogin);
  }

  function ArrayMove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  function handleCurrentAccountClick() {
    toastNotification.Error("You are already viewing this account.");
  }

  onMount(() => {
    PortalApiServer.Get(urls.portalAccounts + $portaluser.email).then(
      (data) => {
        userLogin = data;
      }
    );
  });
</script>

<div>
  <Dropdown>
    <DropdownToggle caret>
      <UserIcon />
      {$portaluser.fullName}
    </DropdownToggle>
    <DropdownMenu>
      {#if userLogin}
        {#each userLogin.portalAccounts as account, i}
          <DropdownItem>
            {#if account.accountID.toLowerCase() == $portaluser.accountID.toLowerCase()}
              <ButtonLink
                text="{account.accountName} - {account.address} (current)"
                callback={() => handleCurrentAccountClick()}
              />
            {:else}
              <ButtonLink
                text="{account.accountName} - {account.address}"
                callback={() => handleAccountChange(i)}
              />
            {/if}
          </DropdownItem>
        {/each}
      {/if}

      <DropdownItem divider />
      <DropdownItem>
        <ButtonLink text="Sign out" callback={onLogout} />
      </DropdownItem>
    </DropdownMenu>
  </Dropdown>
</div>

<style>
  :global(button.dropdown-toggle) {
    text-transform: none;
  }
</style>
