<script>
  import { onMount } from "svelte";
  import { Offcanvas } from "sveltestrap";

  import ButtonCancel from "../../../Lib/Buttons/ButtonCancel.svelte";
  import ButtonLink from "../../../Lib/Buttons/ButtonLink.svelte";
  import { toastNotification } from "../../../Stores/toastNotification";
  import ApiServer from "../../../Server/ApiServer";
  import ButtonSave from "../../../Lib/Buttons/ButtonSave.svelte";
  import NextDaySettingData from "./_NextDaySettingData";

  let isOpen = false;
  let cleaningDays;

  let urls = {
    get: "/api/Company/settings/CleaningDay",
    update: "/api/Company/settings/CleaningDay",
  };

  const openScreen = () => (isOpen = !isOpen);

  function saveSettings() {
    ApiServer.Post(urls.update, cleaningDays).then((data) => {
      toastNotification.Success("Cleaning Days saved!");
      isOpen = false;
    });
  }
  onMount(async () => {
    ApiServer.Get(urls.get).then((data) => {
      cleaningDays = data;
    });
  });
</script>

<ButtonLink
  text="Cleaning Day Settings (Next Day Cleaning)"
  callback={openScreen}
/>

<Offcanvas
  header="Cleaning Day Settings"
  {isOpen}
  toggle={openScreen}
  placement="end"
>
  <p>Use the settings below to handle your cleaning days.</p>
  <p>
    Cleaning Days can be different than pickup (trash/recycling) days. If for
    example you do next day cleaning.
  </p>

  <div class="row">
    {#if cleaningDays}
      <div class="col">
        <strong>Pickup Day</strong>

        {#each cleaningDays as day}
          <select class="form-control" bind:value={day.pickupDay}>
            {#each NextDaySettingData.DaysOfWeek as d}
              <option value={d.id}>{d.text}</option>
            {/each}
          </select>
        {/each}
      </div>

      <div class="col">
        <strong>Cleaning Day</strong>
        {#each cleaningDays as day}
          <select class="form-control" bind:value={day.cleaningDay}>
            {#each NextDaySettingData.DaysOfWeek as d}
              <option value={d.id}>{d.text}</option>
            {/each}
          </select>
        {/each}
      </div>
    {/if}
  </div>

  <p>
    <ButtonCancel text="Close" callback={openScreen} />
    <ButtonSave text="Save" callback={saveSettings} />
  </p>
</Offcanvas>
