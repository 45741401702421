<script>
  import Datepicker from "svelte-calendar";

  import { onMount } from "svelte";

  import ArrowLeftIcon from "../Lib/Icons/ArrowLeftIcon.svelte";
  import ArrowRightIcon from "../Lib/Icons/ArrowRightIcon.svelte";
  import { user } from "../Stores/stores";
  import PayIcon from "../Lib/Icons/BillingIcon.svelte";
  import Money from "../Lib/Money.svelte";
  import ApiServer from "../Server/ApiServer";
  import UTCDate from "../Util/UTCDate";
  import FailedPaymentIcon from "../Lib/Icons/FailedPaymentIcon.svelte";
  import ButtonIcon from "../Lib/Buttons/ButtonIcon.svelte";
  import ButtonConfirmAction from "../Lib/Buttons/ButtonConfirmAction.svelte";
  import ProgressLabel from "../Lib/ProgressLabel.svelte";
  import PageTitle from "../Lib/PageTitle.svelte";
  import { toastNotification } from "../Stores/toastNotification";

  const urlParams = new URLSearchParams(window.location.search);
  const url = new URL(window.location);

  let urls = {
    getBilling: "/api/Billing/billing",
    accountPayment: "/api/Billing/accountpayment",
    bulkaccountPayment: "/api/Billing/bulkaccountpayment",
  };

  let billingListResponse;
  let selectAll = false;
  let loading = false;
  let paymentResultMessage;

  let routeDate;
  let formattedRouteDate = "";

  //Date Picker Dates:
  let routeDateDatePicker;
  //https://github.com/6eDesign/timeUtils (So dumb)
  let routeDateFormat = "#{D} #{m}/#{d}/#{Y}";

  function getStartingDate() {
    const urlDate = urlParams.get("date");
    if (urlDate) {
      routeDate = UTCDate.ToDate(urlDate);
    } else {
      routeDate = UTCDate.TodayAsDate();
      updateDateInUrl();
    }
  }

  function getBilling() {
    selectAll = false;
    paymentResultMessage = null;
    //When date picker changes set url date again
    updateDateInUrl();
    formattedRouteDate = UTCDate.ParseForDatePicker(routeDate);

    let request = {
      startDate: formattedRouteDate,
      endDate: formattedRouteDate,
      filter: parseInt(filter),
    };

    loading = true;
    ApiServer.Post(urls.getBilling, request).then((data) => {
      billingListResponse = data;
      loading = false;
    });
  }

  function nextDay() {
    routeDate = UTCDate.AddDays(1, routeDate);
    updateDateInUrl();
  }

  function updateDateInUrl() {
    let newUrlDate = UTCDate.ParseForDatePicker(routeDate);
    url.searchParams.set("date", newUrlDate);
    window.history.pushState(null, "", url.toString());
  }

  function prevDay() {
    routeDate = UTCDate.AddDays(-1, routeDate);
    updateDateInUrl();
  }

  async function chargeCheckedJobs() {
    let paymentRequestList = [];
    for (let i = 0; i < billingListResponse.data.length; i++) {
      const job = billingListResponse.data[i];

      if (!job.selected) {
        continue;
      }

      let paymentRequest = {
        accountID: job.accountID,
        jobID: job.id,
        userName: $user.fullName,
        setPaidNoCharge: false,
      };

      paymentRequestList.push(paymentRequest);
    }

    if (paymentRequestList.length == 0) {
      alert("Please select at least 1 job.");
      return;
    }

    loading = true;

    let request = {
      paymentRequests: paymentRequestList,
      billingRunDate: formattedRouteDate,
    };

    let accountPaymentResult = await ApiServer.Post(
      urls.bulkaccountPayment,
      request
    );

    if (accountPaymentResult.success) {
      paymentResultMessage = accountPaymentResult.message;
      toastNotification.Success(paymentResultMessage);
    } else {
      paymentResultMessage =
        "Bulk payment request failed. " + accountPaymentResult.message;
      toastNotification.Error(paymentResultMessage);
    }
    loading = false;
  }

  function selectAllJobs() {
    billingListResponse.data.forEach((item) => {
      item.selected = selectAll;
    });
    billingListResponse.data = billingListResponse.data;
  }

  function applyFilter(type) {
    url.searchParams.set("tab", type);
    window.history.pushState(null, "", url.toString());
    filter = type;
    getBilling();
  }

  let filter = urlParams.get("tab") ? urlParams.get("tab") : 1;

  // //Set tab on page load, if exists.
  // const urlTab = ;
  // if (urlTab) filter = urlTab;

  $: routeDate, getBilling();

  getStartingDate();
</script>

<PageTitle title="Billing" />

<div class="card">
  <div class="card-body ">
    <div class="card-title">
      <h3><PayIcon /> Billing</h3>
    </div>
    <div class="card-text">
      <!-- <div class="alert alert-info" role="alert">
        The billing process can be automated by setting up Automatic Billing on
        your Company page :-)
      </div> -->

      <div class="row">
        <div class="col">
          <Datepicker
            format={routeDateFormat}
            bind:selected={routeDate}
            bind:formattedSelected={routeDateDatePicker}
          >
            <button
              style="width: 142px; margin-bottom: 3px"
              type="button"
              id="job.jobDate"
              class="btn btn-sm btn-primary"
            >
              {routeDateDatePicker}
            </button>
          </Datepicker>
          <div>
            <ButtonIcon
              text="Prev"
              icon={ArrowLeftIcon}
              style="btn-outline-primary"
              callback={prevDay}
            />

            <ButtonIcon
              text="Next"
              icon={ArrowRightIcon}
              callback={nextDay}
              iconLeft={false}
            />
            <!--Loading Indicator-->
            {#if loading}
              <div style="font-size: 25px">
                <ProgressLabel text="Loading..." />
              </div>
            {/if}
            {#if paymentResultMessage}
              <div style="font-size: 18px">
                {paymentResultMessage}
              </div>
            {/if}
          </div>
          <div style="width: 142px; margin-top: 3px" />
        </div>
        <div class="col">
          <div class="float-end">
            {#if billingListResponse}
              <div>
                Billable Jobs: <strong
                  >{billingListResponse.billableCount}
                </strong>
              </div>
              <div>
                Total:
                <strong>
                  <Money amount={billingListResponse.billableTotal} />
                </strong>
              </div>
              <div>
                Avg:
                <strong>
                  <Money amount={billingListResponse.averageCostPerJob} />
                </strong>
                /Job
              </div>
              <div>
                <ButtonConfirmAction
                  text="Charge Selected"
                  callback={chargeCheckedJobs}
                  tooltip="Charge selected jobs"
                  warningText="You are about to charge all selected jobs. If there are 10 or less you can wait for them to finish. If there are more than 10 we will send a status email when they are complete. Are you sure?"
                  buttonClass="btn btn-success"
                />
              </div>
            {/if}
          </div>
        </div>
      </div>
      <!--header-->
      {#if billingListResponse}
        <!--Menu-->
        <div class="row">
          <div class="col">
            <div class="same-line">
              <div id="button-menu">
                <div id="action-menu">
                  <span
                    on:click={() => applyFilter(1)}
                    class:selected={filter == 1}
                    class="filter"
                  >
                    Not Paid
                    <span class="badge bg-info"
                      >{billingListResponse.notPaidCount}</span
                    >
                  </span>
                  <span
                    on:click={() => applyFilter(2)}
                    class:selected={filter == 2}
                    class="filter"
                  >
                    Paid
                    <span class="badge bg-info">
                      {billingListResponse.paidCount}
                    </span>
                  </span>
                  <span
                    on:click={() => applyFilter(3)}
                    class:selected={filter == 3}
                    class="filter"
                  >
                    Payment Failed
                    <span class="badge bg-info">
                      {billingListResponse.paymentFailedCount}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Job Grid-->
        <div class="row bb-header">
          <div class="col">
            <input
              type="checkbox"
              class="form-check-input"
              bind:value={selectAll}
              on:change={(e) => {
                selectAll = e.target.checked;
                selectAllJobs();
              }}
            />
          </div>
          <div class="col">Account</div>
          <div class="col">Total Cost</div>
          <div class="col">Status</div>
        </div>
        {#each billingListResponse.data as item}
          <div class="bb-row row">
            <div class="col">
              <input
                type="checkbox"
                class="form-check-input"
                bind:checked={item.selected}
              />
              Job
              <a href="/account/{item.accountID}/jobs/{item.id}"
                >{item.jobNumber}</a
              >
            </div>

            <div class="col">
              <a href="/account/{item.accountID}">{item.accountName}</a>
            </div>

            <div class="col">
              {#if item.paymentResult}
                <span color="red"><FailedPaymentIcon /></span>
                {item.paymentResult.message}
              {/if}

              <Money amount={item.total} />
              {#if item.hasAccountBalance}
                <div title="Customer has balance due of ${item.accountBalance}">
                  <small>
                    <FailedPaymentIcon />
                    Past Due balance <Money amount={item.accountBalance} />
                  </small>
                </div>
              {/if}
            </div>

            <div class="col">{item.status}</div>
          </div>
        {/each}
      {/if}
    </div>
  </div>
</div>

<style>
  .same-line {
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
  }
</style>
