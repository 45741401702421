<script>
  export let title;
  export let icon = null;
</script>

<div class="card">
  <div class="card-body">
    <div class="card-title">
      <h3>
        {#if icon}
          <svelte:component this={icon} />
        {/if}
        {title}
      </h3>
    </div>
    <div class="card-text">
      <slot />
    </div>
  </div>
</div>

<style>
  .card {
    height: 215px;
  }
</style>
