<script>
  export let jobID;
  export let accountID;

  import { onMount } from "svelte";
  // import * as Env from "../../../../Constant/Environment";
  import PrivateRoute from "../../../../Lib/Authentication/PrivateRoute.svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import JobDetail from "./JobDetail.svelte";
  import JobNotes from "./JobNotes.svelte";
  import JobImages from "./JobImages.svelte";
  import JobLog from "./JobLog.svelte";
  import JobBillingLog from "../../../Billing/BillingLog.svelte";
  import JobHeader from "./JobHeader.svelte";
  import JobLogIcon from "../../../../Lib/Icons/JobLogIcon.svelte";
  import NotesIcon from "../../../../Lib/Icons/NotesIcon.svelte";
  import AttachmentIcon from "../../../../Lib/Icons/AttachmentIcon.svelte";
  import BillingIcon from "../../../../Lib/Icons/BillingIcon.svelte";
  import JobIcon from "../../../../Lib/Icons/JobIcon.svelte";
  import JobReminders from "./_JobReminders.svelte";
  import ReminderIcon from "../../../../Lib/Icons/ReminderIcon.svelte";

  let job = null;

  let urls = {
    getJob: `/api/Job/job?jobID=${jobID}`,
  };

  function handleRefreshJob() {
    getJob();
  }

  function getJob() {
    ApiServer.Get(urls.getJob).then((data) => {
      job = data;
    });
  }

  onMount(async () => {
    if (jobID == "new") {
      job = {};
      job.id = "new";
    } else {
      getJob();
    }
  });

  let navigateAction = "job";
</script>

{#if job}
  <PrivateRoute path="" roles={["Admin"]}>
    <div class="card">
      <div class="card-body">
        <div class="card-title">
          <JobHeader bind:action={navigateAction} {job} />
        </div>

        <div class="card-text">
          {#if job.id != "new"}
            <div id="button-menu">
              <div id="action-menu">
                <span
                  on:click={() => (navigateAction = "job")}
                  class:selected={navigateAction == "job"}
                  class="filter"
                >
                  <JobIcon /> Job
                </span>
                <span
                  on:click={() => (navigateAction = "billing")}
                  class:selected={navigateAction == "billing"}
                  class="filter"
                >
                  <BillingIcon /> Billing
                </span>
                <span
                  on:click={() => (navigateAction = "images")}
                  class:selected={navigateAction == "images"}
                  class="filter"
                >
                  <AttachmentIcon /> Images
                </span>
                <span
                  on:click={() => (navigateAction = "notes")}
                  class:selected={navigateAction == "notes"}
                  class="filter"
                >
                  <NotesIcon /> Notes
                </span>
                <span
                  on:click={() => (navigateAction = "joblog")}
                  class:selected={navigateAction == "joblog"}
                  class="filter"
                >
                  <JobLogIcon /> Job Log
                </span>
                <span
                  on:click={() => (navigateAction = "reminders")}
                  class:selected={navigateAction == "reminders"}
                  class="filter"
                >
                  <ReminderIcon /> Reminders
                </span>
              </div>
            </div>
          {/if}

          {#if navigateAction == "joblog"}
            <JobLog {job} />
          {:else if navigateAction == "images"}
            <JobImages {job} />
          {:else if navigateAction == "notes"}
            <JobNotes {job} />
          {:else if navigateAction == "billing"}
            <JobBillingLog id={job.id} type="job" />
          {:else if navigateAction == "reminders"}
            <JobReminders id={job.id} />
          {:else}
            <JobDetail {job} {accountID} />
          {/if}
        </div>
        <!-- <img
          src="{Env.apiUrl}/api/public/stuff/jobwatermark?jobID={job.id}"
          alt="what"
        /> -->
      </div>
    </div>
  </PrivateRoute>
{/if}
