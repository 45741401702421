export default class JobTemplateData {
  static Months = [
    { id: "", text: "--" },
    { id: "1", text: "Jan" },
    { id: "2", text: "Feb" },
    { id: "3", text: "Mar" },
    { id: "4", text: "Apr" },
    { id: "5", text: "May" },
    { id: "6", text: "Jun" },
    { id: "7", text: "Jul" },
    { id: "8", text: "Aug" },
    { id: "9", text: "Sep" },
    { id: "10", text: "Oct" },
    { id: "11", text: "Nov" },
    { id: "12", text: "Dec" },
  ];

  static DaysOfWeek = [
    { id: "", text: "--" },
    { id: "SU", text: "Sunday" },
    { id: "MO", text: "Monday" },
    { id: "TU", text: "Tuesday" },
    { id: "WE", text: "Wednesday" },
    { id: "TH", text: "Thursday" },
    { id: "FR", text: "Friday" },
    { id: "SA", text: "Saturday" },
  ];

  static SetPositionByName = [
    { id: "", text: "--" },
    { id: "first", text: "First" },
    { id: "second", text: "Second" },
    { id: "third", text: "Third" },
    { id: "fourth", text: "Fourth" },
  ];

  static SetPositionByDay = [
    { id: "1", text: "1st" },
    { id: "2", text: "2nd" },
    { id: "3", text: "3rd" },
    { id: "4", text: "4th" },
    { id: "5", text: "5th" },
    { id: "6", text: "6th" },
    { id: "7", text: "7th" },
    { id: "8", text: "8th" },
    { id: "9", text: "9th" },
    { id: "10", text: "10th" },
    { id: "11", text: "11th" },
    { id: "12", text: "12th" },
    { id: "13", text: "13th" },
    { id: "14", text: "14th" },
    { id: "15", text: "15th" },
    { id: "16", text: "16th" },
    { id: "17", text: "17th" },
    { id: "18", text: "18th" },
    { id: "19", text: "19th" },
    { id: "20", text: "20th" },
    { id: "21", text: "20st" },
    { id: "22", text: "22nd" },
    { id: "23", text: "23rd" },
    { id: "24", text: "24th" },
    { id: "25", text: "25th" },
    { id: "26", text: "26th" },
    { id: "27", text: "27th" },
    { id: "28", text: "28th" },
    { id: "29", text: "29th" },
    { id: "30", text: "30th" },
    { id: "31", text: "31st" },
  ];
}
