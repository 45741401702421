<script>
  export let job;

  import DateFormat from "../../../../Lib/DateFormat.svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import { onMount } from "svelte";

  let logs = null;

  let urls = {
    getJobLogs: `/api/Job/joblogs?jobID=`,
    getJob: `/api/Job/job?jobID=`,
  };

  onMount(async () => {
    ApiServer.Get(urls.getJobLogs + job.id).then((data) => {
      logs = data;
    });
  });
</script>

{#if job}
  <p>Shows the log of events created by the techntions in the map.</p>

  {#if logs}
    <div class="row table-row-header">
      <div class="col">Date</div>
      <div class="col">Description</div>
      <div class="col">User</div>
    </div>
    {#each logs as log}
      <div class="row table-row">
        <div class="col">
          <DateFormat formatType="date-time-short" date={log.logDate} />
        </div>
        <div class="col">{log.logText}</div>
        <div class="col"><strong>{log.userName}</strong></div>
      </div>
    {/each}
  {/if}
{/if}

<style>
  .table-row {
    padding: 3px;
    margin: 2px;
    border-bottom: 1px solid lightgray;
  }

  .table-row-header {
    font-size: 16px;
    font-weight: 700;
    padding: 3px;
    margin: 2px;
    border-bottom: 1px solid gray;
  }
</style>
