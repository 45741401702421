<script>
  export let job;
  import ImageGallary from "../../../Lib/Images/ImageGallary.svelte";

  let urls = {
    getJobImages: `/api/Job/images?jobID=`,
  };

  let imageSize = { h: 100, w: 100 };
</script>

<hr />

<div class="primary-nav">
  <div><h4>Job Images</h4></div>
</div>

<div class="row">
  <div class="col">
    <ImageGallary
      relatedID={job.id}
      fetchImagesURL={urls.getJobImages}
      size={imageSize}
      folder="Jobs"
    />
  </div>
</div>

<style>
  .primary-nav {
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
</style>
