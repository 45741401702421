<script>
  import { afterUpdate, onMount } from "svelte";
  import Modal from "sv-bootstrap-modal";
  import { link, navigate } from "svelte-navigator";

  import { BillingStatusStore } from "../Stores/BillingStatusStore";
  import ButtonCancel from "../Lib/Buttons/ButtonCancel.svelte";
  import ButtonAdd from "../Lib/Buttons/ButtonAdd.svelte";
  import ButtonSimple from "../Lib/Buttons/ButtonSimple.svelte";
  import CreditCardInput from "../Controls/Profile/CreditCardInput.svelte";
  import UserStore from "../Stores/UserStore";

  let showModal = false;
  let showAddCreditCard = false;
  let allowCloseModal = true;

  function handlePaymentMethodSaved() {
    location.reload();
  }

  function handlePaymentMethodRemoved() {
    location.reload();
  }

  function handleSignout() {
    UserStore.logoff();
    navigate("/");
    location.reload();
  }

  afterUpdate(() => {
    if (
      $BillingStatusStore?.inTrial &&
      $BillingStatusStore?.daysRemainingInTrial < 10
    ) {
      showModal = true;
    } else if ($BillingStatusStore?.status == "Missing Payment Method") {
      showModal = true;
      allowCloseModal = false;
    } else if ($BillingStatusStore?.status == "Past Due") {
      showModal = true;
      allowCloseModal = true;
    } else if ($BillingStatusStore?.trialExpired) {
      allowCloseModal = false;
      showModal = true;
    }
  });
</script>

{#if $BillingStatusStore}
  <Modal bind:open={showModal} ignoreBackdrop={true} keyboard={false}>
    <div class="modal-header">
      <h5 class="modal-title">Account Status: {$BillingStatusStore?.status}</h5>
      {#if allowCloseModal}
        <button
          type="button"
          class="close"
          on:click={() => (showModal = false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      {/if}
    </div>
    <div class="modal-body">
      {#if $BillingStatusStore.trialExpired}
        <p>
          Oh No! It looks like your trial has expired by {$BillingStatusStore.daysRemainingInTrial}
          days.
        </p>

        <p>
          If you have ran into a snag and need some help please reach out. We
          would love to schedule an <strong>25-minute call</strong> with a
          <strong>Bin Bird Coach</strong>.
        </p>

        <p>
          If you wish to continue using Bin Bird please click the link below,
          update your credit card. Once you have a credit card on file Bin Bird
          access will be restored.
        </p>

        <div class="col d-flex" style="justify-content: flex-end;">
          <ButtonAdd
            text="Add Credit Card"
            callback={() => (showAddCreditCard = true)}
          />
          <ButtonSimple text="Sign Out" callback={handleSignout} />
        </div>

        <br />
      {:else if $BillingStatusStore?.inTrial}
        <p>
          We hope you LOVE Bin Bird and its helped you solve some of the issues
          you are facing as a bin cleaner.
        </p>
        <p>
          This is a reminder that you have <strong>
            {$BillingStatusStore.daysRemainingInTrial}
          </strong>days left of your trail period.
        </p>

        <br />

        <p>
          If you have ran into a snag and need some help please reach out. We
          would love to schedule an <strong>25-minute call</strong> with a
          <strong>Bin Bird Coach</strong>.
        </p>
        <p>
          You can also make it official by adding your credit card. Click the
          link below to add your credit card to the system.
        </p>
        <p>
          <ButtonAdd
            text="Add Credit Card"
            callback={() => (showAddCreditCard = true)}
          />
        </p>
        <br />
      {:else if $BillingStatusStore?.status == "Missing Payment Method"}
        <p>
          Oh No! It looks like we are missing a credit card on file. Please
          update your credit card to continue using Bin Bird.
        </p>

        <p>If you need help please reach out and we can give you a hand.</p>
        <p>
          <ButtonAdd
            text="Add Credit Card"
            callback={() => (showAddCreditCard = true)}
          />
        </p>
        <br />
      {:else if $BillingStatusStore?.status == "Past Due"}
        <p>
          Oh No! It looks like your account is past due! Please update your
          credit card, make a payment or give us a call to restore access to
          your account.
        </p>

        <p>If you need help please reach out and we can give you a hand.</p>
        <p>
          <ButtonAdd
            text="Add Credit Card"
            callback={() => (showAddCreditCard = true)}
          />
        </p>
        <p><a href="/systembilling" use:link> Review your invoices</a></p>
        <br />
      {/if}
    </div>

    <div class="modal-footer">
      {#if allowCloseModal}
        <ButtonCancel text="Close" callback={() => (showModal = false)} />
      {/if}
    </div>
  </Modal>
{/if}

<CreditCardInput
  bind:showPaymentMethods={showAddCreditCard}
  showAddNewCard={true}
  on:paymentMethodAdded={handlePaymentMethodSaved}
  on:paymentMethodRemoved={handlePaymentMethodRemoved}
/>
