<script>
  export let checkoutRules;
  import Checkbox from "../../../Lib/Inputs/InputCheckbox.svelte";
</script>

<div class="row">
  <div class="col">
    <h4>Auto Approve Signups</h4>
    <p>
      Do you want Bin Bird to automatically approve all signups? Bin Bird will
      pick the date of their first cleaning and if the plan is recurring Bin
      Bird will automatically create the recurring templates for the customer.
    </p>
  </div>
  <div class="col">
    <Checkbox
      label="Automatcially approve new sign ups"
      text="Auto Approve?"
      bind:value={checkoutRules.autoApproveJob}
    />
  </div>
</div>
