<script>
  /*This is the JobID or AccountID that the Image will be attached.*/
  export let relatedID;
  export let fetchImagesURL;
  export let size = { h: 200, w: 200 };

  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();

  import ApiServer from "../../Server/ApiServer";
  import CheckIcon from "../Icons/CheckIcon.svelte";

  let imagesList;
  let selectedImages = [];

  function fetchImages() {
    ApiServer.Get(fetchImagesURL + relatedID).then((result) => {
      imagesList = result || [];

      for (let i = 0; i < imagesList.length; i++) {
        const img = imagesList[i];
        img.src = img.sourceURL;
        img.selected = false;
      }
    });
  }

  function handleImageSelect(img) {
    img.selected = !img.selected;

    if (img.selected) {
      selectedImages.push(img);
    } else {
      selectedImages.splice(selectedImages.indexOf(img), 1);
    }
    imagesList = imagesList;
    dispatch("imageSelectionChange", selectedImages);
  }

  $: relatedID, fetchImages();
</script>

{#if imagesList}
  {#each imagesList as img}
    <div class="row">
      <div class="col">
        <img
          class:img-selected={img.selected}
          style="height:{size.h}px; width:{size.w}px"
          src={img.sourceURL}
          alt=""
          on:click={() => handleImageSelect(img)}
        />
      </div>
      <div class="col">
        {#if img.selected}
          <div style="font-size:28px">
            <CheckIcon />
          </div>
        {/if}
      </div>
    </div>
  {/each}
{/if}

<style>
  img {
    width: 100%;
    height: auto !important;
    cursor: pointer;
    border-radius: 10px;
  }
  .img-selected {
    opacity: 0.5;
    filter: grayscale(0.5) blur(1px);
  }

  /* img:hover {
    opacity: 0.5;
    filter: grayscale(0.5) blur(1px);
  } */
</style>
