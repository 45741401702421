import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

export default class UTCDate {
  static UtcNow() {
    return dayjs().utc().toDate();
  }

  static UtcNowAsDayJS() {
    return dayjs().utc();
  }

  static HoursDifference(date1, date2) {
    var start = dayjs(date1);
    console.log(start);
    var end = dayjs(date2);
    console.log(end);
    var diff = start.diff(end, "hour");
    console.log(diff);
    return diff;
  }

  static Today() {
    return dayjs().format("MM/DD/YYYY");
  }

  static TodayAsDate() {
    return dayjs().toDate();
  }

  static Today(format) {
    if (!format) format = "YYYY-MM-DD";
    return dayjs().format(format);
  }

  static AsDate(date) {
    return dayjs(date).format("MM/DD/YYYY");
  }

  static ToDate(dateStr) {
    return dayjs(dateStr).toDate();
  }

  static AddHours(hours, date) {
    return dayjs(date).hour(hours).toDate();
  }

  static AddDays(days, date) {
    return dayjs(date).add(days, "day").toDate();
  }

  static AddDaysStr(days, date) {
    return dayjs(date).add(days, "day").format("YYYY-MM-DD");
  }

  static Parse(strDate) {
    var d = dayjs(strDate).toDate();
    return d;
  }

  static ParseForDatePicker(strDate) {
    return dayjs(strDate).format("YYYY-MM-DD");
  }

  static GetTimezone() {
    return dayjs.tz.guess();
  }

  //No Timezone applied.
  static FormattedDate(date, magicFormatString) {
    let formattedDate = dayjs(date).format("MMM DD, YYYY");
    //let formattedDate = dayjs(date).format("MMM DD, YYYY hh:mm A");

    if (magicFormatString == "date-only") {
      formattedDate = dayjs(date).format("MMM DD, YYYY");
    } else if (magicFormatString == "date-short") {
      formattedDate = dayjs(date).format("M-D-YY");
    } else if (magicFormatString == "date-long") {
      formattedDate = dayjs(date).format("dddd MMM DD, YYYY");
    } else if (magicFormatString == "date-time") {
      formattedDate = dayjs(date).format("MMM DD YYYY hh:mm A");
    } else if (magicFormatString == "date-time-short") {
      formattedDate = dayjs(date).format("M-D-YY hh:mm A");
    } else if (magicFormatString == "timeago") {
      formattedDate = dayjs(date).fromNow();
    } else if (magicFormatString == "tonow") {
      formattedDate = dayjs(date).toNow(true);
    }
    return formattedDate;
  }

  static GetTZDate(date, magicFormatString) {
    //Dirty dirty hack to get timezone conversion to work here.
    if (!date) {
      date = dayjs();
    } else if (!date.endsWith("Z")) {
      date = date + "Z";
    }

    let timeZoneGuess = dayjs.tz.guess();
    if (!timeZoneGuess) timeZoneGuess = "America/Los_Angeles";

    date = dayjs(date).tz(timeZoneGuess);

    return UTCDate.FormattedDate(date, magicFormatString);
  }

  static RRuleFormat(date) {
    //Takes a javascript date!
    //I have no idea what this format is called.  It is standard but don't know where to look it up.
    //It is used when creating the RRule for jobs.  it is NOT toISODate.

    let theDatePart = dayjs(date).format("YYYYMMDD");
    let theTimePart = dayjs(date).format("HHmmss");
    return `${theDatePart}T${theTimePart}Z`;
  }
}
