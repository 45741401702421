<script>
  export let text;
  import Back from "../../Lib/Buttons/Back.svelte";
</script>

<div class="primary-nav">
  <div><Back /></div>
  <div><h3>{text}</h3></div>
</div>

<style>
  .primary-nav {
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
  }
</style>
