<script>
  export let checkoutRules;
  import Editor from "@tinymce/tinymce-svelte";
  import { tinyApiKey } from "../../../Constant/Environment";

  let tinyInit = {
    height: 300,
  };
</script>

<div class="row">
  <div class="col">
    <h4>Checkout Success Message</h4>
    <p>
      After successfully purchasing their trash can cleaning through the
      checkout page, customers are shown this message.
    </p>
    <p>
      <strong>Pro-tip:</strong> Customize your Welcome Email to highlight the same
      things as this post checkout message. Saying the same thing twice shows consistency
      and leaves less room for confusion. Don't be afraid to over-communicate.
    </p>
  </div>
</div>
<div class="row">
  <div class="col">
    <div>
      <Editor
        conf={tinyInit}
        apiKey={tinyApiKey}
        bind:value={checkoutRules.checkoutSuccessMessage}
      />
    </div>
  </div>
</div>
