<script>
  export let account;

  import { onMount } from "svelte";
  import Fa from "svelte-fa";
  import {
    faPlusSquare,
    faWindowClose,
    faSave,
  } from "@fortawesome/free-solid-svg-icons";

  import Note from "./Note.svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import UserStore from "../../../../Stores/UserStore";

  let notes = [];
  let addNote = false;
  let noteText;
  let currentUser = UserStore.getUser();
  let currentNote = null;

  let urls = {
    notes: `/api/Account/Note/Notes?accountID=${account.id}`,
    addnote: `/api/Account/Note/note`,
    editNote: `/api/Account/Note/note`,
    deletenote: `/api/Account/Note/note?id=`,
  };

  function showAddNote() {
    addNote = true;
  }

  function handleNoteDelete(event) {
    var note = event.detail;
    ApiServer.Delete(urls.deletenote + note.id).then((result) => {
      if (result > 0) {
        notes.splice(notes.indexOf(note), 1);
        notes = notes;
        cancelAddNote();
      }
    });
  }

  function cancelAddNote() {
    noteText = "";
    addNote = false;
    currentNote = null;
  }

  function saveNote() {
    if (currentNote) {
      saveNoteEdit();
      return;
    }

    var note = {
      body: noteText,
      accountId: account.id,
      userName: currentUser.fullName,
    };
    ApiServer.Post(urls.addnote, note).then((data) => {
      note = data;
      notes = [note, ...notes];
      cancelAddNote();
    });
  }

  function loadEditNote(event) {
    currentNote = event.detail;
    noteText = currentNote.body;
    addNote = true;
  }

  function saveNoteEdit() {
    currentNote.body = noteText;
    ApiServer.Put(urls.editNote, currentNote).then((data) => {
      currentNote = data;
      //This sucks. Not sure how else to update the note text on the child componenet without loading all the notes again.
      fetchNotes();
      cancelAddNote();
    });
  }

  onMount(async () => {
    fetchNotes();
  });

  function fetchNotes() {
    ApiServer.Get(urls.notes).then((data) => {
      notes = data;
    });
  }
</script>

<div class="card">
  <div class="card-body ">
    <div class="card-title d-flex justify-content-between align-items-center">
      <h5>{account.fullName} Notes ({notes.length})</h5>
      <div class="large-icon">
        <button
          class="btn btn-outline-primary card-link float-right"
          on:click={showAddNote}
        >
          <Fa icon={faPlusSquare} />
          Add Note
        </button>
      </div>
    </div>
    <div class="card-text">
      {#if addNote}
        <div class="border-success">
          <p>
            <textarea class="form-control" bind:value={noteText} rows="2" />
          </p>
        </div>
        <div class="button-container">
          <button class="btn btn-outline-primary card-link" on:click={saveNote}>
            <Fa icon={faSave} />
            Save
          </button>

          <button
            class="btn btn-outline-danger card-link"
            on:click={cancelAddNote}
          >
            <Fa icon={faWindowClose} />
            Cancel
          </button>
        </div>
      {/if}

      {#if notes}
        {#each notes as note}
          <div class="notes">
            <Note
              {note}
              on:deleteNote={handleNoteDelete}
              on:loadEditNote={loadEditNote}
            />
          </div>
        {/each}
      {/if}
    </div>
  </div>
</div>

<style>
  div :global(.large-icon) {
    font-size: 20px;
  }
  .notes {
    margin-bottom: 3px;
  }
  .button-container {
    margin-bottom: 10px;
  }
</style>
