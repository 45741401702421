<script>
  export let showLocation;
  import { getContext, onMount, onDestroy } from "svelte";
  import { contextKey } from "@beyonk/svelte-mapbox/src/lib/components";
  import ApiServer from "../../Server/ApiServer";
  import UTCDate from "../../Util/UTCDate";

  const { getMap, getMapbox } = getContext(contextKey);
  const map = getMap();
  const mapbox = getMapbox();

  let employeeMarkers = [];
  let employees = [];
  let setIntervalGetEmployeeLocation;

  let urls = {
    employeeLocations: `/api/Company/User/mapusers`,
  };

  function removeMarkerLocations(markers) {
    //Remove existing employee locations
    if (!markers) return;
    markers.forEach((marker) => {
      marker.remove();
    });
  }

  function setMarkersByArray(employeeArray) {
    if (!map) return;
    if (!employeeArray) return;

    removeMarkerLocations(employeeMarkers);

    employeeMarkers = [];

    employeeArray.forEach((employee) => {
      makeMarker(employee);
    });
  }

  function makeMarker(employee) {
    let color = "#990000";
    let element = makePin(employee);
    const namedParams = {
      offset: [0, 0],
      color: color,
      element: element,
    };

    let m = new mapbox.Marker(namedParams);
    //So we can find it later...
    m.id = employee.id;
    let popupEl = makePopup(employee);
    m.setPopup(popupEl);
    m.setLngLat({ lat: employee.latitude, lng: employee.longitude });
    m.addTo(map);
    employeeMarkers.push(m);
  }

  function makePin(employee) {
    let markupTemplate = `<svg xmlns="http://www.w3.org/2000/svg" width="28px" height="36px">
        <path d="M 19 31 C 19 32.7 16.3 34 13 34 C 9.7 34 7 32.7 7 31 C 7 29.3 9.7 28 13 28 C 16.3 28 19 29.3 19 31 Z" fill="#000" fill-opacity=".2"/>
        <path d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z" fill="#fff"/>
        <path d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z" fill="#18d"/>
        <text x="13" y="19" font-size="12pt" font-weight="bold" text-anchor="middle" fill="#fff">${employee.initials}</text>
        </svg>`;

    var h1 = document.createElement("div");
    h1.innerHTML = markupTemplate;
    return h1;
  }

  function makePopup(employee) {
    const popupEl = new mapbox.Popup(Object.assign({ offset: 10 }));
    var h1 = document.createElement("div");
    h1.innerHTML =
      UTCDate.FormattedDate(employee.locationUpdateDate) +
      " - " +
      UTCDate.FormattedDate(employee.locationUpdateDate, "timeago") +
      " " +
      employee.fullName;
    popupEl.setDOMContent(h1);
    return popupEl;
  }

  function formatEmployeePopup(employee) {
    var dateLastSeen = employee.locationUpdateDate;
  }

  function updateMyCurrentPosition() {
    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition(
        setMyLocationOnServer,
        locationRequestFailed
      );
    else {
      locationRequestFailed(null);
    }
  }

  function locationRequestFailed(pos) {
    if (pos) {
      //get error message and let the user know.
      // console.log(pos);
    }
    setMyLocationOnServer(null);
  }

  function setMyLocationOnServer(pos) {
    var request = {
      latitude: 0,
      longitude: 0,
    };

    if (pos && showLocation) {
      request.latitude = pos.coords.latitude;
      request.longitude = pos.coords.longitude;
    }

    ApiServer.Put(urls.employeeLocations, request).then((data) => {
      employees = [];
      employees = data;
      setMarkersByArray(employees);
    });
  }

  onMount(async () => {
    //Load the employees location when we first load then every 10 seconds.
    updateMyCurrentPosition();

    setIntervalGetEmployeeLocation = setInterval(() => {
      updateMyCurrentPosition();
    }, 10000);
  });

  onDestroy(() => {
    clearInterval(setIntervalGetEmployeeLocation);
  });
</script>
