<script>
  export let note;

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import DateFormat from "../../../../Lib/DateFormat.svelte";
  import ButtonEdit from "../../../../Lib/Buttons/ButtonEdit.svelte";
  import ButtonDelete from "../../../../Lib/Buttons/ButtonDelete.svelte";

  function loadEditNote(note) {
    dispatch("loadEditNote", note);
  }

  function deleteCurrentNote(note) {
    if (note) {
      dispatch("deleteNote", note);
    }
  }
</script>

<div class="card border-secondary">
  {#if note.subject}
    <div class="card-header">{note.subject}</div>
  {/if}
  <div class="card-body">
    <h4 class="card-text">{note.body}</h4>
  </div>
  <div class="card-footer text-muted">
    <div class="float-left">
      {note.userName}: <DateFormat
        formatType="date-time"
        date={note.noteDate}
      />
      (<DateFormat formatType="timeago" date={note.noteDate} />)
    </div>

    <div class="float-right">
      <ButtonEdit
        text="Edit"
        smallButton={false}
        callback={() => loadEditNote(note)}
      />
      <ButtonDelete text="Delete" callback={() => deleteCurrentNote(note)} />
    </div>
  </div>
</div>
