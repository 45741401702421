<script>
  export let id;

  import { onMount } from "svelte";
  import DateFormat from "../../../../Lib/DateFormat.svelte";
  import ApiServer from "../../../../Server/ApiServer";

  let urls = {
    jobReminders: `/api/Job/jobreminders?jobID=`,
  };

  let reminders;

  onMount(async () => {
    ApiServer.Get(urls.jobReminders + id).then((data) => {
      reminders = data;
    });
  });
</script>

<div class="same-line">
  <h5>Job Reminders</h5>
</div>

{#if reminders}
  <div class="row bb-header">
    <div class="col">Date To Send</div>
    <div class="col">Date Sent</div>
    <div class="col">Send Status</div>

    <div class="col d-none d-md-block">Error Message</div>
    <div class="col d-none d-md-block">Template Name</div>
    <div class="col d-none d-md-block">Send Method</div>
    <div class="col d-none d-md-block">Notification</div>
  </div>
  {#each reminders as reminder}
    <div class="row bb-row">
      <div class="col">
        <DateFormat
          formatType="date-time-short"
          convertTimeZone={false}
          date={reminder.dateToSend}
        />
      </div>
      <div class="col">
        {#if reminder.dateSent}
          <DateFormat formatType="date-time-short" date={reminder.dateSent} />
        {:else}
          Not Sent
        {/if}
      </div>
      <div class="col">{reminder.sendStatus}</div>

      <div class="col d-none d-md-block">
        {#if reminder.sendErrorMessage}
          {reminder.sendErrorMessage}
        {:else}
          None
        {/if}
      </div>

      <div class="col  d-none d-md-block">
        {reminder.templateName}
      </div>
      <div class="col  d-none d-md-block">
        {reminder.notificationMethod}
      </div>
      <div class="col  d-none d-md-block">
        {#if reminder.sendStatus == "Success"}
          {#if reminder.emailAddress}
            {reminder.emailAddress}
          {:else if reminder.phonenumber}
            {reminder.phonenumber}
          {:else}
            No contact information
          {/if}
        {:else}
          Not sent yet
        {/if}
      </div>
    </div>
  {/each}
{/if}
