<div class="row">
  <div class="col-lg-6 mx-auto">
    <div class="text-center">
      <img
        src="/images/logo_horizontal_cropped_100.png"
        style="width:75%; height:75%"
        alt="Bin Bird Logo"
      />
      <p>Software for Bin Cleaners</p>
    </div>
  </div>
</div>
