<script>
  import ApiServer from "../Server/ApiServer";
  import debounce from "just-debounce-it";
  import AddressGoogleLink from "../Lib/AddressGoogleLink.svelte";
  import ButtonLink from "../Lib/Buttons/ButtonLink.svelte";
  import AccountIcon from "../Lib/Icons/AccountIcon.svelte";

  let urls = {
    searchAccounts: "/api/Account/Account/searchaccounts",
  };

  let searchText;
  let results = null;

  function clearSearch() {
    results = null;
    searchText = "";
  }

  const performSearch = debounce((event) => {
    searchText = event.target.value;
    results = null;

    if (searchText == "") return;

    var pageRequest = {
      PageNumber: 0,
      ItemsPerPage: 10,
      SearchText: searchText,
    };

    ApiServer.Post(urls.searchAccounts, pageRequest).then((data) => {
      results = data;
      console.log(
        `Searched ${pageRequest.searchText} ${pageRequest.ItemsPerPage} ${pageRequest.PageNumber}`
      );
    });
  }, 650);
</script>

<div class="search-container">
  <div class="row">
    <div class="col">
      <input
        type="search"
        class="form-control"
        placeholder="Account Search..."
        autocomplete="off"
        spellcheck="false"
        bind:value={searchText}
        on:input={performSearch}
        on:focus={performSearch}
      />
    </div>
  </div>
  <div class="row">
    <div class="container">
      <div class="col" style="position: relative">
        {#if results}
          <ul class="result list-group">
            <li class="list-group-item">
              <div class="row">
                <div class="col d-flex justify-content-start">
                  Found: {results.length}
                  {#if results.length == 10}+{/if}
                </div>

                <div class="col d-flex justify-content-end">
                  <ButtonLink text="Clear" callback={clearSearch} />
                </div>
              </div>
            </li>
            {#each results as result}
              <li class="list-group-item list-group-item-light">
                <div class="row">
                  <div class="col">
                    <h4>
                      <AccountIcon />

                      <a href="/account/{result.id}">
                        {result.firstName}
                        {result.lastName}
                      </a>
                    </h4>
                    <div>{result.email}</div>
                    <div>
                      <a href="tel:{result.formattedPhoneNumber}">
                        {result.formattedPhoneNumber}
                      </a>
                    </div>
                  </div>

                  <div class="col d-flex justify-content-end">
                    <AddressGoogleLink
                      address={result.address}
                      city={result.city}
                      state={result.state}
                      zip={result.zip}
                    />
                  </div>
                </div>
              </li>
            {/each}
          </ul>
        {/if}
      </div>
    </div>
  </div>
</div>

<style>
  .result {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    position: absolute;
  }
  .search-container {
    margin-bottom: 10px;
  }
</style>
