<script>
  import { Offcanvas } from "sveltestrap";
  import { onMount } from "svelte";

  import ButtonCancel from "../../../Lib/Buttons/ButtonCancel.svelte";
  import ButtonLink from "../../../Lib/Buttons/ButtonLink.svelte";
  import ButtonAdd from "../../../Lib/Buttons/ButtonAdd.svelte";
  import { toastNotification } from "../../../Stores/toastNotification";
  import ApiServer from "../../../Server/ApiServer";
  import ButtonSave from "../../../Lib/Buttons/ButtonSave.svelte";
  import JobOption from "./JobOptions/_JobOption.svelte";
  import DeleteIcon from "../../../Lib/Icons/DeleteIcon.svelte";
  import JobVendorRestrictionOption from "./JobOptions/_JobVendorRestrictionOption.svelte";

  let isOpen = false;
  let jobLogOptions;
  let jobCloseOptions;
  let jobVendorRestrictionOptions;

  let urls = {
    get: `/api/Company/Settings/JobOptions`,
    update: `/api/Company/Settings/JobOptions`,
  };

  const openScreen = () => (isOpen = !isOpen);

  function loadSettings() {
    ApiServer.Get(urls.get).then((data) => {
      jobLogOptions = JSON.parse(data.jobLogOptionsJSON);
      jobCloseOptions = JSON.parse(data.jobCloseOptionsJSON);
      jobVendorRestrictionOptions = JSON.parse(data.jobVendorRestrictionsJSON);
    });
  }

  function addVROption() {
    if (!jobVendorRestrictionOptions) jobVendorRestrictionOptions = [];

    jobVendorRestrictionOptions = [...jobVendorRestrictionOptions, {}];
  }

  function deleteVROption(op) {
    jobVendorRestrictionOptions.splice(
      jobVendorRestrictionOptions.indexOf(op),
      1
    );
    jobVendorRestrictionOptions = jobVendorRestrictionOptions;
  }

  function addLogOption() {
    if (!jobLogOptions) jobLogOptions = [];

    jobLogOptions = [...jobLogOptions, ""];
  }

  function deleteLogOption(op) {
    jobLogOptions.splice(jobLogOptions.indexOf(op), 1);
    jobLogOptions = jobLogOptions;
  }

  function addCloseOption() {
    if (!jobCloseOptions) jobCloseOptions = [];

    jobCloseOptions = [...jobCloseOptions, ""];
  }

  function deleteCloseOption(op) {
    jobCloseOptions.splice(jobCloseOptions.indexOf(op), 1);
    jobCloseOptions = jobCloseOptions;
  }

  function saveSettings() {
    var jobOpts = {
      jobLogOptionsJSON: JSON.stringify(jobLogOptions),
      jobCloseOptionsJSON: JSON.stringify(jobCloseOptions),
      jobVendorRestrictionsJSON: JSON.stringify(jobVendorRestrictionOptions),
    };
    ApiServer.Post(urls.update, jobOpts).then((data) => {
      toastNotification.Success("Job Options saved!");
      isOpen = false;
    });
  }

  onMount(async () => {
    loadSettings();
  });
</script>

<div class="row table-row">
  <div class="col">
    <ButtonLink text="Job Options" callback={openScreen} />
  </div>
</div>

<Offcanvas header="Job Options" {isOpen} toggle={openScreen} placement="end">
  {#if jobLogOptions}
    <div class="row">
      <div class="col">
        <div class="same-line">
          <h5>Job Log Options</h5>
          <ButtonAdd size="sm" text="Add" callback={addLogOption} />
        </div>
        {#each jobLogOptions as log}
          <div class="row">
            <div class="col-1">
              <span class="delete" on:click={() => deleteLogOption(log)}
                ><DeleteIcon /></span
              >
            </div>
            <div class="col-11">
              <JobOption bind:option={log} />
            </div>
          </div>
        {/each}
      </div>
    </div>
  {/if}

  {#if jobCloseOptions}
    <div class="row">
      <div class="col">
        <div class="same-line">
          <h5>Job Close Options</h5>
          <ButtonAdd size="sm" text="Add" callback={addCloseOption} />
        </div>
        {#each jobCloseOptions as log}
          <div class="row">
            <div class="col-1">
              <span class="delete" on:click={() => deleteCloseOption(log)}
                ><DeleteIcon /></span
              >
            </div>
            <div class="col-11">
              <JobOption bind:option={log} />
            </div>
          </div>
        {/each}
      </div>
    </div>
  {/if}
  {#if jobVendorRestrictionOptions}
    <div class="row">
      <div class="col">
        <div class="same-line">
          <h5>Vendor Restriction Options</h5>
          <ButtonAdd size="sm" text="Add" callback={addVROption} />
        </div>
        {#each jobVendorRestrictionOptions as log}
          <div class="row">
            <div class="col-1">
              <span class="delete" on:click={() => deleteVROption(log)}
                ><DeleteIcon /></span
              >
            </div>
            <div class="col-11">
              <JobVendorRestrictionOption bind:option={log} />
            </div>
          </div>
        {/each}
      </div>
    </div>
  {/if}

  <br />
  <p>
    <ButtonCancel text="Close" callback={openScreen} />
    <ButtonSave text="Save" callback={saveSettings} />
  </p>
</Offcanvas>

<style>
  .delete {
    cursor: pointer;
  }
  .same-line {
    margin-top: 5px;
    margin-bottom: 5px;
  }
</style>
