import * as Env from "../Constant/Environment";

class ApiServer {
  static _baseUrl = Env.apiUrl;
  static PORTAL_AUTH_KEY = "BinBirdPortalAuthKey";
  //static _baseUrl = "https://localhost:44344";
  //static _baseUrl = "http://api.binbird.io";

  static async Get(url) {
    return await this._get(url, false);
  }

  static async GetAnonymous(url) {
    return await this._get(url, true);
  }

  static async _get(url, isAnon) {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };

    if (!isAnon)
      options.headers.BinBirdPortalAuthKey = localStorage.getItem(
        this.PORTAL_AUTH_KEY
      );

    const response = await fetch(this._baseUrl + url, options).catch(
      (error) => {
        console.log(error);
      }
    );

    if (!isAnon) {
      const token = response.headers.get(this.PORTAL_AUTH_KEY);
      if (!token) {
        //need to decide how to handle a null token here. Maybe redirect to Login?
        console.log("BinBird Token missing from header on get...");
      } else {
        localStorage.setItem(this.PORTAL_AUTH_KEY, token);
      }
    }

    return response.json();
  }

  static async GetFile(url) {
    return await this._getFile(url, false);
  }

  static async _getFile(url, isAnon) {
    const options = {
      method: "GET",
      responseType: "blob",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };

    if (!isAnon)
      options.headers.BinBirdPortalAuthKey = localStorage.getItem(
        this.PORTAL_AUTH_KEY
      );

    const response = await fetch(this._baseUrl + url, options).catch(
      (error) => {
        console.log(error);
      }
    );

    if (!isAnon) {
      const token = response.headers.get(this.PORTAL_AUTH_KEY);
      if (!token) {
        //need to decide how to handle a null token here. Maybe redirect to Login?
        console.log("BinBird Token missing from header on get...");
      } else {
        localStorage.setItem(this.PORTAL_AUTH_KEY, token);
      }
    }

    return response.blob();
  }

  static async PostAnonymous(url, payload) {
    return await this._post(url, payload, true);
  }

  static async Post(url, payload) {
    return await this._post(url, payload, false);
  }

  static async _post(url, payload, isAnon) {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    };

    if (!isAnon)
      options.headers.BinBirdPortalAuthKey = localStorage.getItem(
        this.PORTAL_AUTH_KEY
      );

    const response = await fetch(this._baseUrl + url, options).catch(
      (error) => {
        console.log(error);
      }
    );

    if (!isAnon) {
      const token = response.headers.get(this.PORTAL_AUTH_KEY);
      if (!token) {
        console.log(
          "BinBird Token missing from header on post...this will cause some issues"
        );
      } else {
        localStorage.setItem(this.PORTAL_AUTH_KEY, token);
      }
    }

    return response.json();
  }

  static async PostFile(url, payload) {
    return await this._postFile(url, payload, false);
  }

  static async _postFile(url, payload, isAnon) {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: payload, //Payload is not stringified..
    };

    if (!isAnon)
      options.headers.BinBirdPortalAuthKey = localStorage.getItem(
        this.PORTAL_AUTH_KEY
      );

    const response = await fetch(this._baseUrl + url, options).catch(
      (error) => {
        console.log(error);
      }
    );

    if (!isAnon) {
      const token = response.headers.get(this.PORTAL_AUTH_KEY);
      if (!token) {
        console.log(
          "BinBird Token missing from header on post...this will cause some issues"
        );
      } else {
        localStorage.setItem(this.PORTAL_AUTH_KEY, token);
      }
    }

    return response.json();
  }

  static async Put(url, payload) {
    const options = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        BinBirdPortalAuthKey: localStorage.getItem(this.PORTAL_AUTH_KEY),
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(this._baseUrl + url, options).catch(
      (error) => {
        console.log(error);
      }
    );

    const token = response.headers.get(this.PORTAL_AUTH_KEY);

    if (!token) {
      console.log(
        "BinBird Token missing from header on post...this will cause some issues"
      );
    } else {
      localStorage.setItem(this.PORTAL_AUTH_KEY, token);
    }

    return response.json();
  }

  static async Delete(url) {
    const options = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };

    options.headers.BinBirdPortalAuthKey = localStorage.getItem(
      this.PORTAL_AUTH_KEY
    );

    const response = await fetch(this._baseUrl + url, options).catch(
      (error) => {
        console.log(error);
      }
    );

    const token = response.headers.get(this.PORTAL_AUTH_KEY);
    if (!token) {
      console.log(
        "BinBird Token missing from header on post...this will cause some issues"
      );
    } else {
      localStorage.setItem(this.PORTAL_AUTH_KEY, token);
    }

    return response.json();
  }
}

export default ApiServer;
