<script>
  export let templateID;

  import { onMount } from "svelte";
  import Editor from "@tinymce/tinymce-svelte";
  import ButtonDelete from "../../../Lib/Buttons/ButtonDelete.svelte";
  import InputSelectArray from "../../../Lib/Inputs/InputSelectArray.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import { toastNotification } from "../../../Stores/toastNotification";
  import Header from "../Header.svelte";
  import { tinyApiKey } from "../../../Constant/Environment";
  import CopyIcon from "../../../Lib/Icons/CopyIcon.svelte";
  import SaveIcon from "../../../Lib/Icons/SaveIcon.svelte";
  import InputTextBox from "../../../Lib/Inputs/InputTextBox.svelte";

  let template;
  let templateMethods;
  let templateVariables;
  let notificationTypes;

  let urls = {
    getOne: `/api/Company/NotificationTemplate/template?id=`,
    deleteTemplate: `/api/Company/NotificationTemplate/template?id=`,
    add: `/api/Company/NotificationTemplate/template`,
    getMethods: `/api/Company/NotificationTemplate/templatemethods`,
    getTemplateVariables: `/api/Company/NotificationTemplate/templatevariables`,
    getDefaultTemplate: `/api/Company/NotificationTemplate/defaulttemplate`,
    notificationTypes: `/api/Company/NotificationTemplate/notficationtypes`,
  };

  let tinyMCEconf = {
    plugins: "image",
  };

  function loadDefaultBody() {
    if (template) {
      if (template.isNew && template.templateMethod == "Email") {
        ApiServer.Get(urls.getDefaultTemplate).then((data) => {
          template.body = data;
        });
      } else {
        //Removes the HTML from the body if the user selected Email then SMS.
        template.body = "";
      }
    }
  }

  function save() {
    if (templateID == "new") {
      ApiServer.Post(urls.add, template).then((data) => {
        toastNotification.Success("Template Saved!");
        window.history.back();
      });
      return;
    }

    ApiServer.Put(urls.add, template).then((data) => {
      toastNotification.Success("Template Updated!");
      window.history.back();
    });
  }

  function remove() {
    ApiServer.Delete(urls.deleteTemplate + templateID).then((data) => {
      toastNotification.Success("Template Removed!");
      window.history.back();
    });
  }

  function placeVariableInBody(variable) {
    if (template) {
      if (!template.body) {
        template.body = "";
      }
      template.body = template.body.concat("[" + variable + "]");
    }
  }

  onMount(async () => {
    ApiServer.Get(urls.getMethods).then((data) => {
      templateMethods = data;
    });

    ApiServer.Get(urls.getTemplateVariables).then((data) => {
      templateVariables = data;
    });

    ApiServer.Get(urls.notificationTypes).then((data) => {
      notificationTypes = data;
    });

    if (templateID == "new") {
      template = {
        templateMethod: "SMS",
        isNew: true, //UI only variable
      };
      return;
    }

    ApiServer.Get(urls.getOne + templateID).then((data) => {
      template = data;
    });
  });
</script>

{#if template}
  <Header text={template.name} />

  <article class="card-body">
    <form on:submit|preventDefault={save}>
      <div class="row">
        <div class="col-6">
          <InputTextBox
            label="Template Name"
            bind:value={template.name}
            required={true}
          />
        </div>
        <div class="col-6">
          <InputSelectArray
            label="Send Method"
            bind:value={template.templateMethod}
            options={templateMethods}
            on:valueChanged={loadDefaultBody}
          />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <InputTextBox
            label="Subject"
            placeholder="Template Subject"
            bind:value={template.subject}
          />
        </div>
        <div class="col">
          <InputSelectArray
            label="Notification Type"
            bind:value={template.notificationType}
            options={notificationTypes}
            required={true}
          />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-12">
          <label for="desc">Body</label>
          {#if template.templateMethod == "SMS"}
            <textarea
              id="desc"
              class="form-control"
              bind:value={template.body}
              rows="4"
            />
          {:else}
            <Editor
              conf={tinyMCEconf}
              apiKey={tinyApiKey}
              bind:value={template.body}
            />
          {/if}
        </div>
      </div>
      <br />
      <div class="row">
        <label for="replacements">Variables</label>
        <p>
          When clicked the variable will be copied to your clipboard. Paste it
          where you want in your message. When the message is sent the variable
          will be replaced with the actual text.
        </p>
        {#if templateVariables}
          {#each templateVariables as v}
            <div class="col-3">
              <div class="variable">
                <CopyIcon title="Click to copy [{v}]" textToCopy="[{v}]">
                  {v}
                </CopyIcon>
              </div>
            </div>
          {/each}
        {/if}
      </div>

      <hr />

      <button type="submit" class="btn btn-outline-primary"
        ><SaveIcon /> Save</button
      >
      <ButtonDelete text="Delete" callback={remove} />
    </form>
  </article>
{/if}

<style>
  label {
    font-weight: bold;
  }
  .variable {
    border-radius: 5px;
    background-color: #555555;
    color: white;
    padding: 4px;
    margin: 1px;
    cursor: pointer;
  }
</style>
