<script>
  export let tutorialSettings;

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import NotificationPhoneNumber from "../Settings/Controls/NotificationPhoneNumber.svelte";

  function handleSave() {
    tutorialSettings.tutorialSettings.phoneNumberDone = true;
    dispatch("phoneNumberSave");
  }
</script>

<div class="row">
  <div class="col">
    <div class="card mb-3">
      <div class="card-header">Company Phone Number</div>
      <div class="card-body">
        <div class="card-text">
          <NotificationPhoneNumber on:onPhoneNumberSelected={handleSave} />
        </div>
        <div class="card-footer" />
      </div>
    </div>
  </div>
</div>
