<script>
  import { link, navigate } from "svelte-navigator";
  import Fa from "svelte-fa";
  import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

  import ApiServer from "../../Server/ApiServer";
  import StripeIcon from "../../Lib/Icons/StripeIcon.svelte";
  import AccountIcon from "../../Lib/Icons/AccountIcon.svelte";
  import ArrowRightIcon from "../../Lib/Icons/ArrowRightIcon.svelte";
  import { user } from "../../Stores/stores";
  import ButtonDelete from "../../Lib/Buttons/ButtonDelete.svelte";
  import { toastNotification } from "../../Stores/toastNotification";
  import UserStore from "../../Stores/UserStore";
  import BillingIcon from "../../Lib/Icons/BillingIcon.svelte";
  import CheckoutIcon from "../../Lib/Icons/CheckoutIcon.svelte";
  import TemplateIcon from "../../Lib/Icons/TemplateIcon.svelte";
  import UserIcon from "../../Lib/Icons/UserIcon.svelte";
  import AreaIcon from "../../Lib/Icons/AreaIcon.svelte";
  import SettingsIcon from "../../Lib/Icons/SettingsIcon.svelte";

  function handleLogoff() {
    $user == null;
    UserStore.logoff();
    navigate("/");
    location.reload();
  }

  function deleteCompany() {
    ApiServer.Delete(
      "/api/Company/CompanyInfo/deletecompany?checkString=brettsaidso"
    ).then((data) => {
      if (data.success) {
        toastNotification.Success(
          "Company removed successfully! You will now be logged out."
        );
        handleLogoff();
      } else {
        toastNotification.Error(data.message);
      }
    });
  }
</script>

<h2>{$user.companyName} Settings</h2>
<ul class="list-group">
  <li class="list-group-item d-flex justify-content-between align-items-start">
    <div class="ms-2 me-auto">
      <div class="fw-bold">
        <a class="menu-link" href="company/settings" use:link>
          <SettingsIcon /> Settings</a
        >
      </div>
      Your company settings
    </div>
    <span class=""><ArrowRightIcon /></span>
  </li>
  <li class="list-group-item d-flex justify-content-between align-items-start">
    <div class="ms-2 me-auto">
      <div class="fw-bold">
        <a class="menu-link" href="company/billingplans" use:link>
          <BillingIcon /> Billing Plans</a
        >
      </div>
      Billing plans, line items, prices
    </div>
    <span class=""><ArrowRightIcon /></span>
  </li>

  <li class="list-group-item d-flex justify-content-between align-items-start">
    <div class="ms-2 me-auto">
      <div class="fw-bold">
        <a class="menu-link" href="company/stripeconnect" use:link>
          <StripeIcon /><ArrowRightIcon /><AccountIcon /> Stripe Connect
        </a>
      </div>
      Connect your accounts to stripe
    </div>
    <span class=""><ArrowRightIcon /></span>
  </li>

  <li class="list-group-item d-flex justify-content-between align-items-start">
    <div class="ms-2 me-auto">
      <div class="fw-bold">
        <a class="menu-link" href="company/accountimport" use:link>
          <AccountIcon /> Account Import</a
        >
      </div>
      Get your customers in here!
    </div>
    <span class=""><ArrowRightIcon /></span>
  </li>

  <li class="list-group-item d-flex justify-content-between align-items-start">
    <div class="ms-2 me-auto">
      <div class="fw-bold">
        <a class="menu-link" href="company/checkoutrules" use:link>
          <CheckoutIcon /> Checkout Rules</a
        >
      </div>
      Public Checkout Rules
    </div>
    <span class=""><ArrowRightIcon /></span>
  </li>
  <li class="list-group-item d-flex justify-content-between align-items-start">
    <div class="ms-2 me-auto">
      <div class="fw-bold">
        <a class="menu-link" href="company/routes" use:link>
          <AreaIcon /> Areas</a
        >
      </div>
      Areas, Routes, and maps oh my!
    </div>
    <span class=""><ArrowRightIcon /></span>
  </li>
  <li class="list-group-item d-flex justify-content-between align-items-start">
    <div class="ms-2 me-auto">
      <div class="fw-bold">
        <a class="menu-link" href="company/users" use:link>
          <UserIcon /> Users</a
        >
      </div>
      Get your people in here!
    </div>
    <span class=""><ArrowRightIcon /></span>
  </li>
  <li class="list-group-item d-flex justify-content-between align-items-start">
    <div class="ms-2 me-auto">
      <div class="fw-bold">
        <a class="menu-link" href="company/templates?page=Templates" use:link>
          <TemplateIcon /> Templates</a
        >
      </div>
      For all your SMS and Email needs
    </div>
    <span class=""><ArrowRightIcon /></span>
  </li>
</ul>

<div class="float-end" style="display:none;">
  <ButtonDelete
    text="Delete Company"
    warningText="This will be deleted forever! This is your only warning. Press Yes and you will lose all customers, jobs, and everying forever. WE CANNOT RECOVER THIS!"
    callback={deleteCompany}
  />
</div>

<style>
  ul {
    padding: 0;
    list-style-type: none;
  }

  .menu-link {
    text-decoration: none;
    /*color: #000000; */
  }
</style>
