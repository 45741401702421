<script>
  export let portalSummary;

  import ButtonSimple from "../../Lib/Buttons/ButtonSimple.svelte";
  import PortalCard from "./_PortalCard.svelte";
  import PaymentMethodIcon from "../../Lib/Icons/PaymentMethodIcon.svelte";
  import CustomerPaymentMethods from "./_CustomerPaymentMethods.svelte";

  let paymentEditOpen = false;
</script>

<PortalCard title="Payment Method" icon={PaymentMethodIcon}>
  {#if portalSummary.paymentMethods}
    <h5>
      {#each portalSummary.paymentMethods as method}
        {method.brand} ****{method.last4}
        {method.expiry}
        {#if method.paymentMethodID == portalSummary.defaultPaymentID}
          <strong>(Default)</strong>
        {/if}
        <br />
      {/each}
    </h5>
  {:else}
    <p>No payment methods found. Please add a new payment method.</p>
  {/if}

  <div class="action-button">
    <ButtonSimple text="Update" callback={() => (paymentEditOpen = true)} />
  </div>
</PortalCard>

<CustomerPaymentMethods bind:portalSummary bind:paymentEditOpen />

<style>
  .action-button {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 5px;
  }
</style>
