<script>
  export let checkoutRequest;

  //https://github.com/joshnuss/svelte-stripe-js

  import { onMount } from "svelte";
  import { loadStripe } from "@stripe/stripe-js";
  import {
    Container,
    CardNumber,
    CardExpiry,
    CardCvc,
    Card,
  } from "svelte-stripe-js";

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import ApiServer from "../../Server/ApiServer";
  import { toastNotification } from "../../Stores/toastNotification";
  import ProgressLabel from "../../Lib/ProgressLabel.svelte";

  //Get this from the company settings one day.
  let paymentInProgress = false;

  let stripe = null;
  let cardElement;
  let cardExpiry;
  let cardCvc;

  let stripeKey;

  let urls = {
    stripeKey: `/api/Public/Checkout/stripekey?companyID=`,
    stripePaymentIntent: `/api/Public/Checkout/paymentintent`,
  };

  async function loadStripeObject() {
    stripe = await loadStripe(stripeKey);
  }

  onMount(async () => {
    ApiServer.GetAnonymous(urls.stripeKey + checkoutRequest.companyID).then(
      (data) => {
        stripeKey = data;
        loadStripeObject(stripeKey);
      }
    );
  });

  async function submit() {
    /*The process:
    1. Init Stripe via stripe.createToken API
    2. Build payment intent on BinBird Server (need private key, should not transfer via network)
    3. Client calls stripe.confirmCardPayment API to make payment
    4. If 3 is success, add customer to BinBird server
    5. We need the ChargeID attached to the BillingLedger record in BinBird. So on the server, at the time of creating the customer we query Stripe for the charge. 
    */

    paymentInProgress = true;

    const result = await stripe.createToken(cardElement);

    if (result.error) {
      toastNotification.Error("Payment Failed: " + result.error.message);
      paymentInProgress = false;
      return;
    }

    const paymentIntentRequest = await ApiServer.PostAnonymous(
      urls.stripePaymentIntent,
      checkoutRequest
    );

    if (!paymentIntentRequest || !paymentIntentRequest.success) {
      toastNotification.Error(
        "Payment Failed: " + paymentIntentRequest.message
      );
      paymentInProgress = false;
      return;
    }

    await stripe
      .confirmCardPayment(paymentIntentRequest.clientSecret, {
        payment_method: { card: cardElement },
      })
      .then((paymentResult) => {
        if (paymentResult.error) {
          toastNotification.Error(
            "Payment Failed: " + paymentResult.error.message
          );
          paymentInProgress = false;
        } else {
          checkoutRequest.account.stripeCustomerID =
            paymentIntentRequest.stripeCustomerID;
          // create payment intent
          toastNotification.Success("Payment Successful!");
          dispatch("paymentComplete", paymentResult);
        }
      });
  }
</script>

{#if stripe}
  <Container {stripe}>
    <form on:submit|preventDefault={submit}>
      <!-- <Card class="form-control" bind:element={cardElement} /> -->

      <div class="form-group">
        <label for="card-element">Credit or debit card</label>
        <div id="card-element" required class="form-control fix-height">
          <CardNumber bind:element={cardElement} />
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <label for="card-element">Expiration Date</label>
          <div id="card-element" required class="form-control fix-height">
            <CardExpiry bind:element={cardExpiry} />
          </div>
        </div>
        <div class="col form-group">
          <label for="card-element">CVC</label>
          <div id="card-element" required class="form-control fix-height">
            <CardCvc bind:element={cardCvc} />
          </div>
        </div>
      </div>
      <hr />
      <p>
        <button
          type="submit"
          class="btn btn-primary"
          disabled={paymentInProgress}>Pay Now</button
        >
        {#if paymentInProgress}
          <ProgressLabel text="Save in progress..." />
        {/if}
      </p>
    </form>
  </Container>
{/if}

<style>
  .fix-height {
    height: 2.4em;
    padding-top: 0.7em;
  }
</style>
