<script>
  export let week;

  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();

  import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
  } from "sveltestrap";
  import ButtonCancel from "../../../Lib/Buttons/ButtonCancel.svelte";

  import ButtonDelete from "../../../Lib/Buttons/ButtonDelete.svelte";
  import ButtonSave from "../../../Lib/Buttons/ButtonSave.svelte";

  function saveWeek() {
    dispatch("saveWeek", week);
    close();
  }

  function deleteWeek() {
    dispatch("deleteWeek", week);
    close();
  }

  function close() {
    dispatch("close");
  }

  function setColor(c, name) {
    week.color = c;
    week.colorName = name;
  }
</script>

{#if week}
  <div class="row">
    <div class="col">
      <label for="week">Week</label>
      <input type="text" name="week" bind:value={week.week} />
    </div>

    <!-- <div class="col">
      <label for="Color">Color</label>
      <Dropdown name="color">
        <DropdownToggle caret
          >{week.colorName ? week.colorName : "Color"}</DropdownToggle
        >
        <DropdownMenu>
          <DropdownItem on:click={() => setColor("#FFFF33", "Yellow")}>
            <div class="vmr-option" style="background-color: #FFFF33">
              Yellow
            </div>
          </DropdownItem>

          <DropdownItem on:click={() => setColor("#FF0000", "Red")}>
            <div class="vmr-option" style="background-color:#FF0000;">Red</div>
          </DropdownItem>

          <DropdownItem on:click={() => setColor("#2A9DF4", "Blue")}>
            <div class="vmr-option" style="background-color: #2A9DF4;" />
          </DropdownItem>

          <DropdownItem on:click={() => setColor("#A4DE02", "Green")}>
            <div class="vmr-option" style="background-color: #A4DE02;">
              Green
            </div>
          </DropdownItem>

          <DropdownItem on:click={() => setColor("#FF9100", "Orange")}>
            <div class="vmr-option" style="background-color: #FF9100;">
              Orange
            </div>
          </DropdownItem>

          <DropdownItem on:click={() => setColor("#8E24AA", "Purple")}>
            <div class="vmr-option" style="background-color: #8E24AA;">
              Purple
            </div>
          </DropdownItem>

          <DropdownItem on:click={() => setColor("#F43484", "Pink")}>
            <div class="vmr-option" style="background-color: #F43484;">
              Pink
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div> -->

    <br />
    <br />
    <br />
    <p>
      <ButtonDelete text="Delete" callback={deleteWeek} />
      <ButtonSave text="Save" callback={saveWeek} />
      <ButtonCancel text="Close" callback={close} />
    </p>
  </div>
{/if}
