<script>
  export let tutorialSettings;
  import { createEventDispatcher } from "svelte";
  import { Alert } from "sveltestrap";

  import StripeSettings from "../Settings/Controls/StripeSettings.svelte";

  const dispatch = createEventDispatcher();

  function finishBilling() {
    tutorialSettings.tutorialSettings.stripeDone = true;
    dispatch("saveSettings");
  }
</script>

{#if tutorialSettings && tutorialSettings.tutorialSettings && tutorialSettings.tutorialSettings.stripeDone}
  <Alert color="success">This section is complete!</Alert>
{/if}

<div class="row">
  <div class="col">
    <div class="card mb-3">
      <div class="card-header">Billing - Stripe Settings</div>
      <div class="card-body">
        <div class="card-text">
          <StripeSettings on:onStripeSettingsSaved={finishBilling} />
        </div>
        <div class="card-footer" />
      </div>
    </div>
  </div>
</div>
