<script>
  export let portalUser;

  import ButtonAction from "../../../../Lib/Buttons/ButtonAction.svelte";

  let urls = {
    resetPortalUserPassword: `/api/Account/AccountPortal/enableportaluser?portalUserID=`,
  };

  function sendResetPasswordEmail() {
    console.log("sendResetPasswordEmail");
  }
</script>

<div class="card">
  <div class="card-body ">
    <div class="card-title d-flex justify-content-between align-items-center">
      <h4>Reset Password</h4>
    </div>
    <div class="card-text">
      <p>Click the button below to send this user an reset password email.</p>

      <p>Email will be send to {portalUser.emailAddress}</p>
      <p>
        <ButtonAction
          text="Send Password Reset Email"
          callback={sendResetPasswordEmail}
        />
      </p>
    </div>
  </div>
</div>
