<script>
  export let text;
  export let number;

  function setPlural() {
    if (number > 1) {
      return text + "s";
    } else {
      return text;
    }
  }
</script>

{setPlural()}
