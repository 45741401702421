<script>
  import { onMount } from "svelte";
  import { Link, navigate } from "svelte-navigator";

  import AreasTutorial from "./AreasTutorial.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import BillingPlansTutorial from "./BillingPlansTutorial.svelte";
  import StripeSettingsTutorial from "./StripeSettingsTutorial.svelte";
  import CheckoutRulesTutorial from "./CheckoutRulesTutorial.svelte";
  import CustomerImportTutorial from "./CustomerImportTutorial.svelte";
  import LogoTutorial from "./LogoTutorial.svelte";
  import RoutingAddressTutorial from "./RoutingAddressTutorial.svelte";
  import VerifiedEmailTutorial from "./VerifiedEmailTutorial.svelte";
  import ProgressIcon from "./ProgressIcon.svelte";
  import CompanyPhoneNumberTutorial from "./CompanyPhoneNumberTutorial.svelte";

  var tutorialSettings = {};
  let menu;
  let urls = {
    get: "/api/Company/Tutorial/progress",
    save: "/api/Company/Tutorial/progress",
  };

  function saveProgress() {
    tutorialSettings.TutorialJSON = JSON.stringify(
      tutorialSettings.tutorialSettings
    );
    tutorialSettings.currentStep = menu;

    //We keep saving new settings everytime the page loads. Its not good.
    if (!tutorialSettings.id) {
      return;
    }

    ApiServer.Post(urls.save, tutorialSettings).then((data) => {
      tutorialSettings = data;
    });
  }

  onMount(async () => {
    ApiServer.Get(urls.get).then((data) => {
      tutorialSettings = data;
      menu = tutorialSettings.currentStep;
    });
  });

  $: menu, saveProgress();
</script>

<div class="row">
  <div class="col">
    <h4>Onboarding Tutorial</h4>
    <p>
      Bin Bird will be 100% ready when the list of tasks below are complete.
      <img src="/images/handhorns.png" alt="BAM!" height="55" />
    </p>
  </div>
</div>
{#if tutorialSettings && tutorialSettings.tutorialSettings}
  <div class="row">
    <div class="col-sm-2">
      <p class:selected={menu == ""} on:click={() => (menu = "")}>
        <ProgressIcon
          bind:finished={tutorialSettings.tutorialSettings.billingPlansDone}
        />

        <Link to="">Billing Plans</Link>
      </p>
      <p class:selected={menu == "billing"} on:click={() => (menu = "billing")}>
        <ProgressIcon
          bind:finished={tutorialSettings.tutorialSettings.stripeDone}
        />

        <Link to="billing">Billing</Link>
      </p>
      <p class:selected={menu == "logo"} on:click={() => (menu = "logo")}>
        <ProgressIcon
          bind:finished={tutorialSettings.tutorialSettings.logoDone}
        />

        <Link to="logo">Logo</Link>
      </p>
      <p
        class:selected={menu == "emailaddress"}
        on:click={() => (menu = "emailaddress")}
      >
        <ProgressIcon
          bind:finished={tutorialSettings.tutorialSettings.emailAddressDone}
        />

        <Link to="emailaddress">Verified Email</Link>
      </p>
      <p
        class:selected={menu == "phonenumber"}
        on:click={() => (menu = "phonenumber")}
      >
        <ProgressIcon
          bind:finished={tutorialSettings.tutorialSettings.phoneNumberDone}
        />

        <Link to="phonenumber">Phone Number</Link>
      </p>
      <p class:selected={menu == "address"} on:click={() => (menu = "address")}>
        <ProgressIcon
          bind:finished={tutorialSettings.tutorialSettings.routingAddressDone}
        />

        <Link to="address">Routing Address</Link>
      </p>
      <p
        class:selected={menu == "checkout"}
        on:click={() => (menu = "checkout")}
      >
        <ProgressIcon
          bind:finished={tutorialSettings.tutorialSettings.checkoutRulesDone}
        />

        <Link to="checkout">Checkout Rules</Link>
      </p>
      <p class:selected={menu == "areas"} on:click={() => (menu = "areas")}>
        <ProgressIcon
          bind:finished={tutorialSettings.tutorialSettings.mapAreasDone}
        />

        <Link to="areas">Map Areas</Link>
      </p>
      <p class:selected={menu == "import"} on:click={() => (menu = "import")}>
        <ProgressIcon
          bind:finished={tutorialSettings.tutorialSettings.customerImportDone}
        />

        <Link to="import">Customer Import</Link>
      </p>
    </div>
    <div class="col-sm-10">
      {#if menu == "logo"}
        <LogoTutorial {tutorialSettings} on:saveSettings={saveProgress} />
      {:else if menu == "billing"}
        <StripeSettingsTutorial
          {tutorialSettings}
          on:saveSettings={saveProgress}
        />
      {:else if menu == "emailaddress"}
        <VerifiedEmailTutorial
          {tutorialSettings}
          on:emailVerified={saveProgress}
        />
      {:else if menu == "phonenumber"}
        <CompanyPhoneNumberTutorial
          {tutorialSettings}
          on:phoneNumberSave={saveProgress}
        />
      {:else if menu == "address"}
        <RoutingAddressTutorial
          {tutorialSettings}
          on:saveSettings={saveProgress}
        />
      {:else if menu == "checkout"}
        <CheckoutRulesTutorial
          {tutorialSettings}
          on:saveSettings={saveProgress}
        />
      {:else if menu == "areas"}
        <AreasTutorial {tutorialSettings} on:saveSettings={saveProgress} />
      {:else if menu == "import"}
        <CustomerImportTutorial
          {tutorialSettings}
          on:saveSettings={saveProgress}
        />
      {:else}
        <BillingPlansTutorial
          on:saveBillingPlans={saveProgress}
          {tutorialSettings}
        />
      {/if}
    </div>
  </div>
{/if}

<style>
  p {
    padding: 5px;
    margin: 5px;
  }

  .selected {
    font-weight: bold;
    color: red;
  }
</style>
