<script>
  export let checkoutRules;

  import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Progress,
  } from "sveltestrap";

  import ButtonAdd from "../../../Lib/Buttons/ButtonAdd.svelte";
  import ButtonDelete from "../../../Lib/Buttons/ButtonDelete.svelte";
  import Checkbox from "../../../Lib/Inputs/InputCheckbox.svelte";
  import InputNumberBox from "../../../Lib/Inputs/InputNumberBox.svelte";
  import InputTextBox from "../../../Lib/Inputs/InputTextBox.svelte";

  function addPickupDay() {
    let newPickupDay = {
      dayName: "Bin pickup day",
      dayHelp: "This is the day your bins are emptied",
      maxBins: 6,
      minBins: 1,
      primary: false,
      days: JSON.parse(JSON.stringify(checkoutRules.pickupDays[0].days)),
      daySelectionOptions: JSON.parse(
        JSON.stringify(checkoutRules.pickupDays[0].daySelectionOptions)
      ),
    };
    checkoutRules.pickupDays.push(newPickupDay);
    checkoutRules = checkoutRules;
  }

  function removePickupDay(day) {
    var index = checkoutRules.pickupDays.indexOf(day);
    if (index >= 0) {
      checkoutRules.pickupDays.splice(index, 1);
      checkoutRules = checkoutRules;
    }
  }

  function setSelectedPickupDays() {
    //I know this is kinda crazy. Just wait till you see the save code :-O
    checkoutRules.pickupDays.forEach((pickupDay) => {
      pickupDay.days.forEach((day) => {
        var list = pickupDay.daySelectionOptions.filter(
          (d) => d.name.toLowerCase() == day.name.toLowerCase()
        );
        if (list.length > 0) {
          list[0].isSelected = true;
        }
      });
    });
  }

  setSelectedPickupDays();
</script>

<div class="row">
  <div class="col">
    <h4>Pickup Days</h4>
    <p>
      If your Trash and Recycling are picked up on different days set them up
      below.
    </p>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="float-end">
      <ButtonAdd text="Add Pickup Day" callback={addPickupDay} />
    </div>
    <div style="clear:both" />
    <div>
      {#each checkoutRules.pickupDays as pickupDay}
        <div class="row">
          <div class="col">
            <Checkbox
              label="Primary Pickup Day"
              text="Is Primary"
              value={pickupDay.primary}
            />
          </div>
          <div class="col">
            <InputTextBox label="Name of day" bind:value={pickupDay.dayName} />
          </div>
          <div class="col">
            <InputTextBox
              label="Day help text"
              bind:value={pickupDay.dayHelp}
            />
          </div>
          <div class="col">
            <InputNumberBox
              label="Minimum bins"
              bind:value={pickupDay.minBins}
            />
          </div>
          <div class="col">
            <InputNumberBox
              label="Maximum bins"
              bind:value={pickupDay.maxBins}
            />
          </div>
          <div class="col">
            <label for="dropdown">Days of the week</label>
            <Dropdown>
              <DropdownToggle caret>Days of the week</DropdownToggle>
              <DropdownMenu>
                {#each pickupDay.daySelectionOptions as day}
                  <DropdownItem>
                    <Checkbox
                      label=""
                      text={day.name}
                      bind:value={day.isSelected}
                    /></DropdownItem
                  >
                {/each}
              </DropdownMenu>
            </Dropdown>
          </div>
          <div class="col">
            <label for="del">Remove</label>
            <ButtonDelete
              text="Remove"
              tooltip="Remove this pickup day"
              callback={() => removePickupDay(pickupDay)}
            />
          </div>
        </div>
      {/each}
    </div>
  </div>
</div>
