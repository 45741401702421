export default class FormatChecker {
  static PhoneNumberIsValid(phoneNumber) {
    return phoneNumber.match(/\d/g).length == 10;
  }

  static UrlIsValid(urlToCheck) {
    let url;

    try {
      url = new URL(urlToCheck);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }
}
