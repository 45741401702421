<script>
  export let img;
  import DeleteAction from "../DeleteAction.svelte";
  import DeleteIcon from "../Icons/DeleteIcon.svelte";
  import DownloadIcon from "../Icons/DownloadIcon.svelte";

  import { createEventDispatcher } from "svelte";
  import DateFormat from "../DateFormat.svelte";
  const dispatch = createEventDispatcher();

  //Someday we will implment share so the admin can send the photo to the customer.
  //Share: Maybe allow admin to share these with the customer on their portal, but only if marked as sharable.

  let isBooped = false;

  function triggerBoop() {
    isBooped = true;
  }

  function triggerLeave() {
    isBooped = false;
  }

  function deleteImage(img) {
    dispatch("imageDeleted", img);
  }

  $: style = `
    display: ${isBooped ? "visible" : "none"}`;
</script>

<span on:mouseenter={triggerBoop} on:mouseleave={triggerLeave}>
  <div class="img-container">
    <slot />

    <div class="label-small">
      <DateFormat date={img.dateAdded} formatType="date-time-short" />
      {img.userName}
    </div>

    <a href={img.src} target="_blank" download={img.fileName}>
      <button class="action-left action-button" {style}>
        <DownloadIcon />
      </button>
    </a>

    <DeleteAction callback={() => deleteImage(img)}>
      <button class="action-right action-button" {style}>
        <DeleteIcon />
      </button>
    </DeleteAction>
  </div>
</span>

<style>
  .img-container {
    position: relative;
  }
  .action-button {
    background-color: white;
    color: black;
    border: 1px solid white;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    min-width: 25px;
  }
  .action-button:hover {
    cursor: pointer;
    background-color: whitesmoke;
  }
  .action-right {
    position: absolute;
    z-index: 10;
    left: 35px;
    top: 0px;
  }
  .action-left {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 10;
  }
  .label-small {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 10;
    padding: 5px;
    color: white;
    font-size: small;
    font-weight: bold;
  }
</style>
