<script>
  export let rules;

  import Checkbox from "../../../Lib/Inputs/InputCheckbox.svelte";
</script>

<div class="row">
  <div class="col">
    <h4>Startup Fees</h4>
    <p>
      Do you charge a initial startup fee for this plan? The first jobs for this
      customer will be charged this fee. Any jobs after this initial startup
      will go back to normal price.
    </p>
  </div>
  <div class="col">
    <Checkbox
      text="Enabled"
      label="Enable start up fee"
      bind:value={rules.startupFee.enabled}
    />
  </div>
</div>
{#if rules.startupFee.enabled}
  <div class="row">
    <div class="col-dm-6 form-group">
      <label for="desc">Startup Fee Charge</label>
      <input
        type="number"
        class="form-control"
        bind:value={rules.startupFee.cost}
      />
    </div>
  </div>
{/if}
