<script>
  export let text;
  export let callback;
  export let icon;
  export let style = "btn-outline-primary";
  export let iconLeft = true;
</script>

<button type="button" class="btn {style}" on:click={callback}>
  {#if iconLeft}
    <svelte:component this={icon} />
    {text}
  {:else}
    {text}
    <svelte:component this={icon} />
  {/if}
</button>
