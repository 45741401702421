﻿<script>
  export let template;

  import Datepicker from "svelte-calendar";
  import DaySwitch from "./DaySwitch.svelte";
  import { createEventDispatcher } from "svelte";
  import JobTemplateData from "./JobTemplateData";
  import UTCDate from "../../../../Util/UTCDate";
  import { rrulestr } from "RRule";
  import TemplateBillingPlan from "./TemplateBillingPlan.svelte";
  import BillingPlanSelector from "../../../Company/BillingPlans/BillingPlanSelector.svelte";
  import ControlStartTime from "../Job/ControlStartTime.svelte";
  import InputTextBox from "../../../../Lib/Inputs/InputTextBox.svelte";
  import InputSwitch from "../../../../Lib/Inputs/InputSwitch.svelte";
  import InputDate from "../../../../Lib/Inputs/InputDate.svelte";
  import EndDateSelect from "./EndDateSelect.svelte";

  const dispatch = createEventDispatcher();

  //Template variables
  let month = {};
  let dayOfWeek = {};
  let setPosition = {};

  let rrule = {
    static: {
      yearly: "YEARLY",
      monthly: "MONTHLY",
      weekly: "WEEKLY",
      daily: "DAILY",
      byMonthDay: "BYMONTHDAY",
      bySetPos: "BYSETPOS",
    },
  };

  function setPositionType() {
    //Depding on the value we need to set the proper rrule.
    if (setPosition.id == "first") {
      template.bySetPos = 1;
      template.byWhat = rrule.static.bySetPos;
    } else if (setPosition.id == "second") {
      template.bySetPos = 2;
      template.byWhat = rrule.static.bySetPos;
    } else if (setPosition.id == "third") {
      template.bySetPos = 3;
      template.byWhat = rrule.static.bySetPos;
    } else if (setPosition.id == "fourth") {
      template.bySetPos = 4;
      template.byWhat = rrule.static.bySetPos;
    } else if (setPosition.id == "last") {
      template.bySetPos = -1;
      template.byWhat = rrule.static.bySetPos;
    } else {
      template.byWhat = rrule.static.byMonthDay;
      template.byMonthDay = setPosition.id;
      template.bySetPos = setPosition.id;
    }

    generateRuleText();
  }

  function translateBySetPosValue(intValue) {
    switch (intValue) {
      case 1:
        return "first";
      case 2:
        return "second";
      case 3:
        return "third";
      case 4:
        return "fourth";

      default:
        return "last";
    }
  }

  function generateRuleText() {
    switch (template.frequency) {
      case rrule.static.yearly:
        return buildYearlyRule();
      case rrule.static.monthly:
        return buildMonthlyRule();
      case rrule.static.weekly:
        return buildWeeklyRule();
      case rrule.static.daily:
        return buildDailyRule();
    }
  }

  function buildYearlyRule() {
    var fre = `FREQ=${template.frequency}`;
    var byWhat = "";

    if (template.byWhat === rrule.static.byMonthDay) {
      byWhat = `BYMONTHDAY=${template.byMonthDay};BYMONTH=${month.id}`;
      template.ByMonth = month.id;
      template.BySetPos = null;
      template.ByDay = null;
    } else {
      byWhat = `BYSETPOS=${template.bySetPos};BYDAY=${dayOfWeek.id};BYMONTH=${month.id}`;
      template.ByMonthDay = null;
      template.byDay = dayOfWeek.id;
      template.byMonth = month.id;
    }

    //template.Interval = null;
    template.recurrance = `${addCountAndDates()};${fre};${byWhat}`;
  }

  function buildMonthlyRule() {
    let fre = `FREQ=${template.frequency}`;
    let byWhat = "";
    if (template.byWhat === rrule.static.byMonthDay) {
      byWhat = `BYMONTHDAY=${template.byMonthDay};INTERVAL=${template.interval}`;
      template.byMonthDay = template.byMonthDay;
      // template.bySetPos = null;
    } else {
      byWhat = `BYSETPOS=${template.bySetPos};BYDAY=${dayOfWeek.id};INTERVAL=${template.interval}`;
      template.byMonthDay = null;
      template.byMonth = null;
      template.byDay = dayOfWeek.id;
    }

    template.recurrance = `${addCountAndDates()};${fre};${byWhat}`;
    // console.log(template.recurrance);
  }

  function buildWeeklyRule() {
    let fre = `FREQ=${template.frequency};INTERVAL=${template.interval}`;
    let byDay = "";

    byDay = getWeekDays();

    template.byMonthDay = null;
    template.byMonth = null;
    template.bySetPos = null;
    template.byDay = byDay;
    template.recurrance = `${addCountAndDates()};${fre};BYDAY=${byDay}`;
  }

  function buildDailyRule() {
    let fre = `FREQ=${template.frequency};INTERVAL=${template.interval}`;
    template.byMonthDay = null;
    template.byMonth = null;
    template.bySetPos = null;
    template.byDay = null;

    template.recurrance = `${addCountAndDates()};${fre};`;
  }

  function addCountAndDates() {
    let dtStart = `DTSTART:${UTCDate.RRuleFormat(template.startDate)}\r\n`;
    let cnt = "RRULE:COUNT=" + template.monthsToCreateJobs;
    return `${dtStart}${cnt}`;
  }

  function getWeekDays() {
    let byDay = "";
    days.forEach((day) => {
      byDay += day + ",";
    });
    return byDay.replace(/,\s*$/, "");
  }

  function saveTemplate() {
    //template.runForever = template.endDate == null;
    template.active = true;
    template.bins = getBinCount();
    template.dayName = dayOfWeek.text;
    generateRuleText();
    generateRuleName();
    generateNewJobDates();
    dispatch("saveTemplate", template);
  }

  function getBinCount() {
    let binCount = 0;
    if (template && template.lineItems) {
      template.lineItems.forEach((item) => {
        if (item.isBin) binCount += item.quantity;
      });
    } else if (template.billingPlan && template.billingPlan.lineItems) {
      template.billingPlan.lineItems.forEach((item) => {
        if (item.isBin) binCount += item.quantity;
      });
    }
    return binCount;
  }

  function generateNewJobDates() {
    let theJobRule = rrulestr(template.recurrance);
    template.newTemplateDates = theJobRule.all();
    let jobs = theJobRule.all();
    // jobs.forEach((element) => {
    //   console.log(element);
    // });
  }

  function cancelTemplateSave() {
    template = null;
    dispatch("cancelTemplateSave");
  }

  function generateRuleName() {
    let name;
    switch (template.frequency) {
      case rrule.static.yearly:
        name = `Every ${template.interval} ${plural(
          template.interval,
          "year"
        )}`;
        if (template.byWhat === rrule.static.byMonthDay) {
          name += ` on ${month.text} ${setPosition.text}`;
        } else {
          name += ` on the ${setPosition.text} ${dayOfWeek.text} of ${month.text}`;
        }
        break;
      case rrule.static.monthly:
        name = `Every ${template.interval} ${plural(
          template.interval,
          "month"
        )}`;
        if (template.byWhat === rrule.static.byMonthDay) {
          name += ` on the ${setPosition.text}`;
        } else {
          name += ` on the ${setPosition.text} ${dayOfWeek.text}`;
        }
        break;
      case rrule.static.weekly:
        name = `Every ${template.interval} ${plural(
          template.interval,
          "week"
        )}`;
        name += ` on ${getWeekDays()}`;

        break;
      case rrule.static.daily:
        name = `Every ${template.interval} ${plural(template.interval, "day")}`;
        break;
    }

    template.definition = name;
  }

  function plural(number, name) {
    if (number > 1) {
      return name + "s";
    } else {
      return name;
    }
  }

  const days = [];
  //Passed back an array of days selected. If day already in the array remove it.
  function handleDaySelected(event) {
    var day = event.detail;

    let indexOfDay = days.indexOf(day);

    if (indexOfDay > -1) {
      days.splice(indexOfDay, 1);
    } else {
      days.push(day);
    }
  }

  function mountTemplate(template) {
    template.duration = template.duration.toString();
    template.monthsToCreateJobs = template.monthsToCreateJobs.toString();
    template.interval = template.interval.toString();

    month.id = template.byMonth;
    month = JobTemplateData.Months[template.byMonth];
    dayOfWeek = JobTemplateData.DaysOfWeek.find((x) => x.id === template.byDay);

    if (template.byWhat == rrule.static.byMonthDay) {
      setPosition = JobTemplateData.SetPositionByDay.find(
        (x) => x.id === template.bySetPos.toString()
      );
    } else {
      let setPosValue = translateBySetPosValue(template.bySetPos);
      setPosition = JobTemplateData.SetPositionByName.find(
        (x) => x.id === setPosValue
      );
    }
  }

  mountTemplate(template);
</script>

{#if template}
  <!--Template Name, Start and End-->
  <div class="row">
    <div class="col">
      <InputTextBox label="Name" bind:value={template.name} />
    </div>
    <div class="col">
      <InputDate
        label="Starts"
        date={template.startDate}
        bind:value={template.startDate}
      />
    </div>
    <div class="col">
      <InputSwitch
        label="Runs forever"
        text=""
        bind:value={template.runForever}
      />
    </div>
    {#if !template.runForever}
      <div class="col">
        <InputDate
          label="Stop creating jobs after"
          date={template.endDate}
          bind:value={template.endDate}
        />
      </div>
    {/if}
  </div>

  <!--Repeating Logic-->
  <hr />
  <h5>Repeats</h5>
  <div class="row">
    <div class="col">
      <div class="repeats">
        <table>
          <tr>
            <td class="fifty">Every</td>
            <td class="fifty">
              <select bind:value={template.interval}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </td>
            <td class="fifty">
              <select bind:value={template.frequency}>
                <option value={rrule.static.monthly}>Months</option>
                <option value={rrule.static.weekly}>Weeks</option>
                <!-- <option value={rrule.static.daily}>Days</option> -->
                <option value={rrule.static.yearly}>Years</option>
              </select>
            </td>
          </tr>
        </table>
      </div>
      {#if template.frequency === rrule.static.monthly}
        <table>
          <tr>
            <td>On the </td>
            <td>
              <select bind:value={setPosition} on:change={setPositionType}>
                <optgroup label="On the...">
                  {#each JobTemplateData.SetPositionByName as day}
                    <option value={day}>{day.text}</option>
                  {/each}
                </optgroup>
                <optgroup label="On day of YEAR...">
                  {#each JobTemplateData.SetPositionByDay as day}
                    <option value={day}>{day.text}</option>
                  {/each}
                </optgroup>
              </select>
            </td>
            <td>
              {#if template.byWhat == rrule.static.bySetPos}
                <select bind:value={dayOfWeek}>
                  {#each JobTemplateData.DaysOfWeek as day}
                    <option value={day}>{day.text}</option>
                  {/each}
                </select>
              {/if}
            </td>
          </tr>
        </table>
      {/if}
      {#if template.frequency === rrule.static.yearly}
        <div>
          on
          <select bind:value={setPosition} on:change={setPositionType}>
            <optgroup label="On the...">
              {#each JobTemplateData.SetPositionByName as day}
                <option value={day}>{day.text}</option>
              {/each}
            </optgroup>
            <optgroup label="On day of YEAR...">
              {#each JobTemplateData.SetPositionByDay as day}
                <option value={day}>{day.text}</option>
              {/each}
            </optgroup>
          </select>

          {#if template.byWhat == rrule.static.bySetPos}
            <select bind:value={dayOfWeek}>
              {#each JobTemplateData.DaysOfWeek as day}
                <option value={day}>{day.text}</option>
              {/each}
            </select>
          {/if}
          of
          <select bind:value={month}>
            {#each JobTemplateData.Months as month}
              <option value={month}>{month.text}</option>
            {/each}
          </select>
        </div>
      {/if}
      {#if template.frequency === rrule.static.weekly}
        <div>
          {#each JobTemplateData.DaysOfWeek as day}
            {#if day.id != ""}
              <DaySwitch
                on:daySelected={handleDaySelected}
                currentValue={template.byDay}
                value={day.id}
                text={day.text}
              />
            {/if}
          {/each}
        </div>
      {/if}
    </div>
  </div>

  <hr />
  <h5>Notes</h5>
  <div class="row">
    <div class="col">
      <label for="jobNotes">
        Job Notes
        <small>(Seen on all jobs)</small>
      </label>
      <textarea
        id="jobNotes"
        class="form-control"
        bind:value={template.jobNotes}
        rows="2"
      />
    </div>
    <div class="col">
      <label for="adminNotes">
        Admin Notes
        <small>(Only seen by admins)</small>
      </label>
      <textarea
        id="adminNotes"
        class="form-control"
        bind:value={template.adminNotes}
        rows="2"
      />
    </div>
  </div>

  <!-- Template:
  <ul>
    <li>ID: {template.id}</li>
    <li>name: {template.name}</li>
    <li>definition: {template.definition}</li>
    <li>recurrance: {template.recurrance}</li>
    <li>jobNotes: {template.jobNotes}</li>
    <li>accountID: {template.accountID}</li>
    <li>startTime: {template.startTime}</li>
    <li>duration: {template.duration}</li>
    <li>monthsToCreateJobs: {template.monthsToCreateJobs}</li>
    <li>startDate: {template.startDate}</li>
    <li>endDate: {template.endDate}</li>
    <li>runForever: {template.runForever}</li>
    <li>adminNotes: {template.adminNotes}</li>
    <li>frequency: {template.frequency}</li>
    <li>interval: {template.interval}</li>
    <li>bySetPos: {template.bySetPos}</li>
    <li>byDay: {template.byDay}</li>
    <li>byMonthDay: {template.byMonthDay}</li>
    <li>byMonth: {template.byMonth}</li>
    <li>byWhat: {template.byWhat}</li>
    <li>bins: {template.bins}</li>
    <li>DayName: {template.dayName}</li>
  </ul> -->

  <hr />
  <h5>Billing Plan</h5>
  <BillingPlanSelector bind:templateOrJob={template} />
  <!-- <TemplateBillingPlan {template} /> -->

  <hr />
  <button class="btn btn-outline-primary card-link" on:click={saveTemplate}>
    Save Template
  </button>
  <button
    class="btn btn-outline-warning card-link"
    on:click={cancelTemplateSave}
  >
    Cancel
  </button>
{/if}

<style>
  .fifty {
    width: 50px;
  }
</style>
