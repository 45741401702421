<script>
  import ChartCustomersThisWeek from "../Lib/ChartCustomersThisWeek.svelte";

  import ChartCustomersThisYear from "../Lib/ChartCustomersThisYear.svelte";
  import ChartCustomersAllTime from "../Lib/ChartCustomersAllTime.svelte";
  import MapToday from "../Controls/Dashboard/_MapToday.svelte";
  import DashboardInboxChart from "../Controls/Dashboard/_InboxChart.svelte";
  import InboxDashboardList from "../Controls/Dashboard/_InboxDashboardList.svelte";
  import PageTitle from "../Lib/PageTitle.svelte";
</script>

<PageTitle title="Dashboard" />

<!-- 
<div class="row">
  <div class="col-sm-6">
    <InboxDashboardList />
  </div>
  <div class="col-sm-6">
    <MapToday />
  </div>
</div>

<div class="row">
  <div class="col-sm-6">
    <ChartCustomersThisWeek />
  </div>
  <div class="col-sm-6">
    <ChartCustomersThisYear />
  </div>
</div>

<div class="row">
  <div class="col-sm-6">
    <ChartCustomersAllTime />
  </div>
</div> -->

<div class="box">
  <div><InboxDashboardList /></div>
  <div><MapToday /></div>
  <div><ChartCustomersThisWeek /></div>
  <div><ChartCustomersThisYear /></div>
  <div><ChartCustomersAllTime /></div>
</div>

<style>
  /* .col-sm-6 {
    margin-bottom: 5px;
  } */

  .box {
    display: flex;
    flex-wrap: wrap;
  }
  .box > * {
    flex: 1 1 460px;
    margin: 5px;
  }
</style>
