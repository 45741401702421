<script>
  //Yes date is different than value becuase we want to handle all the conversion crap in this componenent.
  export let date;
  export let label;
  export let value;
  export let required = false;

  import { onMount } from "svelte";
  import UTCDate from "../../Util/UTCDate";

  onMount(async () => {
    value = UTCDate.ParseForDatePicker(date);
  });
</script>

<label for={label}>{label}</label>
<input type="date" class="form-control" {required} bind:value />
