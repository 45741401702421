<script>
  export let planName;
  export let totalCost;
  export let totalTax;
  export let totalBins;

  import Money from "../../Lib/Money.svelte";
</script>

<div class="card">
  <header class="card-header">
    <h4 style="display: inline-block" class="card-title mt-2">Summary</h4>
  </header>
  <article class="card-body">
    {#if totalCost}
      <div class="row">
        <div class="col">{planName} for {totalBins} bins</div>
        <div class="col cost"><Money amount={totalCost} /></div>
      </div>
    {/if}

    {#if totalTax && totalTax > 0}
      <div class="row">
        <div class="col">Tax</div>
        <div class="col cost"><Money amount={totalTax} /></div>
      </div>
    {/if}

    <div class="row">
      <div class="col"><strong>Total</strong></div>
      <div class="col cost">
        <strong><Money amount={totalTax + totalCost} /></strong>
      </div>
    </div>
  </article>
</div>

<style>
  .cost {
    display: -webkit-flex;
    display: flex;
    justify-content: flex-end;
  }
</style>
