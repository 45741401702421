<script>
  export let company;

  import { navigate } from "svelte-navigator";
  import { portaluser } from "../../Stores/PortalUser";
  import PortalUserStore from "../../Stores/PortalUserStore";
  import HeaderUser from "./HeaderUser.svelte";

  function handleLogoff() {
    let url = "/portal?c=" + $portaluser.companyName;
    $portaluser == null;
    PortalUserStore.logoff();
    navigate(url);
    location.reload();
  }
</script>

<svelte:head>
  <title>{$portaluser.companyName} - Portal</title>
</svelte:head>

{#if company}
  <div class="card">
    <div class="card-body">
      <div class="card-title same-line">
        <h3>{company.name} Portal</h3>
        <HeaderUser on:logout={handleLogoff} />
      </div>
    </div>
  </div>
{/if}
