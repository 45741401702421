import { writable } from "svelte/store";
import ApiServer from "../Server/ApiServer";

let urls = {
  getPlaygroundMode: "/api/Company/Settings/GeneralSettings/playgroundmode",
  setPlaygroundMode:
    "/api/Company/Settings/GeneralSettings/setplaygroundmode?enabled=",
};

let playgroundMode = {
  inPlaygroundMode: false,
  updatePlaygroundMode: (value) => {
    ApiServer.Get(urls.setPlaygroundMode + value).then((data) => {});
  },
};

export const PlaygroundModeStore = writable(playgroundMode, (set) => {
  ApiServer.Get(urls.getPlaygroundMode).then((data) => {
    playgroundMode.inPlaygroundMode = data;
    set(playgroundMode);
    return () => console.log("No more subscribers");
  });
});
