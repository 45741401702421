<script>
  export let value;
  export let text;
  export let currentValue;

  let yes = false;

  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();

  function selectDay(day) {
    dispatch("daySelected", day);
  }
  onMount(async () => {
    if (!currentValue) return;
    //this kinda sucks.
    let values = currentValue.split(",");

    values.forEach((element) => {
      if (element == "") {
        return;
      }
      if (value.toLowerCase() == element.toLowerCase()) {
        yes = true;
        selectDay(value);
      }
    });
  });
</script>

<div class="form-check form-switch">
  <input
    class="form-check-input"
    type="checkbox"
    on:change={() => selectDay(value)}
    bind:checked={yes}
    {value}
    id={value}
  />
  <label for={value}>{text}</label>
</div>
