<script>
  export let messageList;
  export let accountName = "?";

  import MessageCard from "./MessageCard.svelte";

  let initial = "?";

  function setInitial() {
    initial = accountName.slice(0, 1).toLocaleUpperCase();
  }

  $: accountName, setInitial();
</script>

{#each messageList as m}
  <div class="row">
    {#if m.fromCustomer}
      <div class="col-md-6">
        <MessageCard {initial} message={m} />
      </div>
      <div class="col-md-6"><br /></div>
    {:else}
      <div class="col-md-6"><br /></div>
      <div class="col-md-6">
        <MessageCard initial="Me" left={false} message={m} />
      </div>
    {/if}
  </div>
{/each}
