<script>
  import Header from "../Header.svelte";
  import TemplateIcon from "../../../Lib/Icons/TemplateIcon.svelte";
  import ReminderIcon from "../../../Lib/Icons/ReminderIcon.svelte";
  import TemplateList from "./TemplateList.svelte";
  import ReminderList from "./ReminderList.svelte";

  const urlParams = new URLSearchParams(window.location.search);
  const url = new URL(window.location);

  function setFilter(f) {
    filter = f;

    url.searchParams.set("page", f);
    window.history.pushState(null, "", url.toString());
  }

  let filter = urlParams.get("page");
</script>

<Header text="Templates & Reminders" />

<div id="button-menu">
  <div id="action-menu">
    <span
      on:click={() => setFilter("Templates")}
      class:selected={filter == "Templates"}
      class="filter"
    >
      <TemplateIcon /> Templates
    </span>
    <span
      on:click={() => setFilter("Reminders")}
      class:selected={filter == "Reminders"}
      class="filter"
    >
      <ReminderIcon /> Reminders
    </span>
  </div>
</div>

<div class="row justify-content-center">
  <div class="col-sm-12">
    {#if filter == "Templates"}
      <TemplateList />
    {:else}
      <ReminderList />
    {/if}
  </div>
</div>
