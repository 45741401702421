<script>
  import { onMount } from "svelte";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import ButtonAdd from "../../../../Lib/Buttons/ButtonAdd.svelte";
  import ButtonSave from "../../../../Lib/Buttons/ButtonSave.svelte";
  import ImageUploadModal from "../../../../Lib/Images/ImageUploadModal.svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import { toastNotification } from "../../../../Stores/toastNotification";

  let urls = {
    companyid: `/api/Company/Settings/GeneralSettings/companyid`,
    companyLogo: `/api/Company/Settings/GeneralSettings/getlogo`,
    addCompanyLogo: `/api/Company/Settings/GeneralSettings/addlogo?fileAttachmentID=`,
    deleteCompanyLogo: `/api/Company/Settings/GeneralSettings/deletelogo?fileAttachmentID=`,
    getcompanyName: `/api/Company/Settings/GeneralSettings/companyname`,
    setcompanyName: `/api/Company/Settings/GeneralSettings/updatecompanyname?name=`,
  };

  let imageCompanyLogUrl;
  let imageModalOpen = false;
  let companyname;

  let companyID;
  onMount(async () => {
    ApiServer.Get(urls.companyid).then((data) => {
      companyID = data;
    });

    ApiServer.Get(urls.companyLogo).then((data) => {
      imageCompanyLogUrl = data;
    });

    ApiServer.Get(urls.getcompanyName).then((data) => {
      companyname = data;
    });
  });

  function setCompanyName() {
    ApiServer.Get(urls.setcompanyName + companyname).then((data) => {
      if (data.success) {
        toastNotification.Success("Company Name updated!");
      } else {
        toastNotification.Error(
          "Changing the company name failed. " + data.message
        );
      }
    });
  }

  function handleFileUploadComplete(event) {
    //Get the ID from the uploaded file and save it in the company table.
    var data = event.detail;
    ApiServer.Get(urls.addCompanyLogo + data.id).then((data) => {
      imageCompanyLogUrl = data;
      toastNotification.Success("Logo updated!");
      dispatch("onLogoSaved");
    });
  }

  function openAddImage() {
    imageModalOpen = true;
  }
</script>

<div class="row">
  <div class="col">
    <p>
      <ButtonAdd text="Add Image" callback={openAddImage} />
    </p>
    <p>
      {#if imageCompanyLogUrl}
        <img src={imageCompanyLogUrl} alt="Company Logo" />
      {/if}
    </p>
  </div>
</div>

<h4>Company Name</h4>
<p>Edit your company name here.</p>

<div class="row">
  <div class="col">
    <input type="text" class="form-control" bind:value={companyname} />
  </div>
  <div class="col">
    <ButtonSave text="Update" callback={setCompanyName} />
  </div>
</div>

<ImageUploadModal
  relatedID={companyID}
  folder="Public"
  isOpen={imageModalOpen}
  isPublicImage={true}
  on:fileUploadComplete={handleFileUploadComplete}
/>
