<script>
  import DateFormat from "../../Lib/DateFormat.svelte";
  import ButtonAdd from "../../Lib/Buttons/ButtonAdd.svelte";
  import { BillingStatusStore } from "../../Stores/BillingStatusStore";
  import CreditCardInput from "./CreditCardInput.svelte";

  let showPaymentMethods = false;

  function openPaymentMethod() {
    showPaymentMethods = true;
  }
</script>

<div class="same-line">
  <h4>Current Billing Period</h4>
  <ButtonAdd text="Add/Modify Payment Method" callback={openPaymentMethod} />
</div>

{#if $BillingStatusStore}
  <p>
    Billing Status: {$BillingStatusStore.status}
    <br />
    Next Bill: <DateFormat
      date={$BillingStatusStore.nextBillDate}
      formatType="tonow"
    />
    <DateFormat
      date={$BillingStatusStore.nextBillDate}
      formatType="date-only"
    />
  </p>
{/if}

<CreditCardInput bind:showPaymentMethods />
