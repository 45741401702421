<script>
  //These are JobNotificationSettings - Calling them Reminders becuase, ya know, its betta

  import ButtonAdd from "../../../Lib/Buttons/ButtonAdd.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import { Link, navigate } from "svelte-navigator";
  import { onMount } from "svelte";

  let settings;

  let urls = {
    getAll: `/api/Company/NotificationSetting/settings`,
  };

  function addSetting() {
    navigate("reminders/new");
  }

  onMount(async () => {
    ApiServer.Get(urls.getAll).then((data) => {
      settings = data;
    });
  });
</script>

<div class="float-end">
  <ButtonAdd text="Add Reminder" callback={addSetting} />
</div>
<br />
<h4>Job Reminders</h4>
<article class="card-body">
  <div class="row table-row-header">
    <div class="col">Edit</div>
    <div class="col">Method</div>
    <div class="col">Days Before Job</div>
    <div class="col d-none d-sm-block">Reminder Time</div>
  </div>
  {#if settings}
    {#each settings as setting}
      <div class="row table-row">
        <div class="col">
          <Link to="/company/reminders/{setting.id}"
            >{setting.templateName}</Link
          >
        </div>
        <div class="col">{setting.notificationMethod}</div>
        <div class="col">{setting.hoursBeforeJob / 24}</div>
        <div class="col d-none d-sm-block">{setting.hourToRun}</div>
      </div>
    {/each}
  {/if}
</article>

<style>
  .table-row {
    padding: 3px;
    margin: 2px;
    border-bottom: 1px solid lightgray;
  }

  .table-row-header {
    font-size: 16px;
    font-weight: 700;
    padding: 3px;
    margin: 2px;
    border-bottom: 1px solid gray;
  }
</style>
