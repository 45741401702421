<script>
  export let showPaymentMethods = false;
  export let showAddNewCard = false;

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import { onMount } from "svelte";
  import { Container, CardNumber, CardExpiry, CardCvc } from "svelte-stripe-js";
  import Modal from "sv-bootstrap-modal";
  import { loadStripe } from "@stripe/stripe-js";

  import ButtonCancel from "../../Lib/Buttons/ButtonCancel.svelte";
  import AddIcon from "../../Lib/Icons/AddIcon.svelte";
  import ButtonConfirmAction from "../../Lib/Buttons/ButtonConfirmAction.svelte";
  import { toastNotification } from "../../Stores/toastNotification";

  import ApiServer from "../../Server/ApiServer";
  import ButtonAdd from "../../Lib/Buttons/ButtonAdd.svelte";
  import DateFormat from "../../Lib/DateFormat.svelte";

  let urls = {
    paymentMethods: "/api/Company/SystemBilling/paymentmethods",
    stripeKey: `/api/Company/SystemBilling/stripekey`,
    addPaymentMethod: `/api/Company/SystemBilling/addpaymentmethod`,
    deletePaymentMethod: `/api/Company/SystemBilling/deletepaymentmethod`,
  };

  //Stripe Stuff
  let paymentMethods = [];
  let errorMessage;
  let stripe = null;
  let cardElement;
  let cardAddMessage;

  function addNewCard() {
    showAddNewCard = true;
  }
  function togglepaymentEdit() {
    showAddNewCard = false;
  }

  function setPaymentMethods() {
    errorMessage = "";
    paymentMethods = [];
    ApiServer.Get(urls.paymentMethods).then((data) => {
      if (data.success) {
        paymentMethods = data.data;
      } else {
        errorMessage = data.message;
      }
    });
  }

  function deletePaymentMethod(cardID) {
    errorMessage = "";
    let request = {
      stripeCardID: cardID,
    };

    ApiServer.Post(urls.deletePaymentMethod, request).then((data) => {
      if (data.success) {
        setPaymentMethods();
        toastNotification.Success("Payment method deleted!");
        dispatch("paymentMethodRemoved");
      } else {
        errorMessage = "Payment method delete failed: " + data.message;
        toastNotification.Error(errorMessage);
      }
    });
  }

  async function submit() {
    cardAddMessage = "";

    const result = await stripe.createToken(cardElement);

    if (result.error) {
      cardAddMessage = "Add Card Failure: " + result.error.message;
      toastNotification.Error(cardAddMessage);
      return;
    }

    let newCardRequest = {
      stripeTokenID: result.token.id,
    };

    ApiServer.Post(urls.addPaymentMethod, newCardRequest).then((data) => {
      if (data.success) {
        toastNotification.Success("New payment method added!");
        setPaymentMethods();
        togglepaymentEdit();
        dispatch("paymentMethodAdded");
      } else {
        cardAddMessage = data.message;
        toastNotification.Error("Add Card Failure: " + data.message);
      }
    });
  }

  onMount(async () => {
    ApiServer.Get(urls.paymentMethods).then((data) => {
      paymentMethods = data;
    });
    let stripeKey = await ApiServer.Get(urls.stripeKey);
    stripe = await loadStripe(stripeKey.data);
  });
</script>

<Modal bind:open={showPaymentMethods} ignoreBackdrop={true}>
  <div class="modal-header">
    <h5 class="modal-title">Add/Modify Payment Methods</h5>
    <button
      type="button"
      class="close"
      on:click={() => (showPaymentMethods = false)}
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="same-line">
      <h5>Payment Methods</h5>
      <ButtonAdd text="Add Card" callback={addNewCard} />
    </div>
    <br />
    {#if showAddNewCard}
      {#if stripe}
        <Container {stripe}>
          <form on:submit|preventDefault={submit}>
            <div class="form-group">
              <label for="card-element">Credit or debit card</label>
              <div id="card-element" required class="form-control fix-height">
                <CardNumber bind:element={cardElement} />
              </div>
            </div>
            <div class="row">
              <div class="col form-group">
                <label for="card-element">Expiration Date</label>
                <div id="card-element" required class="form-control fix-height">
                  <CardExpiry />
                </div>
              </div>
              <div class="col form-group">
                <label for="card-element">CVC</label>
                <div id="card-element" required class="form-control fix-height">
                  <CardCvc />
                </div>
              </div>
            </div>
            <br />
            <ButtonCancel text="Cancel" callback={togglepaymentEdit} />
            <button class="btn btn-outline-primary"> <AddIcon /> Save</button>
            <br />
            <br />
          </form>

          {#if cardAddMessage}
            <p>
              <strong>{cardAddMessage}</strong>
            </p>
          {/if}
        </Container>
      {:else}
        <p>No Stripe</p>
      {/if}
    {/if}

    {#if paymentMethods && paymentMethods.length > 0}
      <div class="row bb-header">
        <div class="col">Action</div>
        <div class="col">Details</div>
        <div class="col">Created</div>
      </div>

      {#each paymentMethods as method}
        <div class="row bb-row">
          <div class="col">
            <ButtonConfirmAction
              text="Delete"
              callback={() => deletePaymentMethod(method.paymentMethodID)}
              tooltip="Delete this payment method"
              warningText="This will permanently delete this card. Are you sure?"
              buttonClass="btn btn-link"
            />
          </div>
          <div class="col">
            {method.brand}
            {method.last4}
            {method.expiry}
          </div>
          <div class="col">
            <DateFormat date={method.dateCreated} />
          </div>
        </div>
      {/each}
    {:else}
      <p>No payment methods found. Click the Add Card button to get started.</p>
    {/if}

    {#if errorMessage}
      <p>
        <strong>{errorMessage}</strong>
      </p>
    {/if}
  </div>
  <div class="modal-footer">
    <ButtonCancel text="Close" callback={() => (showPaymentMethods = false)} />
  </div>
</Modal>
