<!--This componenet is formated (using Row & Col) to go ONLY in the Checkout.svelte file.-->
<script>
  export let customDisplayField;
</script>

{#if customDisplayField}
  <div class="row">
    <div class="col">
      <h4>
        {customDisplayField.label}
      </h4>
      <p>
        {customDisplayField.description}
      </p>
    </div>
  </div>
{/if}

<!-- {#if selectedBillingPlan && selectedBillingPlan.billingPlanRules.checkoutFieldDisplay.AfterPickupSchedule}
<div class="row">
  <div class="col">
    <h4>
      {selectedBillingPlan.billingPlanRules.checkoutFieldDisplay
        .AfterPickupSchedule.label}
    </h4>
    <p>
      {selectedBillingPlan.billingPlanRules.checkoutFieldDisplay
        .AfterPickupSchedule.description}
    </p>
  </div>
</div>
{/if} -->
