<script>
  import ButtonAdd from "../../../Lib/Buttons/ButtonAdd.svelte";
  import ButtonConfirmAction from "../../../Lib/Buttons/ButtonConfirmAction.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import { toastNotification } from "../../../Stores/toastNotification";

  let sources = [];

  let urls = {
    sources: `/api/Company/CompanyInfo/sources`,
  };

  function addSource() {
    let maxSource = 0;
    sources.forEach((data) => {
      if (data.sort > maxSource) maxSource = data.sort;
    });

    let s = {
      source: "",
      sort: maxSource + 1,
    };

    sources = [...sources, s];
  }

  function updateSource(source) {
    ApiServer.Post(urls.sources, source).then((data) => {
      console.log(data);
      if (data.success) {
        source = data.data;
      } else {
        toastNotification.Error("Source updated failed: " + data.message);
      }
    });
  }

  function removeSource(source) {
    console.log(source);
    ApiServer.Delete(urls.sources + `?id=${source.id}`).then((data) => {
      if (data.success) {
        var index = sources.indexOf(source);
        if (index >= 0) {
          sources.splice(index, 1);
          sources = sources;
        }
        toastNotification.Success("Source removed!");
      } else {
        toastNotification.Error("Delete failed: " + data.message);
      }
    });
  }

  function getSources() {
    ApiServer.Get(urls.sources).then((data) => {
      sources = data;
    });
  }

  getSources();
</script>

<div class="row">
  <div class="col">
    <h4>Acquisition Sources</h4>
    <p>How customers find you.</p>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="float-end">
      <ButtonAdd text="Add Source" callback={addSource} />
    </div>
    <div style="clear:both" />
    <div>
      <div class="row">
        <div class="col">Action</div>
        <div class="col">Acquisition Name</div>
        <div class="col">Sort</div>
      </div>
      {#each sources as source}
        <div class="row">
          <div class="col-2">
            <ButtonConfirmAction
              text="Remove"
              callback={() => removeSource(source)}
            />
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              on:blur={() => updateSource(source)}
              bind:value={source.source}
            />
          </div>
          <div class="col">
            <input
              type="number"
              class="form-control"
              on:blur={() => updateSource(source)}
              bind:value={source.sort}
            />
          </div>
        </div>
      {/each}
    </div>
  </div>
</div>
