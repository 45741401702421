<script>
  export let amount;
</script>

<span>
  {new Intl.NumberFormat("en-US", {
    style: "currency",
    currencySign: "accounting",
    currency: "USD",
  }).format(amount)}
</span>
