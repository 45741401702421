<script>
  import InputNumberBox from "../../../Lib/Inputs/InputNumberBox.svelte";

  export let rules;
</script>

<h4>Plan Cost</h4>
<p>Set the cost of this plan.</p>
<div class="row">
  <div class="col-sm-6 form-group">
    <InputNumberBox label="Plan Cost" bind:value={rules.binRule.planCharge} />
  </div>
</div>
<div class="row">
  <div class="col-sm-6 form-group">
    <InputNumberBox
      label="Bins included in plan"
      bind:value={rules.binRule.chargeExtraAfter}
    />
  </div>
</div>
<div class="row">
  <div class="col-sm-6 form-group">
    <InputNumberBox
      label="Charge for extra bins"
      bind:value={rules.binRule.extraBinCharge}
    />
  </div>
</div>
