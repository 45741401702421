<script>
  import { user } from "../Stores/stores";
</script>

<footer class="fixed-bottom wab-footer">
  <div>Bin Bird</div>
</footer>

<style>
  .wab-footer {
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f8f9fa;
    height: 50px;
    line-height: 50px; /* same as height! */
  }
</style>
