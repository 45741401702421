<script>
  export let job;
  export let showModal = false;

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import Modal from "sv-bootstrap-modal";
  import { useNavigate } from "svelte-navigator";
  const navigate = useNavigate();

  import ButtonCancel from "../../../../Lib/Buttons/ButtonCancel.svelte";
  import Money from "../../../../Lib/Money.svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import { user } from "../../../../Stores/stores";
  import { toastNotification } from "../../../../Stores/toastNotification";
  import DateFormat from "../../../../Lib/DateFormat.svelte";
  import InputCheckbox from "../../../../Lib/Inputs/InputCheckbox.svelte";

  let urls = {
    accountPayment: "/api/Billing/accountpayment",
    jobPayments: `/api/Job/jobpayments?jobID=`,
  };

  let paymentMessage = "";
  let jobPayments;
  let setPaidNoCharge = false;

  function handleJobPay() {
    let paymentRequest = {
      accountID: job.accountID,
      jobID: job.id,
      userName: $user.fullName,
      setPaidNoCharge: setPaidNoCharge,
    };

    ApiServer.Post(urls.accountPayment, paymentRequest).then((data) => {
      if (data.success) {
        toastNotification.Success("Job successfully paid");
        showModal = false;
        dispatch("afterStatusChange");
      } else {
        toastNotification.Success("Job payment failed. " + data.message);
        paymentMessage = data.message;
      }
    });
  }

  function loadPayments() {
    if (!jobPayments) {
      ApiServer.Get(urls.jobPayments + job.id).then((data) => {
        jobPayments = data;
      });
    }
  }

  $: showModal, loadPayments();
</script>

<Modal bind:open={showModal}>
  <div class="modal-header">
    <h5 class="modal-title">Mark Job {job.jobNumber} Paid</h5>
    <button type="button" class="close" on:click={() => (showModal = false)}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {#if jobPayments && jobPayments.length > 0}
      This customer already has a charge for this job. Charge them again?

      {#each jobPayments as payment}
        <div class="row table-row">
          <div class="col">
            <DateFormat formatType="date-short" date={payment.dateAdded} />
          </div>
          <div class="col">
            <Money amount={payment.amount} />
          </div>
          <div class="col d-none d-md-block">
            {#if payment.cardBrand}
              {payment.cardBrand}
              {payment.cardLast4}
              {payment.cardExpiry}
            {/if}
          </div>
        </div>
      {/each}
    {/if}

    <p>
      This will charge the customer <Money
        amount={job.totalCost + job.totalTax}
      />. Are you sure?
    </p>
    <p>
      <InputCheckbox
        text="Mark as Paid without charging the customer."
        bind:value={setPaidNoCharge}
      />
    </p>

    {#if paymentMessage}
      <p>
        {paymentMessage}
      </p>
    {/if}
  </div>
  <div class="modal-footer">
    <ButtonCancel text="Cancel" callback={() => (showModal = false)} />
    <button
      type="button"
      class="btn btn-outline-primary"
      on:click={handleJobPay}>Mark Job Paid</button
    >
  </div>
</Modal>
