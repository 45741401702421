<script>
  import { link } from "svelte-navigator";
  import MiniMap from "../Map/MiniMap.svelte";
  let stopsToday = "0 stops today";
</script>

<div class="card">
  <div class="card-body">
    <div class="card-title">
      <h3><a href="/map" use:link>{stopsToday}</a></h3>
    </div>
    <div class="card-text">
      <div style="height: 300px;">
        <MiniMap bind:jobCount={stopsToday} />
      </div>
    </div>
  </div>
</div>
