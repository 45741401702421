<script>
  export let requestID;

  import { onMount } from "svelte";
  import InputPassword from "../Lib/Inputs/InputPassword.svelte";
  import ApiServer from "../Server/ApiServer";
  import { toastNotification } from "../Stores/toastNotification";
  import { navigate } from "svelte-navigator";
  import PublicPageHeader from "./PublicPageHeader.svelte";

  let password;
  let confirmPassword;
  let passwordRequest;
  let passwordRequestFail;
  let strongPassword = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );

  let urls = {
    verify: "/api/public/signup/resetuserpassword",
    getRequest: `/api/public/signup/getpasswordreset?passwordResetID=${requestID}`,
  };

  function handlePasswordChange() {
    if (password != confirmPassword) {
      toastNotification.Error("Passwords do not match.");
      return;
    }

    if (!requestID) {
      toastNotification.Error(
        "Password Reset request not found. Please try again."
      );
      return;
    }

    if (!strongPassword.test(password)) {
      toastNotification.Warning(
        "Password not strong enough. Please provide 1 lower case, 1 upper case, one digit, one special character and at least 8 characters long."
      );
      return;
    }

    let passwordReset = {
      password: password,
      resetID: requestID,
    };

    ApiServer.PostAnonymous(urls.verify, passwordReset).then((data) => {
      if (data.success) {
        toastNotification.Success(
          "Password change success. Please login with your email and shiny new password."
        );
        navigate("/");
      } else {
        if (data.message) {
          toastNotification.Error("Password reset failed: " + data.message);
        }
      }
    });
  }

  onMount(async () => {
    ApiServer.GetAnonymous(urls.getRequest).then((data) => {
      if (data.success) {
        passwordRequest = data.data;
      } else {
        passwordRequestFail = {
          message: data.message,
        };
      }
    });
  });
</script>

<svelte:head>
  <title>Bin Bird - Password Reset</title>
</svelte:head>

<main class="container-fluid container-sm body-content">
  <PublicPageHeader />

  {#if passwordRequest}
    <main class="container-fluid container-sm body-content">
      <div class="row">
        <div class="col-lg-6 mx-auto">
          <div class="card">
            <div class="card-body ">
              <h5>Reset your password</h5>
              <div class="card-text">
                <form on:submit|preventDefault={handlePasswordChange}>
                  <div class="row">
                    <div class="col">
                      <InputPassword
                        label="Password"
                        bind:value={password}
                        required={true}
                      />
                    </div>
                    <div class="col">
                      <InputPassword
                        label="Confirm Password"
                        bind:value={confirmPassword}
                        required={true}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <br />
                      <button type="submit" class="btn btn-primary">
                        Reset
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  {/if}

  {#if passwordRequestFail}
    <div class="row">
      <div class="col-lg-6 mx-auto">
        <h1>Bin Bird Password Reset Expired!</h1>
        <div class="card">
          <div class="card-body ">
            <h5>
              It appears your password reset request has expired or was not
              found. Please try again.
            </h5>
            <div class="card-text">
              <p>{passwordRequestFail.message}</p>
              <p>
                Please check your email and other records. It is possible the
                password reset request has expired or you already reset your
                password. Please try again.
              </p>
            </div>
            <div class="row">
              <div class="col">
                <br />
                <a href="/" class="btn btn-danger">Back to Sign In </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  {/if}
</main>
