<script>
  export let account;

  import ButtonSave from "../../../Lib/Buttons/ButtonSave.svelte";
  import DateFormat from "../../../Lib/DateFormat.svelte";
  import SpinnerIcon from "../../../Lib/Icons/SpinnerIcon.svelte";
  import InputTextBox from "../../../Lib/Inputs/InputTextBox.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import { toastNotification } from "../../../Stores/toastNotification";
  import CheckIcon from "../../../lib/Icons/CheckIcon.svelte";

  let urls = {
    findStripeCustomer: "/api/Account/Account/findstripecustomer?email=",
    updateCustomerWithStripe: "/api/Account/Account/connectaccounttostripe",
  };

  let searching = false;
  let customerResult;
  let customStripeCustomerID;

  function updateCustomer(stripeCustomerID) {
    var request = {
      accountID: account.id,
      stripeCustomerID: stripeCustomerID,
    };

    ApiServer.Put(urls.updateCustomerWithStripe, request).then((data) => {
      if (data.success) {
        account.stripeCustomerID = stripeCustomerID;
      } else {
        toastNotification.Error(
          `${account.accountName} not updated: ${data.message}`
        );
        customStripeCustomerID = "";
      }
    });
  }

  function searchStripe() {
    searching = true;
    if (!account.email) {
      searching = false;
      return;
    }
    ApiServer.Get(urls.findStripeCustomer + account.email).then((data) => {
      customerResult = data;
      searching = false;
    });
  }

  $: account, searchStripe();
</script>

<div class="account-card">
  <div class="row">
    <div class="col">
      <h3>
        <a href="/account/{account.id}"
          >{account.firstName} {account.lastName}</a
        >
      </h3>
      {account.email}<br />
      {account.formattedPhoneNumber} <br />
      {account.planName} <br />
      <DateFormat date={account.dateAdded} />
    </div>
    <div class="col">
      {#if searching}
        <div style="font-size: 22px">
          Searching for Stripe Account
          <SpinnerIcon />
        </div>
      {:else if account.stripeCustomerID}
        <div style="font-size: 22px; color: green">
          <CheckIcon /> Accounts connected!
        </div>
      {:else if customerResult && customerResult.length > 0}
        Customers found that match this email!
        <ul>
          {#each customerResult as c}
            <li>
              <a
                href="https://dashboard.stripe.com/customers/{c.id}"
                target="_blank"
              >
                {c.email}</a
              >
              <ButtonSave
                text="Connect"
                callback={() => updateCustomer(c.id)}
              />
            </li>
          {/each}
        </ul>
      {:else}
        <p>No Stripe customers found with {account.email}.</p>
        <p>
          <a
            href="https://dashboard.stripe.com/search?query={account.email}"
            target="_blank">Search your Stripe account by clicking here</a
          >
        </p>
        <p>
          <InputTextBox
            label="Stripe Customer ID"
            bind:value={customStripeCustomerID}
          />
          <ButtonSave
            text="Connect"
            callback={() => updateCustomer(customStripeCustomerID)}
          />
        </p>
      {/if}
    </div>
  </div>
</div>

<style>
  .account-card {
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 5px;
    margin-bottom: 5px;
  }
</style>
