<script>
  export let label;
  export let value;
  export let required = false;
  export let readonly = false;
  export let maxlength = 250;
  export let help = "";

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  function handleOnChange() {
    dispatch("change", value);
  }
</script>

<div class="form-group row">
  <label for={label} class="col-sm-2 col-form-label">{label}</label>

  <div class="col-sm-10">
    <input
      {maxlength}
      type="text"
      class="form-control"
      placeholder={label}
      on:keyup={handleOnChange}
      {readonly}
      bind:value
      {required}
    />
    {#if help}
      <small class="form-text text-muted">{help}</small>
    {/if}
  </div>
</div>
