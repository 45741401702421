<script>
  export let inviteID;

  import { onMount } from "svelte";
  import { useNavigate } from "svelte-navigator";
  import ButtonDelete from "../../../Lib/Buttons/ButtonDelete.svelte";
  import InputTextBox from "../../../Lib/Inputs/InputTextBox.svelte";
  import InputEmail from "../../../Lib/Inputs/InputEmail.svelte";
  import ApiServer from "../../../Server/ApiServer";
  import { toastNotification } from "../../../Stores/toastNotification";
  import Header from "../Header.svelte";

  const navigate = useNavigate();

  let user;
  let userRoles;
  let urls = {
    getInvite: `/api/Company/UserInvite/invite?inviteID=${inviteID}`,
    updateInvite: `/api/Company/UserInvite/invite`,
    createInvite: `/api/Company/UserInvite/invite`,
    deleteInvite: `/api/Company/UserInvite/invite?inviteID=${inviteID}`,
    getUserRoles: `/api/Company/User/userroles`,
  };

  function setUserRoles() {
    ApiServer.Get(urls.getUserRoles).then((data) => {
      userRoles = data;
    });
  }

  function createNewInvite() {
    ApiServer.Post(urls.createInvite, user).then((data) => {
      toastNotification.Success("User Invited!");
      navigate("/company/users");
    });
  }

  function updateInvite() {
    ApiServer.Put(urls.updateInvite, user).then((data) => {
      toastNotification.Success("Invitation saved and resent!");
      navigate("/company/users");
    });
  }

  function saveUser() {
    if (inviteID == "new") {
      createNewInvite();
    } else {
      updateInvite();
    }
  }

  function deleteInvite() {
    ApiServer.Delete(urls.deleteInvite).then((data) => {
      toastNotification.Success("User Uninvited :-(");
      window.history.back();
    });
  }

  onMount(async () => {
    if (inviteID == "new") {
      user = {};
    } else {
      ApiServer.Get(urls.getInvite).then((data) => {
        user = data;
      });
    }
    setUserRoles();
  });
</script>

{#if user}
  <Header text={user.firstName ? user.firstName + " Invite" : "User Detail"} />
  <div class="row">
    <div class="col-sm-12">
      <article class="card-body">
        <form on:submit|preventDefault={saveUser} autocomplete="off">
          <div class="row">
            <div class="col">
              <InputTextBox
                label="First Name"
                bind:value={user.firstName}
                required={true}
              />
            </div>
            <div class="col">
              <InputTextBox
                label="Last Name"
                bind:value={user.lastName}
                required={true}
              />
            </div>
            <div class="col">
              <InputTextBox
                label="Initials"
                bind:value={user.initials}
                maxlength={2}
              />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <InputEmail
                label="Email Address"
                bind:value={user.email}
                required={true}
              />
            </div>
            <div class="col">
              <InputTextBox
                label="Phone Number"
                bind:value={user.phoneNumber}
              />
            </div>
            <div class="col">
              <label for="role">Role</label>
              <select
                id="role"
                required
                class="form-control"
                bind:value={user.role}
              >
                <option value="">Choose...</option>
                {#if userRoles}
                  {#each userRoles as role}
                    <option value={role}>{role}</option>
                  {/each}
                {/if}
              </select>
            </div>
          </div>

          <div class="row" />

          <div class="row button-row">
            <div class="col">
              <button type="submit" class="btn btn-primary"
                >Send Invitation</button
              >
              <ButtonDelete
                text="Uninvite"
                warningText="This will remove the invitation for this user. They will still be able to click the invitation link but they will not be able to create an account."
                callback={deleteInvite}
              />
              <a href="/acceptinvite/{inviteID}">View Invitation</a>
            </div>
          </div>
        </form>
      </article>
    </div>
  </div>
{/if}

<style>
  .button-row {
    margin-top: 10px;
  }
</style>
