import { writable } from "svelte/store";
import PortalUserStore from "./PortalUserStore";

const store = writable(PortalUserStore.getUser() || "");

store.subscribe((val) => {
  PortalUserStore.setUser(val);
});

export const portaluser = writable(null);
