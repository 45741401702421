<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-navigator";

  import ApiServer from "../Server/ApiServer";
  import AccountCard from "../Controls/Accounts/AccountCard.svelte";
  import AccountIcon from "../Lib/Icons/AccountIcon.svelte";
  import DeleteIcon from "../Lib/Icons/DeleteIcon.svelte";
  import SearchIcon from "../Lib/Icons/SearchIcon.svelte";
  import FailedPaymentIcon from "../Lib/Icons/FailedPaymentIcon.svelte";
  import BillingIcon from "../Lib/Icons/BillingIcon.svelte";
  import PendingIcon from "../Lib/Icons/PendingIcon.svelte";
  import AccountDisabledIcon from "../Lib/Icons/AccountDisabledIcon.svelte";
  import AccountPaidCard from "../Controls/Accounts/AccountPaidCard.svelte";
  import AccountPendingCard from "../Controls/Accounts/AccountPendingCard.svelte";
  import AccountActionMenu from "../Controls/Accounts/Account/AccountActionMenu.svelte";
  import PageTitle from "../Lib/PageTitle.svelte";
  import MessengerIcon from "../Lib/Icons/MessengerIcon.svelte";

  let accounts = [];
  let currentPage = 1;
  let itemsPerPage = 10;
  let searchText = "";

  onMount(async () => {
    fetchAccounts();
  });

  let urls = {
    accountList: "/api/Account/Account/accountpage",
    accounts: "/api/Account/Account/accounts",
    indexAccounts: "/api/Account/Account/indexaccounts",
    searchAccounts: "/api/Account/Account/searchaccounts",
  };

  function onSearchKeyDown(element) {
    if (element.charCode === 13) search();
  }

  function clearSearch() {
    currentPage = 1;
    searchText = "";
    fetchAccounts();
  }

  function search() {
    currentPage = 1;
    fetchAccounts();
  }

  function nextPage() {
    if (accounts.length == itemsPerPage) {
      currentPage++;
      fetchAccounts();
    }
  }

  function prevPage() {
    if (currentPage != 1) {
      currentPage--;
      fetchAccounts();
    }
  }

  function indexAccounts() {
    ApiServer.Get(urls.indexAccounts).then((data) => {
      var result = data;
      console.log(result);
    });
  }

  function fetchAccounts() {
    var pageRequest = {
      PageNumber: currentPage,
      ItemsPerPage: itemsPerPage,
      OrderBy: { column: "DateAdded" },
      SearchText: searchText,
      AccountFilter: accountFilter,
    };

    ApiServer.Post(urls.accounts, pageRequest).then((data) => {
      accounts = data;
    });
  }

  function filterChange(type) {
    accountFilter = type;
    currentPage = 1;
    fetchAccounts();
  }

  function navigateToAccount(event) {
    let account = event.detail;
    navigate("account/" + account.id);
  }

  let accountFilter = "Active";
  fetchAccounts();
</script>

<PageTitle title="Accounts" />

<div class="same-line">
  <h3><AccountIcon /> Accounts</h3>
  <button
    class="btn btn-outline-warning"
    on:click={() => navigate("/messenger")}><MessengerIcon /> Messenger</button
  >
</div>

<div class="card">
  <div class="card-body ">
    <div class="card-title" />
    <div class="card-text">
      <div class="row">
        <div class="col-8">
          <input
            type="text"
            on:keypress={onSearchKeyDown}
            bind:value={searchText}
            class="form-control"
          />
        </div>
        <div class="col-4">
          <div class="tools">
            <button
              on:click={() => search()}
              class="btn btn-primary"
              title="Search"><SearchIcon /></button
            >
            <button
              on:click={() => clearSearch()}
              class="btn btn-warning"
              title="clear"><DeleteIcon /></button
            >
            <AccountActionMenu />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="same-line">
            <div id="button-menu">
              <div id="action-menu">
                <span
                  on:click={() => filterChange("Active")}
                  class:selected={accountFilter == "Active"}
                  class="filter"
                >
                  <AccountIcon /> Active
                  <span class="badge bg-info" />
                </span>
                <span
                  on:click={() => filterChange("Pending")}
                  class:selected={accountFilter == "Pending"}
                  class="filter"
                >
                  <PendingIcon /> Pending Job
                  <span class="badge bg-info" />
                </span>
                <span
                  on:click={() => filterChange("Paid")}
                  class:selected={accountFilter == "Paid"}
                  class="filter"
                >
                  <BillingIcon /> Paid
                  <span class="badge bg-info" />
                </span>
                <span
                  on:click={() => filterChange("PastDue")}
                  class:selected={accountFilter == "PastDue"}
                  class="filter"
                >
                  <FailedPaymentIcon /> Past Due
                  <span class="badge bg-info" />
                </span>
                <span
                  on:click={() => filterChange("Inactive")}
                  class:selected={accountFilter == "Inactive"}
                  class="filter"
                >
                  <AccountDisabledIcon /> Inactive
                  <span class="badge bg-info" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {#if accounts}
        <div class="row">
          <div class="col">
            {#if accountFilter == "Active" || accountFilter == "Inactive"}
              {#each accounts as account}
                <AccountCard on:onAccountSelect={navigateToAccount} {account} />
              {/each}
            {/if}

            {#if accountFilter == "Paid" || accountFilter == "PastDue"}
              {#each accounts as account}
                <AccountPaidCard {account} />
              {/each}
            {/if}

            {#if accountFilter == "Pending"}
              {#each accounts as account}
                <AccountPendingCard {account} />
              {/each}
            {/if}
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div>
              Page: {currentPage}. Records: {accounts.length}
            </div>

            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item">
                  <button
                    class="page-link"
                    aria-label="Previous"
                    on:click={prevPage}
                  >
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">Previous</span>
                  </button>
                </li>
                <li class="page-item">
                  <button
                    class="page-link"
                    on:click={nextPage}
                    aria-label="Next"
                  >
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">Next</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>

<style>
  .tools {
    display: -webkit-flex;
    display: flex;
    justify-content: flex-end;
  }
</style>
