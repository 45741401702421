<script>
  export let job;
  export let accountID;

  import Datepicker from "svelte-calendar";
  import { useNavigate } from "svelte-navigator";

  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();

  import ApiServer from "../../../../Server/ApiServer";
  import { user } from "../../../../Stores/stores";
  import JobPaymentStatus from "./ControlJobPaymentStatus.svelte";
  import ControlStartTime from "./ControlStartTime.svelte";
  import ControlDuration from "./ControlDuration.svelte";
  import UTCDate from "../../../../Util/UTCDate";
  import BillingPlanSelector from "../../../Company/BillingPlans/BillingPlanSelector.svelte";
  import InputCheckbox from "../../../../Lib/Inputs/InputCheckbox.svelte";
  import ButtonDelete from "../../../../Lib/Buttons/ButtonDelete.svelte";
  import { toastNotification } from "../../../../Stores/toastNotification";
  import SaveIcon from "../../../../Lib/Icons/SaveIcon.svelte";
  import JobIcon from "../../../../Lib/Icons/JobIcon.svelte";
  import VendorTimeRestriction from "../../Account/VendorTimeRestriction.svelte";
  import CancelIcon from "../../../../Lib/Icons/CancelIcon.svelte";
  import BillingIcon from "../../../../Lib/Icons/BillingIcon.svelte";

  import ActionStatusCancel from "./_ActionStatusCancel.svelte";
  import ActionStatusReopen from "./_ActionStatusReopen.svelte";
  import ActionStatusClose from "./_ActionStatusClose.svelte";
  import ActionPayJob from "./_ActionPayJob.svelte";
  import ButtonAction from "../../../../Lib/Buttons/ButtonAction.svelte";
  import ActionPayCancelPayment from "./_ActionPayCancelPayment.svelte";
  import ActionPayFailed from "./_ActionPayFailed.svelte";
  import PendingIcon from "../../../../Lib/Icons/PendingIcon.svelte";
  import Account from "../../Account/Account.svelte";
  import OrderSummary from "../../../Checkout/OrderSummary.svelte";
  const navigate = useNavigate();

  let showCloseJobModal = false;
  let showReopenJobModal = false;
  let showCancelJobModal = false;
  let showMarkJobPaidModal = false;
  let showPaymentCancelModal = false;
  let showPaymentFailModal = false;
  let editable = true;
  let jobTotal;
  let jobTax;
  let jobBins;

  var jobDateFormatted;
  var jobDate;
  let urls = {
    saveJob: `/api/Job/job`,
    deleteJob: `/api/Job/job?jobID=${job.id}`,
    setJobPending: `/api/Job/setpending`,
  };

  function handleSetJobNotPaid() {
    console.log("set to not paid not handled yet.");
  }

  function handleStatusChange() {
    //Instead of redirecting the user away from this page we are just going to load the job again.
    //NOTE: When calling this it only reloads the job, not the billing plan template with line items.
    //dispatch("refreshjob");

    //Hoping this will refresh the job and billingplan data when a change is made.
    //NOTE: Nope did not work...
    //action = "job";

    //Dirty Dirty hack to get Page to reload the componenets...I hate it.
    location.reload();
  }

  function handleLineItemChange(event) {
    var lineItemChange = event.detail;
    //calculate tax rate...
    if (job.totalTax != 0) {
      var taxRate = job.totalTax / job.totalCost;
      jobTax = lineItemChange.total * taxRate;
    }
    jobTotal = lineItemChange.total;
    jobBins = lineItemChange.bins;
  }

  function setJobPending() {
    let request = {
      jobID: job.id,
      userName: $user.fullName,
    };

    ApiServer.Post(urls.setJobPending, request).then((data) => {
      toastNotification.Success("Job set to pending");
      handleStatusChange();
    });
  }

  function handleJobSave() {
    //The set hours is weird and will be overwritten by the server to the correct time. Without this the weird svelte-calendar will try to automatically convert the time and it messes things up.
    job.jobDate = UTCDate.AddHours(9, jobDate);
    job.lineItems = job.billingPlan.lineItems;
    job.totalCost = job.billingPlan.cost;

    if (job.id == "new") {
      delete job.id;
      job.accountID = accountID;
      ApiServer.Post(urls.saveJob, job).then((data) => {
        toastNotification.Success("Job Added!");
        navigate(`/account/${data.accountID}/jobs/`);
      });
    } else {
      job.modified = true;
      ApiServer.Put(urls.saveJob, job).then((data) => {
        toastNotification.Success("Job Saved!");
        navigate(`/account/${job.accountID}/jobs/`);
      });
    }
  }

  function deleteJob() {
    ApiServer.Delete(urls.deleteJob).then((job) => {
      toastNotification.Success("Job Deleted");
      navigate(`/account/${accountID}/jobs`);
    });
  }

  editable = job.status != "Complete";

  jobDate = UTCDate.Parse(job.jobDate);

  onMount(async () => {
    jobTotal = job.totalCost;
    jobTax = job.totalTax;
    jobBins = job.bins;
  });
</script>

{#if job.id != "new"}
  <div class="row">
    <div class="col">
      <div id="button-menu">
        <div id="action-buttons">
          {#if job.status == "Scheduled"}
            <ButtonAction
              text="Close Job"
              icon={JobIcon}
              callback={() => (showCloseJobModal = true)}
            />
            <ButtonAction
              text="Cancel Job"
              icon={CancelIcon}
              callback={() => (showCancelJobModal = true)}
            />
            <ButtonAction
              text="Set Pending"
              icon={PendingIcon}
              callback={setJobPending}
            />
          {:else}
            <ButtonAction
              text="Reopen Job"
              callback={() => (showReopenJobModal = true)}
              icon={JobIcon}
            />
          {/if}

          {#if job.paymentStatus == "Paid"}
            <ButtonAction
              text="Mark Failed"
              callback={() => (showPaymentFailModal = true)}
              icon={BillingIcon}
            />

            <ButtonAction
              text="Cancel Payment"
              callback={() => (showPaymentCancelModal = true)}
              icon={BillingIcon}
            />
          {/if}

          {#if job.paymentStatus == "Not Paid"}
            <ButtonAction
              text="Mark Paid"
              callback={() => (showMarkJobPaidModal = true)}
              icon={BillingIcon}
            />

            <ButtonAction
              text="Mark Failed"
              callback={() => (showPaymentFailModal = true)}
              icon={BillingIcon}
            />
          {/if}

          {#if job.paymentStatus == "Payment Failed"}
            <ButtonAction
              text="Mark Paid"
              callback={() => (showMarkJobPaidModal = true)}
              icon={BillingIcon}
            />

            <ButtonAction
              text="Cancel Payment"
              callback={() => (showPaymentCancelModal = true)}
              icon={BillingIcon}
            />
          {/if}
        </div>
      </div>
    </div>
  </div>
{/if}

{#if job.modified}
  <div class="row">
    <div class="col">
      <p>* Job edited and will not be affected by template changes.</p>
    </div>
  </div>
{/if}

<form on:submit|preventDefault={handleJobSave}>
  <div class="row">
    <div class="col">
      <h4>Detail</h4>

      <div class="row">
        <div class="col-md-4">
          <label class="form-label" for="job.jobDate">Job Date</label>
          <br />
          <Datepicker
            bind:selected={jobDate}
            bind:formattedSelected={jobDateFormatted}
          >
            <button
              type="button"
              id="job.jobDate"
              class="btn btn-sm btn-primary"
            >
              {jobDateFormatted}
            </button>
          </Datepicker>
        </div>
        <div class="col-md-4">
          <InputCheckbox
            text="First Time Cleaning"
            bind:value={job.firstTime}
            label="This customers first job"
          />
        </div>
        {#if job.id != "new"}
          <div class="col-md-4">
            Job Address: {job.streetAddress + " " + job.cityStateZip}
          </div>
        {/if}
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="form-label" for="job.startTime">Start Time</label>
          <ControlStartTime bind:value={job.startTime} id="job.startTime" />
        </div>

        <div class="col-md-6">
          <label class="form-label" for="job.duration">Duration</label>
          <ControlDuration bind:value={job.duration} id="job.duration" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <VendorTimeRestriction vtr={job.vendorTimeRestriction} />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="form-label" for="job.jobNote">Job Note</label>
          <textarea
            id="job.jobNote"
            class="form-control"
            bind:value={job.jobNote}
            rows="2"
          />
        </div>

        <div class="col-md-6">
          <label class="form-label" for="job.adminNote"
            >Admin Note <small>(Only seen by admins)</small></label
          >
          <textarea
            id="job.adminNote"
            class="form-control"
            bind:value={job.adminNote}
            rows="2"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <BillingPlanSelector
        on:lineItemChange={handleLineItemChange}
        isJob={true}
        bind:templateOrJob={job}
      />
    </div>
  </div>

  <div class="row">
    <div class="col">
      <OrderSummary
        planName={job.billingPlanName}
        totalCost={jobTotal}
        totalTax={jobTax}
        totalBins={jobBins}
      />
    </div>
  </div>

  <div class="col-sm-12">
    <button type="submit" class="btn btn-outline-primary">
      <SaveIcon /> Save
    </button>

    {#if job.id != "new"}
      <ButtonDelete
        text="Delete"
        warningText="This will delete everything related to this job. The billing history, notes, images will be deleted FOREVER. Are you sure?"
        callback={deleteJob}
      />
    {/if}
  </div>
</form>

<ActionStatusClose
  {job}
  on:afterStatusChange={handleStatusChange}
  bind:showModal={showCloseJobModal}
/>

<ActionStatusReopen
  {job}
  on:afterStatusChange={handleStatusChange}
  bind:showModal={showReopenJobModal}
/>

<ActionStatusCancel
  {job}
  on:afterStatusChange={handleStatusChange}
  bind:showModal={showCancelJobModal}
/>

<ActionPayJob
  {job}
  bind:showModal={showMarkJobPaidModal}
  on:afterStatusChange={handleStatusChange}
/>

<ActionPayCancelPayment
  {job}
  bind:showModal={showPaymentCancelModal}
  on:afterStatusChange={handleStatusChange}
/>

<ActionPayFailed
  {job}
  bind:showModal={showPaymentFailModal}
  on:afterStatusChange={handleStatusChange}
/>

<style>
  #action-buttons {
    margin-bottom: 5px;
  }
</style>
