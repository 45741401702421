<script>
  export let password;
  export let label;
  export let value;
  export let required = false;

  import { passwordStrength } from "check-password-strength";
  import { Badge, Popover } from "sveltestrap";
  import { createEventDispatcher } from "svelte";

  import CheckIcon from "../../lib/Icons/CheckIcon.svelte";
  import CloseIcon from "../../lib/Icons/CloseIcon.svelte";

  const dispatch = createEventDispatcher();

  function handleOnChange() {
    var strengthIndicator = {
      strength: strength.id,
      password: value,
    };
    dispatch("change", strengthIndicator);
  }
  $: strength = passwordStrength(password);
</script>

<label for={label}>{label}</label>
<input
  id="main-password"
  type="password"
  class="form-control"
  on:keyup={handleOnChange}
  bind:value
  {required}
/>

<Popover trigger="focus" target="main-password">
  <div slot="title">
    <b>Password Requirements</b>
  </div>

  <h3>
    Strength
    <Badge color="info">{strength.value}</Badge>
  </h3>

  <p>
    <span class="text-muted small"
      >Please include at least 1 of the following.</span
    >
  </p>
  <ul>
    <li class:valid={strength.contains.includes("lowercase")}>
      Lowercase
      {#if strength.contains.includes("lowercase")}
        <CheckIcon />
      {:else}
        <CloseIcon />
      {/if}
    </li>
    <li class:valid={strength.contains.includes("uppercase")}>
      Uppercase
      {#if strength.contains.includes("uppercase")}
        <CheckIcon />
      {:else}
        <CloseIcon />
      {/if}
    </li>
    <li class:valid={strength.contains.includes("number")}>
      Number
      {#if strength.contains.includes("number")}
        <CheckIcon />
      {:else}
        <CloseIcon />
      {/if}
    </li>
    <li class:valid={strength.contains.includes("symbol")}>
      Symbol
      {#if strength.contains.includes("symbol")}
        <CheckIcon />
      {:else}
        <CloseIcon />
      {/if}
    </li>
    <li class:valid={strength.length >= 6}>
      6 Characters
      {#if strength.length >= 6}
        <CheckIcon />
      {:else}
        <CloseIcon />
      {/if}
    </li>
  </ul>
</Popover>

<style>
  ul {
    padding: 0;
    list-style-type: none;
  }
  li.valid {
    color: green;
  }
</style>
