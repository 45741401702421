<script>
  export let text;
  export let callback;
  export let size = "normal";

  const className =
    size == "normal"
      ? "btn btn-outline-primary"
      : "btn btn-sm btn-outline-primary";

  import AddIcon from "../Icons/AddIcon.svelte";
</script>

<button type="button" class={className} on:click={callback}>
  <AddIcon />
  {text}
  <slot />
</button>
