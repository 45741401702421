<script>
  export let text;
  export let label = "";
  export let value;

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
</script>

{#if label != ""}<label for={text}>{label}</label> {/if}
<div class="form-check form-switch">
  <slot />
  <input
    class="form-check-input"
    type="checkbox"
    id={text}
    checked={value}
    on:change={(e) => {
      value = e.target.checked;
      dispatch("valueChange");
    }}
  />
  <label class="form-check-label" for={text}>
    {text}
  </label>
</div>
