<script>
  export let rules;

  import Checkbox from "../../../Lib/Inputs/InputCheckbox.svelte";
</script>

<div class="row">
  <div class="col">
    <h4>Reschedule Rule</h4>
    <p>
      How do you want to handle jobs left open at the end of the day? If this is
      not enabled jobs left "Scheduled" at the end of the day will remain
      "scheduled".
    </p>
  </div>
  <div class="col">
    <Checkbox
      text="Enabled"
      label="Enable this reschedule rule"
      bind:value={rules.rescheduleRule.enabled}
    />
  </div>
</div>

{#if rules.rescheduleRule.enabled}
  <div class="row">
    <div class="col-sm-6 form-group">
      <label for="rule">Reschedule Rule</label>
      <select
        class="form-control"
        required
        name="rule"
        bind:value={rules.rescheduleRule.rule}
      >
        <option value="">--Select a Reschedule Rule--</option>
        <option value="1">Move to the next day schedule</option>
        <option value="2">Move to the next Trash day schedule</option>
        <option value="3">Set as unscheduled and move to Pending</option>
      </select>
    </div>
  </div>
{/if}
