<script>
  import CheckIcon from "../../../Lib/Icons/CheckIcon.svelte";

  export let jobList;
  export let jobFilter;
  import JobIcon from "../../../Lib/Icons/JobIcon.svelte";
  import ScheduledJobIcon from "../../../Lib/Icons/ScheduledJobIcon.svelte";

  function applyFilter(f) {
    jobFilter = f;
  }
</script>

<div class="same-line">
  <div id="button-menu">
    <div id="action-menu">
      <span
        on:click={() => applyFilter("All")}
        class:selected={jobFilter == "All"}
        class="filter"
      >
        <JobIcon /> All
        <span class="badge bg-info"> {jobList.allCount} </span>
      </span>
      <span
        on:click={() => applyFilter("Scheduled")}
        class:selected={jobFilter == "Scheduled"}
        class="filter"
      >
        <ScheduledJobIcon /> Scheduled
        <span class="badge bg-info"> {jobList.scheduledCount} </span>
      </span>
      <span
        on:click={() => applyFilter("Complete")}
        class:selected={jobFilter == "Complete"}
        class="filter"
      >
        <CheckIcon /> Complete
        <span class="badge bg-info"> {jobList.completeCount} </span>
      </span>
    </div>
  </div>
</div>
