<script>
  export let account;

  import { onMount } from "svelte";
  import ButtonAdd from "../../../../Lib/Buttons/ButtonAdd.svelte";
  import ContactCustomer from "../_ContactCustomer.svelte";
  import ApiServer from "../../../../Server/ApiServer";
  import MessageCard from "./MessageCard.svelte";

  let contactActionsOpen = false;
  let messages = [];

  let urls = {
    allMessages: `/api/Account/Account/communication?accountID=${account.id}`,
  };

  const toggleContactActions = () => (contactActionsOpen = !contactActionsOpen);

  function fetchMessages() {
    messages = [];
    ApiServer.Get(urls.allMessages).then((data) => {
      messages = data;
    });
  }

  onMount(async () => {
    fetchMessages();
  });
</script>

<div class="card">
  <div class="card-body ">
    <div class="card-title d-flex justify-content-between align-items-center">
      <h5>{account.fullName} Messages - {messages.length}</h5>
      <div class="large-icon">
        <ButtonAdd text="Send Message" callback={toggleContactActions} />
      </div>
    </div>
    <div class="card-text">
      {#if messages}
        {#each messages as message}
          <MessageCard {message} />
        {/each}
      {/if}
    </div>
  </div>
</div>

<ContactCustomer
  {account}
  bind:contactActionsOpen
  on:messageSent={fetchMessages}
/>
