<script>
  export let size;
  export let img;
  export let index;

  import ImageAction from "./ImageAction.svelte";

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  function handleImageDelete(event) {
    var img = event.detail;
    dispatch("imageDeleted", img);
  }

  function onImageClick(index) {
    dispatch("imageClicked", index);
  }
</script>

<ImageAction on:imageDeleted={handleImageDelete} {img}>
  <img
    class="job-image"
    style="height:{size.h}px; width:{size.w}px"
    src={img.sourceURL}
    alt=""
    on:click={() => onImageClick(index)}
  />
</ImageAction>

<style>
  .job-image {
    object-fit: cover;
  }
  img {
    width: 100%;
    height: auto !important;
    cursor: pointer;
    border-radius: 10px;
  }

  img:hover {
    opacity: 0.5;
    filter: grayscale(0.5) blur(1px);
  }
</style>
