<script>
  export let message;
  export let initial;
  export let left = true;
  import DateFormat from "../../Lib/DateFormat.svelte";
  import MessageDetailModal from "./MessageDetailModal.svelte";
  import ButtonLink from "../../Lib/Buttons/ButtonLink.svelte";

  let showDetailModal = false;
  let selectedMessage;
  function showMessageDetailModal(message) {
    selectedMessage = message;
    showDetailModal = true;
  }
</script>

{#if left}
  <div class="row message-card">
    <div class="col-sm-1">
      <div class="message-sender">
        <div class="circle">{initial}</div>
      </div>
    </div>
    <div class="col-sm-11">
      <div class="message-body">
        {message.body}
      </div>
    </div>
    <div class="row">
      <div class="col">
        <hr />
        <div class="message-date-right">
          <ButtonLink
            text="Detail"
            callback={() => showMessageDetailModal(message)}
          />
        </div>
        <div class="message-date-left">
          <DateFormat date={message.dateAdded} formatType="date-time-short" />
          <DateFormat date={message.dateAdded} formatType="timeago" />
        </div>

        <div style="clear:both" />
      </div>
    </div>
  </div>
{:else}
  <div class="row message-card-right">
    <div class="col-sm-11">
      <div class="message-body">
        {#if message.messageType == "EMAIL"}
          {message.subject}
        {:else}
          {message.body}
        {/if}
      </div>
    </div>
    <div class="col-sm-1">
      <div class="message-sender">
        <!-- <div class="circle">{initial}</div> -->
      </div>
    </div>

    <div class="row">
      <div class="col">
        <hr />
        <div class="message-date-right">
          <ButtonLink
            text="Detail"
            callback={() => showMessageDetailModal(message)}
          />
        </div>
        <div class="message-date-left">
          <DateFormat date={message.dateAdded} formatType="date-time-short" />
          <DateFormat date={message.dateAdded} formatType="timeago" />
        </div>
        <div style="clear:both" />
      </div>
    </div>
  </div>
{/if}

<MessageDetailModal
  bind:message={selectedMessage}
  bind:show={showDetailModal}
/>

<style>
  .message-card-right {
    background-color: #38bb3c;
    color: #ffffff;
    border-radius: 5px;
    margin: 10px;
    padding: 5px;
  }

  .message-date-left {
    font-size: 12px;
    float: right;
  }

  .message-date-right {
    font-size: 12px;
    float: left;
  }

  .message-body {
    margin-left: 10px;
  }

  .message-card {
    background-color: #f6f9fb;
    color: #676778;
    border-radius: 5px;
    margin: 10px;
    padding: 5px;
  }

  .circle {
    width: 32px;
    line-height: 32px;
    border-radius: 50%;
    text-align: center;
    font-size: 28px;
    background-color: #325d88;
    color: #ffffff;
  }
</style>
