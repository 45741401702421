<script>
  import ScheduleDay from "../Controls/Schedule/_ScheduleDay.svelte";
  //import ButtonLink from "../lib/Buttons/ButtonLink.svelte";

  let isEditMode = false;

  function toggleEditMode() {
    isEditMode = !isEditMode;
  }
</script>

<!-- <div class="row">
  <div class="col">
    <h3>Schedule for Week of Jan 2</h3>
    <ButtonLink text="Edit Schedule" callback={toggleEditMode} />
  </div>
</div> -->

<div class="row">
  <ScheduleDay
    bind:editMode={isEditMode}
    day="Sunday"
    date="2 Jan"
    schedule={[
      { name: "Kvon", time: "Off" },
      { name: "Chris", time: "Off" },
      { name: "Brian", time: "Off" },
    ]}
  />
  <ScheduleDay
    bind:editMode={isEditMode}
    day="Monday"
    date="3 Jan"
    schedule={[
      { name: "Kvon", time: "Off" },
      { name: "Chris", time: "1:00 PM" },
      { name: "Brian", time: "Off" },
    ]}
  />
  <ScheduleDay
    bind:editMode={isEditMode}
    day="Tuesday"
    date="4 Jan"
    schedule={[
      { name: "Kvon", time: "2:30 PM" },
      { name: "Chris", time: "Off" },
      { name: "Brian", time: "Off" },
    ]}
  />
  <ScheduleDay
    bind:editMode={isEditMode}
    day="Wednesday"
    date="5 Jan"
    schedule={[
      { name: "Kvon", time: "2:30 PM" },
      { name: "Chris", time: "2:30 PM" },
      { name: "Brian", time: "2:30 PM" },
    ]}
  />
  <ScheduleDay
    bind:editMode={isEditMode}
    day="Thursday"
    date="6 Jan"
    schedule={[
      { name: "Kvon", time: "2:30 PM" },
      { name: "Chris", time: "2:30 PM" },
      { name: "Brian", time: "2:30 PM" },
    ]}
  />
  <ScheduleDay
    bind:editMode={isEditMode}
    day="Friday"
    date="7 Jan"
    schedule={[
      { name: "Kvon", time: "2:30 PM" },
      { name: "Chris", time: "2:30 PM" },
      { name: "Brian", time: "2:30 PM" },
    ]}
  />

  <ScheduleDay
    bind:editMode={isEditMode}
    day="Saturday"
    date="8 Jan"
    schedule={[
      { name: "Kvon", time: "2:30 PM" },
      { name: "Chris", time: "2:30 PM" },
      { name: "Brian", time: "2:30 PM" },
    ]}
  />
</div>

<div class="row">
  <div class="col">
    <ul>
      <li>Kvon: 6 Shifts</li>
      <li>Chris: 3 Shifts</li>
      <li>Brian: 12 Shifts</li>
    </ul>
  </div>
</div>

<style>
  ul {
    list-style-type: none;
    padding-left: 2px;
  }
</style>
