<script>
  export let text;
  export let callback;
  export let icon = null;
</script>

<button
  type="button"
  class="btn btn-sm btn-outline-primary"
  on:click={callback}
>
  <div class="large-icon">
    <svelte:component this={icon} />
  </div>
  {text}
</button>

<style>
  .large-icon {
    font-size: 20px;
  }
  .btn {
    font-size: 11px;
  }
</style>
