export default class UserSettings {
  static getSettings() {
    var settings = localStorage.getItem("settings");

    if (!settings) {
      settings = {
        showClosedJobs: false,
      };
    } else {
      settings = JSON.parse(settings);
    }

    return settings;
  }

  static setShowClosedJobs(value) {
    let settings = this.getSettings();
    settings.showClosedJobs = value;
    this.saveSettings(settings);
  }

  static saveSettings(settings) {
    if (!settings) return;

    settings = JSON.stringify(settings);
    localStorage.setItem("settings", settings);
  }
}
